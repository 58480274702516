import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { StyleProps } from "../../types";

const styles = () => ({});

export type UploadFailure = {
  badRowCount: number;
  errors: string[];
  totalRowCount: number;
};

export type UploadSuccess = {
  deleteCount?: number;
  updateCount: number;
};

export type UploadSummary = {
  failure?: UploadFailure;
  success?: UploadSuccess;
};

type Props = StyleProps & {
  itemTitleText: string;
  itemPluralText: string;
  uploadSummary: UploadSummary;
};

class ProductUploadSummaryText extends React.PureComponent<Props> {
  downloadLog(filename: string) {
    const {
      uploadSummary: { failure },
    } = this.props;
    const { body } = window.document;
    if (failure && body) {
      const text = failure.errors.join(`\n`);
      const blob = new Blob([text], { type: `text/plain` });
      const linkEl = window.document.createElement(`a`);
      linkEl.href = window.URL.createObjectURL(blob);
      linkEl.download = filename;
      body.appendChild(linkEl);
      linkEl.click();
      body.removeChild(linkEl);
    }
  }

  render() {
    const {
      uploadSummary: { success, failure },
      itemTitleText,
      itemPluralText,
      ...rest
    } = this.props;

    return (
      <div {...rest}>
        {success && (
          <React.Fragment>
            <Typography>
              Saved {success.updateCount} {itemPluralText}
            </Typography>
            {success.deleteCount != null && (
              <Typography>
                Deleted {success.deleteCount} {itemPluralText}
              </Typography>
            )}
          </React.Fragment>
        )}
        {failure && (
          <React.Fragment>
            <Typography>
              There is an issue with {failure.badRowCount} rows out of{" "}
              {failure.totalRowCount}
            </Typography>
            <Button
              onClick={() =>
                this.downloadLog(`${itemTitleText} upload log.txt`)
              }
            >
              Download log
            </Button>
            <List>
              {failure.errors.map((error, index) => (
                <ListItem key={index}>
                  <ListItemText>{error}</ListItemText>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProductUploadSummaryText);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { OrderStep } from "../../../helpers/trading/orderStep";

const styles = (theme) => ({
  root: {
    alignSelf: `stretch`,
    marginBottom: theme.spacing(1),
  },
});

type Props = StyleProps & {
  step: number;
};

class OrderStepper extends React.PureComponent<Props> {
  render() {
    const { classes, step } = this.props;

    const stepList: OrderStep[] = OrderStep.orderStepList;

    // If we are on the last step, add one so the confirmed screen shows a tick
    const currentStep: number = step < stepList.length - 1 ? step : step + 1;

    return (
      <div className={classes.root}>
        <Stepper activeStep={currentStep}>
          {stepList.map((step) => (
            <Step key={step.id}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

export default withStyles(styles)(OrderStepper);

import * as React from "react";
import gql from "graphql-tag";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import GridContainer from "../../generic/grid/GridContainer";
import DetailGridItem from "../../generic/grid/DetailGridItem";
import { ShippingBatchDetailsShippingBatch } from "./__generated__/ShippingBatchDetailsShippingBatch";

export const shippingBatchDetailsFragments = {
  shippingBatch: gql`
    fragment ShippingBatchDetailsShippingBatch on ShippingBatch {
      id
      origin {
        id
        name
      }
      destination {
        id
        ...GetAddressStringAddress
      }
    }
    ${addressFragment}
  `,
};

type Props = {
  className?: string;
  shippingBatch?: ShippingBatchDetailsShippingBatch;
};

const ShippingBatchDetails = (props: Props) => {
  const { className, shippingBatch } = props;

  return (
    <GridContainer className={className}>
      <DetailGridItem
        title="From"
        value={
          shippingBatch != null && shippingBatch.origin != null
            ? shippingBatch.origin.name
            : ``
        }
      />

      <DetailGridItem
        title="To"
        value={
          shippingBatch != null && shippingBatch.destination != null
            ? getAddressString(shippingBatch.destination)
            : ``
        }
      />
    </GridContainer>
  );
};

export default ShippingBatchDetails;

import * as React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { amber } from "@material-ui/core/colors";

const INDECO_BLUE_LIGHT = "#55798a";
const INDECO_BLUE = "#003650";
const INDECO_GREEN = "#43b552";
const INDECO_RED = "#f23535";

const FEATURE_GREY = "#f0f0f0";

const theme = createMuiTheme({
  // @ts-ignore
  customMixins: {
    centerChildrenInParent: {
      alignItems: `center`,
      bottom: 0,
      display: `flex`,
      justifyContent: `center`,
      left: 0,
      position: `absolute`,
      right: 0,
      top: 0,
    },
  },
  customPalette: {
    severe: INDECO_RED,
  },
  palette: {
    primary: {
      main: INDECO_BLUE,
    },
    secondary: {
      main: INDECO_RED,
    },
  },
  dimensions: {
    drawerWidth: 240,
    snackbarHeight: 60,
    staticDialogHeight: 400,
    textFieldProgressSize: 25,
    textFieldWidth: 250,
  },
  margins: {
    paper: 16,
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingLeft: `16px`,
        paddingRight: `16px`,
      },
      stickyHeader: {
        backgroundColor: FEATURE_GREY,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: FEATURE_GREY,
        color: INDECO_BLUE,
        boxShadow: "inset -4px 0 8px -4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  statusPalette: {
    error: INDECO_RED,
    info: INDECO_BLUE_LIGHT,
    success: INDECO_GREEN,
    warning: amber[700],
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export const CustomThemeProvider = ({ children }: any) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

import * as React from "react";
import * as yup from "yup";
import EditProductTable from "./EditProductTable";
import {
  formatCurrency,
  parseCurrency,
} from "../../../../helpers/formatCurrency";
import { CatalogueViewEditProductTableIncludesProduct as IncludesProduct } from "./__generated__/CatalogueViewEditProductTableIncludesProduct";
import createAutosaveListForm from "../../generic/AutosaveListForm";

const AutosaveListForm = createAutosaveListForm({
  itemIDAccessor: `Product.id`,
  itemSchema: yup.object().shape({
    price: yup
      .number()
      .typeError(`Price must be a number`)
      .nullable()
      .required()
      .positive()
      .label(`Price`),
  }),
  getValuesForItem: (item: IncludesProduct) => ({
    price: item.price != null ? formatCurrency(item.price) : null,
  }),
});

export type UpdateCatalogueViewIncludesProductFn = (values: {
  price?: number | null | undefined;
  productID: string;
}) => Promise<void> | void;

export type Props = {
  items: IncludesProduct[];
  loading?: boolean;
  onSelectedIDsChange: (selectedIDs: string[]) => void;
  selectedIDs: string[];
  tableProps?: any;
  updateCatalogueViewIncludesProduct: UpdateCatalogueViewIncludesProductFn;
};

/**
 * Component to manage fields for the properties of relationships between a catalogue view and product.
 */
class IncludesProductsForm extends React.PureComponent<Props> {
  updateItem(itemID: string, fieldName: string, value: string) {
    const { updateCatalogueViewIncludesProduct } = this.props;

    const valueToStore =
      fieldName === `price` ? parseCurrency(value) : Number(value);

    if (valueToStore != null && !isNaN(valueToStore)) {
      return updateCatalogueViewIncludesProduct({
        [fieldName]: valueToStore,
        productID: itemID,
      });
    }

    return Promise.reject(new Error(`Failed to parse field value`));
  }

  render() {
    const {
      items,
      loading,
      onSelectedIDsChange,
      selectedIDs,
      tableProps,
      ...otherProps
    } = this.props;

    return (
      // @ts-ignore
      <AutosaveListForm
        key={String(items.length)} // Reset state when items are added or removed
        items={items}
        itemName="product"
        updateItem={this.updateItem.bind(this)}
        {...otherProps}
      >
        {/* @ts-ignore */}
        {({ getAutosaveListFieldProps }) => (
          <EditProductTable
            data={items}
            loading={loading}
            selectedIDs={selectedIDs}
            getFieldProps={getAutosaveListFieldProps}
            onSelectedIDsChange={onSelectedIDsChange}
            {...tableProps}
          />
        )}
      </AutosaveListForm>
    );
  }
}

export default IncludesProductsForm;

import MarketplaceLayout from "./MarketplaceLayout";
import gql from "graphql-tag";
import { compose, withState } from "recompose";
import { activeMarketplaceListFragments } from "./ActiveMarketplaceList";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { MarketplaceCurationGetIndustry_industry_marketplaceList as MarketplaceInList } from "./__generated__/MarketplaceCurationGetIndustry";
import { MarketplaceCurationGetMarketplace_marketplace as Marketplace } from "./__generated__/MarketplaceCurationGetMarketplace";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";

export const GET_ACTIVE_MARKETPLACES = gql`
  query MarketplaceCurationGetIndustry($industryID: String!) {
    industry: getIndustry(id: $industryID) {
      id
      marketplaceList {
        ...ActiveMarketplaceListMarketplace
      }
    }
  }
  ${activeMarketplaceListFragments.marketplace}
`;

const GET_MARKETPLACE = gql`
  query MarketplaceCurationGetMarketplace($marketplaceID: String!) {
    marketplace: getMarketplace(id: $marketplaceID) {
      ...ActiveMarketplaceListMarketplace
    }
  }
  ${activeMarketplaceListFragments.marketplace}
`;

export const DELETE_ACTIVE_MARKETPLACE = gql`
  mutation MarketplaceCurationDeleteMarketplace($marketplaceID: String!) {
    marketplace: deleteMarketplace(id: $marketplaceID) {
      id
      name
    }
  }
`;

export type EnhancedProps = {
  industryID?: string;
  onIndustryIDChange: (id: string) => void;
  onPressCategories: (marketplaceID: string) => void;
  onPressEdit: (marketplaceID: string) => void;
};

export type ProvidedProps = EnhancedProps & {
  marketplace: Marketplace | null | undefined;
  loadingMarketplace: boolean;
  marketplaces: MarketplaceInList[] | null;
  loadingMarketplaces: boolean;
  deletingMarketplaceID: string;
  deleteMarketplace: (marketplaceID: string) => Promise<unknown>;
  setDeletingMarketplaceID: (marketplaceID: string | typeof undefined) => void;
};

const enhancer = compose<Partial<ProvidedProps>, EnhancedProps>(
  withQuery(GET_ACTIVE_MARKETPLACES, {
    dataPaths: {
      marketplaces: `industry.marketplaceList`,
    },
    loadingProp: `loadingMarketplaces`,
    errorMessage: `Failed to load marketplaces`,
    skip: ({ industryID }) => industryID == null,
  }),
  withMutation(DELETE_ACTIVE_MARKETPLACE, {
    errorMessage: `Failed to delete marketplace`,
    mapMutationToProps: (deleteMarketplace) => ({ deleteMarketplace }),
    mutationOptions: ({ industryID }, [marketplaceID]) => ({
      optimisticResponse: {
        marketplace: {
          id: marketplaceID,
          name: `description`,
          __typename: `Marketplace`,
        },
      },
      update: deleteMutationUpdate({
        itemID: marketplaceID,
        itemIDAccessPath: `id`,
        listDataAccessPath: `industry.marketplaceList`,
        listQuery: GET_ACTIVE_MARKETPLACES,
        listQueryVariables: {
          industryID,
        },
      }),
      variables: {
        marketplaceID,
      },
    }),
    successMessage: (result) =>
      `Successfully deleted marketplace ${result.data.marketplace.name || ``}`,
  }),
  withState(`deletingMarketplaceID`, `setDeletingMarketplaceID`, undefined),
  withQuery(GET_MARKETPLACE, {
    dataPaths: {
      marketplace: `marketplace`,
    },
    skip: ({ deletingMarketplaceID }) => deletingMarketplaceID == null,
    options: ({ deletingMarketplaceID }) => ({
      variables: {
        marketplaceID: deletingMarketplaceID,
      },
    }),
    errorMessage: `Failed to get Marketplace`,
    loadingProp: `loadingMarketplace`,
  })
);

export default enhancer(MarketplaceLayout);

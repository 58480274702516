import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

type Props = {
  disabled?: boolean;
  Icon: React.ComponentType;
  actionText: string;
  count: number;
  itemName: string;
  itemNamePlural: string;
  performAction?: () => void;
};

/**
 * Icon Button with tooltip for performing an action on any number of items.
 */
class ItemsActionButton extends React.PureComponent<Props> {
  render() {
    const {
      Icon,
      actionText,
      count,
      itemName,
      itemNamePlural,
      performAction,
      ...buttonProps
    } = this.props;
    const tooltip = `${actionText}${count > 1 ? ` ${count}` : ``} ${
      count === 1 ? itemName : itemNamePlural
    }`;

    return (
      <Tooltip title={tooltip}>
        <div>
          <IconButton onClick={performAction} {...buttonProps}>
            <Icon />
          </IconButton>
        </div>
      </Tooltip>
    );
  }
}

export default ItemsActionButton;

import * as React from "react";
import gql from "graphql-tag";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../../generic/ListQuerySelectField";

export type EnhancedProps = ListQuerySelectFieldProps & {
  onSelectedIDChange: (id: string) => unknown;
  organisationID: string;
};

export const GET_INDUSTRIES = gql`
  query OrganisationIndustrySelectGetIndustries($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        activeIndustryList {
          id
          name
        }
      }
    }
  }
`;

const OrganisationIndustrySelect = ListQuerySelectField({
  dataPath: `getSelf.organisation.activeIndustryList`,
  itemPluralLabel: `industries`,
  itemTitleLabel: `Industry`,
  query: GET_INDUSTRIES,
  variables: ({ organisationID }) => ({ organisationID }),
}) as React.ComponentType<EnhancedProps>;

export default OrganisationIndustrySelect;

import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../generic/grid/GridContainer";
import Typography from "@material-ui/core/Typography";
import ProductUploadForm from "./UploadForm";
import ProductTable from "./ProductTable";
import { StyleProps } from "../types";
import { SPACING_PAPER } from "../../../helpers/constants";
import ActiveMarketplaceSelect from "../../components/app/ActiveMarketplaceSelect";

const styles = (theme: Theme) => ({
  paper: {
    padding: theme.spacing(SPACING_PAPER),
  },
  table: {
    maxHeight: `75vh`,
  },
  marketplaceSelect: {
    display: `flex`,
    flexDirection: `column` as const,
  },
});

type Props = StyleProps & {
  onPressProduct: (productID: string) => void;
  organisationID: string | null | undefined;
};

class ProductOverviewLayout extends React.PureComponent<Props> {
  render() {
    const { classes, onPressProduct, organisationID } = this.props;

    return (
      <React.Fragment>
        <GridContainer spacing={1}>
          <Grid item xs={12}>
            <div className={classes.marketplaceSelect}>
              <ActiveMarketplaceSelect organisationID={organisationID} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <ProductTable
              className={classes.table}
              organisationID={organisationID}
              onPressProduct={onPressProduct}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="caption">
                Upload Product Catalogue
              </Typography>
              <ProductUploadForm organisationID={organisationID} />
            </Paper>
          </Grid>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProductOverviewLayout);

import { compose } from "recompose";
import withQuery from "../graphql/withQuery";
import withCurationMutations, {
  ProvidedProps as CurationMutationsProvidedProps,
} from "./withCurationMutations";
import withAvailableItems from "./withAvailableItems";

// Options for creating the HOC
type Options = {
  activate: {
    // Map activated item from cache and data passed to mutation to an array of items to add to active items cache
    getActiveItems?: (item: any, extraData?: any) => any[];
    mutation: any;
    optimisticResponse: any;
    variables: (
      itemID: string,
      props: any,
      extraData: any
    ) => any | null | undefined;
  };
  activeItems: {
    dataPath: string;
    extraProps?: (data: any) => any;
    fragment: any;
    idAccessPath?: string;
    query: any;
    variables: (props: any) => any | void;
  };
  activeListName: string;
  availableItems: {
    dataPath: string;
    extraProps?: (data: any) => any;
    idAccessPath?: string;
    query: any;
    variables: (props: any) => any | void;
  };
  deactivate?: {
    mutation: any;
    optimisticResponse: any;
    refetchQueries?: (ownProps: any) => any[];
    variables: (
      itemID: string,
      props: any,
      extraData: any
    ) => any | null | undefined;
  };
  itemLabel: string;
  itemLabelPlural: string;
  itemTypeName: string;
};

export type ProvidedProps = CurationMutationsProvidedProps & {
  activeItems?: any[];
  activeItemsLoading: boolean;
  availableItems?: any[];
  availableItemsLoading: boolean;
};

/**
 * HOC managing queries and mutations for the curation of a list of active items based on a list of available items.
 * Combines withQuery, withCurationMutations and withAvailableItems. There is a lot more flexibility if used separately.
 * @param {any} options Options for configuring queries.
 * @return Function for creating enhanced component.
 */
const withCurationQueries = (options: Options) =>
  compose<Partial<ProvidedProps>, any>(
    withCurationMutations({
      ...options,
    }),
    withQuery(options.activeItems.query, {
      dataPaths: {
        activeItems: options.activeItems.dataPath,
      },
      errorMessage: `Failed to load ${options.itemLabelPlural} in ${options.activeListName}`,
      loadingProp: `activeItemsLoading`,
      options: (props) => ({
        variables: options.activeItems.variables(props) || {},
      }),
      props: ({ data }) =>
        options.activeItems.extraProps
          ? options.activeItems.extraProps(data)
          : {},
      skip: (props) => options.activeItems.variables(props) == null,
    }),
    withQuery(options.availableItems.query, {
      dataPaths: {
        allAvailableItems: options.availableItems.dataPath,
      },
      errorMessage: `Failed to load ${options.itemLabelPlural}`,
      loadingProp: `availableItemsLoading`,
      options: (props) => ({
        variables:
          (options.availableItems.variables &&
            options.availableItems.variables(props)) ||
          {},
      }),
      props: ({ data }) =>
        options.availableItems.extraProps
          ? options.availableItems.extraProps(data)
          : {},
      skip: (props) =>
        options.availableItems.variables != null &&
        options.availableItems.variables(props) == null,
    }),
    (withAvailableItems as any)({
      activeItemIDAccessPath: options.activeItems.idAccessPath,
      availableItemIDAccessPath: options.availableItems.idAccessPath,
    })
  );

export default withCurationQueries;

import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../generic/grid/GridContainer";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import LoadingScreen from "../../generic/LoadingScreen";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import * as currencyCalculation from "../../../../helpers/currencyCalculation";
import { AddProductDetailsCatalogueViewProduct as CatalogueViewProduct } from "./__generated__/AddProductDetailsCatalogueViewProduct";

export const catalogueViewProductFragment = gql`
  fragment AddProductDetailsCatalogueViewProduct on CatalogueViewProduct {
    price
    taxPercentage
    Product {
      id
      description
    }
  }
`;

const styles = () => ({});

type Props = StyleProps & {
  loading: boolean;
  catalogueViewProduct: CatalogueViewProduct | null;
  quantity: number;
  quantityInputValue: string;
  setQuantityInputValue: (quantity: string) => void;
};

class AddProductDetails extends React.PureComponent<Props> {
  render() {
    const {
      catalogueViewProduct,
      quantity,
      quantityInputValue,
      setQuantityInputValue,
      loading,
    } = this.props;
    if (
      loading ||
      catalogueViewProduct == null ||
      catalogueViewProduct.Product == null
    ) {
      return <LoadingScreen />;
    }
    const price = catalogueViewProduct.price || 0;
    const { taxPercentage } = catalogueViewProduct;

    return (
      <GridContainer spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Name</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">
            {catalogueViewProduct.Product.description}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Quantity</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="number"
            inputProps={{
              min: 0,
            }}
            value={quantityInputValue}
            onChange={(event) => {
              setQuantityInputValue(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Unit Price</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">{displayCurrency(price)}</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Tax</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">
            {taxPercentage != null
              ? displayCurrency(
                  currencyCalculation.calculateTax(price, taxPercentage)
                )
              : `N/A`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Total Tax</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">
            {taxPercentage != null
              ? displayCurrency(
                  currencyCalculation.calculateTotalTax(
                    price,
                    taxPercentage,
                    quantity
                  )
                )
              : `N/A`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">Total Price</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2">
            {displayCurrency(
              currencyCalculation.calculateTotalPrice(
                price,
                taxPercentage || 0,
                quantity
              )
            )}
          </Typography>
        </Grid>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AddProductDetails);

import * as React from "react";
import gql from "graphql-tag";
import { Theme, withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { StyleProps } from "../../types";

import AddShippingCarrierTable, {
  addShippingCarrierTableFragments,
} from "./AddShippingCarrierTable";
import ShippingBatchDetails, {
  shippingBatchDetailsFragments,
} from "./ShippingBatchDetails";
import { Typography } from "@material-ui/core";
import { AddShippingCarrierLayoutShippingBatch } from "./__generated__/AddShippingCarrierLayoutShippingBatch";
import {
  shippingBatchOrderItemPartTableFragments,
  ShippingBatchOrderItemPartTable,
} from "./ShippingBatchOrderItemPartTable";
import { Close, LocalShipping } from "@material-ui/icons";

export const addShippingCarrierLayoutFragments = {
  shippingBatch: gql`
    fragment AddShippingCarrierLayoutShippingBatch on ShippingBatch {
      id
      ...ShippingBatchDetailsShippingBatch
      availableCarrierRates {
        ...AddShippingCarrierRateCardOptions
      }
      orderItemPartList {
        id
        ...ShippingBatchOrderItemPartTableOrderItemPart
      }
    }
    ${addShippingCarrierTableFragments.rateCardRateOptions}
    ${shippingBatchDetailsFragments.shippingBatch}
    ${shippingBatchOrderItemPartTableFragments.orderItemPart}
  `,
};

const styles = (theme: Theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  table: {
    maxHeight: "40vh",
    overflow: "auto",
  },
});

type Props = StyleProps & {
  loading: boolean;
  shippingBatch?: AddShippingCarrierLayoutShippingBatch;
  onDismiss: () => Promise<void>;
  onCancel: () => void;
  selectedID?: string;
  onSelectedIDChange: any; // TODO: make better type
};

class AddDestinationLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      shippingBatch,
      onCancel,
      onDismiss,
      onSelectedIDChange,
      loading,
      selectedID,
    } = this.props;

    return (
      <div>
        <DialogTitle>Add Carrier</DialogTitle>

        <DialogContent>
          <ShippingBatchDetails shippingBatch={shippingBatch} />

          <Typography className={classes.marginTop} variant={"subtitle1"}>
            Items:
          </Typography>
          <ShippingBatchOrderItemPartTable
            className={classes.table}
            orderItemPartList={
              shippingBatch != null ? shippingBatch.orderItemPartList : []
            }
            loading={loading}
          />

          <Typography className={classes.marginTop} variant={"subtitle1"}>
            Available Carrier Rates:
          </Typography>
          <AddShippingCarrierTable
            availableCarrierRates={
              shippingBatch && shippingBatch.availableCarrierRates
            }
            className={classes.table}
            loading={loading}
            selectedID={selectedID}
            onSelectedIDChange={onSelectedIDChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onCancel} startIcon={<Close />}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant={"contained"}
            disabled={selectedID == null}
            onClick={onDismiss}
            startIcon={<LocalShipping />}
          >
            Select
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(styles)(AddDestinationLayout);

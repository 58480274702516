import { Item } from "./ItemList";

export const getListItems = <D>(
  items: any[] | null | undefined,
  options: { default: D; id?: string; text?: string }
): Item[] | D =>
  items
    ? items.map((item) => ({
        id: item[options.id || `id`],
        text: item[options.text || `name`],
      }))
    : options.default;

export default getListItems;

import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import CallOnMount from "../../generic/CallOnMount";
import { OrganisationDashboardOrganisation } from "./__generated__/OrganisationDashboardOrganisation";
import { organisationDashboardDetailsFragments } from "./OrganisationDashboardDetails";
import {
  OrganisationDashboardGetOrganisation,
  OrganisationDashboardGetOrganisationVariables,
} from "./__generated__/OrganisationDashboardGetOrganisation";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const organisationDashboardFragments = {
  organisation: gql`
    fragment OrganisationDashboardOrganisation on Organisation {
      id
      ...OrganisationDashboardDetailsOrganisation
    }
    ${organisationDashboardDetailsFragments.organisation}
  `,
};

export const GET_ORGANISATION = gql`
  query OrganisationDashboardGetOrganisation($id: String!) {
    getSelf {
      id
      organisation(id: $id) {
        ...OrganisationDashboardOrganisation
      }
    }
  }
  ${organisationDashboardFragments.organisation}
`;

export type ChildProps = {
  loading: boolean;
  organisation?: OrganisationDashboardOrganisation;
};

type EnhancedProps = {
  children: (arg0: ChildProps) => React.ReactNode;
  organisationID: string;
};

type Props = EnhancedProps & {
  error?: Error;
  loading: boolean;
  openSnackbar: OpenSnackbarFn;
  organisation?: OrganisationDashboardOrganisation;
};

export class OrganisationDashboardData extends React.PureComponent<Props> {
  render() {
    const { children, error, loading, organisation, openSnackbar } = this.props;

    if (error || (!loading && !organisation)) {
      return (
        <CallOnMount
          callback={() =>
            openSnackbar(SnackbarType.error, `Failed to load organisation`)
          }
        />
      );
    }

    return children({
      loading,
      organisation,
    });
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql<
    EnhancedProps,
    OrganisationDashboardGetOrganisation,
    OrganisationDashboardGetOrganisationVariables
  >(GET_ORGANISATION, {
    options: ({ organisationID }) => ({
      variables: {
        id: organisationID,
      },
    }),
    // @ts-ignore
    props: ({ data: { getSelf, loading, error } }) => ({
      error,
      loading,
      organisation: getSelf ? getSelf.organisation : undefined,
    }),
  }),
  withOpenSnackbar
);

export default enhancer(OrganisationDashboardData);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../types";
import Typography from "@material-ui/core/Typography";
import { UserStatus } from "../../../helpers/user/getStatus";

const styles = () => ({});

type Props = StyleProps & {
  userStatus?: UserStatus;
};

class UserModerationMessage extends React.PureComponent<Props> {
  render() {
    const { userStatus } = this.props;

    if (userStatus !== `New` && userStatus !== `Pending`) {
      return null;
    }

    return (
      <Typography>
        {userStatus === `New` &&
          `Please fill in your profile to complete your registration.`}
        {userStatus === `Pending` &&
          `Your account is pending approval from an administrator. Please come back later.`}
      </Typography>
    );
  }
}

export default withStyles(styles)(UserModerationMessage);

import OrganisationCategoryLayout from "./OrganisationCategoryLayout";
import gql from "graphql-tag";
import { compose } from "recompose";
import withCurationQueries from "../../../generic/curation/withCurationQueries";
import withActivateHandler from "../../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../../generic/curation/withDeactivateHandler";

export const organisationCategoryFragments = {
  category: gql`
    fragment OrganisationCategory on Category {
      id
      name
    }
  `,
};

export const GET_AVAILABLE_CATEGORIES = gql`
  query OrganisationCategoriesGetAvailableCategories($marketplaceID: String!) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      categoryList {
        ...OrganisationCategory
      }
    }
  }
  ${organisationCategoryFragments.category}
`;

export const GET_ORGANISATION_CATEGORIES = gql`
  query OrganisationCategoriesGetOrganisationCategories(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      activeCategoryList(organisationId: $organisationID) {
        id
        ...OrganisationCategory
      }
    }
  }
  ${organisationCategoryFragments.category}
`;

export const ADD_TO_CATEGORY = gql`
  mutation OrganisationCategoriesAddToCategory(
    $organisationID: String!
    $marketplaceID: String!
    $categoryID: String!
  ) {
    addOrganisationToCategory(
      from: { id: $organisationID }
      to: { id: $categoryID }
      data: { marketplaceId: $marketplaceID }
    ) {
      marketplaceID: marketplaceId
    }
  }
`;

export const REMOVE_ORGANISATION_TO_CATEGORY = gql`
  mutation OrganisationCategoryAffiliationsRemoveCategorySubscriptionToOrganisation(
    $organisationID: String!
    $categoryID: String!
  ) {
    removeOrganisationToCategory(
      from: { id: $organisationID }
      to: { id: $categoryID }
    )
  }
`;

export type EnhancedProps = {
  industryID?: string;
  marketplaceID?: string;
  onIndustryMarketplaceIDChange: (arg0: {
    industryID: string;
    marketplaceID: string;
  }) => unknown;
  onPressClasses: (classID: string) => void;
  organisationID: string;
};

/**
 * Map (categoryID, props) to the variables for de/activate mutations
 * @param {string} categoryID ID of category being de/activated
 * @param {EnhancedProps} props Props passed to enhanced component
 * @return {any} Variables
 */
const mutationVariables = (
  categoryID,
  { organisationID, marketplaceID }: EnhancedProps
) =>
  marketplaceID != null
    ? {
        categoryID,
        marketplaceID,
        organisationID,
      }
    : undefined;

const enhancer = compose<Partial<any>, EnhancedProps>(
  withCurationQueries({
    activate: {
      mutation: ADD_TO_CATEGORY,
      optimisticResponse: {
        addOrganisationToCategory: {
          __typename: `OrganisationTradesInCategory`,
          marketplaceID: ``,
        },
      },
      variables: mutationVariables,
    },
    activeItems: {
      dataPath: `marketplace.activeCategoryList`,
      fragment: organisationCategoryFragments.category,
      query: GET_ORGANISATION_CATEGORIES,
      variables: ({ organisationID, industryID, marketplaceID }) =>
        industryID != null && marketplaceID != null
          ? {
              marketplaceID,
              organisationID,
            }
          : undefined,
    },
    activeListName: `organisation`,
    availableItems: {
      dataPath: `marketplace.categoryList`,
      query: GET_AVAILABLE_CATEGORIES,
      variables: ({ marketplaceID }) =>
        marketplaceID != null ? { marketplaceID } : undefined,
    },
    deactivate: {
      mutation: REMOVE_ORGANISATION_TO_CATEGORY,
      optimisticResponse: {
        removeOrganisationToCategory: `SUCCESS`,
      },
      variables: mutationVariables,
    },
    itemLabel: `category`,
    itemLabelPlural: `categories`,
    itemTypeName: `Category`,
  }),
  withActivateHandler(
    `selectedAvailableItems`,
    `onSelectedAvailableItemsChange`
  ),
  withDeactivateHandler(`selectedActiveItems`, `onSelectedActiveItemsChange`)
);

export default enhancer(OrganisationCategoryLayout);

import React from "react";
import gql from "graphql-tag";
import GridContainer from "../../generic/grid/GridContainer";
import DetailGridItem from "../../generic/grid/DetailGridItem";
import getStatusString from "../../../../helpers/order/getStatusString";
import { formatUTCDate } from "../../../../helpers/formatDate";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { OrderDetailGridOrder } from "./__generated__/OrderDetailGridOrder";

export const orderDetailGridFragments = {
  order: gql`
    fragment OrderDetailGridOrder on Order {
      confirmationDate
      cost {
        shipping
        total
      }
      orderNumber
      status
    }
  `,
};

type Props = {
  className?: string;
  order: OrderDetailGridOrder | null | undefined;
};

const OrderDetailGrid = ({ className, order }: Props) => {
  if (order == null) {
    return null;
  }

  return (
    <GridContainer className={className}>
      <DetailGridItem
        title={"Order Number"}
        value={order && order.orderNumber ? order.orderNumber : "-"}
      />

      <DetailGridItem
        title={"Status"}
        value={order && order.status ? getStatusString(order.status) : "-"}
      />
      <DetailGridItem
        title={"Confirmed"}
        value={
          order && order.confirmationDate
            ? formatUTCDate(order.confirmationDate)
            : "-"
        }
      />

      <DetailGridItem
        title={"Shipping Cost"}
        value={
          order && order.cost && order.cost.shipping
            ? displayCurrency(order.cost.shipping)
            : "-"
        }
      />

      <DetailGridItem
        title={"Total Cost"}
        value={
          order && order.cost && order.cost.total
            ? displayCurrency(order.cost.total)
            : "-"
        }
      />
    </GridContainer>
  );
};

export default OrderDetailGrid;

import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import OrderTableData from "../../components/organisation/purchases/OrderTableData";
import OrderDetailsData from "../../components/organisation/purchases/OrderDetailsData";
import { match, Route } from "react-router-dom";
import * as H from "history";
import {
  purchasesOrderRoute,
  purchasesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

interface State {
  pageOffset: number;
  itemsPerPage: number;
}

class Purchases extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageOffset: 0,
      itemsPerPage: 10,
    };
  }

  render() {
    const { history, match } = this.props;
    const { organisationID } = match.params;
    if (organisationID == null) {
      return null;
    }

    return (
      <MainPanel title="Purchases">
        <OrderTableData
          organisationID={organisationID}
          onPressOrder={(orderID: string) =>
            history.push(purchasesOrderRoute(organisationID, orderID))
          }
          pageOffset={this.state.pageOffset}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={(event, newPage) => {
            this.setState({ pageOffset: newPage });
          }}
          handleRowsPerPageChange={(event) => {
            this.setState({
              itemsPerPage: parseInt(event.target.value, 10),
              pageOffset: 0,
            });
          }}
        />
        <Route
          path={purchasesOrderRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            `${ROUTE_PARAMETERS.ORDER_ID}?`
          )}
        >
          {({ match: formMatch }) => (
            <OrderDetailsData
              open={formMatch != null}
              organisationID={organisationID}
              orderID={formMatch && formMatch.params.orderID}
              onClose={() => history.push(purchasesRoute(organisationID))}
            />
          )}
        </Route>
      </MainPanel>
    );
  }
}

export default Purchases;

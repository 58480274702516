import * as React from "react";
import * as yup from "yup";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { CustomField } from "../generic/form/index";
import TextInput from "../generic/form/TextInput";
import CreateUpdateForm from "../generic/form/CreateUpdateForm";
import getOptimisticID from "../../../helpers/getOptimisticID";
import { compose } from "recompose";
import createMutationUpdate from "../../../apollo/updateFunctions/createMutationUpdate";
import { CreateOrganisationFormOrganisation } from "./__generated__/CreateOrganisationFormOrganisation";
import { CreateOrganisationFormCreateOrganisationVariables } from "./__generated__/CreateOrganisationFormCreateOrganisation";

export const createOrganisationFormFragments = {
  organisation: gql`
    fragment CreateOrganisationFormOrganisation on Organisation {
      id
      name
    }
  `,
};

export const CREATE_ORGANISATION = gql`
  mutation CreateOrganisationFormCreateOrganisation($name: String!) {
    createOrganisation(values: { name: $name }) {
      ...CreateOrganisationFormOrganisation
    }
  }
  ${createOrganisationFormFragments.organisation}
`;

const GET_SELF = gql`
  query CreateOrganisationFormGetSelf {
    getSelf {
      id
      organisationList {
        ...CreateOrganisationFormOrganisation
      }
    }
  }
  ${createOrganisationFormFragments.organisation}
`;

const schema = yup.object({
  name: yup.string().required().label(`Name`),
});

type EnhancedProps = {
  organisation?: CreateOrganisationFormOrganisation;
};

type Props = EnhancedProps & {
  createMutation: (
    organisation: CreateOrganisationFormCreateOrganisationVariables
  ) => Promise<void>;
};

export class CreateOrganisationForm extends React.PureComponent<Props> {
  render() {
    const { organisation, ...createUpdateFormProps } = this.props;
    const disableForm = organisation != null;

    return (
      <CreateUpdateForm
        schema={schema}
        item={organisation}
        itemType="organisation"
        itemNameAccessor="name"
        disabled={disableForm}
        alert={{
          body:
            "Your Organisation's registration has been saved. An Administrator will contact you within 72 hours to unlock the full functionality.",
          buttonText: "Ok",
          title: "Registration Saved",
        }}
        {...createUpdateFormProps}
      >
        <CustomField
          name="name"
          label="Name"
          component={TextInput}
          disabled={disableForm}
        />
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql(CREATE_ORGANISATION, {
    props: ({ mutate }) => ({
      createMutation: (
        variables: CreateOrganisationFormCreateOrganisationVariables
      ) =>
        mutate({
          optimisticResponse: {
            createOrganisation: {
              __typename: `Organisation`,
              id: getOptimisticID(),
              name: variables.name,
            },
          },
          update: createMutationUpdate({
            itemDataAccessPath: `createOrganisation`,
            listDataAccessPath: `getSelf.organisationList`,
            listQuery: GET_SELF,
            listQueryVariables: {},
          }),
          variables,
        }),
    }),
  })
);

export default enhancer(CreateOrganisationForm);

import * as React from "react";
import gql from "graphql-tag";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../generic/grid/GridContainer";
import DetailGridItem from "../../generic/grid/DetailGridItem";
import { GRID_FULL_WIDTH } from "../../../../helpers/constants";
import { OrganisationDashboardDetailsOrganisation } from "./__generated__/OrganisationDashboardDetailsOrganisation";

export const organisationDashboardDetailsFragments = {
  organisation: gql`
    fragment OrganisationDashboardDetailsOrganisation on Organisation {
      name
      abn
      addressLine1
      addressLine2
      contactEmail
      telephoneNumber
    }
  `,
};

type Props = {
  organisation: OrganisationDashboardDetailsOrganisation;
};

class OrganisationDashboardDetails extends React.PureComponent<Props> {
  render() {
    const { organisation } = this.props;

    return (
      <GridContainer>
        <DetailGridItem title={"Organisation Name"} value={organisation.name} />
        <DetailGridItem title={"ABN"} value={organisation.abn} />

        <Grid item xs={GRID_FULL_WIDTH}>
          <Typography variant={"h6"}>Contact Details</Typography>
        </Grid>

        <DetailGridItem
          title={"Primary Contact Email"}
          value={organisation.contactEmail}
        />
        <DetailGridItem
          title={"Telephone Number"}
          value={organisation.telephoneNumber}
        />
        <DetailGridItem
          title={"Address Line 1"}
          value={organisation.addressLine1}
        />
        <DetailGridItem
          title={"Address Line 2"}
          value={organisation.addressLine2}
        />
      </GridContainer>
    );
  }
}

export default OrganisationDashboardDetails;

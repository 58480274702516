import * as React from "react";
import DataTable from "../generic/DataTable";
import { Column } from "../generic/DataTable/BaseDataTable";
import { RateCardTableGetRateCard_self_organisation_rateCard_rates as RateCardRate } from "./__generated__/RateCardTableGetRateCard";
import { displayCurrency } from "../../../helpers/formatCurrency";
import { displayLeadTime } from "../../../helpers/displayLeadTime";

const columns: Column<RateCardRate>[] = [
  {
    Header: `Service`,
    id: `service`,
  },
  {
    Header: `Origin`,
    id: `origin.name`,
  },
  {
    Header: `Destination`,
    id: `destination.name`,
  },
  {
    Header: `Lead Time`,
    id: `leadTime`,
    Cell: ({ value }) => displayLeadTime(value),
  },
  {
    Header: `Minimum`,
    id: `minimumPrice`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Basic`,
    id: `basicPrice`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `0 - 500 Kg`,
    id: `range1Price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `>500 - 1000 Kg`,
    id: `range2Price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `>1000 - 3000 Kg`,
    id: `range3Price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `>3000 - 6000 Kg`,
    id: `range4Price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `>6000 Kg`,
    id: `range5Price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
];

type Props = {
  className?: string;
  organisationID?: string;
  rateCardID?: string;
  rateCardRates: ReadonlyArray<RateCardRate>;
  loadingRateCard: boolean;
};

class RateCardTable extends React.Component<Props> {
  render() {
    const {
      className,
      rateCardRates,
      loadingRateCard,
      ...otherProps
    } = this.props;

    return (
      <React.Fragment>
        <DataTable
          className={className}
          columns={columns}
          data={rateCardRates || []}
          loading={loadingRateCard}
          emptyMessage={`No rate card selected.`}
          idAccessor="id"
          {...otherProps}
        />
      </React.Fragment>
    );
  }
}

export default RateCardTable;

import gql from "graphql-tag";
import { compose, withState } from "recompose";
import ServiceAreasLayout from "./ServiceAreasLayout";
import withCurationQueries from "../../generic/curation/withCurationQueries";
import { warehouseNameFragments } from "../WarehouseTitle";

export const serviceAreaFragment = gql`
  fragment WarehouseServiceAreasServiceArea on ServiceArea {
    id
    name
  }
`;

export const warehouseFragment = gql`
  fragment WarehouseServiceAreasWarehouse on Warehouse {
    id
    name
    ...WarehouseNameWarehouse
    serviceAreaList {
      ...WarehouseServiceAreasServiceArea
    }
  }
  ${warehouseNameFragments.warehouse}
  ${serviceAreaFragment}
`;

export const GET_SERVICE_AREAS = gql`
  query WarehouseServiceAreasGetServiceAreas {
    serviceAreaList: getServiceAreaList {
      ...WarehouseServiceAreasServiceArea
    }
  }
  ${serviceAreaFragment}
`;

export const GET_WAREHOUSE_SERVICE_AREAS = gql`
  query WarehouseServiceAreasGetWarehouse(
    $warehouseID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          ...WarehouseServiceAreasWarehouse
        }
      }
    }
  }
  ${warehouseFragment}
`;

export const ADD_TO_WAREHOUSE = gql`
  mutation WarehouseServiceAreasAddToWarehouse(
    $warehouseID: String!
    $serviceAreaID: String!
  ) {
    addWarehouseToServiceArea(
      from: { id: $warehouseID }
      to: { id: $serviceAreaID }
    ) {
      to {
        id
      }
    }
  }
`;

export const REMOVE_FROM_WAREHOUSE = gql`
  mutation WarehouseServiceAreasRemoveFromWarehouse(
    $warehouseID: String!
    $serviceAreaID: String!
  ) {
    removeWarehouseToServiceArea(
      from: { id: $warehouseID }
      to: { id: $serviceAreaID }
    )
  }
`;

export type EnhancedProps = {
  organisationID: string;
  warehouseID: string;
  onDismiss: () => void;
};

const enhancer = compose<any, EnhancedProps>(
  withCurationQueries({
    activate: {
      mutation: ADD_TO_WAREHOUSE,
      optimisticResponse: {
        addWarehouseToServiceArea: {
          __typename: `WarehouseServicesServiceArea`,
          to: null,
        },
      },
      variables: (serviceAreaID, { warehouseID }) => ({
        serviceAreaID,
        warehouseID,
      }),
    },
    activeItems: {
      dataPath: `getSelf.organisation.warehouse.serviceAreaList`,
      extraProps: ({ getSelf }) => ({
        warehouse:
          getSelf && getSelf.organisation
            ? getSelf.organisation.warehouse
            : undefined,
      }),
      fragment: serviceAreaFragment,
      query: GET_WAREHOUSE_SERVICE_AREAS,
      variables: ({ organisationID, warehouseID }) => ({
        organisationID,
        warehouseID,
      }),
    },
    activeListName: `warehouse`,
    availableItems: {
      dataPath: `serviceAreaList`,
      query: GET_SERVICE_AREAS,
      variables: () => ({}),
    },
    deactivate: {
      mutation: REMOVE_FROM_WAREHOUSE,
      optimisticResponse: { removeWarehouseToServiceArea: `SUCCESS` },
      variables: (serviceAreaID, { warehouseID }) => ({
        serviceAreaID,
        warehouseID,
      }),
    },
    itemLabel: `service area`,
    itemLabelPlural: `service areas`,
    itemTypeName: `ServiceArea`,
  }),
  withState(`addDialogOpen`, `setAddDialogOpen`, false),
  withState(`removingServiceAreaID`, `setRemovingServiceAreaID`, undefined)
);

export default enhancer(ServiceAreasLayout);

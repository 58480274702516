import * as React from "react";
import gql from "graphql-tag";
import ShoppingCartLayout from "./Layout";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { compose } from "recompose";
import { shoppingCartIncludesProductListFragment } from "./Table";
import { ShoppingCartDataGetShoppingCart_getSelf_shoppingCart_productList as ShoppingCartIncludesProductList } from "./__generated__/ShoppingCartDataGetShoppingCart";
import { get } from "lodash";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";
import { ERROR_NO_RECORDS_FOUND } from "../../../../helpers/constants";
import { GET_CART } from "../productSearch/Data";

export const GET_SHOPPING_CART = gql`
  query ShoppingCartDataGetShoppingCart($organisationID: String!) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        productList {
          ...ShoppingCartTableIncludesProductListFragment
        }
        total {
          display
          value
        }
      }
    }
  }
  ${shoppingCartIncludesProductListFragment}
`;

const GET_SHOPPING_CART_TOTAL = gql`
  query ShoppingCartDataGetShoppingCartTotal($organisationID: String!) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        total {
          display
          value
        }
      }
    }
  }
`;

export const UPDATE_QUANTITY = gql`
  mutation ShoppingCartDataUpdateQuantity(
    $productID: String!
    $organisationID: String!
    $quantity: Int!
  ) {
    addProductToShoppingCart(
      product: { id: $productID }
      organisation: { id: $organisationID }
      data: { quantity: $quantity }
    ) {
      quantity
    }
  }
`;

export const REMOVE_PRODUCT_FROM_CART = gql`
  mutation ShoppingCartDataRemoveProductFromCart(
    $productID: String!
    $organisationID: String!
  ) {
    removeProductFromShoppingCart(
      product: { id: $productID }
      organisation: { id: $organisationID }
    )
  }
`;

type EnhancedProps = {
  organisationID: string;
  onBack: () => void;
  onNext: () => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  productList: ShoppingCartIncludesProductList[] | null;
  total: number | null;
  updateShoppingCartProductQuantity: (
    productID: string,
    quantity: number
  ) => Promise<void>;
  removeProductFromShoppingCart: (productID: string) => Promise<void>;
};

class ShoppingCartData extends React.PureComponent<Props> {
  render() {
    const {
      loading,
      productList,
      total,
      onBack,
      onNext,
      updateShoppingCartProductQuantity,
      removeProductFromShoppingCart,
    } = this.props;

    return (
      <ShoppingCartLayout
        loading={loading}
        productList={productList || []}
        total={total || 0}
        updateShoppingCartProductQuantity={updateShoppingCartProductQuantity}
        removeProductFromShoppingCart={removeProductFromShoppingCart}
        onBack={onBack}
        onNext={onNext}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_SHOPPING_CART, {
    dataPaths: {
      productList: `getSelf.shoppingCart.productList`,
      total: `getSelf.shoppingCart.total`,
    },
    errorMessage: `Failed to load shopping cart`,
    errorOnNull: false,
    loadingProp: `loading`,
    options: ({ organisationID }: EnhancedProps) => ({
      variables: {
        organisationID,
      },
      fetchPolicy: `network-only`,
    }),
  }),
  withMutation(UPDATE_QUANTITY, {
    mapMutationToProps: (updateShoppingCartProductQuantity) => ({
      updateShoppingCartProductQuantity,
    }),
    mutationOptions: ({ organisationID }, [productID, quantity]) => ({
      variables: {
        productID,
        organisationID,
        quantity,
      },
      refetchQueries: [
        {
          query: GET_SHOPPING_CART,
          variables: {
            organisationID,
          },
        },
        {
          query: GET_CART,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        addProductToShoppingCart: {
          quantity: 10,
          __typename: `ShoppingCartIncludesProduct`,
        },
      },
    }),
    throwOnError: true,
    errorMessage: (error) =>
      get(error, `graphQLErrors[0].message`) === ERROR_NO_RECORDS_FOUND
        ? `Failed to update quantity, stock may not be available`
        : `Failed to update quantity, please try again`,
  }),
  withMutation(REMOVE_PRODUCT_FROM_CART, {
    mapMutationToProps: (removeProductFromShoppingCart) => ({
      removeProductFromShoppingCart,
    }),
    mutationOptions: ({ organisationID }, [productID]) => ({
      variables: {
        productID,
        organisationID,
      },
      update: deleteMutationUpdate({
        itemID: productID,
        itemIDAccessPath: `Product.id`,
        listDataAccessPath: `getSelf.shoppingCart.productList`,
        listQuery: GET_SHOPPING_CART,
        listQueryVariables: {
          organisationID,
        },
      }),
      refetchQueries: [
        {
          query: GET_SHOPPING_CART_TOTAL,
          variables: {
            organisationID,
          },
        },
        {
          query: GET_CART,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        removeProductFromShoppingCart: `SUCCESS`,
      },
    }),
    successMessage: `Successfully removed product from cart`,
    errorMessage: `Failed to remove product from cart, please try again`,
  })
);

export default enhancer(ShoppingCartData);

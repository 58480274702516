import * as React from "react";
import MainPanel from "../components/app/MainPanel";
import ProfileData from "../components/profile/ProfileData";
import ProfileLayout from "../components/profile/ProfileLayout";

export class Profile extends React.PureComponent {
  render() {
    return (
      <MainPanel title="Profile">
        <ProfileData>
          {(props: any) => <ProfileLayout {...props} />}
        </ProfileData>
      </MainPanel>
    );
  }
}

export default Profile;

export class Route {
  urlString: string;

  constructor(url: string) {
    this.urlString = url;
  }

  // eslint-disable-next-line no-use-before-define
  static builder(initialUrl = ``): RouteBuilder {
    return new RouteBuilder(initialUrl);
  }

  static fromList(urlList: string[]): Route {
    const urlString = urlList && urlList.length > 0 ? urlList.join(``) : `/`;

    return new Route(urlString);
  }

  static fromString(url: string): Route {
    return new Route(url);
  }

  toString() {
    return this.urlString;
  }
}

export class RouteBuilder {
  urlList: string[];

  constructor(initialUrl?: string | null) {
    this.urlList = initialUrl != null && initialUrl !== `` ? [initialUrl] : [];
  }

  build(): Route {
    return Route.fromList(this.urlList);
  }

  getUrl(): string {
    return this.build().toString();
  }

  /*
   * Route Parts
   */
  addresses(): RouteBuilder {
    this.urlList.push(`/addresses`);

    return this;
  }

  add(): RouteBuilder {
    this.urlList.push(`/add`);

    return this;
  }

  admin(): RouteBuilder {
    this.urlList.push(`/admin`);

    return this;
  }

  myNetwork(): RouteBuilder {
    this.urlList.push(`/my_network`);

    return this;
  }

  buy(): RouteBuilder {
    this.urlList.push(`/buy`);

    return this;
  }

  customers(): RouteBuilder {
    this.urlList.push(`/customers`);

    return this;
  }

  callback(): RouteBuilder {
    this.urlList.push(`/callback`);

    return this;
  }

  catalogues(): RouteBuilder {
    this.urlList.push(`/catalogues`);

    return this;
  }

  catalogueViews(): RouteBuilder {
    this.urlList.push(`/catalogue_views`);

    return this;
  }

  categories(): RouteBuilder {
    this.urlList.push(`/categories`);

    return this;
  }

  classes(): RouteBuilder {
    this.urlList.push(`/classes`);

    return this;
  }

  dashboard(): RouteBuilder {
    this.urlList.push(`/dashboard`);

    return this;
  }

  details(): RouteBuilder {
    this.urlList.push(`/details`);

    return this;
  }

  edit(): RouteBuilder {
    this.urlList.push(`/edit`);

    return this;
  }

  form(): RouteBuilder {
    this.urlList.push(`/form`);

    return this;
  }

  freightRates(): RouteBuilder {
    this.urlList.push(`/freight_rates`);

    return this;
  }

  guides(): RouteBuilder {
    this.urlList.push(`/guides`);

    return this;
  }

  help(): RouteBuilder {
    this.urlList.push(`/help`);

    return this;
  }

  inbox(): RouteBuilder {
    this.urlList.push(`/inbox`);

    return this;
  }

  industries(): RouteBuilder {
    this.urlList.push(`/industries`);

    return this;
  }

  inventory(): RouteBuilder {
    this.urlList.push(`/inventory`);

    return this;
  }

  loggedOut(): RouteBuilder {
    this.urlList.push(`/loggedout`);

    return this;
  }

  login(): RouteBuilder {
    this.urlList.push(`/login`);

    return this;
  }

  logistics(): RouteBuilder {
    this.urlList.push(`/logistics`);

    return this;
  }

  marketplaces(): RouteBuilder {
    this.urlList.push(`/marketplaces`);

    return this;
  }

  operatingHours(): RouteBuilder {
    this.urlList.push(`/operating_hours`);

    return this;
  }

  order(): RouteBuilder {
    this.urlList.push(`/order`);

    return this;
  }

  orderItem(): RouteBuilder {
    this.urlList.push(`/order_item`);

    return this;
  }

  organisation(): RouteBuilder {
    this.urlList.push(`/organisation`);

    return this;
  }

  product(): RouteBuilder {
    this.urlList.push(`/product`);

    return this;
  }

  products(): RouteBuilder {
    this.urlList.push(`/products`);

    return this;
  }

  profile(): RouteBuilder {
    this.urlList.push(`/profile`);

    return this;
  }

  purchases(): RouteBuilder {
    this.urlList.push(`/purchases`);

    return this;
  }

  role(): RouteBuilder {
    this.urlList.push(`/role`);

    return this;
  }

  sales(): RouteBuilder {
    this.urlList.push(`/sales`);

    return this;
  }

  serviceAreas(): RouteBuilder {
    this.urlList.push(`/service_areas`);

    return this;
  }

  step(): RouteBuilder {
    this.urlList.push(`/step`);

    return this;
  }

  shippingBatch(): RouteBuilder {
    this.urlList.push(`/shipping_batch`);

    return this;
  }

  trading(): RouteBuilder {
    this.urlList.push("/trading");

    return this;
  }

  vendors(): RouteBuilder {
    this.urlList.push(`/vendors`);

    return this;
  }

  unauthenticated(): RouteBuilder {
    this.urlList.push(`/unauthenticated`);

    return this;
  }

  users(): RouteBuilder {
    this.urlList.push(`/users`);

    return this;
  }

  unverified(): RouteBuilder {
    this.urlList.push(`/unverified`);

    return this;
  }

  videos(): RouteBuilder {
    this.urlList.push(`/videos`);

    return this;
  }

  view(): RouteBuilder {
    this.urlList.push(`/view`);

    return this;
  }

  warehouses(): RouteBuilder {
    this.urlList.push(`/warehouses`);

    return this;
  }

  withId(id?: string): RouteBuilder {
    this.urlList.push(`/${id || ``}`);

    return this;
  }
}

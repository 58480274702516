import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { MenuDataGetSelf } from "./__generated__/MenuDataGetSelf";

export const GET_SELF = gql`
  query MenuDataGetSelf {
    user: getSelf {
      id
      organisationList {
        id
      }
    }
  }
`;

type ChildProps = {
  loading: boolean;
  organisationID?: string;
  organisationRole?: string;
};

type Props = {
  children: (arg0: ChildProps) => React.ReactNode;
};

class GetSelfQuery extends Query<MenuDataGetSelf, null> {}

class MenuData extends React.PureComponent<Props> {
  render() {
    const { children } = this.props;

    return (
      <GetSelfQuery query={GET_SELF}>
        {({ data, loading, error }) => {
          const organisation =
            !loading &&
            !error &&
            data &&
            data.user &&
            data.user.organisationList.length > 0
              ? data.user.organisationList[0]
              : undefined;
          const organisationID = organisation && organisation.id;

          return children({
            loading,
            organisationID,
            organisationRole: `OWNER`,
          });
        }}
      </GetSelfQuery>
    );
  }
}

export default MenuData;

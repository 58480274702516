import * as React from "react";
import MainPanel from "../components/app/MainPanel";

class Welcome extends React.Component {
  render() {
    return <MainPanel title="Welcome" />;
  }
}

export default Welcome;

import * as React from "react";
import { Field, FieldConfig, FieldProps } from "formik";
import { get } from "lodash";

/**
 * The props for the rendered children
 */
export type CustomFieldProps<V> = FieldProps<V> & {
  error: string | null | undefined;
  touched?: boolean;
};

type Props = Omit<FieldConfig, "component"> & {
  fullWidth?: boolean;
  label?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  inputProps?: any;
  InputProps?: any;
  checked?: boolean;
  multiline?: boolean;
  children?: (props: CustomFieldProps<any>) => React.ReactNode;
  component?: any;
  render?: (props: CustomFieldProps<any>) => React.ReactNode;
};

/**
 * An extension of the Formik Field component that provides extra props to rendered components.
 * Extra provided props: error, touched.
 * @param {FieldProps} props Props same as for Formik Field,
 * 	except for the three render props which accept the additional props.
 * @return {React.Node} Component
 */
const CustomField = (props: Props) => {
  const { component: Component, render, children, ...rest } = props;

  return (
    <Field {...rest}>
      {(fieldProps: FieldProps) => {
        const { form, field } = fieldProps;
        const touched =
          get(form.touched, field.name) || form.submitCount > 0
            ? `touched`
            : undefined;
        const error = touched ? get(form.errors, field.name) : undefined;
        const renderProps: CustomFieldProps<any> = {
          id: rest.name,
          ...rest,
          ...fieldProps,
          // @ts-ignore
          error,
          // @ts-ignore
          touched,
        };

        if (children) {
          return children(renderProps);
        } else if (Component) {
          return <Component {...renderProps} />;
        } else if (render) {
          return render(renderProps);
        }
      }}
    </Field>
  );
};

export default CustomField;

import { Badge } from "@material-ui/core";
import * as React from "react";
import { SvgIconComponent } from "@material-ui/icons";

interface IconWithBadgeProps {
  notificationCount: number;
  Icon: SvgIconComponent;
}

export default ({
  notificationCount,
  Icon,
}: IconWithBadgeProps): JSX.Element => (
  <Badge badgeContent={notificationCount} color="secondary">
    <Icon color="inherit" />
  </Badge>
);

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import Layout from "./Layout";
import { CatalogueViewGetCatalogueDetails } from "./__generated__/CatalogueViewGetCatalogueDetails";

const catalogueViewLayoutFragments = {
  catalogueView: gql`
    fragment CatalogueViewCatalogueDetails on CatalogueView {
      id
      name
      isGeneratedDefault
      readOnly
    }
  `,
};

export const GET_CATALOGUE_VIEW = gql`
  query CatalogueViewGetCatalogueDetails(
    $catalogueViewID: String!
    $organisationID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueView(id: $catalogueViewID) {
          ...CatalogueViewCatalogueDetails
        }
      }
    }
  }
  ${catalogueViewLayoutFragments.catalogueView}
`;

export type EnhancedProps = {
  catalogueViewID: string | null | undefined;
  onCatalogueViewIDChange: (catalogueViewID?: string) => unknown;
  onPressEdit: (catalogueViewID: string) => unknown;
  organisationID: string;
};

type Props = EnhancedProps & {
  canEditCatalogue?: boolean;
};

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql<EnhancedProps, CatalogueViewGetCatalogueDetails>(GET_CATALOGUE_VIEW, {
    options: (props) => ({
      variables: {
        catalogueViewID: props.catalogueViewID,
        organisationID: props.organisationID,
      },
    }),
    // @ts-ignore
    props: ({ data }) => {
      const isValidResult = data && !data.error && !data.loading;
      const foundCatalogueView =
        (data &&
          data.self &&
          data.self.organisation &&
          data.self.organisation.catalogueView) != null;
      const canEdit =
        isValidResult &&
        foundCatalogueView &&
        data.self.organisation.catalogueView.readOnly !== true;

      return {
        canEditCatalogue: canEdit,
      };
    },
    skip: (props) => props.catalogueViewID == null,
  })
);

export default enhancer(Layout);

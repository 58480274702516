import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  GRID_FULL_WIDTH,
  GRID_HALF_WIDTH,
} from "../../../../helpers/constants";

type ProductDetailGridItemProps = {
  title: string | React.ReactNode;
  value: (string | number | React.ReactNode) | null | undefined;
};

const DetailGridItem = (props: ProductDetailGridItemProps) => (
  <>
    <Grid item xs={GRID_FULL_WIDTH} sm={GRID_HALF_WIDTH}>
      <Typography variant="subtitle1">{props.title}:</Typography>
    </Grid>

    <Grid item xs={GRID_FULL_WIDTH} sm={GRID_HALF_WIDTH}>
      <Typography variant="body2">{props.value}</Typography>
    </Grid>
  </>
);

export default DetailGridItem;

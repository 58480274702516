import EditLayout from "./EditLayout";
import gql from "graphql-tag";
import { compose } from "recompose";
import withCurationMutations from "../../generic/curation/withCurationMutations";
import withAvailableItems from "../../generic/curation/withAvailableItems";
import withActivateHandler from "../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../generic/curation/withDeactivateHandler";
import withMutation from "../../generic/graphql/withMutation";
import withQuery from "../../generic/graphql/withQuery";
import { CatalogueViewEditProduct as Product } from "./__generated__/CatalogueViewEditProduct";
import updateMutationUpdate from "../../../../apollo/updateFunctions/updateMutationUpdate";
import { productTableFragments } from "./EditProductTable";
import { catalogueViewEditFormFragments } from "./EditForm";
import { GET_SUBSCRIBED_ORGANISATIONS_TO_CATALOGUE_VIEW } from "./catalogueViewSubscriptions/SubscribedOrganisationData";
import { UpdateCatalogueViewIncludesProductFn } from "./IncludedProductsForm";
import { GET_CATALOGUE_VIEWS } from "./CatalogueViewSelect";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";

export const catalogueViewEditFragments = {
  product: gql`
    fragment CatalogueViewEditProduct on Product {
      id
      sku
      description
      standardPrice {
        value
      }
    }
  `,
};

export const GET_CATALOGUE_VIEW_PRODUCTS = gql`
  query CatalogueViewEditGetCatalogueViewProducts(
    $catalogueViewID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueView(id: $catalogueViewID) {
          id
          includesProductList {
            ...CatalogueViewEditProductTableIncludesProduct
            Product {
              ...CatalogueViewEditProduct
            }
            price
          }
          marketplace {
            id
          }
          ...CatalogueViewEditFormCatalogueView
        }
      }
    }
  }
  ${catalogueViewEditFragments.product}
  ${productTableFragments.catalogueViewIncludesProduct}
  ${catalogueViewEditFormFragments.catalogueView}
`;

export const GET_ORGANISATION_PRODUCTS = gql`
  query CatalogueViewEditGetOrganisationProducts(
    $marketplaceID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        productList: productListInMarketplace(marketplaceId: $marketplaceID) {
          ...CatalogueViewEditProduct
        }
      }
    }
  }
  ${catalogueViewEditFragments.product}
`;

export const CREATE_CATALOGUE_VIEW_INCLUDES_PRODUCT = gql`
  mutation CatalogueViewEditCreateCatalogueViewIncludesProduct(
    $catalogueViewID: String!
    $productID: String!
  ) {
    addCatalogueViewToProduct(
      from: { id: $catalogueViewID }
      to: { id: $productID }
    ) {
      to {
        id
      }
    }
  }
`;

export const DELETE_CATALOGUE_VIEW_INCLUDES_PRODUCT = gql`
  mutation CatalogueViewEditDeleteCatalogueViewIncludesProduct(
    $catalogueViewID: String!
    $productID: String!
  ) {
    removeCatalogueViewToProduct(
      from: { id: $catalogueViewID }
      to: { id: $productID }
    )
  }
`;

export const DELETE_CATALOGUE_VIEW = gql`
  mutation CatalogueViewEditDeleteCatalogueView($id: String!) {
    deleteCatalogueView(id: $id) {
      id
    }
  }
`;

export const UPDATE_CATALOGUE_VIEW_INCLUDES_PRODUCT = gql`
  mutation CatalogueViewEditUpdateCatalogueViewIncludesProduct(
    $catalogueViewID: String!
    $productID: String!
    $price: Int
  ) {
    updateCatalogueViewToProduct(
      from: { id: $catalogueViewID }
      to: { id: $productID }
      data: { price: $price }
    ) {
      price
    }
  }
`;

// List of subscriptions for all catalogue views which needs refetching on deletion (since subscriptions on default may change)
export const GET_ALL_CATALOGUE_VIEW_SUBSCRIPTIONS = gql`
  query CatalogueViewEditGetCatalogueViewSubscriptions(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueViewList(marketplaceId: $marketplaceID) {
          id
          subscribedOrganisationList {
            id
            name
          }
        }
      }
    }
  }
`;

export type EnhancedProps = {
  catalogueViewID: string;
  organisationID: string;
  onDismiss: () => void;
};

const enhancer = compose<any, EnhancedProps>(
  withQuery(GET_CATALOGUE_VIEW_PRODUCTS, {
    dataPaths: {
      activeItems: `getSelf.organisation.catalogueView.includesProductList`,
      catalogueView: `getSelf.organisation.catalogueView`,
      marketplaceID: `getSelf.organisation.catalogueView.marketplace.id`,
    },
    errorMessage: `Failed to load products in catalogue view`,
    loadingProp: `activeItemsLoading`,
    options: ({ catalogueViewID, organisationID }) => ({
      variables: {
        catalogueViewID,
        organisationID,
      },
    }),
  }),
  withQuery(GET_ORGANISATION_PRODUCTS, {
    dataPaths: {
      allAvailableItems: `getSelf.organisation.productList`,
    },
    errorMessage: `Failed to load products`,
    loadingProp: `availableItemsLoading`,
    options: ({ marketplaceID, organisationID }) => ({
      variables: {
        marketplaceID,
        organisationID,
      },
    }),
    skip: ({ marketplaceID }) => marketplaceID == null,
  }),
  withQuery(GET_SUBSCRIBED_ORGANISATIONS_TO_CATALOGUE_VIEW, {
    dataPaths: {
      subscribedOrganisations: `self.organisation.catalogueView.subscribedOrganisationList`,
    },
    errorMessage: `Failed to get list of subscribed organisations to catalogue view`,
    loadingProp: `subscribedOrganisationsLoading`,
    options: ({ catalogueViewID, organisationID }) => ({
      variables: {
        catalogueViewID,
        organisationID,
      },
    }),
    skip: ({ catalogueViewID }) => catalogueViewID == null,
  }),
  withAvailableItems({
    activeItemIDAccessPath: `Product.id`,
  }),
  withCurationMutations({
    activate: {
      getActiveItems: (activatedProduct: Product) => [
        {
          // Create the default CatalogueViewIncludesProduct relationship object from the product being activated
          Product: activatedProduct,
          __typename: `_CatalogueViewIncludesProductList`,
          price: null,
        },
      ],
      mutation: CREATE_CATALOGUE_VIEW_INCLUDES_PRODUCT,
      optimisticResponse: {
        addCatalogueViewToProduct: {
          __typename: `CatalogueViewIncludesProduct`,
          to: {
            __typename: `Product`,
            id: `productId`,
          },
        },
      },
      variables: (productID, { catalogueViewID }) => ({
        catalogueViewID,
        productID,
      }),
    },
    activeItems: {
      dataPath: `getSelf.organisation.catalogueView.includesProductList`,
      fragment: catalogueViewEditFragments.product,
      idAccessPath: `Product.id`,
      query: GET_CATALOGUE_VIEW_PRODUCTS,
      variables: ({ catalogueViewID, organisationID }) => ({
        catalogueViewID,
        organisationID,
      }),
    },
    activeListName: `view`,
    deactivate: {
      mutation: DELETE_CATALOGUE_VIEW_INCLUDES_PRODUCT,
      optimisticResponse: { removeCatalogueViewToProduct: `SUCCESS` },
      variables: (productID, { catalogueViewID }) => ({
        catalogueViewID,
        productID,
      }),
    },
    itemLabel: `product`,
    itemLabelPlural: `products`,
    itemTypeName: `Product`,
  }),
  withActivateHandler(
    `selectedAvailableItems`,
    `onSelectedAvailableItemsChange`
  ),
  withDeactivateHandler(`selectedActiveItems`, `onSelectedActiveItemsChange`),
  withMutation(UPDATE_CATALOGUE_VIEW_INCLUDES_PRODUCT, {
    // @ts-ignore
    mapMutationToProps: (
      updateCatalogueViewIncludesProduct: UpdateCatalogueViewIncludesProductFn
    ) => ({ updateCatalogueViewIncludesProduct }),
    mutationOptions: (
      { catalogueViewID, organisationID },
      [{ productID, price }]
    ) => ({
      update: updateMutationUpdate({
        itemDataAccessPath: `updateCatalogueViewToProduct`,
        listDataAccessPath: `getSelf.organisation.catalogueView.includesProductList`,
        listItemPredicate: (item) =>
          item.Product && item.Product.id === productID,
        listQuery: GET_CATALOGUE_VIEW_PRODUCTS,
        listQueryVariables: {
          catalogueViewID,
          organisationID,
        },
      }),
      variables: {
        catalogueViewID,
        price,
        productID,
      },
    }),
    throwOnError: true,
  }),
  withMutation(DELETE_CATALOGUE_VIEW, {
    errorMessage: `Failed to delete catalogue view`,
    mapMutationToProps: (deleteCatalogueView) => ({ deleteCatalogueView }),
    mutationOptions: ({ marketplaceID, organisationID }, [id]) => ({
      optimisticResponse: {
        deleteCatalogueView: {
          __typename: `CatalogueView`,
          id,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_CATALOGUE_VIEW_SUBSCRIPTIONS,
          variables: {
            marketplaceID,
            organisationID,
          },
        },
      ],
      update: deleteMutationUpdate({
        itemID: id,
        listDataAccessPath: `getSelf.organisation.catalogueViewList`,
        listQuery: GET_CATALOGUE_VIEWS,
        listQueryVariables:
          marketplaceID != null
            ? {
                marketplaceID,
                organisationID,
              }
            : undefined,
      }),
      variables: { id },
    }),
    successMessage: `Successfully deleted catalogue view`,
  })
);

export default enhancer(EditLayout);

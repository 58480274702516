import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { StyleProps } from "../../types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  root: {
    marginBottom: theme.margins.paper,
  },
});

export type AffiliationType = "industry" | "marketplace" | "category" | "class";
export const tabs: AffiliationType[] = [
  `industry`,
  `marketplace`,
  `category`,
  `class`,
];

type Props = StyleProps & {
  onSelectedTabChange: (tab: AffiliationType) => void;
  selectedTab: AffiliationType;
};

class OrganisationAffiliationsTabs extends React.PureComponent<Props> {
  render() {
    const { classes, selectedTab, onSelectedTabChange } = this.props;

    return (
      <Paper className={classes.root}>
        <Tabs
          centered
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_event, value) => onSelectedTabChange(value)}
        >
          <Tab value="industry" label="Industries" />
          <Tab value="marketplace" label="Marketplaces" />
          <Tab value="category" label="Categories" />
          <Tab value="class" label="Classes" />
        </Tabs>
      </Paper>
    );
  }
}

export default withStyles(styles)(OrganisationAffiliationsTabs);

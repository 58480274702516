import * as React from "react";
import TextInput from "./TextInput";
import RestrictedInput from "./RestrictedInput";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CustomFieldProps } from "./CustomField";
import { ThemeStyleProps } from "../../types";

const styles = () => ({});

type Props = CustomFieldProps<string> &
  ThemeStyleProps & {
    InputProps: any;
    textFieldComponent: React.ComponentType<any>;
  };

const CurrencyField = ({ InputProps, textFieldComponent, ...rest }: Props) => {
  const TextFieldComponent = textFieldComponent || TextInput;

  return (
    <TextFieldComponent
      InputProps={{
        ...InputProps,
        inputComponent: RestrictedInput,
        inputProps: {
          ...(InputProps ? InputProps.inputProps : {}),
          pattern: /^\d*\.?\d{0,2}$/,
        },
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(CurrencyField);

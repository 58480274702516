import React from "react";

import { Route, useHistory } from "react-router-dom";
import { ORDER_STEP_DESTINATIONS } from "../../../../helpers/trading/orderStep";
import DestinationData from "../../../components/trading/destination/Data";
import {
  buyStepOrderItemAddRoute,
  buyStepOrderItemRoute,
  buyStepRoute,
  ROUTE_PARAMETERS,
} from "../../index";
import AddDestinationData from "../../../components/trading/destination/AddDestinationData";
import AddDestinationToItemData from "../../../components/trading/destination/AddDestinationToItemData";

type Props = {
  organisationID: string;
  onNext: () => void;
  onBack: () => void;
};

export const DestinationsRoute = ({
  organisationID,
  onBack,
  onNext,
}: Props) => {
  const history = useHistory();

  return (
    <>
      <DestinationData
        organisationID={organisationID}
        onBack={onBack}
        onNext={onNext}
        onEditPressed={(orderItemID: string) =>
          history.push(
            buyStepOrderItemRoute(
              organisationID,
              ORDER_STEP_DESTINATIONS,
              orderItemID
            )
          )
        }
      />

      <Route
        path={buyStepOrderItemRoute(
          ROUTE_PARAMETERS.ORGANISATION_ID,
          ROUTE_PARAMETERS.STEP,
          ROUTE_PARAMETERS.ORDER_ITEM_ID
        )}
      >
        {({ match: formMatch }) => {
          const orderItemID: string | null | undefined =
            formMatch && formMatch.params.orderItemID;

          return (
            <AddDestinationData
              organisationID={organisationID}
              orderItemID={orderItemID}
              onAddDestinationPressed={() =>
                history.push(
                  buyStepOrderItemAddRoute(
                    organisationID,
                    ORDER_STEP_DESTINATIONS,
                    orderItemID || ``
                  )
                )
              }
              onClose={() =>
                history.push(
                  buyStepRoute(organisationID, ORDER_STEP_DESTINATIONS)
                )
              }
            />
          );
        }}
      </Route>

      <Route
        path={buyStepOrderItemAddRoute(
          ROUTE_PARAMETERS.ORGANISATION_ID,
          ROUTE_PARAMETERS.STEP,
          ROUTE_PARAMETERS.ORDER_ITEM_ID
        )}
      >
        {({ match: formMatch }) => {
          const orderItemID: string | null | undefined =
            formMatch && formMatch.params.orderItemID;

          return (
            <AddDestinationToItemData
              organisationID={organisationID}
              orderItemID={orderItemID}
              open={formMatch != null}
              onClose={() =>
                history.push(
                  buyStepOrderItemRoute(
                    organisationID,
                    ORDER_STEP_DESTINATIONS,
                    orderItemID || ``
                  )
                )
              }
            />
          );
        }}
      </Route>
    </>
  );
};

import * as React from "react";
import { compose } from "recompose";
import gql from "graphql-tag";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import ShippingBatchSaleDialogLayout, {
  shippingBatchSaleDialogFragments,
} from "./ShippingBatchSaleDialogLayout";
import { GET_SHIPPING_BATCH_SALE_LIST_QUERY } from "./SalesData";
import { ShippingBatchSaleDialogGetShippingBatchSale_self_organisation_shippingBatchSale as ShippingBatchSaleType } from "./__generated__/ShippingBatchSaleDialogGetShippingBatchSale";
import { DEFAULT_POLL_INTERVAL } from "../../../../helpers/constants";
import { ShippingBatchStatus } from "../../../../__generated__/globalTypes";

const GET_SHIPPING_BATCH_SALE = gql`
  query ShippingBatchSaleDialogGetShippingBatchSale(
    $organisationID: String!
    $shippingBatchID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        shippingBatchSale(id: $shippingBatchID) {
          id
          ...ShippingBatchSaleDialog
        }
      }
    }
  }
  ${shippingBatchSaleDialogFragments.shippingBatch}
`;

const UPDATE_SHIPPING_BATCH = gql`
  mutation ShippingBatchSaleDialogUpdateShippingBatch(
    $id: String!
    $status: ShippingBatchStatus!
  ) {
    updateShippingBatch(id: $id, values: { status: $status }) {
      id
      status
    }
  }
`;

type EnhancedProps = {
  organisationID: string;
  shippingBatchID: string | null | undefined;
  onClose: () => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  shippingBatchSale: ShippingBatchSaleType;
  updateShippingBatch: (status: ShippingBatchStatus) => Promise<void>;
};

class ShippingBatchSaleDialogData extends React.PureComponent<Props> {
  render() {
    const {
      organisationID,
      shippingBatchID,
      loading,
      shippingBatchSale,
      updateShippingBatch,
      onClose,
    } = this.props;

    const updateShippingBatchStatus = async (status: ShippingBatchStatus) => {
      onClose();
      await updateShippingBatch(status);
    };

    return (
      <ShippingBatchSaleDialogLayout
        open={shippingBatchID != null && organisationID != null}
        loading={loading}
        shippingBatchSale={shippingBatchSale}
        onClose={onClose}
        onDispatchedPressed={() =>
          updateShippingBatchStatus(ShippingBatchStatus.DISPATCHED)
        }
        onPickingPressed={() =>
          updateShippingBatchStatus(ShippingBatchStatus.PICKING)
        }
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_SHIPPING_BATCH_SALE, {
    dataPaths: {
      shippingBatchSale: `self.organisation.shippingBatchSale`,
    },
    options: ({ organisationID, shippingBatchID }: EnhancedProps) => ({
      variables: {
        organisationID,
        shippingBatchID,
      },
      fetchPolicy: `network-only`,
      pollInterval: DEFAULT_POLL_INTERVAL,
    }),
    errorMessage: `Could not fetch sale`,
    skip: ({ organisationID, shippingBatchID }: EnhancedProps) =>
      organisationID == null || shippingBatchID == null,
  }),
  withMutation(UPDATE_SHIPPING_BATCH, {
    errorMessage: `Failed to update status`,
    successMessage: `The order status was updated`,
    mapMutationToProps: (updateShippingBatch) => ({ updateShippingBatch }),
    mutationOptions: ({ organisationID, shippingBatchID }, [status]) => ({
      variables: {
        id: shippingBatchID,
        status,
      },
      refetchQueries: [
        {
          query: GET_SHIPPING_BATCH_SALE_LIST_QUERY,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        updateShippingBatch: {
          __typename: `ShippingBatch`,
          id: shippingBatchID,
          status,
        },
      },
    }),
  })
);

export default enhancer(ShippingBatchSaleDialogData);

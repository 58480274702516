import * as React from "react";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import GridContainer from "../../generic/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import withQuery from "../../generic/graphql/withQuery";
import { compose } from "recompose";
import CheckboxField from "../../generic/form/CheckboxField";
import { UserRolesFormAddRoleToUserVariables } from "./__generated__/UserRolesFormAddRoleToUser";
import { UserRolesFormGetUser_user as User } from "./__generated__/UserRolesFormGetUser";
import { UserFormGetRoles_roles as Role } from "./__generated__/UserFormGetRoles";

export const ADD_ROLE = gql`
  mutation UserRolesFormAddRoleToUser($userID: String!, $roleList: [String!]!) {
    user: setRolesForUser(userId: $userID, roles: $roleList) {
      id
      username
      roles {
        id
        name
      }
    }
  }
`;

export const GET_ROLES = gql`
  query UserFormGetRoles {
    roles: getRoleList {
      id
      name
    }
  }
`;

export const GET_USER = gql`
  query UserRolesFormGetUser($id: String!) {
    user: getUser(id: $id) {
      id
      username
      fullName
      roles {
        id
        name
      }
    }
  }
`;

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  item?: Partial<User>;
  roles: Role[] | null;
  updateMutation: (user: UserRolesFormAddRoleToUserVariables) => Promise<void>;
};

export class UserRoleForm extends React.PureComponent<Props> {
  // Get the values from data and map them into form values
  getValues = (user: any, roles: any) => {
    if (user) {
      const item = {};
      if (roles) {
        roles.forEach((role) => {
          item[`${role.id}`] = user.roles.some(
            (value) => value.id === `${role.id}`
          );
        });
      }

      return item;
    } else {
      return undefined;
    }
  };

  render() {
    const {
      BodyContainer,
      itemID,
      roles,
      item,
      updateMutation,
      ...createUpdateFormProps
    } = this.props;

    return (
      <CreateUpdateForm
        itemID={itemID}
        item={this.getValues(item, roles)}
        // @ts-ignore
        roles={roles}
        itemType="User"
        itemTypeTitle="User Roles"
        itemNameAccessor="fullName"
        updateMutation={(values) => {
          const roleList = Object.keys(values).filter(
            (key) => values[key] === true
          );

          return updateMutation({
            roleList,
            userID: itemID,
          });
        }}
        {...createUpdateFormProps}
      >
        {/* @ts-ignore */}
        {({ values }) => (
          <FieldList component={BodyContainer}>
            <GridContainer>
              {roles &&
                roles.map((value) => (
                  <Grid item key={value.id} xs={12} sm={12}>
                    <CustomField
                      checked={values[value.id] === true}
                      name={value.id}
                      label={value.name}
                      component={CheckboxField}
                    />
                  </Grid>
                ))}
            </GridContainer>
          </FieldList>
        )}
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `addRole`,
    createMutation: ADD_ROLE,
    getItemAccessPath: `user`,
    getQuery: GET_USER,
    getQueryVariables: ({ itemID }) =>
      itemID
        ? {
            id: itemID,
          }
        : undefined,
    listAccessPath: `getRoles`,
    listQuery: GET_ROLES,
    updateMutation: ADD_ROLE,
  }),
  withQuery(GET_ROLES, {
    dataPaths: {
      roles: `roles`,
    },
    errorMessage: `Failed to load user roles`,
    loadingProp: `loadingUsers`,
  })
);

export default enhancer(UserRoleForm);

/**
 * Handle strings for displaying currency that is stored as integer cents.
 */
// Number of cents that corresponds to a single unit/dollar
const CENTS_PER_UNIT = 100;
// The decimal precision to use for display
const UNIT_PRECISION = 2;
// Multiplier to convert decimal values to percentage values
const CONVERT_DECIMAL_TO_PERCENTAGE = 100;

/**
 * Convert value in cents to string in dollars.
 * @param {number} cents The value in cents.
 * @return {string} String representing the value.
 */
export const formatCurrency = (cents: number): string =>
  (cents / CENTS_PER_UNIT).toFixed(UNIT_PRECISION);

/**
 * Parse a currency value represented by a string.
 * @param {string} amount The amount in dollars.
 * @return {number} The amount as an integer in cents, or null if amount is invalid.
 */
export const parseCurrency = (amount: string): number | null | undefined => {
  const inputNumber = Number(amount);
  if (Number.isFinite(inputNumber)) {
    return Math.round(inputNumber * CENTS_PER_UNIT);
  } else {
    return null;
  }
};

/**
 * Display a nullable value in cents to string in dollars or as empty.
 * @param {?number} value The nullable value in cents
 * @return {string} String representing the value.
 */
export const displayCurrency = (value: number | null | undefined): string =>
  value != null ? `$${formatCurrency(value)}` : ``;

/**
 * Display a nullable tax amount to percentage as string or as empty.
 * @param {?number} amount The nullable value in cents
 * @return {string} String representing the tax percentage.
 */
export const displayTax = (amount: number | null | undefined): string =>
  amount != null ? `${amount * CONVERT_DECIMAL_TO_PERCENTAGE}%` : `N/A`;

import * as React from "react";
import { Form, Formik, FormikProps } from "formik";
import { compose } from "recompose";
import withOpenSnackbar, { OpenSnackbarFn } from "../snackbar/withOpenSnackbar";
import { FieldStatus } from "./StatusIcon";
import * as yup from "yup";
import { get } from "lodash";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export type AutosaveFieldProps = {
  onBlur: (...args: any) => any;
  onChange: (...args: any) => any;
  state: FieldStatus;
};

export type ChildProps = {
  formikBag: FormikProps<any>;
  getAutosaveFieldProps: (fieldName: string) => AutosaveFieldProps;
};

export type EnhancedProps<V> = {
  children: (arg0: ChildProps) => React.ReactElement;
  className?: string;
  hasChanged: (fieldName: string, fieldValue: any) => boolean;
  item: V | null | undefined;
  // String for item to display in messages
  itemName: string;
  schema?: any;
  updateField: (fieldName: string, fieldValue: any) => Promise<void>;
  showErrorMessages?: boolean;
};

type Props<V> = EnhancedProps<V> & {
  openSnackbar: OpenSnackbarFn;
};

type State = {
  fieldState: {
    [fieldName: string]: FieldStatus;
  };
};

/**
 * Reusable form component that will asynchronously save field values on blur.
 *
 * The child function should spread getAutosaveFieldProps to the fields.
 */
export class AutosaveForm<V> extends React.PureComponent<Props<V>, State> {
  static defaultProps = {
    showErrorMessages: true,
  };

  constructor(props: Props<V>) {
    super(props);

    this.state = {
      fieldState: {},
    };
  }

  _setFieldState(fieldName: string, state: FieldStatus) {
    this.setState({
      fieldState: {
        ...this.state.fieldState,
        [fieldName]: state,
      },
    });
  }

  async _save(value: any, fieldName: string) {
    const {
      itemName,
      updateField,
      openSnackbar,
      showErrorMessages,
    } = this.props;

    this._setFieldState(fieldName, `saving`);
    try {
      // Perform mutation to update field value
      await updateField(fieldName, value);

      this._setFieldState(fieldName, `saved`);
      openSnackbar(SnackbarType.success, `Successfully updated ${itemName}`);
    } catch (error) {
      this._setFieldState(fieldName, `failed`);
      if (showErrorMessages) {
        openSnackbar(
          SnackbarType.error,
          `Failed to update ${itemName}, please try again`
        );
      }
    }
  }

  _handleBlur(values: any, fieldName: string) {
    const { hasChanged, schema } = this.props;
    const fieldValue = get(values, fieldName);
    // Get the sub-schema for the field
    const fieldSchema = schema && yup.reach(schema, fieldName, values);
    // Validate the value against the schema
    const valid = !fieldSchema || fieldSchema.isValidSync(fieldValue);

    if (valid) {
      // Save the value if it has changed
      if (hasChanged(fieldName, fieldValue)) {
        // Save this fields value
        this._save(fieldValue, fieldName);
      }
    }
  }

  _handleChange(fieldName: string) {
    // Reset field state on change
    this._setFieldState(fieldName, `normal`);
  }

  render() {
    const { children, className, item, schema } = this.props;
    const { fieldState } = this.state;

    return (
      // @ts-ignore
      <Formik initialValues={item} validationSchema={schema}>
        {(formikBag) => {
          const { values, handleChange, handleBlur } = formikBag;
          const getAutosaveFieldProps = (fieldName): AutosaveFieldProps => ({
            onBlur: (event) => {
              handleBlur(event);
              this._handleBlur(values, event.target.name);
            },
            onChange: (event) => {
              handleChange(event);
              this._handleChange(event.target.name);
            },
            state: item == null ? `saving` : fieldState[fieldName] || `normal`,
          });

          return (
            <Form className={className}>
              {children({
                formikBag,
                getAutosaveFieldProps,
              })}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props<any>>, EnhancedProps<any>>(
  withOpenSnackbar
);

export default enhancer(AutosaveForm);

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { WithOpenSnackbarVariables } from "./__generated__/WithOpenSnackbar";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export type OpenSnackbarFn = (type: SnackbarType, message: string) => void;

const OPEN_SNACKBAR = gql`
  mutation WithOpenSnackbar($message: String!, $type: SnackbarType!) {
    openSnackbar(message: $message, type: $type) @client {
      message
      open
      type
    }
  }
`;

export default graphql(OPEN_SNACKBAR, {
  props: ({ mutate }) => ({
    openSnackbar: ((type, message) => {
      mutate({
        variables: {
          message,
          type,
        } as WithOpenSnackbarVariables,
      });
    }) as OpenSnackbarFn,
  }),
});

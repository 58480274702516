import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Item } from "./ItemList";
import ItemListWithHeaders, {
  EnhancedProps as ItemListWithHeadersProps,
} from "./ItemListWithHeaders";

export type MenuAction = {
  perform: (itemID: string) => void;
  text: string;
};

export type Props = ItemListWithHeadersProps & {
  menuActions: MenuAction[];
};

type State = {
  menuAnchor?: HTMLElement | null | undefined;
  menuItemID?: string | null | undefined;
};

const MENU_BUTTON_WIDTH = 48;

/**
 * HOC to add menus to items in ItemList
 * @param {React.ComponentType} ItemListComponent ItemList or compatible component.
 * @returns {React.ComponentType} New list component.
 */
export const withMenu = (
  ItemListComponent: React.ComponentType<ItemListWithHeadersProps>
) => {
  class ItemListWithMenu extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        menuAnchor: undefined,
        menuItemID: undefined,
      };
    }

    handleMenuButtonPressed(buttonElement: HTMLElement, itemID: string) {
      this.setState({
        menuAnchor: buttonElement,
        menuItemID: itemID,
      });
    }

    handleMenuClosed() {
      this.setState({
        menuAnchor: undefined,
      });
    }

    handleActionPressed(action: MenuAction) {
      const { menuItemID } = this.state;

      if (menuItemID) {
        action.perform(menuItemID);
      }
      this.setState({ menuAnchor: undefined });
    }

    getSecondaryActions() {
      const { secondaryActions } = this.props;

      return [
        ...(secondaryActions || []),
        {
          render: ({ item }: { item: Item }) => (
            <IconButton
              onClick={(event) =>
                this.handleMenuButtonPressed(event.currentTarget, item.id)
              }
            >
              <MenuIcon />
            </IconButton>
          ),
          width: MENU_BUTTON_WIDTH,
        },
      ];
    }

    render() {
      const { menuActions, ...itemListProps } = this.props;
      const { menuAnchor } = this.state;

      return (
        <React.Fragment>
          <ItemListComponent
            {...itemListProps}
            secondaryActions={this.getSecondaryActions()}
          />
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={this.handleMenuClosed.bind(this)}
          >
            {menuActions.map((action, index) => (
              <MenuItem
                key={index}
                onClick={() => this.handleActionPressed(action)}
              >
                {action.text}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    }
  }

  return ItemListWithMenu;
};

export default withMenu(ItemListWithHeaders);

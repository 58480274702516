import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import userHasRoles from "../../../helpers/user/hasRoles";

export const GET_AUTH = gql`
  query RequireRolesGetAuth {
    auth: getAuth @client {
      roles
    }
  }
`;

type EnhancedProps = {
  children: React.ReactElement | React.ReactElement[];
  roles: string[];
};

type Props = EnhancedProps & {
  userRoles: string[] | null | undefined;
};

/**
 * Conditionally renders children depending on the user's roles.
 * @param {Props} props {roles}
 * @return {Node} node
 */
const RequireRoles = ({ children, roles: requiredRoles, userRoles }: Props) => {
  const hidden = !userHasRoles(userRoles, requiredRoles);

  return hidden ? null : children;
};

const enhancer = compose<Props, EnhancedProps>(
  graphql(GET_AUTH, {
    // @ts-ignore
    props: ({ data: { auth, loading } }) => ({
      userRoles: !loading && auth && auth.roles ? auth.roles : null,
    }),
  })
);

// @ts-ignore
export default enhancer(RequireRoles);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { StyleProps } from "../types";
import { SPACING_PAPER } from "../../../helpers/constants";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(SPACING_PAPER),
    padding: theme.spacing(SPACING_PAPER),
  },
});

type EnhancedProps = {
  children: React.ReactNode;
  heading?: string;
  noGutter?: boolean;
};

type Props = EnhancedProps & StyleProps;

class PaperSection extends React.PureComponent<Props> {
  render() {
    const { children, classes, heading, noGutter } = this.props;

    return (
      <Paper className={classes.paper}>
        {heading && (
          <Typography gutterBottom={!noGutter} variant="h5">
            {heading}
          </Typography>
        )}
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(PaperSection);

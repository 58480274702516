import { get } from "lodash";
import { InMemoryCache } from "apollo-cache-inmemory";

type Options = {
  activatedItemFragment: any; // Fragment for item being activated
  activatedItemID: string; // ID of the item being activated
  activatedItemTypeName: string; // GraphQL typename for active items
  activeItemsDataPath: string; // Path within data from active list query to access the list to mutate
  activeItemsQuery: any; // Query document for query with result containing the list to update
  activeItemsVariables?: any; // Variables to pass to active list query, update skipped if not defined
  getActiveItems?: (item: any) => any[]; // Map item from cache to active list items
};

/**
 * Create an update function that will add an item to a list in Apollo cache when an activate mutation is performed.
 * The item to activate is read from cache, and optionally mapped for where items in active list have a different shape.
 * @param {any} options Configuration for the query to update.
 * @return {void}
 */
const activateItemUpdate = (options: Options) => (store: InMemoryCache) => {
  if (options.activeItemsVariables == null) {
    return;
  }
  try {
    // Read the current active item list
    const activeItemsQuery = {
      query: options.activeItemsQuery,
      variables: options.activeItemsVariables,
    };
    const data = store.readQuery(activeItemsQuery);
    const activeList = get(data, options.activeItemsDataPath, null);
    // Read the activated item
    const item = store.readFragment({
      fragment: options.activatedItemFragment,
      id: `${options.activatedItemTypeName}:${options.activatedItemID}`,
    });
    if (item && activeList) {
      // Append the activated item to the active list
      const activeItems = options.getActiveItems
        ? options.getActiveItems(item)
        : [item];
      activeList.push(...activeItems);
      store.writeQuery({
        ...activeItemsQuery,
        data,
      });
    }
  } catch (error) {
    // Leave cache empty
  }
};

export default activateItemUpdate;

/*
 * Resolvers handling local user state and details required for determining authorization.
 */
import { Resolver } from "../apollo/types";
import AuthService from "../services/Auth";
import config from "../config";
import {
  dashboardRoute,
  unauthenticatedRoute,
  unverifiedRoute,
} from "../ui/routes";

export type Auth = {
  email: string | null;
  error: string | null;
  isLoggedIn: boolean;
  loginReturnPath: string | null;
  roles: string[];
  userID: string | null;
};

/**
 * Default auth data, before initialisation with state of the Auth service.
 *
 * The cache should not be initialised with this data, the first query needs to be
 * handled by the getAuth query resolver.
 */
export const defaults = {
  getAuth: {
    __typename: `Auth`,
    email: null,
    error: null,
    isLoggedIn: false,
    loginReturnPath: null,
    roles: [],
    userID: null,
  } as Auth,
};

// Construct Auth from the current state of the Auth service.
const getCurrentData = (authService: AuthService<string>): Auth => {
  const isLoggedIn = authService.isAuthenticated();
  const auth: Auth = {
    ...defaults.getAuth,
    isLoggedIn,
  };

  if (isLoggedIn) {
    const id = authService.getID();
    if (id) {
      // Extract data from the ID token
      auth.userID = id.sub || null;
      auth.email = id.email || null;
      auth.roles = id[`${config.auth0.customClaimsNamespace}roles`] || [];
    }
  }

  return auth;
};

export const resolvers = {
  Mutation: {
    login: (async (obj, { referrer }, { cache, authService }) => {
      const result = await authService.authorize(referrer);
      const getAuth: Auth = getCurrentData(authService);

      if (result.isAuthenticated) {
        // Authenticated silently
        getAuth.loginReturnPath = referrer || dashboardRoute();
        cache.writeData({ data: { getAuth } });
      }

      return getAuth;
    }) as Resolver<{ referrer?: string }, Promise<Auth | null | undefined>>,

    logout: ((obj, vars, { cache, authService }) => {
      authService.logout();

      const getAuth = getCurrentData(authService);
      cache.writeData({ data: { getAuth } });

      return getAuth;
    }) as Resolver<null, Auth>,

    performAuthentication: (async (obj, vars, { cache, authService }) => {
      const result = await authService.handleAuthentication();
      const getAuth = getCurrentData(authService);

      if (result.isAuthenticated) {
        if (result.isEmailVerified) {
          // Successfuly performed authentication
          getAuth.loginReturnPath = result.state || dashboardRoute();
        } else {
          // User authenticated, but email is not verified
          const { email } = getAuth;
          getAuth.loginReturnPath = unverifiedRoute(
            email != null ? email : `you`
          );
        }
      } else if (result.error) {
        const { message } = result.error;
        // Error occured during authentication, update getAuth
        getAuth.error = message;
        getAuth.loginReturnPath = unauthenticatedRoute();
      }
      cache.writeData({ data: { getAuth } });

      return getAuth;
    }) as Resolver<null, Promise<Auth>>,
  },

  Query: {
    getAuth: ((obj, vars, { authService }) =>
      getCurrentData(authService)) as Resolver<null, Auth>,
  },
};

export default {
  resolvers,
};

import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { QueryResult } from "react-apollo";
import { CustomField, FieldList } from "../generic/form/index";
import TextInput from "../generic/form/TextInput";
import CreateUpdateForm from "../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../generic/withCreateUpdateQueries";
import {
  RateCardFormCreateRateCard,
  RateCardFormCreateRateCardVariables,
} from "./__generated__/RateCardFormCreateRateCard";
import {
  RateCardFormUpdateRateCard,
  RateCardFormUpdateRateCardVariables,
} from "./__generated__/RateCardFormUpdateRateCard";
import { RateCardFormRateCard as RateCard } from "./__generated__/RateCardFormRateCard";
import { compose, withState } from "recompose";
import FileInput from "../generic/form/FileInput";
import getGraphqlException from "../../../helpers/getGraphqlException";
import { UploadSummary } from "../generic/uploadSummary/UploadSummaryText";
import UploadSummaryLink from "../generic/uploadSummary/UploadSummaryLink";

type MutationResult = QueryResult<
  RateCardFormCreateRateCard & RateCardFormUpdateRateCard
>;
type CreateUpdateResult = {
  deleteCount?: number;
  updateCount: number;
};

export const rateCardFormFragments = {
  rateCard: gql`
    fragment RateCardFormRateCard on RateCard {
      id
      name
      rates {
        id
      }
    }
  `,
  fullRateCard: gql`
    fragment RateCardFormFullRateCard on RateCard {
      id
      name
      rates {
        id
        service
        origin {
          name
        }
        destination {
          name
        }
        leadTime
        minimumPrice
        basicPrice
        range1Price
        range2Price
        range3Price
        range4Price
        range5Price
      }
    }
  `,
};

export const CREATE_RATE_CARD = gql`
  mutation RateCardFormCreateRateCard(
    $organisationID: String!
    $name: String!
    $file: Upload!
  ) {
    createRateCard(
      values: { name: $name }
      organisation: { id: $organisationID }
      rateCardFile: $file
    ) {
      ...RateCardFormRateCard
    }
  }
  ${rateCardFormFragments.rateCard}
`;

export const UPDATE_RATE_CARD = gql`
  mutation RateCardFormUpdateRateCard(
    $id: String!
    $organisationID: String!
    $name: String!
    $file: Upload
  ) {
    updateRateCard(
      id: $id
      values: { name: $name }
      organisation: { id: $organisationID }
      rateCardFile: $file
    ) {
      ...RateCardFormFullRateCard
    }
  }
  ${rateCardFormFragments.fullRateCard}
`;

export const GET_RATE_CARD = gql`
  query RateCardFormGetRateCard(
    $rateCardID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        rateCard(id: $rateCardID) {
          ...RateCardFormRateCard
        }
      }
    }
  }
  ${rateCardFormFragments.rateCard}
`;

export const GET_RATECARD_LIST = gql`
  query RateCardSelectGetOrganisationRateCardList($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        rateCardList {
          ...RateCardFormRateCard
        }
      }
    }
  }
  ${rateCardFormFragments.rateCard}
`;

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  organisationID: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  item?: Partial<RateCard>;
  createMutation: (
    rateCard: RateCardFormCreateRateCardVariables
  ) => Promise<MutationResult>;
  updateMutation: (
    rateCard: RateCardFormUpdateRateCardVariables
  ) => Promise<MutationResult>;
  uploadSummary?: UploadSummary;
  onNewUploadSummary: (summary?: UploadSummary) => void;
};

export class RateCardForm extends React.PureComponent<Props> {
  getMutationHandler(mutation: (values: any) => Promise<MutationResult>) {
    const { onNewUploadSummary, organisationID, item } = this.props;

    return async (values: any): Promise<CreateUpdateResult> => {
      try {
        const result: MutationResult = await mutation({
          ...values,
          organisationID,
        });
        const rateCard =
          result && result.data
            ? result.data.createRateCard || result.data.updateRateCard
            : null;

        return {
          deleteCount: item && item.rates.length,
          updateCount: rateCard ? rateCard.rates.length : 0,
        };
      } catch (error) {
        // Store info about failure
        const exception =
          getGraphqlException(error, [`createRateCard`], `BAD_USER_INPUT`) ||
          getGraphqlException(error, [`updateRateCard`], `BAD_USER_INPUT`);
        onNewUploadSummary({
          failure:
            exception != null
              ? {
                  badRowCount: exception.badRowCount || 0,
                  errors: exception.errors || [],
                  totalRowCount: exception.totalRowCount || 0,
                }
              : undefined,
        });
        throw error;
      }
    };
  }

  getSuccessMessage(
    { deleteCount, updateCount }: CreateUpdateResult,
    name: string,
    { file }: any
  ): string {
    if (deleteCount == null) {
      return `Saved ${name} with ${updateCount} rates`;
    } else if (file == null) {
      return `Updated ${name}`;
    } else {
      return `Updated ${name}, replaced ${deleteCount} rates with ${updateCount}`;
    }
  }

  render() {
    const {
      BodyContainer,
      itemID,
      uploadSummary,
      createMutation,
      updateMutation,
      ...createUpdateFormProps
    } = this.props;

    const fileSchema = yup.mixed().nullable().label(`Rate card file`);
    const schema = yup.object({
      name: yup.string().required().label(`Name`),
      file: itemID == null ? fileSchema.required() : fileSchema,
    });

    return (
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        itemType="rate card"
        itemTypeTitle="Rate Card"
        itemNameAccessor="name"
        successMessage={this.getSuccessMessage.bind(this)}
        // @ts-ignore
        createMutation={this.getMutationHandler(createMutation)}
        // @ts-ignore
        updateMutation={this.getMutationHandler(updateMutation)}
        {...createUpdateFormProps}
      >
        <FieldList component={BodyContainer}>
          <CustomField
            fullWidth
            autoFocus={itemID == null}
            name="name"
            label="Name"
            component={TextInput}
          />
          <CustomField
            fullWidth
            name="file"
            component={FileInput}
            inputProps={{ accept: `.csv` }}
          />
          <UploadSummaryLink
            uploadSummary={uploadSummary}
            itemTitleText="Rate Card"
            itemPluralText="rate cards"
          />
        </FieldList>
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createRateCard`,
    createMutation: CREATE_RATE_CARD,
    getItemAccessPath: `getSelf.organisation.rateCard`,
    getQuery: GET_RATE_CARD,
    getQueryVariables: ({ organisationID, itemID }) =>
      itemID
        ? {
            organisationID,
            rateCardID: itemID,
          }
        : undefined,
    listAccessPath: `getSelf.organisation.rateCardList`,
    listQuery: GET_RATECARD_LIST,
    listQueryVariables: ({ organisationID }) => ({
      organisationID,
    }),
    updateMutation: UPDATE_RATE_CARD,
  }),
  withState(`uploadSummary`, `onNewUploadSummary`, undefined)
);

export default enhancer(RateCardForm);

import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { GET_ACTIVE_MARKETPLACES } from "./MarketplaceCuration";
import { activeMarketplaceListFragments } from "./ActiveMarketplaceList";
import { MarketplaceFormCreateMarketplaceVariables } from "./__generated__/MarketplaceFormCreateMarketplace";
import { MarketplaceFormGetMarketplace_marketplace as Marketplace } from "./__generated__/MarketplaceFormGetMarketplace";
import { MarketplaceFormUpdateMarketplaceVariables } from "./__generated__/MarketplaceFormUpdateMarketplace";
import { compose } from "recompose";

export const marketplaceFormFragments = {
  marketplace: gql`
    fragment MarketplaceFormMarketplace on Marketplace {
      id
      name
      description
    }
  `,
};

export const CREATE_MARKETPLACE = gql`
  mutation MarketplaceFormCreateMarketplace(
    $industryID: String!
    $name: String!
    $description: String
  ) {
    createMarketplace(
      values: { name: $name, description: $description }
      industry: { id: $industryID }
    ) {
      ...MarketplaceFormMarketplace
      ...ActiveMarketplaceListMarketplace
    }
  }
  ${marketplaceFormFragments.marketplace}
  ${activeMarketplaceListFragments.marketplace}
`;

export const UPDATE_MARKETPLACE = gql`
  mutation MarketplaceFormUpdateMarketplace(
    $id: String!
    $name: String
    $description: String
  ) {
    updateMarketplace(
      id: $id
      values: { name: $name, description: $description }
    ) {
      ...MarketplaceFormMarketplace
      ...ActiveMarketplaceListMarketplace
    }
  }
  ${marketplaceFormFragments.marketplace}
  ${activeMarketplaceListFragments.marketplace}
`;

export const GET_MARKETPLACE = gql`
  query MarketplaceFormGetMarketplace($id: String!) {
    marketplace: getMarketplace(id: $id) {
      ...MarketplaceFormMarketplace
    }
  }
  ${marketplaceFormFragments.marketplace}
`;

const schema = yup.object({
  description: yup.string().label(`Description`).nullable(),
  name: yup.string().required().label(`Name`),
});

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  industryID?: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  item: Partial<Marketplace>;
  createMutation: (
    variables: MarketplaceFormCreateMarketplaceVariables
  ) => Promise<void>;
  updateMutation: (
    variables: MarketplaceFormUpdateMarketplaceVariables
  ) => Promise<void>;
};

export class MarketplaceForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      itemID,
      industryID,
      createMutation,
      ...createUpdateFormProps
    } = this.props;

    return (
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        itemType="marketplace"
        itemTypeTitle="Marketplace"
        itemNameAccessor="name"
        createMutation={(values) =>
          createMutation({
            ...values,
            industryID,
          })
        }
        {...createUpdateFormProps}
      >
        <FieldList component={BodyContainer}>
          <CustomField
            fullWidth
            autoFocus={itemID == null}
            name="name"
            label="Name"
            component={TextInput}
          />
          <CustomField
            fullWidth
            multiline
            name="description"
            label="Description"
            component={TextInput}
          />
        </FieldList>
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createMarketplace`,
    createMutation: CREATE_MARKETPLACE,
    updateMutation: UPDATE_MARKETPLACE,
    getQuery: GET_MARKETPLACE,
    getQueryVariables: ({ itemID }) => (itemID ? { id: itemID } : undefined),
    getItemAccessPath: `marketplace`,
    listQuery: GET_ACTIVE_MARKETPLACES,
    // Enable list of categories to be update in UI immediately on change
    listQueryVariables: ({ industryID }) => ({ industryID }),
    listAccessPath: `industry.marketplaceList`,
  })
);

export default enhancer(MarketplaceForm);

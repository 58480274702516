import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import ColumnHeader from "./ColumnHeader";
import EnhancedToolbar from "./EnhancedToolbar";
import { StyleProps } from "../../types";
import classnames from "classnames";
import { get } from "lodash";
import Fade from "@material-ui/core/Fade";
import { DEFAULT_TRANSITION_TIMEOUT } from "../../../../helpers/constants";
import { TableContainer, TablePagination } from "@material-ui/core";

const styles = (theme) => ({
  emptyContainer: {
    alignItems: "center" as const,
    display: "flex" as const,
    flex: 1,
    justifyContent: "center" as "center ",
    padding: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column" as const,
    overflow: "auto",
  },
  clickableRow: {
    cursor: "pointer",
  },
  loading: {
    height: "4px",
    maxHeight: "4px",
  },
  tableHeader: {
    backgroundColor: theme.overrides.MuiTableCell.stickyHeader.backgroundColor,
  },
});

export interface Column<Row> {
  Cell?: (arg0: { id: string; row: Row; value: any }) => React.ReactNode;
  Header: React.ReactNode;
  cellProps?: any;
  compact?: boolean;
  headerCellProps?: any;
  id: string;
  numeric?: boolean;
  disableSortable?: boolean;
}

export type SortDirection = "asc" | "desc";

export interface EnhancedProps<Row> {
  TableHeadItem?: React.ReactNode;
  ToolbarLeftItems?: React.ReactNode;
  ToolbarRightItems?: React.ReactNode;
  className?: string;
  columns: Column<Row>[];
  data: ReadonlyArray<Row>;
  disableStickyHeader?: boolean;
  emptyMessage: string;
  idAccessor?: string; // Where to access ID within a data item, defaults to 'id'
  loading?: boolean;
  onRowPress?: (id: string) => void;
  onSortPress?: (event: React.SyntheticEvent, columnID: string) => void;
  rowProps?: (arg0: { id: string; row: Row }) => any;
  sortColumn?: string; // ID of column that is currently sorted
  sortDirection?: SortDirection;
  tableProps?: any;
  pagination?: {
    handlePageChange: (event: unknown, newPage: number) => void;
    handleRowsPerPageChange: (newRowsPerPage) => void;
    pageOffset: number;
    itemsPerPage: number;
  };
}

interface Props<Row> extends StyleProps, EnhancedProps<Row> {}

/**
 * Basic stateless presentational component for a table of data.
 * Supports sortable columns and a toolbar.
 * Sorting data is left to the user.
 */
class DataTable<Row> extends React.PureComponent<Props<Row>> {
  static defaultProps = {
    emptyMessage: "No rows found",
    idAccessor: "id",
  };

  render() {
    const {
      TableHeadItem,
      ToolbarLeftItems,
      ToolbarRightItems,
      classes,
      className,
      columns,
      data,
      disableStickyHeader,
      emptyMessage,
      idAccessor,
      loading,
      onRowPress,
      onSortPress,
      rowProps,
      sortColumn,
      sortDirection,
      tableProps,
      pagination,
    } = this.props;

    return (
      <Paper className={classnames(classes.root, className)}>
        <div className={disableStickyHeader ? undefined : classes.tableHeader}>
          <Fade in={loading || false} timeout={DEFAULT_TRANSITION_TIMEOUT}>
            <LinearProgress className={classes.loading} />
          </Fade>

          {(ToolbarLeftItems || ToolbarRightItems) && (
            <EnhancedToolbar
              LeftItems={ToolbarLeftItems}
              RightItems={ToolbarRightItems}
            />
          )}
          {TableHeadItem}
        </div>

        <TableContainer>
          <Table
            stickyHeader={!disableStickyHeader}
            className={classes.table}
            {...tableProps}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <ColumnHeader
                    key={column.id}
                    column={column}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSortPress={onSortPress}
                  />
                ))}
              </TableRow>
            </TableHead>
            <Fade in={data != null && data.length > 0}>
              <TableBody>
                <>
                  {data.map((row) => {
                    const rowID = String(get(row, idAccessor));

                    return (
                      <TableRow
                        key={rowID}
                        className={classnames(
                          onRowPress && classes.clickableRow
                        )}
                        hover={onRowPress != null}
                        onClick={
                          onRowPress ? () => onRowPress(rowID) : undefined
                        }
                        {...(rowProps
                          ? rowProps({
                              id: rowID,
                              row,
                            })
                          : {})}
                      >
                        {columns.map((column) => {
                          const data = get(row, column.id);
                          let value = data;

                          // Check for an object with a display key
                          const display = get(data, `display`);

                          if (display) {
                            value = data.display;
                          }

                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric ? `right` : `left`}
                              {...column.cellProps}
                            >
                              {column.Cell
                                ? column.Cell({
                                    id: rowID,
                                    row,
                                    value,
                                  })
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              </TableBody>
            </Fade>
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={-1}
            rowsPerPage={pagination.itemsPerPage}
            page={pagination.pageOffset}
            onChangePage={pagination.handlePageChange}
            onChangeRowsPerPage={pagination.handleRowsPerPageChange}
          />
        )}

        <div className={classes.emptyContainer}>
          <Fade in={!loading && data.length === 0}>
            <Typography>{emptyMessage}</Typography>
          </Fade>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(DataTable);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../types";

const styles = () => ({
  root: {
    display: `flex`,
    flexDirection: `row` as const,
    height: `100vh`,
    position: `relative` as const,
  },
});

type Props = StyleProps & {
  children?: React.ReactNode;
};

const Root = ({ children, classes }: Props) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(Root);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import gql from "graphql-tag";
import { StyleProps } from "../../types";
import { WarehouseDetailsInfoWarehouse } from "./__generated__/WarehouseDetailsInfoWarehouse";
import WarehouseDetailsOperatingTimes, {
  warehouseDetailsOperatingTimesFragments,
} from "./WarehouseDetailsOperatingTimes";
import { get } from "lodash";
import { warehouseNameFragments } from "../WarehouseTitle";

export const warehouseDetailsInfoFragments = {
  warehouse: gql`
    fragment WarehouseDetailsInfoWarehouse on Warehouse {
      name
      addressLine1
      addressLine2
      city
      postcode {
        id
        postcode
        state
        country {
          id
          name
        }
      }
      telephoneNumber
      emailAddress
      contactName
      ...WarehouseNameWarehouse
      ...WarehouseDetailsOperatingTimesWarehouse
    }
    ${warehouseDetailsOperatingTimesFragments.warehouse}
    ${warehouseNameFragments.warehouse}
  `,
};

const styles = () => ({});

type Props = StyleProps & {
  warehouse: WarehouseDetailsInfoWarehouse;
};

class WarehouseDetailsInfo extends React.PureComponent<Props> {
  render() {
    const { warehouse } = this.props;

    return (
      <div>
        <Typography variant="subtitle2">Warehouse Address</Typography>
        {warehouse.addressLine1 != null && (
          <Typography variant="body1">{warehouse.addressLine1}</Typography>
        )}
        {warehouse.addressLine2 != null && (
          <Typography>{warehouse.addressLine2}</Typography>
        )}
        <Typography>
          {warehouse.city} {get(warehouse, `postcode.state`, ``)}
        </Typography>
        {warehouse.postcode != null && (
          <Typography>{warehouse.postcode.country.name}</Typography>
        )}
        {warehouse.postcode != null && (
          <Typography>{warehouse.postcode.postcode}</Typography>
        )}
        <br />

        <Typography variant="subtitle2">Contact Details</Typography>
        {warehouse.contactName != null && (
          <Typography>{warehouse.contactName}</Typography>
        )}
        {warehouse.telephoneNumber != null && (
          <Typography>{warehouse.telephoneNumber}</Typography>
        )}
        {warehouse.emailAddress != null && (
          <Typography>{warehouse.emailAddress}</Typography>
        )}
        <br />

        <WarehouseDetailsOperatingTimes warehouse={warehouse} />
      </div>
    );
  }
}

export default withStyles(styles)(WarehouseDetailsInfo);

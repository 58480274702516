import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  severeContent: {
    color: theme.customPalette.severe,
  },
});

type Props = StyleProps & {
  children?: React.ReactNode;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
  severe?: boolean;
  text?: React.ReactNode;
  title: string;
};

class ConfirmDialog extends React.Component<Props> {
  render() {
    const {
      children,
      classes,
      onAccept,
      onClose,
      open,
      severe,
      text,
      title,
    } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {severe && (
            <Typography
              gutterBottom
              variant={`h6`}
              className={classes.severeContent}
            >
              WARNING:
            </Typography>
          )}
          {children}
          <DialogContentText
            className={severe ? classes.severeContent : undefined}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>No</Button>
          <Button color="secondary" onClick={onAccept}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmDialog);

import * as React from "react";
import gql from "graphql-tag";
import { OrderDetailsDialogOrder } from "./__generated__/OrderDetailsDialogOrder";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { StyleProps } from "../../types";
import ShippingBatchTable, {
  shippingBatchTableFragments,
} from "./ShippingBatchTable";
import withStyles from "@material-ui/core/styles/withStyles";
import OrderDetailGrid, { orderDetailGridFragments } from "./OrderDetailsGrid";

const styles = (theme) => ({
  table: {
    marginTop: theme.spacing(1),
  },
});

export const orderDetailsFragments = {
  order: gql`
    fragment OrderDetailsDialogOrder on Order {
      id
      orderNumber
      ...OrderDetailGridOrder
      shippingBatchList {
        id
        ...ShippingBatchTableShippingBatch
      }
    }
    ${orderDetailGridFragments.order}
    ${shippingBatchTableFragments.shippingBatch}
  `,
};

type EnhancedProps = {
  loading?: boolean;
  open: boolean;
  order: OrderDetailsDialogOrder | null;
  updateShippingBatchStatus: (arg0: string, arg1: string) => Promise<void>;
  onClose: () => void;
};

type Props = EnhancedProps & StyleProps;

/**
 * Component to display order details in a dialog.
 */
export class OrderDetailsDialog extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      open,
      order,
      onClose,
      updateShippingBatchStatus,
    } = this.props;

    return (
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          Order
          {order && order.orderNumber != null && `: ${order.orderNumber}`}
        </DialogTitle>

        <DialogContent>
          <OrderDetailGrid order={order} />

          <ShippingBatchTable
            className={classes.table}
            loading={loading}
            shippingBatches={order && order.shippingBatchList}
            updateShippingBatchStatus={updateShippingBatchStatus}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(OrderDetailsDialog);

import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../generic/CallOnMount";
import { CallbackPerformAuthentication } from "./__generated__/CallbackPerformAuthentication";

export const PERFORM_AUTHENTICATION = gql`
  mutation CallbackPerformAuthentication {
    performAuthentication @client {
      isLoggedIn
    }
  }
`;

class PerformAuthenticationMutation extends Mutation<
  CallbackPerformAuthentication
> {}

const PerformAuthenticate = () => (
  <PerformAuthenticationMutation mutation={PERFORM_AUTHENTICATION}>
    {(performAuthentication) => (
      <CallOnMount callback={() => performAuthentication({})} />
    )}
  </PerformAuthenticationMutation>
);

export default PerformAuthenticate;

import React from "react";
import {
  Grid,
  Typography,
  Select,
  Button,
  withStyles,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { StyleProps } from "../../types";
import { AddDestinationTableOrderItemPart_destination } from "./__generated__/AddDestinationTableOrderItemPart";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";
import { DestinationLayoutOrderItem } from "./__generated__/DestinationLayoutOrderItem";

export const assignQtyCheckboxLabel =
  "Also assign all item quantities to this address";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "unset",
  },
  formControl: {
    width: "33vw",
    marginRight: theme.spacing(2),
  },
  select: {},
});

type Props = StyleProps & {
  organisationID: string;
  addressList: AddDestinationTableOrderItemPart_destination[];
  orderItems: DestinationLayoutOrderItem[];
  addDestinationToOrderItems: (
    arg0: UpdateOrderItemPartInput[]
  ) => Promise<void>;
};

type State = {
  assignAllAddressID: string;
  shouldAssignQuantities: boolean;
};

class AddDestinationToAllLayout extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { assignAllAddressID: "", shouldAssignQuantities: true };
  }

  componentDidUpdate() {
    const { orderItems } = this.props;
    if (this.shouldNotAllowAssignAllQuantities(orderItems)) {
      this.setState({
        ...this.state,
        shouldAssignQuantities: false,
      });
    }
  }

  async assignAddressToAll() {
    const {
      addDestinationToOrderItems,
      orderItems,
      organisationID,
    } = this.props;
    const { assignAllAddressID } = this.state;

    // Create OrderItemPartialInput array
    if (!!assignAllAddressID) {
      let updateOrderItemParts: UpdateOrderItemPartInput[] = [];

      orderItems.forEach((item) => {
        const input: UpdateOrderItemPartInput = {
          organisation: { id: organisationID },
          orderItem: { id: item.id },
          destination: { id: assignAllAddressID },
          quantityAllocated: this.state.shouldAssignQuantities
            ? item.quantity
            : undefined,
        };
        updateOrderItemParts = [...updateOrderItemParts, input];
      });

      // Execute mutation
      addDestinationToOrderItems(updateOrderItemParts);
    }
  }

  toggleShouldAssignQuantities = () =>
    this.setState({
      ...this.state,
      shouldAssignQuantities: !this.state.shouldAssignQuantities,
    });

  shouldNotAllowAssignAllQuantities = (
    orderItems: DestinationLayoutOrderItem[]
  ) => orderItems.some((item) => item.quantity - item.unallocated !== 0);

  render() {
    const { classes, addressList, orderItems } = this.props;
    const { assignAllAddressID, shouldAssignQuantities } = this.state;

    return (
      <Grid
        container
        direction="column"
        className={classes.root}
        alignItems="flex-start"
      >
        <Typography variant="h5">Quick Assign</Typography>
        <Grid container direction="row" alignItems="flex-end">
          <FormControl className={classes.formControl}>
            <InputLabel>Delivery Address</InputLabel>
            <Select
              inputProps={{
                "aria-label": "address-select",
                "data-testid": "address-select",
              }}
              className={classes.select}
              value={assignAllAddressID}
              onChange={(e) =>
                this.setState({ assignAllAddressID: e.target.value as string })
              }
            >
              <MenuItem key="blank-item" value="">
                None
              </MenuItem>
              {!!addressList &&
                addressList.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {`${address.addressLine1}, ${address.city}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => this.assignAddressToAll()}
            disabled={!assignAllAddressID}
          >
            ASSIGN TO ALL
          </Button>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldAssignQuantities}
              color="primary"
              onChange={this.toggleShouldAssignQuantities}
              disabled={this.shouldNotAllowAssignAllQuantities(orderItems)}
            />
          }
          label={assignQtyCheckboxLabel}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(AddDestinationToAllLayout);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { StyleProps } from "../../types";
import TimeInput from "../../generic/form/TimeInput";

const styles = () => ({
  root: {
    alignItems: `center`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    justifyContent: `center`,
  },

  textField: {
    width: `5em`,
  },
});

type Props = StyleProps & {
  closingTime: string;
  disabled?: boolean;
  onBlur: (event: any) => void;
  onTimesChange: (openingTime: string, closingTime: string) => void;
  openingTime: string;
  dayOfTheWeek: string;
};

const TimeRange = ({
  classes,
  closingTime,
  disabled,
  onBlur,
  onTimesChange,
  openingTime,
  dayOfTheWeek,
}: Props) => (
  <div className={classes.root}>
    <TextField
      className={classes.textField}
      disabled={disabled}
      value={openingTime}
      InputProps={{
        inputComponent: TimeInput,
      }}
      inputProps={{ "aria-label": `${dayOfTheWeek}-opening-time-textbox` }}
      onBlur={onBlur}
      onChange={(event) => {
        onTimesChange(event.target.value, closingTime);
      }}
    />
    -
    <TextField
      className={classes.textField}
      disabled={disabled}
      value={closingTime}
      InputProps={{ inputComponent: TimeInput }}
      inputProps={{ "aria-label": `${dayOfTheWeek}-closing-time-textbox` }}
      onBlur={onBlur}
      onChange={(event) => {
        onTimesChange(openingTime, event.target.value);
      }}
    />
  </div>
);

export default withStyles(styles)(TimeRange);

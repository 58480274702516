import * as React from "react";
import ConfirmDialog from "../../generic/ConfirmDialog";
import { withPropsOnChange } from "recompose";

type Props = {
  // ID of class to be deleted
  classID?: string;
  name: string | null | undefined;
  onAccept: (classID: string) => void;
  onClose: () => void;
};

class ConfirmDeleteClass extends React.PureComponent<Props> {
  render() {
    const { classID, name, onAccept, onClose } = this.props;

    return (
      <ConfirmDialog
        severe
        open={classID != null}
        text={
          <React.Fragment>
            Do you really want to delete the class
            <strong>{name ? ` ${name}` : ``}</strong>?
          </React.Fragment>
        }
        title="Delete Class"
        onAccept={() => classID != null && onAccept(classID)}
        onClose={onClose}
      />
    );
  }
}

export default withPropsOnChange(
  // Update name only when classID is set
  (_prevProps, { classID }) => classID != null,
  ({ name }) => ({ name })
)(ConfirmDeleteClass);

import * as React from "react";
import * as yup from "yup";
import ShoppingCartTable from "./Table";
import { ShoppingCartTableIncludesProductListFragment as ShoppingCartIncludesProduct } from "./__generated__/ShoppingCartTableIncludesProductListFragment";
import createAutosaveListForm from "../../generic/AutosaveListForm";
import { StyleProps } from "../../types";

const AutosaveListForm = createAutosaveListForm({
  itemIDAccessor: `Product.id`,
  getValuesForItem: (item: { quantity: number }) => ({
    quantity: item.quantity,
  }),
  itemSchema: yup.object().shape({
    quantity: yup
      .number()
      .typeError(`Quantity must be a number`)
      .nullable()
      .required()
      .positive()
      .integer()
      .label(`Quantity`),
  }),
});

export type Props = StyleProps & {
  className?: string;
  productList: ShoppingCartIncludesProduct[];
  loading: boolean;
  updateShoppingCartProductQuantity: (
    productID: string,
    quantity: number
  ) => Promise<void>;
  removeProductFromShoppingCart: (productID: string) => Promise<void>;
};

/**
 * Component to manage field state and autosaving for the items in a shopping cart.
 */
class ShoppingCartForm extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      className,
      productList,
      loading,
      removeProductFromShoppingCart,
      updateShoppingCartProductQuantity,
      ...otherProps
    } = this.props;

    return (
      // @ts-ignore
      <AutosaveListForm
        className={className}
        key={String(productList.length)} // Reset state when items are loaded
        items={productList}
        updateItem={(productID, field, value) =>
          updateShoppingCartProductQuantity(productID, value)
        }
        showErrorMessages={false}
        itemName="product"
        {...otherProps}
      >
        {/* @ts-ignore */}
        {({ getAutosaveListFieldProps }) => (
          <ShoppingCartTable
            className={classes.table}
            productList={productList}
            loading={loading}
            getFieldProps={getAutosaveListFieldProps}
            onDeletePressed={removeProductFromShoppingCart}
          />
        )}
      </AutosaveListForm>
    );
  }
}

export default ShoppingCartForm;

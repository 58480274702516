import React from "react";
import MainTitle from "../app/MainTitle";
import gql from "graphql-tag";
import { WarehouseNameWarehouse } from "./__generated__/WarehouseNameWarehouse";

export const warehouseNameFragments = {
  warehouse: gql`
    fragment WarehouseNameWarehouse on Warehouse {
      name
    }
  `,
};

type Props = {
  warehouse?: WarehouseNameWarehouse;
};

const WarehouseName = ({ warehouse }: Props) => (
  <MainTitle>{warehouse ? warehouse.name : `Warehouse`}</MainTitle>
);

export default WarehouseName;

import * as React from "react";
import { compose } from "recompose";
import gql from "graphql-tag";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { GET_CONFIRMED_ORDER } from "../confirmation/ConfirmationData";
import ShippingLayout, { shippingLayoutFragments } from "./ShippingLayout";
import { ShippingDataGetShippingBatches_getSelf_shoppingCart_order_shippingBatchList } from "./__generated__/ShippingDataGetShippingBatches";

export const CREATE_SHIPPING_BATCHES_FOR_ORGANISATION = gql`
  mutation ShippingDataCreateShippingBatches($organisationID: String!) {
    createShippingBatchesForOrderForOrganisation(
      organisation: { id: $organisationID }
    )
  }
`;

export const GET_SHIPPING_BATCHES = gql`
  query ShippingDataGetShippingBatches($organisationID: String!) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        order {
          id
          isFullyCarried
          ...ShippingLayoutOrder
        }
      }
    }
  }
  ${shippingLayoutFragments.order}
`;

export const CONFIRM_ORDER_MUTATION = gql`
  mutation ConfirmationDataConfirmOrder($organisationID: String!) {
    confirmOrder(organisation: { id: $organisationID })
  }
`;

type EnhancedProps = {
  organisationID: string;
  onBack: () => void;
  onNext: (orderID: string) => void;
  onEditPressed: (arg0: string) => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  shippingBatchList: ShippingDataGetShippingBatches_getSelf_shoppingCart_order_shippingBatchList[];
  isFullyCarried: boolean;
  orderID?: string;
  createShippingBatchesForOrganisation: (
    organisationID: string
  ) => Promise<void>;
  confirmOrder: (organisationID: string) => Promise<void>;
};

class ShippingData extends React.PureComponent<Props> {
  async componentDidMount(): Promise<void> {
    // Allocate warehouses and create Shipping Batches for the order
    await this.props.createShippingBatchesForOrganisation(
      this.props.organisationID
    );
  }

  render() {
    const {
      loading,
      confirmOrder,
      shippingBatchList,
      isFullyCarried,
      orderID,
      organisationID,
      onBack,
      onNext,
      onEditPressed,
    } = this.props;

    return (
      <ShippingLayout
        loading={loading}
        shippingBatchList={shippingBatchList || []}
        isFullyCarried={isFullyCarried}
        onBack={onBack}
        onNext={async () => {
          await confirmOrder(organisationID);
          onNext(orderID ? orderID : ``);
        }}
        onEditPressed={onEditPressed}
      />
    );
  }
}

export const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_SHIPPING_BATCHES, {
    dataPaths: {
      shippingBatchList: `getSelf.shoppingCart.order.shippingBatchList`,
      isFullyCarried: `getSelf.shoppingCart.order.isFullyCarried`,
      orderID: `getSelf.shoppingCart.order.id`,
    },
    errorOnNull: true,
    errorMessage: `Failed to load shipping batches`,
    loadingProp: `loading`,
    options: ({ organisationID }: EnhancedProps) => ({
      variables: {
        organisationID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ organisationID }) => organisationID == null,
  }),
  withMutation(CREATE_SHIPPING_BATCHES_FOR_ORGANISATION, {
    mapMutationToProps: (createShippingBatchesForOrganisation) => ({
      createShippingBatchesForOrganisation,
    }),
    mutationOptions: ({ organisationID }) => ({
      variables: {
        organisationID,
      },
      refetchQueries: [
        {
          query: GET_SHIPPING_BATCHES,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        createShippingBatchesForOrderForOrganisation: `SUCCESS`,
      },
      awaitRefetchQueries: true,
    }),
    successMessage: `Successfully allocated order items from available warehouses`,
    errorMessage: `Could not create the shipping batches`,
  }),
  withMutation(CONFIRM_ORDER_MUTATION, {
    mapMutationToProps: (confirmOrder) => ({ confirmOrder }),
    mutationOptions: ({ organisationID, orderID }) => ({
      variables: {
        organisationID,
      },
      refetchQueries: [
        {
          query: GET_CONFIRMED_ORDER,
          variables: {
            organisationID,
            orderID,
          },
        },
      ],
      optimisticResponse: {
        confirmOrder: `SUCCESS`,
      },
      awaitRefetchQueries: true,
    }),
    successMessage: `Order confirmed.`,
    errorMessage: `Unable to confirm order`,
  })
);

export default enhancer(ShippingData);

import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { loginRoute } from "../index";

class LoggedOut extends React.Component {
  render() {
    return (
      <MainPanel title="Logged Out">
        <Typography>You have been logged out.</Typography>
        <Button component={Link} to={loginRoute()}>
          Log in again
        </Button>
      </MainPanel>
    );
  }
}

export default LoggedOut;

import * as React from "react";
import { QueryResult, graphql } from "react-apollo";
import gql from "graphql-tag";
import { FormikBag, Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import withSelection, { Selection } from "../generic/withSelection";
import { CustomField } from "../generic/form/index";
import FileInput from "../generic/form/FileInput";
import { compose } from "recompose";
import { GET_OWN_ORGANISATION_PRODUCTS_OVERVIEW } from "./ProductTable";
import { productTableFragments } from "./catalogueView/IncludedProductTable";
import getGraphqlException from "../../../helpers/getGraphqlException";
import UploadFormSubmitContainer from "./UploadFormSubmitContainer";
import UploadSummaryLink from "../generic/uploadSummary/UploadSummaryLink";
import { UploadSummary } from "../generic/uploadSummary/UploadSummaryText";
import { ProductUploadFormUploadProducts } from "./__generated__/ProductUploadFormUploadProducts";
import { SnackbarType } from "../../../__generated__/globalTypes";
import { CloudUpload } from "@material-ui/icons";

export const UPLOAD_PRODUCTS = gql`
  mutation ProductUploadFormUploadProducts(
    $organisationID: String!
    $marketplaceID: String!
    $file: Upload!
  ) {
    updateOwnOrganisationProducts(
      organisation: { id: $organisationID }
      marketplace: { id: $marketplaceID }
      productFile: $file
    ) {
      deleteCount
      updateCount
    }
  }
`;

export const GET_CATALOGUE_VIEWS_WITH_PRODUCTS = gql`
  query UploadFormCatalogueViewUpdate(
    $marketplaceID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueViewList(marketplaceId: $marketplaceID) {
          id
          name
          includesProductList {
            ...CatalogueViewIncludedProductTableIncludesProduct
          }
        }
      }
    }
  }
  ${productTableFragments.catalogueViewIncludesProduct}
`;

export type EnhancedProps = {
  organisationID: string | null | undefined;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
  selection: Selection;
  uploadProducts: (
    organisationID: string,
    marketplaceID: string,
    file: any
  ) => Promise<QueryResult<ProductUploadFormUploadProducts>>;
};

type State = {
  uploadSummary?: UploadSummary;
};

type Values = {
  productFile: any;
};

export class ProductUploadForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploadSummary: undefined,
    };
  }

  async _handleSubmit(
    values: Values,
    { resetForm, setSubmitting }: FormikBag<any, Values>
  ) {
    const {
      uploadProducts,
      openSnackbar,
      organisationID,
      selection: { activeMarketplaceID },
    } = this.props;

    if (organisationID != null && activeMarketplaceID != null) {
      try {
        const successResult: QueryResult<ProductUploadFormUploadProducts> = await uploadProducts(
          organisationID,
          activeMarketplaceID,
          values.productFile
        );
        // Show snackbar on success
        openSnackbar(SnackbarType.success, `Successfully uploaded products`);
        setSubmitting(false);
        resetForm();
        // Store info about success
        this.setState({
          uploadSummary: {
            success:
              successResult &&
              successResult.data &&
              successResult.data.updateOwnOrganisationProducts
                ? {
                    deleteCount:
                      successResult.data.updateOwnOrganisationProducts
                        .deleteCount,
                    updateCount:
                      successResult.data.updateOwnOrganisationProducts
                        .updateCount,
                  }
                : undefined,
          },
        });
      } catch (error) {
        openSnackbar(SnackbarType.error, `Failed to upload products`);
        setSubmitting(false);
        // Store info about failure
        const exception = getGraphqlException(
          error,
          [`updateOwnOrganisationProducts`],
          `BAD_USER_INPUT`
        );
        this.setState({
          uploadSummary:
            exception != null
              ? {
                  failure: {
                    badRowCount: exception.badRowCount || 0,
                    errors: exception.errors || [],
                    totalRowCount: exception.totalRowCount || 0,
                  },
                }
              : undefined,
        });
      }
    }
  }

  render() {
    const {
      selection: { activeMarketplaceID },
    } = this.props;

    return (
      // @ts-ignore
      <Formik onSubmit={this._handleSubmit.bind(this)}>
        {(formikBag) => (
          <Form>
            <CustomField
              fullWidth
              name="productFile"
              component={FileInput}
              inputProps={{ accept: `.csv` }}
            />
            <Typography>
              Warning: products missing from the file will be deleted
            </Typography>
            <UploadFormSubmitContainer>
              <Button
                color="primary"
                disabled={
                  formikBag.isSubmitting ||
                  !formikBag.dirty ||
                  !formikBag.values.productFile ||
                  activeMarketplaceID == null
                }
                startIcon={<CloudUpload />}
                type="submit"
                variant={"contained"}
              >
                Upload
              </Button>
              <UploadSummaryLink
                itemTitleText="Product"
                itemPluralText="products"
                uploadSummary={this.state.uploadSummary}
              />
            </UploadFormSubmitContainer>
          </Form>
        )}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props>, any>(
  withOpenSnackbar as any,
  withSelection,
  graphql(UPLOAD_PRODUCTS, {
    props: ({ mutate }) => ({
      uploadProducts: (
        organisationID: string,
        marketplaceID: string,
        file: any
      ) =>
        mutate({
          refetchQueries: () => [
            {
              query: GET_OWN_ORGANISATION_PRODUCTS_OVERVIEW,
              variables: {
                marketplaceID,
                organisationID,
              },
            },
            {
              query: GET_CATALOGUE_VIEWS_WITH_PRODUCTS,
              variables: {
                marketplaceID,
                organisationID,
              },
            },
          ],
          variables: {
            file,
            marketplaceID,
            organisationID,
          },
        }),
    }),
  })
);

export default enhancer(ProductUploadForm);

import gql from "graphql-tag";
import { RemoveRolesFromUserUpdateUser as User } from "./__generated__/RemoveRolesFromUserUpdateUser";
import { InMemoryCache } from "apollo-cache-inmemory";

const userFragment = gql`
  fragment RemoveRolesFromUserUpdateUser on User {
    id
    roles {
      id
    }
  }
`;

const removeRolesFromUserUpdate = (userID: string, id: string) => (
  store: InMemoryCache
) => {
  // Read an existing user from the cache
  const user: User | null | undefined = store.readFragment({
    fragment: userFragment,
    id: `User:${userID}`,
  });
  if (user && user.roles) {
    const { roles } = user;
    // Remove the user role from the user
    const userRoleIndex = roles.findIndex((role) => role.id === id);
    if (userRoleIndex >= 0) {
      roles.splice(userRoleIndex, 1);
      store.writeFragment({
        data: user,
        fragment: userFragment,
        id: `User:${userID}`,
      });
    }
  }
};

export default removeRolesFromUserUpdate;

import * as React from "react";
import gql from "graphql-tag";
import AddDestinationToItemLayout, {
  addDestinationToItemLayoutFragments,
} from "./AddDestinationToItemLayout";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { compose } from "recompose";
import { GET_ORDER_ITEM_DESTINATION_LIST } from "./AddDestinationData";
import { AddDestinationToItemDataGetAddressList_getSelf_shoppingCart_order_orderItem_unallocatedDestinationList as UnallocatedAddresses } from "./__generated__/AddDestinationToItemDataGetAddressList";
import { ADD_DESTINATIONS } from "./AddDestinationToAllData";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";

export const GET_ADDRESS_LIST = gql`
  query AddDestinationToItemDataGetAddressList(
    $organisationID: String!
    $orderItemID: String!
  ) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        order {
          id
          orderItem(id: $orderItemID) {
            id
            unallocatedDestinationList {
              id
              ...AddDestinationToItemLayoutAddress
            }
          }
        }
      }
    }
  }
  ${addDestinationToItemLayoutFragments.address}
`;

type EnhancedProps = {
  organisationID: string;
  orderItemID: string | null | undefined;
  open: boolean;
  onClose: () => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  unallocatedAddressList: UnallocatedAddresses[];
  addDestinationToOrderItem: (
    orderItemParts: UpdateOrderItemPartInput[]
  ) => Promise<void>;
};

class AddDestinationToItemData extends React.PureComponent<Props> {
  render() {
    const {
      open,
      loading,
      unallocatedAddressList,
      addDestinationToOrderItem,
      organisationID,
      orderItemID,
      onClose,
    } = this.props;

    return (
      <AddDestinationToItemLayout
        loading={loading}
        organisationID={organisationID}
        orderItemID={orderItemID}
        addressList={unallocatedAddressList || []}
        open={open}
        onClose={onClose}
        onAddDestinationPressed={addDestinationToOrderItem}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_ADDRESS_LIST, {
    dataPaths: {
      unallocatedAddressList: `getSelf.shoppingCart.order.orderItem.unallocatedDestinationList`,
    },
    errorMessage: `Failed to load unallocated delivery addresses`,
    loadingProp: `loading`,
    options: ({ organisationID, orderItemID }: EnhancedProps) => ({
      variables: {
        organisationID,
        orderItemID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ orderItemID }) => orderItemID == null,
  }),
  withMutation(ADD_DESTINATIONS, {
    mapMutationToProps: (addDestinationToOrderItem) => ({
      addDestinationToOrderItem,
    }),
    errorMessage: `Unable to add address as delivery address`,
    mutationOptions: (
      { orderItemID, organisationID },
      [updateOrderItemParts]
    ) => ({
      variables: {
        updateOrderItemParts,
      },
      refetchQueries: [
        {
          query: GET_ORDER_ITEM_DESTINATION_LIST,
          variables: {
            organisationID,
            orderItemID,
          },
        },
        {
          query: GET_ADDRESS_LIST,
          variables: {
            organisationID,
            orderItemID,
          },
        },
      ],
    }),
  })
);

export default enhancer(AddDestinationToItemData);

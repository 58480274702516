import * as React from "react";
import * as H from "history";
import queryString from "query-string";

// Props passed to the wrapped component
type ProvidedProps<Q> = {
  queryParams: Q;
  setQueryParams: (params: Partial<Q>, basePath?: string) => void;
};

// Props required by the resulting enhanced component
type EnhancedProps = {
  history: H.History;
  location: Location;
};

/**
 * HOC to map query parameters from the search string in React-Router's Location to props.
 * @return {ComponentType} Component wrapping provided component
 */
const withQueryParams = <Q extends any, P extends any>() => (
  Component: React.ComponentType<P & ProvidedProps<Q>>
) => {
  class WithQueryParams extends React.PureComponent<EnhancedProps & P> {
    handleSetQueryParams(params: Partial<Q>, basePath?: string) {
      const { history, location } = this.props;

      const prevParams = queryString.parse(location.search);
      const newParams = {
        ...prevParams,
        ...params,
      };

      history.push(
        `${
          basePath == null ? location.pathname : basePath
        }?${queryString.stringify(newParams)}`
      );
    }

    render() {
      const { location } = this.props;
      const queryParams = queryString.parse(location.search);

      return (
        // @ts-ignore
        <Component
          queryParams={queryParams}
          setQueryParams={this.handleSetQueryParams.bind(this)}
          {...this.props}
        />
      );
    }
  }

  return WithQueryParams;
};

export default withQueryParams;

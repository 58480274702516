import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ItemList from "../../generic/ItemListWithHeaders";
import getListItems from "../../generic/getListItems";
import { WarehouseServiceAreasServiceArea as ServiceArea } from "./__generated__/WarehouseServiceAreasServiceArea";

const DELETE_COLUMN_WIDTH = 48;

type Props = {
  className?: string;
  loading?: boolean;
  onPressDelete: (id: string) => void;
  serviceAreas?: ServiceArea[];
};

/**
 * Component to display a single row of an operating times table
 */
class ServiceAreaList extends React.PureComponent<Props> {
  render() {
    const { className, loading, serviceAreas, onPressDelete } = this.props;

    return (
      <ItemList
        className={className}
        items={getListItems(serviceAreas, { default: [] })}
        loading={loading}
        secondaryActions={[
          {
            render: ({ item: { id } }) => (
              <IconButton onClick={() => onPressDelete(id)}>
                <DeleteIcon />
              </IconButton>
            ),
            width: DELETE_COLUMN_WIDTH,
          },
        ]}
      />
    );
  }
}

export default ServiceAreaList;

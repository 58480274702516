import OrganisationIndustryLayout from "./OrganisationIndustryLayout";
import gql from "graphql-tag";
import { compose } from "recompose";
import withCurationQueries from "../../../generic/curation/withCurationQueries";
import withActivateHandler from "../../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../../generic/curation/withDeactivateHandler";

export const organisationIndustryFragments = {
  industry: gql`
    fragment OrganisationIndustry on Industry {
      id
      name
    }
  `,
};

export const GET_AVAILABLE_INDUSTRIES = gql`
  query OrganisationIndustriesGetAvailableIndustries {
    industries: getIndustryList {
      ...OrganisationIndustry
    }
  }
  ${organisationIndustryFragments.industry}
`;

export const GET_ORGANISATION_INDUSTRIES = gql`
  query OrganisationIndustriesGetOrganisationIndustries(
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        activeIndustryList {
          ...OrganisationIndustry
        }
      }
    }
  }
  ${organisationIndustryFragments.industry}
`;

export const ADD_TO_INDUSTRY = gql`
  mutation OrganisationIndustriesAddToIndustry(
    $organisationID: String!
    $industryID: String!
  ) {
    addOrganisationToIndustry(
      from: { id: $organisationID }
      to: { id: $industryID }
    ) {
      to {
        id
      }
    }
  }
`;

export const REMOVE_ORGANISATION_TO_INDUSTRY = gql`
  mutation OrganisationIndustryAffiliationsRemoveCategorySubscriptionToOrganisation(
    $organisationID: String!
    $industryID: String!
  ) {
    removeOrganisationToIndustry(
      from: { id: $organisationID }
      to: { id: $industryID }
    )
  }
`;

export type EnhancedProps = {
  onPressMarketplaces: (marketplaceID: string) => void;
  organisationID: string;
};

const enhancer = compose<Partial<any>, EnhancedProps>(
  withCurationQueries({
    activate: {
      mutation: ADD_TO_INDUSTRY,
      optimisticResponse: {
        addOrganisationToIndustry: {
          __typename: `OrganisationInIndustry`,
          to: {
            __typename: `Industry`,
            id: `industryID`,
          },
        },
      },
      variables: (industryID, { organisationID }) => ({
        industryID,
        organisationID,
      }),
    },
    activeItems: {
      dataPath: `getSelf.organisation.activeIndustryList`,
      fragment: organisationIndustryFragments.industry,
      query: GET_ORGANISATION_INDUSTRIES,
      variables: ({ organisationID }) => ({ organisationID }),
    },
    activeListName: `organisation`,
    availableItems: {
      dataPath: `industries`,
      query: GET_AVAILABLE_INDUSTRIES,
      variables: () => ({}),
    },
    deactivate: {
      mutation: REMOVE_ORGANISATION_TO_INDUSTRY,
      optimisticResponse: {
        removeOrganisationToIndustry: `SUCCESS`,
      },
      variables: (industryID, { organisationID }) => ({
        industryID,
        organisationID,
      }),
    },
    itemLabel: `industry`,
    itemLabelPlural: `industries`,
    itemTypeName: `Industry`,
  }),
  withActivateHandler(
    `selectedAvailableItems`,
    `onSelectedAvailableItemsChange`
  ),
  withDeactivateHandler(`selectedActiveItems`, `onSelectedActiveItemsChange`)
);

export default enhancer(OrganisationIndustryLayout);

import gql from "graphql-tag";
import SubscribedOrganisationLayout, {
  EnhancedProps as LayoutProps,
} from "./SubscribedOrganisationLayout";
import withSelection from "../../../generic/withSelection";
import withCurationQueries from "../../../generic/curation/withCurationQueries";
import withActivateHandler from "../../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../../generic/curation/withDeactivateHandler";
import { compose, withState } from "recompose";

// Define fragments to be used by queries
const subscribedOrganisationDataFragments = {
  catalogueViewDetails: gql`
    fragment SubscribedOrganisationCatalogueViewDetails on CatalogueView {
      id
      name
      isGeneratedDefault
    }
  `,
  partialOrganisation: gql`
    fragment SubscribedOrganisationPartialOrganisation on PartialOrganisation {
      id
      name
      addressLine1
      addressLine2
    }
  `,
};

// Define queries to be used by withCurationQueries
const GET_PUBLIC_ORGANISATION_LIST = gql`
  query SubscribedOrganisationDataGetPublicOrganisationList {
    publicOrganisationList: getPublicOrganisationList {
      ...SubscribedOrganisationPartialOrganisation
    }
  }
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

export const GET_SUBSCRIBED_ORGANISATIONS_TO_CATALOGUE_VIEW = gql`
  query SubscribedOrganisationDataGetSubscribedOrganisationsToCatalogueView(
    $catalogueViewID: String!
    $organisationID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueView(id: $catalogueViewID) {
          ...SubscribedOrganisationCatalogueViewDetails
          subscribedOrganisationList {
            ...SubscribedOrganisationPartialOrganisation
          }
        }
      }
    }
  }
  ${subscribedOrganisationDataFragments.catalogueViewDetails}
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

const ADD_CATALOGUE_VIEW_TO_ORGANISATION = gql`
  mutation SubscribedOrganisationDataSubscribeOrganisationToCatalogueView(
    $catalogueViewID: String!
    $organisationID: String!
  ) {
    addCatalogueViewToOrganisation(
      from: { id: $catalogueViewID }
      to: { id: $organisationID }
    ) {
      ...SubscribedOrganisationPartialOrganisation
    }
  }
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

const REMOVE_CATALOGUE_VIEW_TO_ORGANISATION = gql`
  mutation SubscribedOrganisationDataRemoveOrganisationsSubscriptionToCatalogueView(
    $catalogueViewID: String!
    $organisationID: String!
    $shouldReassign: Boolean!
  ) {
    removeCatalogueViewToOrganisation(
      from: { id: $catalogueViewID }
      to: { id: $organisationID }
    ) @skip(if: $shouldReassign)
    reassignOrganisationToDefaultCatalogue(
      organisation: { id: $organisationID }
      catalogueView: { id: $catalogueViewID }
    ) @include(if: $shouldReassign)
  }
`;

/*
 *	We need to fetch all subscriptions to each catalogue to update the cache in apollo.
 *	This ensures that the list of subscriptions to the default catalogue view gets updated
 *	when some subscriptions get reassigned.
 */
const REFETCH_CATALOGUE_VIEW_SUBSCRIPTIONS = gql`
  query SubscribedOrganisationDataRefetchCatalogueViewSubscriptions(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        catalogueViewList(marketplaceId: $marketplaceID) {
          id
          subscribedOrganisationList {
            ...SubscribedOrganisationPartialOrganisation
          }
        }
      }
    }
  }
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

export type EnhancedProps = {
  catalogueViewID: string | null | undefined;
  organisationID: string;
};

const enhancer = compose<LayoutProps, EnhancedProps>(
  withSelection,
  withCurationQueries({
    activate: {
      mutation: ADD_CATALOGUE_VIEW_TO_ORGANISATION,
      optimisticResponse: {
        addCatalogueViewToOrganisation: {
          __typename: `PartialOrganisation`,
          id: `id`,
          name: `name`,
        },
      },
      variables: (organisationID, { catalogueViewID }) =>
        catalogueViewID != null
          ? {
              catalogueViewID,
              organisationID,
            }
          : undefined,
    },
    activeItems: {
      dataPath: `self.organisation.catalogueView.subscribedOrganisationList`,
      extraProps: (data) => ({
        catalogueViewDetails:
          (data &&
            data.self &&
            data.self.organisation &&
            data.self.organisation.catalogueView) == null
            ? undefined
            : {
                isGeneratedDefault:
                  data.self.organisation.catalogueView.isGeneratedDefault,
                name: data.self.organisation.catalogueView.name,
              },
      }),
      fragment: subscribedOrganisationDataFragments.partialOrganisation,
      query: GET_SUBSCRIBED_ORGANISATIONS_TO_CATALOGUE_VIEW,
      variables: ({ catalogueViewID, organisationID }) =>
        catalogueViewID != null
          ? {
              catalogueViewID,
              organisationID,
            }
          : undefined,
    },
    activeListName: `catalogue view's publish list`,
    availableItems: {
      dataPath: `publicOrganisationList`,
      query: GET_PUBLIC_ORGANISATION_LIST,
      variables: () => ({}),
    },
    deactivate: {
      mutation: REMOVE_CATALOGUE_VIEW_TO_ORGANISATION,
      optimisticResponse: {
        reassignOrganisationToDefaultCatalogue: `SUCCESS`,
        removeCatalogueViewToOrganisation: `SUCCESS`,
      },
      refetchQueries: ({ selection, organisationID }) => [
        {
          query: REFETCH_CATALOGUE_VIEW_SUBSCRIPTIONS,
          variables: {
            marketplaceID: selection.activeMarketplaceID,
            organisationID,
          },
        },
      ],
      variables: (organisationID, { catalogueViewID }, shouldReassign) =>
        catalogueViewID != null
          ? {
              catalogueViewID,
              organisationID,
              shouldReassign,
            }
          : undefined,
    },
    itemLabel: `selected organisation`,
    itemLabelPlural: `selected organisations`,
    itemTypeName: `PartialOrganisation`,
  }),
  withActivateHandler(
    `subscribingOrganisationID`,
    `setSubscribingOrganisationID`
  ),
  withDeactivateHandler(
    `unsubscribingOrganisationID`,
    `setUnsubscribingOrganisationID`
  ),
  withState(
    `addSubscriptionsDialogOpen`,
    `setAddSubscriptionsDialogOpen`,
    false
  ),
  withState(
    `confirmUnsubscribeDialogOpen`,
    `setConfirmUnsubscribeDialogOpen`,
    false
  )
);

export default enhancer(SubscribedOrganisationLayout);

import * as React from "react";
import gql from "graphql-tag";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SubscribedOrganisationList from "../../../generic/SubscribedOrganisationList";
import { Theme, withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../../types";
import { InviteNewUserToCatalogueViewData } from "./InviteNewUserToCatalogueViewData";
import { AddSubscribedOrganisationDialogLayoutFragmentPartialOrganisation as PartialOrganisation } from "./__generated__/AddSubscribedOrganisationDialogLayoutFragmentPartialOrganisation";
import { Close, Email, Publish } from "@material-ui/icons";

export const addSubscribedOrganisationDialogLayoutFragments = {
  partialOrganisation: gql`
    fragment AddSubscribedOrganisationDialogLayoutFragmentPartialOrganisation on PartialOrganisation {
      id
      name
      addressLine1
      addressLine2
    }
  `,
};

const styles = (theme: Theme) => ({
  inviteButton: {
    marginTop: theme.spacing(1),
  },
  list: {
    flex: 1,
  },
  root: {
    display: `flex`,
    flexDirection: `column` as const,
    justifyItems: `center`,
  },
});

type Props = StyleProps & {
  availableItems: PartialOrganisation[];
  availableItemsLoading: boolean;
  catalogueViewID: string;
  inviteDialogOpen: boolean;
  onActivatePressed: () => void;
  onClose: () => void;
  open: boolean;
  setInviteDialogOpen: (b: boolean) => void | Promise<void>;
  setSubscribingOrganisationID: (selectIds: string[]) => void;
  subscribingOrganisationID: string[];
};

class AddSubscribedOrganisationsDialogLayout extends React.Component<Props> {
  render() {
    const {
      availableItems,
      availableItemsLoading,
      catalogueViewID,
      classes,
      inviteDialogOpen,
      onActivatePressed,
      onClose,
      open,
      setInviteDialogOpen,
      setSubscribingOrganisationID,
      subscribingOrganisationID,
    } = this.props;

    return (
      <>
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Publish</DialogTitle>
          <DialogContent className={classes.root}>
            <SubscribedOrganisationList
              className={classes.list}
              emptyMessage="No available organisations"
              loading={availableItemsLoading}
              selectedIDs={subscribingOrganisationID}
              subscribedOrganisations={availableItems}
              title="Publish to organisations:"
              onSelectedIDsChange={setSubscribingOrganisationID}
            />

            <Button
              startIcon={<Email />}
              className={classes.inviteButton}
              color="primary"
              variant="outlined"
              onClick={() => setInviteDialogOpen(true)}
            >
              Invite
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} startIcon={<Close />}>
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={subscribingOrganisationID.length === 0}
              onClick={onActivatePressed}
              startIcon={<Publish />}
              variant={"contained"}
            >
              Publish
            </Button>
          </DialogActions>
        </Dialog>

        <InviteNewUserToCatalogueViewData
          open={inviteDialogOpen}
          onClose={() => setInviteDialogOpen(false)}
          catalogueViewID={catalogueViewID}
        />
      </>
    );
  }
}

export default withStyles(styles)(AddSubscribedOrganisationsDialogLayout);

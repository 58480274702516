import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import CallOnMount from "../../generic/CallOnMount";
import { WarehouseDetailsWarehouse } from "./__generated__/WarehouseDetailsWarehouse";
import { warehouseDetailsInfoFragments } from "./WarehouseDetailsInfo";
import { get } from "lodash";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const warehouseDetailsFragments = {
  warehouse: gql`
    fragment WarehouseDetailsWarehouse on Warehouse {
      id
      ...WarehouseDetailsInfoWarehouse
    }
    ${warehouseDetailsInfoFragments.warehouse}
  `,
};

export const GET_WAREHOUSE = gql`
  query WarehouseDetailsGetWarehouse(
    $warehouseID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          ...WarehouseDetailsWarehouse
        }
      }
    }
  }
  ${warehouseDetailsFragments.warehouse}
`;

export type ChildProps = {
  loading: boolean;
  warehouse?: WarehouseDetailsWarehouse;
};

type EnhancedProps = {
  children: (arg0: ChildProps) => React.ReactNode;
  organisationID: string;
  warehouseID: string;
};

type Props = EnhancedProps & {
  error?: Error;
  loading: boolean;
  openSnackbar: OpenSnackbarFn;
  warehouse?: WarehouseDetailsWarehouse;
};

export class WarehouseDetailsData extends React.PureComponent<Props> {
  render() {
    const { children, error, loading, warehouse, openSnackbar } = this.props;

    if (error || (!loading && !warehouse)) {
      return (
        <CallOnMount
          callback={() =>
            openSnackbar(SnackbarType.error, `Failed to load warehouse`)
          }
        />
      );
    }

    return children({
      loading,
      warehouse,
    });
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql(GET_WAREHOUSE, {
    // @ts-ignore
    options: ({ organisationID, warehouseID }) => ({
      variables: {
        organisationID,
        warehouseID,
      },
    }),
    // @ts-ignore
    props: ({ data: { getSelf, loading, error } }) => ({
      error,
      loading,
      warehouse: get(getSelf, `organisation.warehouse`, undefined),
    }),
  }),
  withOpenSnackbar
);

export default enhancer(WarehouseDetailsData);

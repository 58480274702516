import gql from "graphql-tag";
import { GetUserRolesUser } from "./__generated__/GetUserRolesUser";

export const getUserRolesFragment = gql`
  fragment GetUserRolesUser on User {
    roles {
      id
    }
  }
`;

const getUserRoles = (
  user: GetUserRolesUser
): ReadonlyArray<string | null | undefined> => {
  if (user.roles) {
    return user.roles.map((role) => role.id);
  } else {
    return [];
  }
};

export default getUserRoles;

import * as React from "react";
import gql from "graphql-tag";
import { compose } from "recompose";

import OperationHoursLayout from "./OperationHoursLayout";
import LoadingScreen from "../generic/LoadingScreen";
import withQuery from "../generic/graphql/withQuery";
import { OperationHoursDataAddress } from "./__generated__/OperationHoursDataAddress";

export const operationHoursDataFragments = {
  operationHours: gql`
    fragment OperationHoursDataOperationHours on OperationHours {
      id
      dayOfTheWeek
      openingTime
      closingTime
    }
  `,
};

export const addressFragments = {
  address: gql`
    fragment OperationHoursDataAddress on Address {
      id
      addressLine1
      addressLine2
      businessName
      city
      operationHoursList {
        ...OperationHoursDataOperationHours
      }
    }
    ${operationHoursDataFragments.operationHours}
  `,
};

export const GET_ADDRESS = gql`
  query GetAddress($organisationId: String!, $addressId: String!) {
    getSelf {
      id
      organisation(id: $organisationId) {
        id
        address(id: $addressId) {
          ...OperationHoursDataAddress
        }
      }
    }
  }
  ${addressFragments.address}
`;

type EnhancedProps = {
  onDismiss?: () => void;
  organisationId: string;
  addressId: string;
};

type Props = EnhancedProps & {
  error?: Error;
  loading: boolean;
  address?: OperationHoursDataAddress;
};

export class OperatingHoursData extends React.PureComponent<Props> {
  render() {
    const { error, loading, organisationId, address } = this.props;

    if (loading) {
      return <LoadingScreen />;
    } else if (error || !address) {
      return null;
    }

    return (
      <OperationHoursLayout address={address} organisationId={organisationId} />
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withQuery(GET_ADDRESS, {
    dataPaths: {
      address: `getSelf.organisation.address`,
    },
    errorMessage: `Failed to load address operation hours`,
    loadingProp: `loading`,
    options: ({ organisationId, addressId }) => ({
      variables: {
        organisationId,
        addressId,
      },
      fetchPolicy: "network-only",
    }),
    skip: ({ addressId }) => addressId == null,
  })
);

export default enhancer(OperatingHoursData);

import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { match } from "react-router-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { UnVerifiedUserLogout } from "./__generated__/UnVerifiedUserLogout";

export const UNVERIFIED_LOGOUT = gql`
  mutation UnVerifiedUserLogout {
    logout @client {
      isLoggedIn
    }
  }
`;

class LogoutUnVerifiedMutation extends Mutation<UnVerifiedUserLogout> {}

type MatchProps = {
  email?: string;
};

type Props = {
  match: match<MatchProps>;
};

class EmailUnverified extends React.Component<Props> {
  render() {
    const {
      match: {
        params: { email },
      },
    } = this.props;

    return (
      <MainPanel title="Email Unverified">
        <Typography>
          A verification email has been sent to {email != null ? email : `you`},
          please follow the link in the email before continuing.
        </Typography>
        <LogoutUnVerifiedMutation mutation={UNVERIFIED_LOGOUT}>
          {(logout) => (
            <Button
              onClick={() => {
                logout({});
              }}
            >
              Log Out
            </Button>
          )}
        </LogoutUnVerifiedMutation>
      </MainPanel>
    );
  }
}

export default EmailUnverified;

import * as React from "react";
import gql from "graphql-tag";
import { Button } from "@material-ui/core";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { DestinationsTableOrderItem } from "./__generated__/DestinationsTableOrderItem";

export const destinationsTableFragments = {
  orderItem: gql`
    fragment DestinationsTableOrderItem on OrderItem {
      id
      description
      quantity
      supplier {
        id
        name
      }
      unallocated
    }
  `,
};

type Props = {
  className?: string;
  loading: boolean;
  productList: DestinationsTableOrderItem[];
  onEditPressed: (orderItemID: string) => Promise<void> | void;
};

class DestinationTable extends React.PureComponent<Props> {
  render() {
    const { className, loading, productList, onEditPressed } = this.props;

    const columns: Column<DestinationsTableOrderItem>[] = [
      {
        Header: `Supplier`,
        id: `supplier.name`,
      },
      {
        Header: `Product`,
        id: `description`,
      },
      {
        Header: `Qty`,
        id: `quantity`,
        numeric: true,
      },
      {
        Header: `Unallocated`,
        id: `unallocated`,
        numeric: true,
      },
      {
        Header: null,
        id: "assign",
        disableSortable: true,
        numeric: true,
        Cell: ({ row }) => (
          <Button
            color={"primary"}
            onClick={async (e) => {
              e.stopPropagation();
              await onEditPressed(row.id);
            }}
            variant={"outlined"}
          >
            Assign
          </Button>
        ),
      },
    ];

    return (
      <DataTable
        className={className}
        loading={loading}
        columns={columns}
        data={productList}
        idAccessor="id"
        emptyMessage="No items in order"
        onRowPress={onEditPressed}
      />
    );
  }
}

export default DestinationTable;

import * as React from "react";
import gql from "graphql-tag";
import { PostcodeRangeTablePostcodeRange as PostcodeRange } from "./__generated__/PostcodeRangeTablePostcodeRange";
import { Column } from "../../generic/DataTable/BaseDataTable";
import DataTable from "../../generic/DataTable";
import Typography from "@material-ui/core/Typography";

export const postcodeRangeFragments = {
  postcodeRange: gql`
    fragment PostcodeRangeTablePostcodeRange on PostcodeRange {
      id
      startPostcode
      endPostcode
    }
  `,
};

type Props = {
  postcodeRanges: ReadonlyArray<PostcodeRange>;
};

/**
 * Component to display a list of postcode ranges in a table.
 */
export class PostcodeRangeTable extends React.PureComponent<Props> {
  render() {
    const { postcodeRanges } = this.props;

    const columns: Column<PostcodeRange>[] = [
      {
        Header: `Start`,
        id: `startPostcode`,
        numeric: true,
      },
      {
        Header: `End`,
        id: `endPostcode`,
        numeric: true,
      },
    ];

    return (
      <div>
        <Typography gutterBottom variant="h6">
          Current Postcodes for Service Area
        </Typography>
        <DataTable
          columns={columns}
          data={postcodeRanges}
          idAccessor={`id`}
          emptyMessage={`No Postcodes`}
        />
      </div>
    );
  }
}

export default PostcodeRangeTable;

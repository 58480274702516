import { get } from "lodash";

const statusMap = {
  PENDING: `Pending`,
  PICKING: `Picking`,
  DISPATCHED: `Dispatched`,
  RECEIVED: `Received`,
};

const getStatusString = (status: string) => get(statusMap, status, status);
export default getStatusString;

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../../types";
import CurationLayout from "../../../generic/curation/CurationLayout";
import ItemListWithHeaders from "../../../generic/ItemListWithHeaders";
import {
  EnhancedProps as ItemListWithSelectionProps,
  withSelection,
} from "../../../generic/ItemListWithSelection";
import { withFilter } from "../../../generic/ItemListWithFilter";
import {
  ItemWithRoles,
  Props as ItemListWithRolesProps,
  withRoles,
} from "../ItemListWithRoles";
import MarketplaceSelect from "../../MarketplaceSelect";
import CategorySelect from "../CategorySelect";
import FormDialog from "../../../../components/generic/FormDialog";
import ActivateForm from "./ActivateForm";
import { compose } from "recompose";
import { OrganisationClass } from "./__generated__/OrganisationClass";
import { TradingClass } from "../../../../../helpers/class/mergeOrganisationTradesInClass";
import { omit } from "lodash";

const ItemListWithSelection = withSelection(
  ItemListWithHeaders as any
) as React.ComponentType<ItemListWithSelectionProps>;
const AvailableList = withFilter()(
  ItemListWithSelection as any
) as React.ComponentType<ItemListWithSelectionProps>;
const ActiveListWithSelection = withSelection(
  ItemListWithHeaders as any
) as React.ComponentType<ItemListWithSelectionProps>;
const ActiveList = compose(withRoles as any)(
  ActiveListWithSelection
) as React.ComponentType<ItemListWithSelectionProps & ItemListWithRolesProps>;

const styles = (theme) => ({
  select: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  selectContainer: {
    display: `flex`,
    flexDirection: `row` as const,
    marginBottom: theme.spacing(1),
  },
});

type Props = StyleProps & {
  activateFormOpen: boolean;
  activateItems: (
    items: string[],
    roles: { buyer: boolean; seller: boolean }
  ) => void;
  activeItems?: TradingClass[];
  activeItemsLoading?: boolean;
  availableItems?: OrganisationClass[];
  availableItemsLoading?: boolean;
  categoryID?: string;
  industryID?: string;
  marketplaceID?: string;
  onActivatePressed: (arg0: {
    buyer: boolean;
    seller: boolean;
  }) => Promise<void>;
  onCategoryIDChange: (categoryID: string) => void;
  onIndustryMarketplaceIDChange: (arg0: {
    industryID?: string;
    marketplaceID: string;
  }) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  organisationID: string;
  selectedAvailableItems: string[];
  setActivateFormOpen: (open: boolean) => void;
  selectedActiveItems: string[];
  onSelectedActiveItemsChange: (selectIDs: string[]) => void;
  onDeactivatePressed: () => Promise<void>;
  deactivateEnabled?: boolean;
};

class OrganisationClassLayout extends React.PureComponent<Props> {
  handleActivate({ buyer, seller }) {
    const { onActivatePressed, setActivateFormOpen } = this.props;

    onActivatePressed({
      buyer,
      seller,
    });
    setActivateFormOpen(false);
  }

  render() {
    const {
      activateItems,
      activateFormOpen,
      categoryID,
      industryID,
      classes,
      marketplaceID,
      onCategoryIDChange,
      onIndustryMarketplaceIDChange,
      organisationID,
      setActivateFormOpen,
      onDeactivatePressed,
      ...curationLayoutProps
    } = this.props;

    return (
      <React.Fragment>
        <div className={classes.selectContainer}>
          <MarketplaceSelect
            className={classes.select}
            organisationID={organisationID}
            selectedID={marketplaceID}
            onSelectedIDChange={(marketplaceID, industryID) =>
              onIndustryMarketplaceIDChange({
                industryID,
                marketplaceID,
              })
            }
          />
          <CategorySelect
            className={classes.select}
            organisationID={organisationID}
            industryID={industryID}
            marketplaceID={marketplaceID}
            selectedID={categoryID}
            onSelectedIDChange={onCategoryIDChange}
          />
        </div>
        <CurationLayout
          activateEnabled
          AvailableListComponent={AvailableList}
          ActiveListComponent={ActiveList}
          activeListProps={{
            emptyMessage:
              marketplaceID == null
                ? `Select a category to edit class memberships`
                : `There are no active class memberships in this category`,
            mainHeader: `Active Class Memberships`,
            onRoleChanged: (classID, role, enabled) => {
              if (enabled) {
                activateItems([classID], {
                  buyer: role === `buyer`,
                  seller: role === `seller`,
                });
              }
            },
          }}
          activeItemsListMap={(item: TradingClass): ItemWithRoles => ({
            id: item.id,
            roles: {
              buyer: item.roles.includes(`BUYER`),
              seller: item.roles.includes(`SELLER`),
            },
            text: item.name,
          })}
          availableListProps={{
            emptyMessage:
              categoryID == null
                ? `Select a category to view available classes`
                : `There are no more classes available in this category`,
            mainHeader: `Available Classes`,
          }}
          itemName="class"
          itemNamePlural="classes"
          {...omit(curationLayoutProps, [`onActivatePressed`])}
          onActivatePressed={() => setActivateFormOpen(true)}
          onDeactivatePressed={onDeactivatePressed}
          deactivateEnabled
        />
        <FormDialog
          open={activateFormOpen}
          onClose={() => setActivateFormOpen(false)}
        >
          {({ handleClose, ...rest }) => (
            <ActivateForm
              {...rest}
              onActivate={this.handleActivate.bind(this)}
              onDismiss={handleClose}
            />
          )}
        </FormDialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrganisationClassLayout);

import * as React from "react";
import * as H from "history";
import { match, Route } from "react-router-dom";
import MainPanel from "../../components/app/MainPanel";
import ProductOverviewLayout from "../../components/product/OverviewLayout";
import ProductDetailData from "../../components/product/ProductDetailData";
import {
  catalogueProductRoute,
  cataloguesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type MatchParams = {
  organisationID: string;
};

type Props = {
  history: H.History;
  location: Location;
  match: match<MatchParams>;
};

class ProductOverview extends React.PureComponent<Props> {
  render() {
    const { history, match, location } = this.props;

    const { organisationID } = match.params;
    if (organisationID == null) {
      return null;
    }

    return (
      <MainPanel title="Catalogues">
        <ProductOverviewLayout
          organisationID={organisationID}
          onPressProduct={(productId: string) =>
            history.push(catalogueProductRoute(organisationID, productId))
          }
        />

        <Route
          path={catalogueProductRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            ROUTE_PARAMETERS.PRODUCT_ID
          )}
        >
          {({ match: productMatch }) => {
            const productID = productMatch && productMatch.params.productID;

            return (
              <ProductDetailData
                open={productMatch != null}
                productID={productID}
                organisationID={organisationID}
                onClose={() =>
                  history.push(
                    cataloguesRoute(organisationID) + location.search
                  )
                }
              />
            );
          }}
        </Route>
      </MainPanel>
    );
  }
}

export default ProductOverview;

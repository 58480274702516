import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import MarketplaceSelect from "../MarketplaceSelect";
import { CategoryCurationGetCategory_category as Category } from "./__generated__/CategoryCurationGetCategory";
import CategoryList from "./CategoryList";
import ConfirmDeleteCategory from "./ConfirmDeleteCategory";
import UploadForm from "./UploadForm";

const styles = (theme) => ({
  select: {
    marginBottom: theme.spacing(1),
  },
  root: {
    flex: 1,
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `column` as const,
    alignItems: `stretch`,
  },
});

type Props = StyleProps & {
  setDeletingCategoryID: (categoryID: string | typeof undefined) => void;
  category: Category | null | undefined;
  loadingCategories?: boolean;
  marketplaceID?: string;
  deleteCategory: (categoryID: string) => Promise<void>;
  categories: Category[] | null;
  onMarketplaceIDChange: (id: string) => void;
  onPressClasses: (marketplaceID: string) => void;
  onPressEdit: (categoryID: string) => void;
};

class CategoryLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      setDeletingCategoryID,
      category,
      marketplaceID,
      categories,
      loadingCategories,
      onMarketplaceIDChange,
      onPressClasses,
      deleteCategory,
      onPressEdit,
    } = this.props;

    return (
      <div className={classes.root}>
        <MarketplaceSelect
          className={classes.select}
          selectedID={marketplaceID}
          onSelectedIDChange={onMarketplaceIDChange}
        />
        <CategoryList
          categories={categories}
          loading={loadingCategories}
          onPressClasses={onPressClasses}
          onPressEdit={onPressEdit}
          onPressDelete={(categoryID) => {
            setDeletingCategoryID(categoryID);
          }}
        />
        <ConfirmDeleteCategory
          categoryID={category && category.id}
          name={category && category.name}
          onAccept={(categoryID) => {
            deleteCategory(categoryID);
            setDeletingCategoryID(undefined);
          }}
          onClose={() => {
            setDeletingCategoryID(undefined);
          }}
        />
        <UploadForm marketplaceID={marketplaceID} />
      </div>
    );
  }
}

export default withStyles(styles)(CategoryLayout);

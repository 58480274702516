/**
 * Check whether a user has a given set of roles.
 * @param {?Array<string>} userRoles Names of the user's roles.
 * @param {Array<string>} requiredRoles Array of the names of roles to check for.
 * @return {boolean} Whether the user has all of the roles.
 */
const userHasRoles = (
  userRoles: string[] | null | undefined,
  requiredRoles: string[] | null | undefined
): boolean => {
  if (requiredRoles == null) {
    return true;
  }
  const roles = userRoles || [];

  for (const requiredRole of requiredRoles) {
    if (!roles.includes(requiredRole)) {
      return false;
    }
  }

  return true;
};

export default userHasRoles;

import * as React from "react";
import gql from "graphql-tag";
import AddDestinationLayout, {
  addDestinationLayoutFragments,
} from "./AddDestinationLayout";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { compose } from "recompose";
import { GET_ORDER } from "./Data";
import {
  AddDestinationDataGetOrderitemDestinationList_getSelf_shoppingCart_order_orderItem as OrderItem,
  AddDestinationDataGetOrderitemDestinationList_getSelf_shoppingCart_order_orderItem_orderItemPartList_destination as Address,
} from "./__generated__/AddDestinationDataGetOrderitemDestinationList";
import FormDialog from "../../generic/FormDialog";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";

export const REMOVE_DEST_FROM_ORDER_ITEM = gql`
  mutation RemoveDestinationFromOrderItem(
    $orderItemID: String!
    $addressID: String!
    $organisationID: String!
  ) {
    deleteOrderItemPart(
      orderItemPart: {
        orderItem: { id: $orderItemID }
        destination: { id: $addressID }
        organisation: { id: $organisationID }
      }
    )
  }
`;

export const UPDATE_ORDER_ITEMS = gql`
  mutation UpdateOrderItems(
    $updateOrderItemParts: [UpdateOrderItemPartInput!]!
  ) {
    updateOrderItemParts(updateOrderItemParts: $updateOrderItemParts) {
      id
      quantityAllocated
      orderItem {
        id
        description
      }
      destination {
        id
        businessName
        addressLine1
      }
    }
  }
`;

export const GET_ORDER_ITEM_DESTINATION_LIST = gql`
  query AddDestinationDataGetOrderitemDestinationList(
    $organisationID: String!
    $orderItemID: String!
  ) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        order {
          id
          orderItem(id: $orderItemID) {
            id
            ...AddDestinationTableOrderItem
          }
        }
      }
    }
  }
  ${addDestinationLayoutFragments.orderItem}
`;

type EnhancedProps = {
  organisationID: string;
  orderItemID: string | null | undefined;
  onAddDestinationPressed: () => void;
  onClose: () => void | Promise<void>;
};

type Props = EnhancedProps & {
  loading: boolean;
  addressList: Address[];
  orderItem: OrderItem;
  updateOrderItemDestinationQuantity: (
    updateOrderItemParts: UpdateOrderItemPartInput[]
  ) => Promise<void>;
  removeDestinationFromOrderItem: (arg0: {
    addressID: string;
  }) => void | Promise<void>;
};

class AddDestinationData extends React.PureComponent<Props> {
  render() {
    const {
      loading,
      orderItem,
      orderItemID,
      organisationID,
      onAddDestinationPressed,
      onClose,
      removeDestinationFromOrderItem,
      updateOrderItemDestinationQuantity,
    } = this.props;

    return (
      <FormDialog open={orderItemID != null} onClose={onClose}>
        {({ handleClose, ...rest }) => (
          <AddDestinationLayout
            loading={loading}
            orderItem={orderItem}
            organisationID={organisationID}
            removeDestinationFromOrderItem={removeDestinationFromOrderItem}
            onAddDestinationPressed={onAddDestinationPressed}
            onUpdateOrderItemDestinationQuantityPressed={
              updateOrderItemDestinationQuantity
            }
            onDismiss={handleClose}
            {...rest}
          />
        )}
      </FormDialog>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withQuery(GET_ORDER_ITEM_DESTINATION_LIST, {
    dataPaths: {
      addressList: `getSelf.shoppingCart.order.orderItem.orderItemPartList`,
      orderItem: `getSelf.shoppingCart.order.orderItem`,
    },
    errorMessage: `Failed to load delivery addresses`,
    loadingProp: `loading`,
    options: ({ organisationID, orderItemID }: EnhancedProps) => ({
      variables: {
        organisationID,
        orderItemID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ orderItemID }) => orderItemID == null,
  }),
  withMutation(UPDATE_ORDER_ITEMS, {
    mapMutationToProps: (updateOrderItemDestinationQuantity: any) => ({
      updateOrderItemDestinationQuantity,
    }),
    mutationOptions: (
      { orderItemID, organisationID },
      [updateOrderItemParts]
    ) => {
      return {
        variables: {
          updateOrderItemParts,
        },
        refetchQueries: [
          {
            query: GET_ORDER_ITEM_DESTINATION_LIST,
            variables: {
              organisationID,
              orderItemID,
            },
          },
          {
            query: GET_ORDER,
            variables: {
              organisationID,
            },
          },
        ],
      };
    },
    throwOnError: true,
  }),
  withMutation(REMOVE_DEST_FROM_ORDER_ITEM, {
    mapMutationToProps: (removeDestinationFromOrderItem: any) => ({
      removeDestinationFromOrderItem,
    }),
    mutationOptions: ({ orderItemID, organisationID }, [{ addressID }]) => ({
      variables: {
        orderItemID,
        addressID,
        organisationID,
      },
      refetchQueries: [
        {
          query: GET_ORDER_ITEM_DESTINATION_LIST,
          variables: {
            organisationID,
            orderItemID,
          },
        },
        {
          query: GET_ORDER,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        deleteOrderItemPart: `SUCCESS`,
      },
    }),
    errorMessage: `Could not delete delivery addresses from order item`,
  })
);

export default enhancer(AddDestinationData);

import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import getUserStatus, {
  getUserStatusFragment,
  UserStatus,
} from "../../../helpers/user/getStatus";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { profileFormFragments } from "./ProfileForm";
import { createOrganisationFormFragments } from "./CreateOrganisationForm";
import { get } from "lodash";
import {
  ProfileDataGetSelf_user as ProfileUser,
  ProfileDataGetSelf_user_organisationList as ProfileOrganisation,
} from "./__generated__/ProfileDataGetSelf";
import { SnackbarType } from "../../../__generated__/globalTypes";

const GET_SELF = gql`
  query ProfileDataGetSelf {
    user: getSelf {
      id
      ...ProfileFormUser
      ...GetUserStatusUser
      organisationList {
        ...CreateOrganisationFormOrganisation
      }
    }

    auth: getAuth @client {
      roles
    }
  }
  ${profileFormFragments.user}
  ${createOrganisationFormFragments.organisation}
  ${getUserStatusFragment}
`;

type ChildProps = {
  loading: boolean;
  organisation?: ProfileOrganisation;
  user?: ProfileUser;
  userStatus?: UserStatus;
};

type EnhancedProps = {
  children: (arg0: ChildProps) => React.ReactNode;
};

type Props = EnhancedProps & {
  error?: Error;
  loading: boolean;
  openSnackbar: OpenSnackbarFn;
  roles?: string[];
  user?: ProfileUser;
};

export class ProfileData extends React.PureComponent<Props> {
  componentDidMount() {
    this._displayError();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.error && !prevProps.error) {
      this._displayError();
    }
  }

  _displayError() {
    const { error, openSnackbar } = this.props;
    if (error) {
      openSnackbar(
        SnackbarType.error,
        `Failed to load your profile. Please try again.`
      );
    }
  }

  render() {
    const { children, error, loading, roles, user } = this.props;
    const userStatus =
      !error && user && roles ? getUserStatus(user, roles) : undefined;
    const organisation: ProfileOrganisation | undefined = get(
      user,
      `organisationList[0]`,
      undefined
    );

    return children({
      loading,
      organisation,
      user: !error && user ? user : undefined,
      userStatus,
    });
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql(GET_SELF, {
    // @ts-ignore
    props: ({ data: { user, auth, loading, error } }) => ({
      error: error || (!loading && !user && new Error(`No user found`)),
      loading,
      roles: auth && !loading && !error ? auth.roles : null,
      user: user && !loading && !error ? user : null,
    }),
  }),
  withOpenSnackbar
);

export default enhancer(ProfileData);

import * as React from "react";
import gql from "graphql-tag";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../generic/ListQuerySelectField";

export type EnhancedProps = ListQuerySelectFieldProps & {
  onSelectedIDChange: (id: string) => unknown;
  marketplaceID?: string;
};

export const GET_MARKETPLACE = gql`
  query CategorySelectGetMarketplace($marketplaceID: String!) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      categoryList {
        id
        name
      }
    }
  }
`;

const CategorySelect = ListQuerySelectField({
  dataPath: `marketplace.categoryList`,
  itemPluralLabel: `categories`,
  itemTitleLabel: `Category`,
  query: GET_MARKETPLACE,
  variables: ({ marketplaceID }) =>
    marketplaceID ? { marketplaceID } : undefined,
}) as React.ComponentClass<EnhancedProps>;

export default CategorySelect;

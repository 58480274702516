import { compose, withHandlers, withState } from "recompose";

type EnhancedProps = {
  activateItems: (itemIDs: string[]) => Promise<unknown>;
};

/**
 * HOC to add selection state for IDs of items to be activated.
 * Provides onActivatePressed handler which passes selection to the activateItems props and clears the selection.
 * @param {string} selectionStateName Name for list of selected IDs.
 * @param {string} selectionUpdaterName Name for function to update list of selected IDs.
 * @return {HOC} HOC
 */
export default (selectionStateName: string, selectionUpdaterName: string) =>
  compose<any, EnhancedProps>(
    withState(selectionStateName, selectionUpdaterName, []),
    withHandlers({
      onActivatePressed: (props) => (extraData?: any) => {
        // Activate selected items
        // @ts-ignore
        props.activateItems(props[selectionStateName], extraData);
        // Clear selection
        props[selectionUpdaterName]([]);
      },
    })
  );

import * as React from "react";
import gql from "graphql-tag";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import ShippingBatchOrderItemPartTable, {
  shippingBatchOrderItemPartTableFragments,
} from "./ShippingBatchOrderItemPartTable";
import ShippingBatchSaleDetails, {
  shippingBatchSaleDetailsFragments,
} from "./ShippingBatchSaleDetails";
import { ShippingBatchSaleDialog as ShippingBatchSaleDialogType } from "./__generated__/ShippingBatchSaleDialog";
import LoadingScreen from "../../generic/LoadingScreen";
import { StyleProps } from "../../types";
import { AssignmentTurnedIn, ExitToApp } from "@material-ui/icons";

export const shippingBatchSaleDialogFragments = {
  shippingBatch: gql`
    fragment ShippingBatchSaleDialog on ShippingBatch {
      id
      ...ShippingBatchSaleDetails
      orderItemPartList {
        id
        ...ShippingBatchOrderItemPartTableOrderItem
      }
    }
    ${shippingBatchOrderItemPartTableFragments.orderItemPart}
    ${shippingBatchSaleDetailsFragments.shippingBatch}
  `,
};

const styles = {
  actions: {
    display: `flex`,
    flexDirection: `row` as const,
  },
  actionsSpacer: {
    flex: 1,
  },
};

type Props = StyleProps & {
  open: boolean;
  loading: boolean;
  shippingBatchSale: ShippingBatchSaleDialogType;
  onClose: () => void;
  onDispatchedPressed: () => Promise<void>;
  onPickingPressed: () => Promise<void>;
};

class ShippingBatchSaleDialogLayout extends React.PureComponent<Props> {
  renderContent() {
    const { loading, shippingBatchSale } = this.props;

    return (
      <React.Fragment>
        <ShippingBatchSaleDetails
          loading={loading}
          shippingBatchSale={shippingBatchSale}
        />
        <ShippingBatchOrderItemPartTable
          loading={loading}
          orderItemList={
            shippingBatchSale && shippingBatchSale.orderItemPartList
              ? shippingBatchSale.orderItemPartList
              : []
          }
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      classes,
      open,
      loading,
      shippingBatchSale,
      onClose,
      onDispatchedPressed,
      onPickingPressed,
    } = this.props;

    const status = shippingBatchSale && shippingBatchSale.status;
    const enablePickingButton = shippingBatchSale && status === `PENDING`;
    const enableDispatchedButton = shippingBatchSale && status === `PICKING`;

    return (
      <React.Fragment>
        <Dialog open={open} maxWidth="md" onClose={onClose}>
          <DialogTitle>Sale Detail</DialogTitle>

          <DialogContent>
            {loading && <LoadingScreen />}
            {!loading && this.renderContent()}
          </DialogContent>

          <DialogActions className={classes.actions}>
            {enablePickingButton && (
              <Button
                color="primary"
                variant={`contained`}
                onClick={onPickingPressed}
                startIcon={<AssignmentTurnedIn />}
              >
                Mark as Picking
              </Button>
            )}
            {enableDispatchedButton && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={onDispatchedPressed}
                startIcon={<ExitToApp />}
              >
                Mark as Dispatched
              </Button>
            )}
            <div className={classes.actionsSpacer} />
            <Button onClick={onClose}>Done</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ShippingBatchSaleDialogLayout);

import * as React from "react";
import ItemListWithMenu from "../../generic/ItemListWithMenu";
import gql from "graphql-tag";
import { ActiveMarketplaceListMarketplace as Marketplace } from "./__generated__/ActiveMarketplaceListMarketplace";

export const activeMarketplaceListFragments = {
  marketplace: gql`
    fragment ActiveMarketplaceListMarketplace on Marketplace {
      id
      name
    }
  `,
};

type Props = {
  className?: string;
  loading?: boolean;
  marketplaces: Marketplace[] | null;
  onPressCategories: (marketplaceID: string) => void;
  onPressDelete: (marketplaceID: string) => void;
  onPressEdit: (marketplaceID: string) => void;
};

/**
 * Component to display active marketplaces in a list.
 */
export class MarketplaceList extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      marketplaces,
      onPressCategories,
      onPressDelete,
      onPressEdit,
    } = this.props;

    const listItems = marketplaces
      ? marketplaces.map((marketplace) => ({
          id: marketplace.id,
          text: marketplace.name,
        }))
      : [];

    return (
      <ItemListWithMenu
        className={className}
        listProps={{ dense: true }}
        items={listItems}
        mainHeader="Active Marketplaces"
        menuActions={[
          {
            perform: onPressEdit,
            text: `Edit`,
          },
          {
            perform: onPressCategories,
            text: `Categories`,
          },
          {
            perform: onPressDelete,
            text: `Delete`,
          },
        ]}
        loading={loading}
        emptyMessage={
          marketplaces == null
            ? `Select an industry to edit active marketplaces`
            : `This industry has no active marketplaces`
        }
      />
    );
  }
}

export default MarketplaceList;

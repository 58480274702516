import * as React from "react";
import { QueryResult, graphql } from "react-apollo";
import gql from "graphql-tag";
import { FormikBag, Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import { CustomField } from "../../generic/form/index";
import FileInput from "../../generic/form/FileInput";
import { compose } from "recompose";
import getGraphqlException from "../../../../helpers/getGraphqlException";
import UploadSummaryLink from "../../generic/uploadSummary/UploadSummaryLink";
import { UploadSummary } from "../../generic/uploadSummary/UploadSummaryText";
import { SnackbarType } from "../../../../__generated__/globalTypes";
import { CloudUpload } from "@material-ui/icons";
import UploadFormSubmitContainer from "../../generic/uploads/UploadFormSubmitContainer";

export const UPLOAD_CLASSES = gql`
  mutation UploadClassesForMarketplace($categoryID: String!, $file: Upload!) {
    createClassesForCategoryWithFile(
      category: { id: $categoryID }
      file: $file
    ) {
      createdCount
      updatedCount
      errors
    }
  }
`;

export const GET_AVAILABLE_CLASSES = gql`
  query OrganisationClassesGetAvailableClasses($categoryID: String!) {
    category: getCategory(id: $categoryID) {
      id
      classList {
        id
        name
      }
    }
  }
`;

type Props = {
  openSnackbar: OpenSnackbarFn;
  categoryID: string;
  uploadClasses: (categoryID: string, file: any) => Promise<QueryResult<any>>;
};

type State = {
  uploadSummary?: UploadSummary;
};

type Values = {
  categoriesFile: any;
};

export class CategoryUploadForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploadSummary: undefined,
    };
  }

  async _handleSubmit(
    values: Values,
    { resetForm, setSubmitting }: FormikBag<any, Values>
  ) {
    const { uploadClasses, openSnackbar, categoryID } = this.props;

    if (categoryID != null) {
      try {
        const successResult: QueryResult<any> = await uploadClasses(
          categoryID,
          values.categoriesFile
        );
        // Show snackbar on success
        openSnackbar(SnackbarType.success, `Successfully uploaded classes`);
        setSubmitting(false);
        resetForm();
        // Store info about success
        this.setState({
          uploadSummary: {
            success:
              successResult &&
              successResult.data &&
              successResult.data.createClassesForCategoryWithFile
                ? {
                    deleteCount:
                      successResult.data.createClassesForCategoryWithFile
                        .deletedCount,
                    updateCount:
                      successResult.data.createClassesForCategoryWithFile
                        .updatedCount,
                  }
                : undefined,
          },
        });
      } catch (error) {
        openSnackbar(SnackbarType.error, `Failed to upload classes`);
        setSubmitting(false);
        // Store info about failure
        const exception = getGraphqlException(
          error,
          [`createClassesForCategoryWithFile`],
          `BAD_USER_INPUT`
        );
        this.setState({
          uploadSummary:
            exception != null
              ? {
                  failure: {
                    badRowCount: exception.badRowCount || 0,
                    errors: exception.errors || [],
                    totalRowCount: exception.totalRowCount || 0,
                  },
                }
              : undefined,
        });
      }
    }
  }

  render() {
    const { categoryID } = this.props;
    return (
      // @ts-ignore
      <Formik onSubmit={this._handleSubmit.bind(this)}>
        {(formikBag) => {
          const buttonIsDisabled =
            formikBag.isSubmitting ||
            !formikBag.dirty ||
            !formikBag.values.categoriesFile ||
            categoryID == null;
          return (
            <Form>
              <CustomField
                fullWidth
                name="categoriesFile"
                component={FileInput}
                inputProps={{ accept: `.csv` }}
              />
              <UploadFormSubmitContainer>
                <Button
                  color="primary"
                  disabled={buttonIsDisabled}
                  startIcon={<CloudUpload />}
                  type="submit"
                  variant={"contained"}
                >
                  Upload
                </Button>
                <UploadSummaryLink
                  itemTitleText="Class"
                  itemPluralText="classes"
                  uploadSummary={this.state.uploadSummary}
                />
              </UploadFormSubmitContainer>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props>, any>(
  withOpenSnackbar as any,
  graphql(UPLOAD_CLASSES, {
    props: ({ mutate }) => ({
      uploadClasses: (categoryID: string, file: any) =>
        mutate({
          refetchQueries: () => [
            {
              query: GET_AVAILABLE_CLASSES,
              variables: {
                categoryID: categoryID,
              },
            },
          ],
          variables: {
            file,
            categoryID: categoryID,
          },
        }),
    }),
  })
);

export default enhancer(CategoryUploadForm);

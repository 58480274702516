import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import {
  ActiveMarketplaceSelectSetActiveMarketplaceID,
  ActiveMarketplaceSelectSetActiveMarketplaceIDVariables,
} from "./__generated__/ActiveMarketplaceSelectSetActiveMarketplaceID";
import { ActiveMarketplaceSelectGetSelection } from "./__generated__/ActiveMarketplaceSelectGetSelection";

const activeMarketplaceSelectionFragment = gql`
  fragment ActiveMarketplaceSelection on Selection {
    activeMarketplaceID @client
  }
`;

export const GET_SELECTION = gql`
  query ActiveMarketplaceSelectGetSelection {
    selection: getSelection @client {
      ...ActiveMarketplaceSelection
    }
  }
  ${activeMarketplaceSelectionFragment}
`;

class GetSelectionQuery extends Query<ActiveMarketplaceSelectGetSelection> {}

export const SET_ACTIVE_MARKETPLACE_ID = gql`
  mutation ActiveMarketplaceSelectSetActiveMarketplaceID($id: String) {
    setActiveMarketplaceID(id: $id) @client {
      ...ActiveMarketplaceSelection
    }
  }
  ${activeMarketplaceSelectionFragment}
`;
class SetActiveMarketplaceIDMutation extends Mutation<
  ActiveMarketplaceSelectSetActiveMarketplaceID,
  ActiveMarketplaceSelectSetActiveMarketplaceIDVariables
> {}

export type ChildProps = {
  onSelectedIDChange: (id: string) => void;
  selectedID: string;
};

type Props = {
  children: (arg0: ChildProps) => React.ReactNode;
};

class ActiveMarketplaceSelectionQuery extends React.Component<Props> {
  render() {
    const { children } = this.props;

    return (
      <GetSelectionQuery query={GET_SELECTION}>
        {({ data, loading }) => {
          if (loading || !data || !data.selection) {
            return null;
          }
          const {
            selection: { activeMarketplaceID },
          } = data;

          return (
            <SetActiveMarketplaceIDMutation
              mutation={SET_ACTIVE_MARKETPLACE_ID}
            >
              {(setActiveMarketplaceID) =>
                children({
                  onSelectedIDChange: (id) => {
                    setActiveMarketplaceID({ variables: { id: id || null } });
                  },
                  selectedID: activeMarketplaceID || ``,
                })
              }
            </SetActiveMarketplaceIDMutation>
          );
        }}
      </GetSelectionQuery>
    );
  }
}

export default ActiveMarketplaceSelectionQuery;

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import WarehouseDetailsInfo from "./WarehouseDetailsInfo";
import WarehouseStockedProductsTableData from "./WarehouseStockedProductsTableData";
import { StyleProps } from "../../types";
import LoadingScreen from "../../generic/LoadingScreen";
import { WarehouseDetailsInfoWarehouse } from "./__generated__/WarehouseDetailsInfoWarehouse";
import WarehouseName from "../WarehouseTitle";
import Paper from "@material-ui/core/Paper";
import { SPACING_PAPER } from "../../../../helpers/constants";

const styles = (theme) => ({
  container: {
    display: `flex`,
    flexDirection: `row` as const,
    overflow: `auto`,
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(SPACING_PAPER),
    marginRight: theme.margins.paper,
    flex: 1,
    overflow: `auto`,
  },
  table: {
    flex: 2,
  },
});

type Props = StyleProps & {
  loading?: boolean;
  warehouse?: WarehouseDetailsInfoWarehouse;
  organisationID: string;
  warehouseID: string;
};

class WarehouseDetailsLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      warehouse,
      organisationID,
      warehouseID,
    } = this.props;

    return (
      <>
        <WarehouseName warehouse={warehouse} />
        <div className={classes.container}>
          <Paper className={classes.paper}>
            {warehouse && !loading && (
              <WarehouseDetailsInfo warehouse={warehouse} />
            )}
            {loading && <LoadingScreen />}
          </Paper>
          <WarehouseStockedProductsTableData
            className={classes.table}
            organisationID={organisationID}
            warehouseID={warehouseID}
            warehouseName={warehouse ? warehouse.name : `Warehouse`} //TODO: see if there's a better way to do this
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(WarehouseDetailsLayout);

import { get } from "lodash";
import { InMemoryCache } from "apollo-cache-inmemory";

type Options = {
  itemID: string; // ID of item to be deleted
  itemIDAccessPath?: string; // Path on items to read ID for comparison, defaults to `id`
  listDataAccessPath: string; // Path within data from list query to access the list to mutate
  listQuery: any; // Query document for query with result containing the list to update
  listQueryVariables?: any; // Variables to pass to list query, update skipped if not defined
};

/**
 * Create an update function that will remove an item from a list in Apollo cache when a delete mutation is performed.
 * @param {any} options Configuration for the query to update.
 * @return {void}
 */
const deleteMutationUpdate = (options: Options) => (store: InMemoryCache) => {
  if (options.listQueryVariables == null) {
    return;
  }
  try {
    // Read the current list of items
    const listQuery = {
      query: options.listQuery,
      variables: options.listQueryVariables,
    };
    const data = store.readQuery(listQuery);
    const list: Array<any> = get(data, options.listDataAccessPath);
    if (list) {
      let deletedIndex = list.findIndex(
        (item) => get(item, options.itemIDAccessPath || `id`) === options.itemID
      );
      while (deletedIndex >= 0) {
        // Remove deleted item from the list
        list.splice(deletedIndex, 1);
        // Write the modified data to the cache
        store.writeQuery({
          ...listQuery,
          data,
        });
        deletedIndex = list.findIndex(
          (item) =>
            get(item, options.itemIDAccessPath || `id`) === options.itemID
        );
      }
    }
  } catch (error) {
    // Leave cache empty
  }
};

export default deleteMutationUpdate;

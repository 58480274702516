import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { ShippingBatchOrderItemPartTableOrderItem as OrderItemPartType } from "./__generated__/ShippingBatchOrderItemPartTableOrderItem";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { displayWarehouseAllocationToOrder } from "../../../../helpers/displayWarehouseAllocationToOrder";

export const shippingBatchOrderItemPartTableFragments = {
  orderItemPart: gql`
    fragment ShippingBatchOrderItemPartTableOrderItem on OrderItemPart {
      id
      quantityAllocated
      orderItem {
        id
        sku
        description
      }
      originList {
        toOrder
      }
      cost {
        unitPrice
        unitTax
        totalTax
        total
      }
    }
  `,
};

const styles = (theme) => ({
  table: {
    flex: 1,
    paddingTop: theme.spacing(1),
  },
});

const columns: Column<OrderItemPartType>[] = [
  {
    Header: `SKU`,
    id: `orderItem.sku`,
  },
  {
    Header: `Product`,
    id: `orderItem.description`,
  },
  {
    Header: `Quantity`,
    id: `quantityAllocated`,
    numeric: true,
  },
  {
    Header: `Supplied`,
    id: `originList[0].toOrder`,
    Cell: ({ value }) => displayWarehouseAllocationToOrder(value),
  },
  {
    Header: `Unit Price`,
    id: `cost.unitPrice`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Unit Tax`,
    id: `cost.unitTax`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Total Tax`,
    id: `cost.totalTax`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Total`,
    id: `cost.total`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
];

type Props = StyleProps & {
  loading: boolean;
  orderItemList: OrderItemPartType[];
};

class ShippingBatchSalesTable extends React.PureComponent<Props> {
  render() {
    const { classes, loading, orderItemList } = this.props;

    return (
      <div className={classes.table}>
        <DataTable
          loading={loading}
          columns={columns}
          data={orderItemList}
          idAccessor="id"
          emptyMessage="Batch is empty"
        />
      </div>
    );
  }
}

export default withStyles(styles)(ShippingBatchSalesTable);

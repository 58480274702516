import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DownArrowIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "react-router-dom";
import { compose, withState } from "recompose";
import { loggedOutRoute, profileRoute } from "../../../routes";

const styles = () => ({});

type EnhancedProps = {
  email: string;
  onLogout: () => void;
};

type Props = EnhancedProps &
  StyleProps & {
    menuAnchor: HTMLElement | null | undefined;
    updateMenuAnchor: (anchor: HTMLElement | null | undefined) => void;
  };

class UserMenu extends React.PureComponent<Props> {
  handleClick(event) {
    this.props.updateMenuAnchor(event.currentTarget);
  }

  handleClose() {
    this.props.updateMenuAnchor(null);
  }

  handleLogout() {
    this.props.onLogout();
    this.handleClose();
  }

  render() {
    const { email, menuAnchor } = this.props;

    return (
      <div>
        <Button color={"inherit"} onClick={this.handleClick.bind(this)}>
          {email}
          <DownArrowIcon />
        </Button>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.handleClose.bind(this)}
        >
          <MenuItem
            component={Link}
            to={profileRoute()}
            onClick={this.handleClose.bind(this)}
          >
            Profile
          </MenuItem>
          <MenuItem
            component={Link}
            to={loggedOutRoute()}
            onClick={this.handleLogout.bind(this)}
          >
            Log out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withStyles(styles),
  withState(`menuAnchor`, `updateMenuAnchor`, null)
);
export default enhancer(UserMenu);

import { compose, withHandlers, withState } from "recompose";

type EnhancedProps = {
  deactivateItems: (itemIDs: string[]) => unknown;
};

/**
 * HOC to add selection state for IDs of items to be deactivated.
 * Provides onDeactivatePressed handler which passes selection to the deactivateItems prop and clears the selection.
 * @param {string} selectionStateName Name for list of selected IDs.
 * @param {string} selectionUpdaterName Name for function to update list of selected IDs.
 * @return {HOC} HOC
 */
export default (selectionStateName: string, selectionUpdaterName: string) =>
  compose<any, EnhancedProps>(
    withState(selectionStateName, selectionUpdaterName, []),
    withHandlers({
      onDeactivatePressed: (props) => (extraData?: any) => {
        // Deactivate selected items
        // @ts-ignore
        props.deactivateItems(props[selectionStateName], extraData);
        // Clear selection
        props[selectionUpdaterName]([]);
      },
    })
  );

import * as React from "react";
import DataTable from "../../generic/DataTable";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../../../components/generic/CallOnMount";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../../components/generic/snackbar/withOpenSnackbar";
import {
  IncludedProductTableGetProductList,
  IncludedProductTableGetProductListVariables,
} from "./__generated__/IncludedProductTableGetProductList";
import { CatalogueViewIncludedProductTableIncludesProduct as IncludesProduct } from "./__generated__/CatalogueViewIncludedProductTableIncludesProduct";
import { compose } from "recompose";
import { get } from "lodash";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const productTableFragments = {
  catalogueViewIncludesProduct: gql`
    fragment CatalogueViewIncludedProductTableIncludesProduct on _CatalogueViewIncludesProductList {
      Product {
        id
        sku
        description
        standardPrice {
          value
        }
      }
      price
    }
  `,
};

export const GET_PRODUCT_LIST = gql`
  query IncludedProductTableGetProductList(
    $catalogueViewID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        catalogueView(id: $catalogueViewID) {
          id
          includesProductList {
            ...CatalogueViewIncludedProductTableIncludesProduct
          }
        }
        id
      }
    }
  }
  ${productTableFragments.catalogueViewIncludesProduct}
`;

class GetProductListQuery extends Query<
  IncludedProductTableGetProductList,
  IncludedProductTableGetProductListVariables
> {}

type EnhancedProps = {
  className?: string;
  catalogueViewID: string | null | undefined;
  organisationID: string;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
};

const columns = [
  {
    Header: `SKU`,
    id: `Product.sku`,
  },
  {
    Header: `Product Name`,
    id: `Product.description`,
  },
  {
    Header: `Standard Price`,
    id: `Product.standardPrice.value`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Price Offered`,
    id: `price`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
];

/**
 * Component to query catalogueView's included products and display results in a DataTable.
 */
export class IncludedProductTable extends React.PureComponent<Props> {
  render() {
    const {
      openSnackbar,
      catalogueViewID,
      organisationID,
      ...otherProps
    } = this.props;

    return (
      <GetProductListQuery
        query={GET_PRODUCT_LIST}
        variables={{
          catalogueViewID: catalogueViewID || ``,
          organisationID,
        }}
        skip={catalogueViewID == null}
      >
        {({ data, loading, error }) => {
          const includesProducts:
            | IncludesProduct[]
            | null
            | undefined = catalogueViewID
            ? get(
                data,
                `getSelf.organisation.catalogueView.includesProductList`,
                null
              )
            : [];

          return (
            <React.Fragment>
              <DataTable
                columns={columns}
                data={includesProducts || []}
                emptyMessage={
                  catalogueViewID == null
                    ? `Select a catalogue view`
                    : `There are no products in the catalogue view`
                }
                idAccessor="Product.id"
                loading={loading && catalogueViewID != null}
                {...otherProps}
              />
              {(error || (!includesProducts && !loading)) && (
                <CallOnMount
                  callback={() =>
                    openSnackbar(SnackbarType.error, `Failed to load products`)
                  }
                />
              )}
            </React.Fragment>
          );
        }}
      </GetProductListQuery>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withOpenSnackbar);
export default enhancer(IncludedProductTable);

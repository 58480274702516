import * as React from "react";
import gql from "graphql-tag";
import Checkbox from "@material-ui/core/Checkbox";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { WarehouseStockedProductTableStockedProduct } from "./__generated__/WarehouseStockedProductTableStockedProduct";
import { displayLeadTime } from "../../../../helpers/displayLeadTime";

export const EMPTY_MESSAGE = "No Products Stocked";

export const warehouseStockedProductTableFragments = {
  warehouseProductStockLevel: gql`
    fragment WarehouseStockedProductTableStockedProduct on _WarehouseStockLevelList {
      Product {
        id
        sku
        description
        leadTime
      }
      quantity
      orderToPromise
    }
  `,
};

type Props = {
  stockLevelsLoading: boolean;
  stockLevels: WarehouseStockedProductTableStockedProduct[] | null | undefined;
  className?: string;
};

const columns: Column<WarehouseStockedProductTableStockedProduct>[] = [
  {
    Header: `SKU`,
    id: `Product.sku`,
  },
  {
    Header: `Product`,
    id: `Product.description`,
  },
  {
    Cell: ({ value }) => displayLeadTime(value),
    Header: `Lead Time`,
    id: `Product.leadTime`,
  },
  {
    Header: `Order To Promise`,
    id: `orderToPromise`,
    Cell: ({ value }) => <Checkbox disabled checked={Boolean(value)} />,
    cellProps: {
      padding: "checkbox",
    },
  },
  {
    Header: `Stock On Hand`,
    id: `quantity`,
    numeric: true,
  },
];

class WarehouseStockLevelTable extends React.Component<Props> {
  render() {
    const { stockLevels, stockLevelsLoading, className } = this.props;

    return (
      <DataTable
        className={className}
        columns={columns}
        data={stockLevels || []}
        emptyMessage={`No Products Stocked`}
        loading={stockLevelsLoading}
        idAccessor={`Product.id`}
      />
    );
  }
}

export default WarehouseStockLevelTable;

import * as React from "react";
import SelectField, {
  EnhancedProps as SelectFieldProps,
  Option,
} from "../../generic/form/SelectField";

type Props = SelectFieldProps;

const filterEnabledOptions = (options: Option[]): Option[] =>
  options.filter((option) => !option.subheader);

/**
 * Select field that will auto-select the first option:
 * - if only one option is available.
 * - if not option is currently selected
 */
class AutoSelectField extends React.Component<Props> {
  autoSelectOptions() {
    const { onChange, options, value } = this.props;

    const enabledOptions = filterEnabledOptions(options);

    const valueSelected: boolean = value != null && value !== ``;
    const optionsAvailable: boolean = enabledOptions.length > 1;
    const singleOptionAvailable: boolean = enabledOptions.length === 1;

    if (
      (!valueSelected && optionsAvailable) ||
      (singleOptionAvailable && value !== enabledOptions[0].value)
    ) {
      // Select first option
      onChange({ target: { value: enabledOptions[0].value } } as any);
    }
  }

  componentDidMount() {
    this.autoSelectOptions();
  }

  componentDidUpdate() {
    this.autoSelectOptions();
  }

  render() {
    return <SelectField {...this.props} />;
  }
}

export default AutoSelectField;

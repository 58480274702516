import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import gql from "graphql-tag";
import { StyleProps } from "../../types";
import { WarehouseDetailsOperatingTimesWarehouse } from "./__generated__/WarehouseDetailsOperatingTimesWarehouse";
import { format24Hour } from "../../../../helpers/formatTime";

export const warehouseDetailsOperatingTimesFragments = {
  warehouse: gql`
    fragment WarehouseDetailsOperatingTimesWarehouse on Warehouse {
      operationHoursList {
        id
        dayOfTheWeek
        openingTime
        closingTime
      }
    }
  `,
};

const daysOfTheWeek = [
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
  `Sunday`,
];

const styles = () => ({});

type Props = StyleProps & {
  warehouse: WarehouseDetailsOperatingTimesWarehouse;
};

class WarehouseDetailsOperatingTimes extends React.PureComponent<Props> {
  render() {
    const { warehouse } = this.props;

    return (
      <div>
        <Typography variant="subtitle2">Operating Hours:</Typography>
        {daysOfTheWeek.map((dayOfTheWeek) => {
          const operationHours = warehouse.operationHoursList.find(
            (operationHours) => operationHours.dayOfTheWeek === dayOfTheWeek
          );
          const times =
            operationHours == null
              ? `Closed`
              : `${format24Hour(operationHours.openingTime) || ``} - ${
                  format24Hour(operationHours.closingTime) || ``
                }`;

          return (
            <Typography key={dayOfTheWeek}>
              {dayOfTheWeek}: {times}
            </Typography>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(WarehouseDetailsOperatingTimes);

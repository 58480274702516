/**
 * Calculate tax per item.
 * @param {number} price Price in cents
 * @param {number} taxPercentage Tax percentage from 0 to 1
 * @return {number} Tax amount
 */
export const calculateTax = (price: number, taxPercentage: number) =>
  price * taxPercentage;

/**
 * Calculate total tax given quantity.
 * @param {number} price Price in cents
 * @param {number} taxPercentage Tax percentage from 0 to 1
 * @param {number} quantity Number of items
 * @return {number} Total tax amount
 */
export const calculateTotalTax = (
  price: number,
  taxPercentage: number,
  quantity: number
) => price * quantity * taxPercentage;

/**
 * Calculate total price including tax given quantity.
 * @param {number} price Price in cents
 * @param {number} taxPercentage Tax percentage from 0 to 1
 * @param {number} quantity Number of items
 * @return {number} Total price including tax
 */
export const calculateTotalPrice = (
  price: number,
  taxPercentage: number,
  quantity: number
) => price * quantity + calculateTotalTax(price, taxPercentage, quantity);

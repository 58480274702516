import gql from "graphql-tag";
import React from "react";
import { compose } from "recompose";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";
import withMutation from "../../generic/graphql/withMutation";
import withQuery from "../../generic/graphql/withQuery";

import AddDestinationToAllLayout from "./AddDestinationToAllLayout";
import { GET_ORDER } from "./Data";
import { AddDestinationTableOrderItemPart_destination } from "./__generated__/AddDestinationTableOrderItemPart";
import { DestinationLayoutOrderItem } from "./__generated__/DestinationLayoutOrderItem";

export const GET_ORG_ADDRESSES = gql`
  query GetUserOrganisationAddresses($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        addressList {
          id
          addressLine1
          addressLine2
          businessName
          city
        }
      }
    }
  }
`;

export const ADD_DESTINATIONS = gql`
  mutation UpdateOrderItems(
    $updateOrderItemParts: [UpdateOrderItemPartInput!]!
  ) {
    updateOrderItemParts(updateOrderItemParts: $updateOrderItemParts) {
      id
      quantityAllocated
      orderItem {
        id
        description
      }
      destination {
        id
        businessName
        addressLine1
      }
    }
  }
`;

type EnhancedProps = {
  organisationID: string;
  orderItems: DestinationLayoutOrderItem[];
};

type Props = EnhancedProps & {
  loading: boolean;
  addressList: AddDestinationTableOrderItemPart_destination[];
  addDestinationToOrderItems: (
    arg0: UpdateOrderItemPartInput[]
  ) => Promise<void>;
};

class AddDestinationToAllData extends React.PureComponent<
  Props,
  EnhancedProps
> {
  render() {
    const {
      addressList,
      addDestinationToOrderItems,
      orderItems,
      organisationID,
    } = this.props;
    return (
      <AddDestinationToAllLayout
        addressList={addressList}
        addDestinationToOrderItems={addDestinationToOrderItems}
        orderItems={orderItems}
        organisationID={organisationID}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_ORG_ADDRESSES, {
    dataPaths: {
      addressList: `getSelf.organisation.addressList`,
    },
    errorMessage: `Failed to load delivery addresses`,
    loadingProp: `loading`,
    options: ({ organisationID }: EnhancedProps) => ({
      variables: {
        organisationID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ organisationID }) => organisationID == null,
  }),
  withMutation(ADD_DESTINATIONS, {
    mapMutationToProps: (addDestinationToOrderItems: any) => ({
      addDestinationToOrderItems,
    }),
    mutationOptions: ({ organisationID }, [updateOrderItemParts]) => ({
      variables: {
        updateOrderItemParts,
      },
      refetchQueries: [
        {
          query: GET_ORDER,
          variables: {
            organisationID,
          },
        },
      ],
    }),
    errorMessage: `Could not add delivery addresses to items`,
    successMessage: "Address assigned to all items",
  })
);

export default enhancer(AddDestinationToAllData);

import DataTable from "../../generic/DataTable";
import * as React from "react";
import gql from "graphql-tag";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { ShippingBatchOrderItemPartTableOrderItemPart } from "./__generated__/ShippingBatchOrderItemPartTableOrderItemPart";
import { displayWarehouseAllocationToOrder } from "../../../../helpers/displayWarehouseAllocationToOrder";
import { displayLeadTime } from "../../../../helpers/displayLeadTime";

export const shippingBatchOrderItemPartTableFragments = {
  orderItemPart: gql`
    fragment ShippingBatchOrderItemPartTableOrderItemPart on OrderItemPart {
      id
      orderItem {
        id
        description
        weight
        volume
        leadTime
      }
      quantityAllocated
      totalWeight
      totalVolume
      originList {
        toOrder
      }
    }
  `,
};

type Props = {
  className?: string;
  loading: boolean;
  orderItemPartList: ShippingBatchOrderItemPartTableOrderItemPart[];
};

const columns: Column<ShippingBatchOrderItemPartTableOrderItemPart>[] = [
  {
    Header: "Description",
    id: "orderItem.description",
  },
  {
    Header: "Qty",
    id: "quantityAllocated",
    numeric: true,
  },
  {
    Header: "Supplied",
    id: "originList[0].toOrder",
    Cell: ({ value }) => displayWarehouseAllocationToOrder(value),
  },
  {
    Header: "Lead Time",
    id: "lead time",
    disableSortable: true,
    Cell: ({ row }) => {
      const r: ShippingBatchOrderItemPartTableOrderItemPart = row as ShippingBatchOrderItemPartTableOrderItemPart;

      const toOrder: boolean = r.originList
        .filter((item) => item != null)
        .reduce((result, item) => result && item.toOrder, true);

      const leadTime: number | undefined =
        toOrder === true
          ? undefined
          : (row as ShippingBatchOrderItemPartTableOrderItemPart).orderItem
              .leadTime;

      return displayLeadTime(leadTime);
    },
  },
  {
    Header: "Total Weight",
    id: "totalWeight",
    numeric: true,
  },
  {
    Header: "Total Volume",
    id: "totalVolume",
    numeric: true,
  },
];

export const ShippingBatchOrderItemPartTable = ({
  className,
  loading,
  orderItemPartList,
}: Props): React.ReactElement => {
  return (
    <DataTable
      className={className}
      columns={columns}
      data={orderItemPartList}
      emptyMessage={"No order item parts"}
      idAccessor={"id"}
      loading={loading}
    />
  );
};

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import DataTable from "../../generic/DataTable/WithSelection";
import gql from "graphql-tag";
import TextInputWithState from "../../generic/form/TextInputWithState";
import CustomField from "../../generic/form/CustomField";
import CurrencyField from "../../generic/form/CurrencyField";
import { CatalogueViewEditProductTableIncludesProduct as IncludesProduct } from "./__generated__/CatalogueViewEditProductTableIncludesProduct";
import { displayCurrency } from "../../../../helpers/formatCurrency";

export const productTableFragments = {
  catalogueViewIncludesProduct: gql`
    fragment CatalogueViewEditProductTableIncludesProduct on _CatalogueViewIncludesProductList {
      Product {
        id
        sku
        description
        standardPrice {
          value
        }
      }
      price
    }
  `,
};

const styles = () => ({
  input: {
    minWidth: `2em`,
    width: `100%`,
  },
});

export type Props = StyleProps & {
  data: IncludesProduct[];
  getFieldProps: (productID: string, name: string) => any;
  loading?: boolean;
  onSelectedIDsChange: (selectedIDs: string[]) => void;
  selectedIDs: string[];
};

/**
 * Component to display a table of products for a catalogue view with editable fields within cells.
 */
class EditProductTable extends React.PureComponent<Props> {
  render() {
    const { classes, getFieldProps, ...tableProps } = this.props;

    const columns = [
      {
        Header: `SKU`,
        id: `Product.sku`,
      },
      {
        Header: `Product`,
        id: `Product.description`,
      },
      {
        Header: `Standard Price`,
        id: `Product.standardPrice.value`,
        numeric: true,
        Cell: ({ value }) => displayCurrency(value),
      },
      {
        Header: `Price Offered`,
        id: `price`,
        numeric: true,
        Cell: ({ id }) => (
          <CustomField
            fullWidth
            component={CurrencyField}
            textFieldComponent={TextInputWithState}
            InputProps={{
              inputProps: {
                className: classes.input,
              },
            }}
            {...getFieldProps(id, `price`)}
          />
        ),
      },
    ];

    return (
      <DataTable
        columns={columns}
        emptyMessage="There are no products in the catalogue view"
        idAccessor="Product.id"
        {...tableProps}
      />
    );
  }
}

export default withStyles(styles)(EditProductTable);

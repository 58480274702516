import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StyleProps } from "../types";
import ItemList, { EnhancedProps as ItemListProps, Item } from "./ItemList";
import { compose } from "recompose";
import classnames from "classnames";

// The spacing MUI uses to the right of the ListItemSecondaryAction component
const SECONDARY_ACTIONS_RIGHT_MARGIN = 4;

const styles = () => ({
  actionHeader: {
    textAlign: `center` as const,
  },

  actionHeadersContainer: {
    display: `flex`,
    flex: 1,
    flexDirection: `row` as const,
    justifyContent: `flex-end`,
    marginRight: SECONDARY_ACTIONS_RIGHT_MARGIN,
  },

  headers: {
    display: `flex`,
    flexDirection: `row` as const,
    justifyContent: `space-between`,
  },

  root: {
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },

  secondaryAction: {
    display: `inline-flex`,
    flexDirection: `row` as const,
    justifyContent: `center`,
  },
});

export type SecondaryAction = {
  header?: string;
  render: (props: { item: Item }) => React.ReactNode;
  width: number;
};

export type EnhancedProps = ItemListProps & {
  mainHeader?: string;
  secondaryActions?: Array<SecondaryAction>;
};

type Props = EnhancedProps & StyleProps;

/**
 * Function to create HOC to add secondaryActions with header text to ItemList.
 * @returns HOC to create new list component.
 */
export const withHeaders = () => (
  ItemListComponent: React.ComponentType<ItemListProps>
) => {
  class ItemListWithHeaders extends React.PureComponent<Props> {
    renderSecondaryActions({ item }: { item: Item }) {
      const { secondaryActions, classes, renderSecondaryActions } = this.props;

      if (!secondaryActions) {
        return renderSecondaryActions ? renderSecondaryActions({ item }) : null;
      }

      return secondaryActions.map((secondaryAction, index) => (
        <div
          key={index}
          className={classes.secondaryAction}
          style={{ width: secondaryAction.width }}
        >
          {secondaryAction.render({ item })}
        </div>
      ));
    }

    render() {
      const {
        classes,
        className,
        secondaryActions,
        listItemProps,
        mainHeader,
        ...itemListProps
      } = this.props;

      // Padding to apply to item to avoid overlap with secondary secondaryActions
      const itemPaddingRight = secondaryActions
        ? secondaryActions.reduce(
            (padding, action) => padding + action.width,
            0
          )
        : 0;

      return (
        <div className={classnames(classes.root, className)}>
          <div className={classes.headers}>
            {
              <Typography gutterBottom variant="caption">
                {mainHeader || ``}
              </Typography>
            }
            {secondaryActions && (
              <div
                id="actionHeaders"
                className={classes.actionHeadersContainer}
              >
                {secondaryActions.map((action, index) => (
                  <Typography
                    key={index}
                    className={classes.actionHeader}
                    style={{ width: action.width }}
                    variant="caption"
                  >
                    {action.header || ``}
                  </Typography>
                ))}
              </div>
            )}
          </div>
          <ItemListComponent
            {...itemListProps}
            listItemProps={(item) => ({
              ...(listItemProps ? listItemProps(item) : undefined),
              style: itemPaddingRight
                ? { paddingRight: itemPaddingRight }
                : undefined,
            })}
            renderSecondaryActions={this.renderSecondaryActions.bind(this)}
          />
        </div>
      );
    }
  }

  const enhancer = compose<Partial<Props>, EnhancedProps>(withStyles(styles));

  return enhancer(ItemListWithHeaders);
};

export default withHeaders()(ItemList);

import * as React from "react";
import ItemListWithMenu from "../../generic/ItemListWithMenu";
import gql from "graphql-tag";
import { CategoryListCategory as Category } from "./__generated__/CategoryListCategory";

export const categoryListFragments = {
  category: gql`
    fragment CategoryListCategory on Category {
      id
      name
    }
  `,
};

type Props = {
  className?: string;
  loading?: boolean;
  categories: Category[] | null;
  onPressClasses: (categoryID: string) => void;
  onPressDelete: (categoryID: string) => void;
  onPressEdit: (categoryID: string) => void;
};

/**
 * Component to display active categories in a list.
 */
export class CategoryList extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      categories,
      onPressClasses,
      onPressDelete,
      onPressEdit,
    } = this.props;

    const listItems = categories
      ? categories.map((category) => ({
          id: category.id,
          text: category.name,
        }))
      : [];

    return (
      <ItemListWithMenu
        className={className}
        listProps={{ dense: true }}
        items={listItems}
        mainHeader="Categories in selected marketplace"
        menuActions={[
          {
            perform: onPressEdit,
            text: `Edit`,
          },
          {
            perform: onPressClasses,
            text: `Classes`,
          },
          {
            perform: onPressDelete,
            text: `Delete`,
          },
        ]}
        loading={loading}
        emptyMessage={
          categories == null
            ? `Select a marketplace to edit its categories`
            : `This marketplace has no categories`
        }
      />
    );
  }
}

export default CategoryList;

import { Route } from "./helpers/Route";
import { ORDER_STEP_CART } from "../../helpers/trading/orderStep";

export const ROUTE_PARAMETERS = {
  AFFILIATION_TYPE: `:affiliationType`,
  ADDRESS_ID: `:addressID`,
  CATALOGUE_VIEW_ID: `:catalogueViewID`,
  CATEGORY_ID: `:categoryID`,
  CLASS_ID: `:classID`,
  EMAIL: `:email`,
  INDUSTRY_ID: `:industryID`,
  MARKETPLACE_ID: `:marketplaceID`,
  ORGANISATION_ID: `:organisationID`,
  ORDER_ID: `:orderID`,
  ORDER_ITEM_ID: `:orderItemID`,
  PRODUCT_ID: `:productID`,
  RATE_CARD_ID: `:rateCardID`,
  SERVICE_AREA_ID: `:serviceAreaID`,
  SHIPPING_BATCH_ID: `:shippingBatchID`,
  STEP: `:step`,
  USER_ID: `:userID`,
  WAREHOUSE_ID: `:warehouseID`,
};

/*
 * Auth
 */
// Callback
const callbackBuilder = () => Route.builder().callback();
export const callbackRoute = () => callbackBuilder().getUrl();

// Login
const loginBuilder = () => Route.builder().login();
export const loginRoute = () => loginBuilder().getUrl();

// Logged Out
const loggedOutBuilder = () => Route.builder().loggedOut();
export const loggedOutRoute = () => loggedOutBuilder().getUrl();

// Unverified
const unverifiedBuilder = (email: string) =>
  Route.builder().unverified().withId(email);
export const unverifiedRoute = (email: string) =>
  unverifiedBuilder(email).getUrl();

// Unauthenticated
const unauthenticatedBuilder = () => Route.builder().unauthenticated();
export const unauthenticatedRoute = () => unauthenticatedBuilder().getUrl();

/*
 * Dashboard
 */
export const dashboardRoute = () => Route.builder().getUrl();

// Profile
const profileBuilder = () => Route.builder().profile();
export const profileRoute = () => profileBuilder().getUrl();

const organisationBaseBuilder = (orgID: string) =>
  Route.builder().organisation().withId(orgID);

/*
 * Organisation
 */
const organisationBuilder = (orgID: string) =>
  organisationBaseBuilder(orgID).organisation();
export const organisationRoute = (orgID: string) =>
  organisationBuilder(orgID).getUrl();

// Dashboard
const organisationDashboardBuilder = (orgID: string) =>
  organisationBuilder(orgID).dashboard();
export const organisationDashboardRoute = (orgID: string) =>
  organisationDashboardBuilder(orgID).getUrl();

// My Network
const myNetworkBuilder = (orgID: string) =>
  organisationBuilder(orgID).myNetwork();
const myNetworkTypeBuilder = (orgID: string, type: string) =>
  myNetworkBuilder(orgID).withId(type);

export const myNetworkRoute = (orgID: string) =>
  myNetworkBuilder(orgID).getUrl();
export const myNetworkTypeRoute = (orgID: string, type: string) =>
  myNetworkTypeBuilder(orgID, type).getUrl();

// Details
const organisationDetailsBuilder = (orgID: string) =>
  organisationBuilder(orgID).details();
export const organisationDetailsRoute = (orgID: string) =>
  organisationDetailsBuilder(orgID).getUrl();

// Customers
const customersBuilder = (orgID: string) =>
  organisationBuilder(orgID).customers();
export const customersRoute = (orgID: string) =>
  customersBuilder(orgID).getUrl();

// Vendors
const vendorsBuilder = (orgID: string) => organisationBuilder(orgID).vendors();
export const vendorsRoute = (orgID: string) => vendorsBuilder(orgID).getUrl();

// Inbox
const inboxBuilder = (orgID: string) => organisationBuilder(orgID).inbox();
export const inboxRoute = (orgID: string) => inboxBuilder(orgID).getUrl();

/*
 * Products
 */
const productsBuilder = (orgID: string) =>
  organisationBaseBuilder(orgID).products();
export const productsRoute = (orgID: string) => productsBuilder(orgID).getUrl();

// Catalogues
const cataloguesBuilder = (orgID: string) =>
  productsBuilder(orgID).catalogues();
export const cataloguesRoute = (orgID: string) =>
  cataloguesBuilder(orgID).getUrl();

// Catalogue - Product
const catalogueProductBuilder = (orgID: string, productID: string) =>
  cataloguesBuilder(orgID).product().withId(productID);
export const catalogueProductRoute = (orgID: string, productID: string) =>
  catalogueProductBuilder(orgID, productID).getUrl();

// Catalogue views
const catalogueViewsBuilder = (orgID: string) =>
  productsBuilder(orgID).catalogueViews();
export const catalogueViewsRoute = (orgID: string) =>
  catalogueViewsBuilder(orgID).getUrl();

// Catalogue views - Form
const catalogueViewFormBuilder = (orgID: string) =>
  catalogueViewsBuilder(orgID).form();
export const catalogueViewFormRoute = (orgID: string) =>
  catalogueViewFormBuilder(orgID).getUrl();

// Catalogue views - edit
const catalogueViewEditBuilder = (orgID: string, catalogueID: string) =>
  catalogueViewsBuilder(orgID).withId(catalogueID).edit();
export const catalogueViewEditRoute = (orgID: string, catalogueID: string) =>
  catalogueViewEditBuilder(orgID, catalogueID).getUrl();

/*
 * Trading
 */
const tradingBuilder = (orgID: string) =>
  organisationBaseBuilder(orgID).trading();
export const tradingRoute = (orgID: string) => tradingBuilder(orgID).getUrl();

// Buy - Order Steps
const buyStepBuilder = (orgID: string, orderStep: string) =>
  tradingBuilder(orgID).buy().withId(orderStep);
export const buyStepRoute = (
  orgID: string,
  orderStep: string = ORDER_STEP_CART
) => buyStepBuilder(orgID, orderStep).getUrl();

// Buy - Product
const buyProductDetailsBuilder = (orgID: string, productID: string) =>
  buyStepBuilder(orgID, ORDER_STEP_CART).product().withId(productID);
export const buyProductDetailsRoute = (orgID: string, productID: string) =>
  buyProductDetailsBuilder(orgID, productID).getUrl();

// Buy - Product - Add to Cart
const buyAddProductToCartBuilder = (orgID: string, productID: string) =>
  buyProductDetailsBuilder(orgID, productID).add();
export const buyAddProductToCartRoute = (orgID: string, productID: string) =>
  buyAddProductToCartBuilder(orgID, productID).getUrl();

// Buy - Order Steps - Order Item
const buyStepOrderItemBuilder = (
  orgID: string,
  orderStep: string,
  orderItemID: string
) => buyStepBuilder(orgID, orderStep).orderItem().withId(orderItemID);
export const buyStepOrderItemRoute = (
  orgID: string,
  orderStep: string,
  orderItemID: string
) => buyStepOrderItemBuilder(orgID, orderStep, orderItemID).getUrl();

// Buy - Order Steps - Order Item - Add
const buyStepOrderItemAddBuilder = (
  orgID: string,
  orderStep: string,
  orderItemID: string
) => buyStepOrderItemBuilder(orgID, orderStep, orderItemID).add();
export const buyStepOrderItemAddRoute = (
  orgID: string,
  orderStep: string,
  orderItemID: string
) => buyStepOrderItemAddBuilder(orgID, orderStep, orderItemID).getUrl();

// Buy - Order Steps - Shipping Batch
const buyStepShippingBatchBuilder = (
  orgID: string,
  orderStep: string,
  batchID: string
) => buyStepBuilder(orgID, orderStep).shippingBatch().withId(batchID);
export const buyStepShippingBatchRoute = (
  orgID: string,
  orderStep: string,
  batchID: string
) => buyStepShippingBatchBuilder(orgID, orderStep, batchID).getUrl();

// Buy- Order Steps - Order
const buyStepOrderBuilder = (
  orgID: string,
  orderStep: string,
  orderID: string
) => buyStepBuilder(orgID, orderStep).order().withId(orderID);
export const buyStepOrderRoute = (
  orgID: string,
  orderStep: string,
  orderID: string
) => buyStepOrderBuilder(orgID, orderStep, orderID).getUrl();

// Purchases
const purchasesBuilder = (orgID: string) => tradingBuilder(orgID).purchases();
export const purchasesRoute = (orgID: string) =>
  purchasesBuilder(orgID).getUrl();

// Purchases - Order
const purchasesOrderBuilder = (orgID: string, orderID: string) =>
  purchasesBuilder(orgID).order().withId(orderID);
export const purchasesOrderRoute = (orgID: string, orderID: string) =>
  purchasesOrderBuilder(orgID, orderID).getUrl();

// Sales
const salesBuilder = (orgID: string) => tradingBuilder(orgID).sales();
export const salesRoute = (orgID: string) => salesBuilder(orgID).getUrl();

// Sales - Shipping batch
const salesShippingBatchBuilder = (orgID: string, batchID: string) =>
  salesBuilder(orgID).shippingBatch().withId(batchID);

export const salesShippingBatchRoute = (orgID: string, batchID: string) =>
  salesShippingBatchBuilder(orgID, batchID).getUrl();

/*
 * Logistics
 */
const logisticsBuilder = (orgID: string) =>
  organisationBaseBuilder(orgID).logistics();
export const logisticsRoute = (orgID: string) =>
  logisticsBuilder(orgID).getUrl();

// Addresses
const addressesBuilder = (orgID: string) => logisticsBuilder(orgID).addresses();
export const addressesRoute = (orgID: string) =>
  addressesBuilder(orgID).getUrl();

// Addresses - Form
const addressFormBuilder = (orgID: string, addressID?: string) =>
  addressesBuilder(orgID).form().withId(addressID);
export const addressFormRoute = (orgID: string, addressID?: string) =>
  addressFormBuilder(orgID, addressID).getUrl();

// Addresses - Hours
const addressesHoursBuilder = (orgID: string, addressID: string) =>
  addressesBuilder(orgID).withId(addressID).operatingHours();
export const addressesHoursRoute = (orgID: string, addressID: string) =>
  addressesHoursBuilder(orgID, addressID).getUrl();

// Warehouses
const warehousesBuilder = (orgID: string) =>
  logisticsBuilder(orgID).warehouses();
export const warehousesRoute = (orgID: string) =>
  warehousesBuilder(orgID).getUrl();

// Warehouses - Form
const warehousesFormBuilder = (orgID: string, warehouseID?: string) =>
  warehousesBuilder(orgID).form().withId(warehouseID);
export const warehousesFormRoute = (orgID: string, warehouseID?: string) =>
  warehousesFormBuilder(orgID, warehouseID).getUrl();

// Warehouses - Warehouse
const warehousesWarehouseBuilder = (orgID: string, warehouseID: string) =>
  warehousesBuilder(orgID).withId(warehouseID);

// Warehouses - View
const warehousesViewBuilder = (orgID: string, warehouseID: string) =>
  warehousesWarehouseBuilder(orgID, warehouseID).view();
export const warehousesViewRoute = (orgID: string, warehouseID: string) =>
  warehousesViewBuilder(orgID, warehouseID).getUrl();

// Warehouses - Inventory
const warehousesInventoryBuilder = (orgID: string, warehouseID: string) =>
  warehousesWarehouseBuilder(orgID, warehouseID).inventory();
export const warehousesInventoryRoute = (orgID: string, warehouseID: string) =>
  warehousesInventoryBuilder(orgID, warehouseID).getUrl();

// Warehouses - Hours
const warehousesHoursBuilder = (orgID: string, warehouseID: string) =>
  warehousesWarehouseBuilder(orgID, warehouseID).operatingHours();
export const warehousesHoursRoute = (orgID: string, warehouseID: string) =>
  warehousesHoursBuilder(orgID, warehouseID).getUrl();

// Warehouses - Service Areas
const warehousesServiceAreasBuilder = (orgID: string, warehouseID: string) =>
  warehousesWarehouseBuilder(orgID, warehouseID).serviceAreas();
export const warehousesServiceAreasRoute = (
  orgID: string,
  warehouseID: string
) => warehousesServiceAreasBuilder(orgID, warehouseID).getUrl();

// Freight Rates
const freightRatesBuilder = (orgID: string) =>
  logisticsBuilder(orgID).freightRates();
export const freightRatesRoute = (orgID: string) =>
  freightRatesBuilder(orgID).getUrl();

// Freight Rates - Form
const freightRatesFormBuilder = (orgID: string, rateID?: string) =>
  freightRatesBuilder(orgID).form().withId(rateID);
export const freightRatesFormRoute = (orgID: string, rateID?: string) =>
  freightRatesFormBuilder(orgID, rateID).getUrl();

/*
 * Help
 */
const helpBuilder = () => Route.builder().help();

// Help Guides
const helpGuidesBuilder = () => helpBuilder().guides();
export const helpGuidesRoute = () => helpGuidesBuilder().getUrl();

// Help videos
const helpVideosBuilder = () => helpBuilder().videos();
export const helpVideosRoute = () => helpVideosBuilder().getUrl();

/*
 * Admin
 */
const adminBuilder = () => Route.builder().admin();

// Categories
const adminCategoriesBuilder = () => adminBuilder().categories();
export const adminCategoriesRoute = () => adminCategoriesBuilder().getUrl();

// Categories - Form
const adminCategoriesFormBuilder = (id?: string) =>
  adminCategoriesBuilder().form().withId(id);
export const adminCategoriesFormRoute = (id?: string) =>
  adminCategoriesFormBuilder(id).getUrl();

// Classes
const adminClassesBuilder = () => adminBuilder().classes();
export const adminClassesRoute = () => adminClassesBuilder().getUrl();

// Industries
const adminIndustriesBuilder = () => adminBuilder().industries();
export const adminIndustriesRoute = () => adminIndustriesBuilder().getUrl();

// Industries - Form
const adminIndustriesFormBuilder = (industryID?: string) =>
  adminIndustriesBuilder().form().withId(industryID);
export const adminIndustriesFormRoute = (industryID?: string) =>
  adminIndustriesFormBuilder(industryID).getUrl();

// Marketplaces
const adminMarketplacesBuilder = () => adminBuilder().marketplaces();
export const adminMarketplacesRoute = () => adminMarketplacesBuilder().getUrl();

// Marketplaces - Form
const adminMarketplacesFormBuilder = (id?: string) =>
  adminMarketplacesBuilder().form().withId(id);
export const adminMarketplacesFormRoute = (id?: string) =>
  adminMarketplacesFormBuilder(id).getUrl();

// Service Areas
const adminServiceAreasBuilder = () => adminBuilder().serviceAreas();
export const adminServiceAreasRoute = () => adminServiceAreasBuilder().getUrl();

// Service Areas - Form
const adminServiceAreasFormBuilder = (id?: string) =>
  adminServiceAreasBuilder().form().withId(id);
export const adminServiceAreasFormRoute = (id?: string) =>
  adminServiceAreasFormBuilder(id).getUrl();

// Users
const adminUsersBuilder = () => adminBuilder().users();
export const adminUsersRoute = () => adminUsersBuilder().getUrl();

// Users - Roles
const adminUsersRoleFormBuilder = (userID: string) =>
  adminUsersBuilder().form().withId(userID).role();
export const adminUsersRoleFormRoute = (userID: string) =>
  adminUsersRoleFormBuilder(userID).getUrl();

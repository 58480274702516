export const ORDER_STEP_CART = "cart";
export const ORDER_STEP_REVIEW = "review";
export const ORDER_STEP_DESTINATIONS = "destinations";
export const ORDER_STEP_SHIPPING = "shipping";
export const ORDER_STEP_CONFIRMATION = "confirmation";

export class OrderStep {
  id: string;
  title: string;

  constructor({ id, title }: { id: string; title: string }) {
    this.id = id;
    this.title = title;
  }

  static orderStepList: OrderStep[] = [
    new OrderStep({
      id: ORDER_STEP_CART,
      title: "Search",
    }),
    new OrderStep({
      id: ORDER_STEP_REVIEW,
      title: "Review",
    }),
    new OrderStep({
      id: ORDER_STEP_DESTINATIONS,
      title: "Destinations",
    }),
    new OrderStep({
      id: ORDER_STEP_SHIPPING,
      title: "Shipping",
    }),
    new OrderStep({
      id: ORDER_STEP_CONFIRMATION,
      title: "Confirmed",
    }),
  ];

  static getById = (stepName: string): OrderStep => {
    const step: OrderStep | typeof undefined = OrderStep.orderStepList.find(
      (step) => step.id === stepName
    );

    return step || OrderStep.orderStepList[0];
  };

  static getStepNumberById = (stepName: string): number => {
    const step: number = OrderStep.orderStepList.findIndex(
      (step) => step.id === stepName
    );

    return step || 0;
  };

  static getStepNumber = (step: OrderStep): number => {
    return OrderStep.getStepNumberById(step.id);
  };

  static getNextStep = (step: OrderStep): OrderStep => {
    let nextStep: number = OrderStep.getStepNumberById(step.id) + 1;

    if (nextStep >= OrderStep.orderStepList.length) {
      nextStep = OrderStep.orderStepList.length - 1;
    }

    return OrderStep.orderStepList[nextStep];
  };

  static getPreviousStep = (step: OrderStep): OrderStep => {
    let nextStep: number = OrderStep.getStepNumberById(step.id) - 1;

    if (nextStep < 0) {
      nextStep = 0;
    }

    return OrderStep.orderStepList[nextStep];
  };
}

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../types";
import Checkbox from "@material-ui/core/Checkbox";
import ItemList, { EnhancedProps as ItemListProps, Item } from "./ItemList";
import { omit } from "lodash";

const styles = () => ({
  checkbox: {
    height: `100%`,
  },
});

export type EnhancedProps = ItemListProps & {
  onSelectedIDsChange: (selectedIDs: string[]) => void;
  selectedIDs: string[];
};

type Props = EnhancedProps & StyleProps;

/**
 * HOC to add select boxes to items in ItemList
 * @param {React.ComponentType} ItemListComponent ItemList or compatible component.
 * @returns {React.ComponentType} New list component.
 */
export const withSelection = (
  ItemListComponent: React.ComponentType<ItemListProps>
) => {
  class ItemListWithSelection extends React.PureComponent<Props> {
    handleToggle(item: Item) {
      const { selectedIDs, onSelectedIDsChange } = this.props;

      const newSelectedIDs = selectedIDs.includes(item.id)
        ? selectedIDs.filter((id) => id !== item.id)
        : [...selectedIDs, item.id];
      onSelectedIDsChange(newSelectedIDs);
    }

    renderItemStart({ item }: { item: Item }) {
      const { classes, renderItemStart, selectedIDs } = this.props;
      const selected = selectedIDs.includes(item.id);

      return (
        <React.Fragment>
          {/* Render other nodes for the start of the item if specified */}
          {renderItemStart && renderItemStart({ item })}
          {/* Render select box */}
          <Checkbox
            disableRipple
            className={classes.checkbox}
            checked={selected}
            tabIndex={-1}
          />
        </React.Fragment>
      );
    }

    listItemProps(item: Item) {
      const { listItemProps } = this.props;

      return {
        ...(listItemProps ? listItemProps(item) : {}),
        button: true,
        onClick: () => this.handleToggle(item),
      };
    }

    render() {
      const itemListProps = omit(this.props, [`classes`]);

      return (
        <React.Fragment>
          <ItemListComponent
            {...itemListProps}
            renderItemStart={this.renderItemStart.bind(this)}
            listItemProps={this.listItemProps.bind(this)}
          />
        </React.Fragment>
      );
    }
  }

  return withStyles(styles)(ItemListWithSelection);
};

export default withSelection(ItemList);

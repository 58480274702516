import * as React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import TimeRange from "./TimeRange";
import StatusIcon, { FieldStatus } from "../../generic/form/StatusIcon";

const styles = () => ({
  timesCell: {
    display: `flex`,
    flexDirection: `row` as const,
  },
});

type Props = StyleProps & {
  closingTime: string;
  dayOfTheWeek: string;
  disabled?: boolean;
  onOpenChange: (open: boolean) => void;
  onTimesBlur: () => void;
  onTimesChange: (openingTime: string, closingTime: string) => void;
  open: boolean;
  openingTime: string;
  status: FieldStatus;
};

/**
 * Component to display a single row of an operating times table
 */
class OperatingTimesRowLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      closingTime,
      dayOfTheWeek,
      disabled,
      onOpenChange,
      onTimesBlur,
      onTimesChange,
      open,
      openingTime,
      status,
    } = this.props;

    return (
      <TableRow>
        <TableCell>{dayOfTheWeek}</TableCell>
        <TableCell>
          <Checkbox
            inputProps={{ "aria-label": `${dayOfTheWeek}-checkbox` }}
            checked={open}
            disabled={disabled}
            onChange={(event) => onOpenChange(event.target.checked)}
          />
        </TableCell>
        <TableCell>
          <div className={classes.timesCell}>
            <TimeRange
              dayOfTheWeek={dayOfTheWeek}
              openingTime={openingTime}
              closingTime={closingTime}
              disabled={disabled || !open}
              onTimesChange={onTimesChange}
              onBlur={onTimesBlur}
            />
            <StatusIcon status={status} />
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(OperatingTimesRowLayout);

import * as React from "react";
import MaskedInput from "react-text-mask";

type Props = {
  inputRef: (ref: any) => any;
};

const TimeInput = ({ inputRef, ...other }: Props) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/\d/, /\d/, /\d/, /\d/]}
    placeholderChar={`\u2000`}
  />
);

export default TimeInput;

import * as React from "react";
import gql from "graphql-tag";
import DestinationLayout, { destinationsLayoutFragments } from "./Layout";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { compose } from "recompose";
import { OrderDataGetOrder_getSelf_shoppingCart_order_orderItemList as OrderItemList } from "./__generated__/OrderDataGetOrder";

export const CREATE_ORDER_FOR_ORGANISATION = gql`
  mutation CreateOrderForOrganisation($organisationID: String!) {
    createOrderForOrganisation(organisation: { id: $organisationID })
  }
`;

export const GET_ORDER = gql`
  query OrderDataGetOrder($organisationID: String!) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        order {
          id
          isFullyAllocatedToDestinations
          orderItemList {
            id
            ...DestinationLayoutOrderItem
          }
        }
      }
    }
  }
  ${destinationsLayoutFragments.orderItem}
`;

type EnhancedProps = {
  organisationID: string;
  onBack: () => void;
  onNext: () => void;
  onEditPressed: (orderItemID: string) => Promise<void> | void;
};

type Props = EnhancedProps & {
  loading: boolean;
  productList: OrderItemList[];
  destinationsAllocated: boolean;
  total: number | null;
  removeDestinationFromOrderItem: (
    orderItemID: string,
    destinationItemId: string
  ) => Promise<void> | void;
  createOrderForOrganisation: (organisationID: string) => Promise<void> | void;
};

class DestinationData extends React.PureComponent<Props> {
  async componentDidMount(): Promise<void> {
    // Create/Re-create the Order and OrderItems every time this is reloaded
    await this.props.createOrderForOrganisation(this.props.organisationID);
  }

  render() {
    const {
      loading,
      productList,
      destinationsAllocated,
      onBack,
      onNext,
      onEditPressed,
      organisationID,
    } = this.props;

    return (
      <DestinationLayout
        loading={loading}
        productList={productList || []}
        organisationID={organisationID}
        destinationsAllocated={destinationsAllocated}
        onBack={onBack}
        onNext={onNext}
        onEditPressed={onEditPressed}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_ORDER, {
    dataPaths: {
      productList: `getSelf.shoppingCart.order.orderItemList`,
      destinationsAllocated: `getSelf.shoppingCart.order.isFullyAllocatedToDestinations`,
    },
    errorMessage: `Failed to load order`,
    errorOnNull: false, // Sometimes the page might load before the order is created - therefore the order may not exist
    loadingProp: `loading`,
    options: ({ organisationID }: EnhancedProps) => ({
      variables: {
        organisationID,
      },
      fetchPolicy: `network-only`,
    }),
  }),
  withMutation(CREATE_ORDER_FOR_ORGANISATION, {
    mapMutationToProps: (createOrderForOrganisation) => ({
      createOrderForOrganisation,
    }),
    mutationOptions: ({ organisationID }) => ({
      variables: {
        organisationID,
      },
      refetchQueries: [
        {
          query: GET_ORDER,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        createOrderForOrganisation: `SUCCESS`,
      },
      awaitRefetchQueries: true,
    }),
    throwOnError: true,
    errorMessage: `Could not create the Order`,
  })
);

export default enhancer(DestinationData);

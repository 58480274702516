import * as React from "react";
import AutosaveForm from "../generic/form/AutosaveForm";
import * as yup from "yup";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { CustomField } from "../generic/form/index";
import FieldList from "../generic/form/FieldList";
import PaperSection from "../generic/PaperSection";
import TextInput from "../generic/form/TextInput";
import TextInputWithState from "../generic/form/TextInputWithState";
import LoadingScreen from "../generic/LoadingScreen";
import CallOnMount from "../generic/CallOnMount";
import telephoneNumberSchema from "../../../helpers/schema/telephoneNumber";
import { OrganisationDetailsOrganisation } from "./__generated__/OrganisationDetailsOrganisation";
import { OrganisationDetailsUpdateOrganisationVariables } from "./__generated__/OrganisationDetailsUpdateOrganisation";
import { SnackbarType } from "../../../__generated__/globalTypes";

export const organisationDetailsFormFragments = {
  organisation: gql`
    fragment OrganisationDetailsOrganisation on Organisation {
      id
      name
      abn
      addressLine1
      addressLine2
      contactEmail
      telephoneNumber
    }
  `,
};

const GET_ORGANISATION = gql`
  query OrganisationDetailsGetOrganisation($id: String!) {
    getSelf {
      id
      organisation(id: $id) {
        ...OrganisationDetailsOrganisation
      }
    }
  }
  ${organisationDetailsFormFragments.organisation}
`;

const UPDATE_ORGANISATION = gql`
  mutation OrganisationDetailsUpdateOrganisation(
    $id: String!
    $values: UpdateOrganisationInput!
  ) {
    updateOwnOrganisation(id: $id, values: $values) {
      ...OrganisationDetailsOrganisation
    }
  }
  ${organisationDetailsFormFragments.organisation}
`;

type EnhancedProps = {
  organisationID: string;
};

type Props = EnhancedProps & {
  error: Error | null | undefined;
  loading: boolean;
  openSnackbar: OpenSnackbarFn;
  organisation: OrganisationDetailsOrganisation | null | undefined;
  updateOrganisation: (
    arg0: OrganisationDetailsUpdateOrganisationVariables
  ) => Promise<any>;
};

const ABN_LENGTH = 11;
const schema = yup.object({
  abn: yup
    .string()
    .required()
    .nullable()
    .matches(/^\d*$/, `ABN must only contain digits.`)
    .min(ABN_LENGTH, `ABN must have ${ABN_LENGTH} digits.`)
    .max(ABN_LENGTH, `ABN must have ${ABN_LENGTH} digits.`)
    .label(`ABN`),
  addressLine1: yup.string().required().nullable().label(`Address Line 1`),
  addressLine2: yup.string().nullable(),
  contactEmail: yup
    .string()
    .required()
    .nullable()
    .email()
    .label(`Primary Contact Email`),
  name: yup.string().required().label(`Name`),
  telephoneNumber: telephoneNumberSchema
    .required()
    .nullable()
    .label(`Telephone Number`),
});

export class OrganisationDetailsForm extends React.PureComponent<Props> {
  render() {
    const {
      error,
      loading,
      organisation,
      openSnackbar,
      updateOrganisation,
    } = this.props;

    if (loading) {
      return <LoadingScreen />;
    }
    if (error || !organisation) {
      return (
        <CallOnMount
          callback={() =>
            openSnackbar(SnackbarType.error, `Failed to load organisation`)
          }
        />
      );
    }

    return (
      <AutosaveForm
        item={organisation}
        itemName="organisation"
        schema={schema}
        updateField={(fieldName, fieldValue) =>
          updateOrganisation({
            id: organisation.id,
            values: {
              [fieldName]: fieldValue,
            },
          })
        }
        hasChanged={(fieldName, fieldValue) =>
          organisation[fieldName] !== fieldValue
        }
      >
        {({ getAutosaveFieldProps }) => (
          <React.Fragment>
            <PaperSection>
              <FieldList>
                <CustomField
                  disabled
                  name="name"
                  label="Name"
                  component={TextInput}
                />
                <CustomField
                  name="abn"
                  label="ABN"
                  component={TextInputWithState}
                  {...getAutosaveFieldProps(`abn`)}
                />
              </FieldList>
            </PaperSection>
            <PaperSection heading="Contact Details">
              <FieldList>
                <CustomField
                  name="telephoneNumber"
                  label="Telephone Number"
                  component={TextInputWithState}
                  {...getAutosaveFieldProps(`telephoneNumber`)}
                />
                <CustomField
                  name="addressLine1"
                  label="Address Line 1"
                  component={TextInputWithState}
                  {...getAutosaveFieldProps(`addressLine1`)}
                />
                <CustomField
                  name="addressLine2"
                  label="Address Line 2"
                  component={TextInputWithState}
                  {...getAutosaveFieldProps(`addressLine2`)}
                />
                <CustomField
                  name="contactEmail"
                  label="Primary Contact Email"
                  component={TextInputWithState}
                  {...getAutosaveFieldProps(`contactEmail`)}
                />
              </FieldList>
            </PaperSection>
          </React.Fragment>
        )}
      </AutosaveForm>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql<EnhancedProps>(GET_ORGANISATION, {
    options: ({ organisationID }) => ({
      variables: {
        id: organisationID,
      },
    }),
    // @ts-ignore
    props: ({ data: { getSelf, loading, error } }) => ({
      error,
      loading,
      organisation: getSelf ? getSelf.organisation : undefined,
    }),
  }),
  graphql(UPDATE_ORGANISATION, {
    props: ({ mutate }) => ({
      updateOrganisation: (
        variables: OrganisationDetailsUpdateOrganisationVariables
      ) => mutate({ variables }),
    }),
  }),
  withOpenSnackbar
);

export default enhancer(OrganisationDetailsForm);

import * as React from "react";
import { Redirect } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { CallbackGetAuth } from "./__generated__/CallbackGetAuth";
import PerformAuthenticate from "../../components/auth/PerformAuthenticate";
import LoadingScreen from "../../components/generic/LoadingScreen";

export const GET_AUTH = gql`
  query CallbackGetAuth {
    auth: getAuth @client {
      loginReturnPath
    }
  }
`;

class GetAuthQuery extends Query<CallbackGetAuth> {}

class Callback extends React.Component {
  render() {
    return (
      <LoadingScreen>
        <GetAuthQuery query={GET_AUTH}>
          {({ data, loading }) => {
            const redirectPath = data && data.auth && data.auth.loginReturnPath;

            if (loading) {
              return null;
            }

            if (redirectPath) {
              return <Redirect to={redirectPath} />;
            } else {
              return <PerformAuthenticate />;
            }
          }}
        </GetAuthQuery>
      </LoadingScreen>
    );
  }
}

export default Callback;

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../../types";
import CurationLayout from "../../../generic/curation/CurationLayout";
import ItemListWithHeaders from "../../../generic/ItemListWithHeaders";
import {
  EnhancedProps as ItemListWithSelectionProps,
  withSelection,
} from "../../../generic/ItemListWithSelection";
import { withFilter } from "../../../generic/ItemListWithFilter";
import ItemListWithMenu from "../../../generic/ItemListWithMenu";
import MarketplaceSelect from "../../MarketplaceSelect";
import { OrganisationCategory as Category } from "./__generated__/OrganisationCategory";

const ItemListWithSelection: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithHeaders as any
);
const AvailableList: React.ComponentType<ItemListWithSelectionProps> = withFilter()(
  ItemListWithSelection as any
) as any;
const ActiveList: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithMenu as any
);

const styles = (theme) => ({
  select: {
    marginBottom: theme.spacing(1),
  },
});

type Props = StyleProps & {
  activeItems?: Category[];
  activeItemsLoading?: boolean;
  availableItems?: Category[];
  availableItemsLoading?: boolean;
  marketplaceID?: string;
  onActivatePressed: () => Promise<void>;
  onDeactivatePressed: () => Promise<void>;
  onIndustryMarketplaceIDChange: (arg0: {
    industryID?: string;
    marketplaceID: string;
  }) => void;
  onPressClasses: (categoryID: string) => void;
  onSelectedActiveItemsChange: (selectIDs: string[]) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  organisationID: string;
  selectedActiveItems: string[];
  selectedAvailableItems: string[];
  deactivateEnabled?: boolean;
};

class OrganisationCategoryLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      marketplaceID,
      onIndustryMarketplaceIDChange,
      onPressClasses,
      organisationID,
      ...curationLayoutProps
    } = this.props;

    return (
      <React.Fragment>
        <MarketplaceSelect
          className={classes.select}
          organisationID={organisationID}
          selectedID={marketplaceID}
          onSelectedIDChange={(marketplaceID, industryID) =>
            onIndustryMarketplaceIDChange({
              industryID,
              marketplaceID,
            })
          }
        />
        <CurationLayout
          activateEnabled
          AvailableListComponent={AvailableList}
          ActiveListComponent={ActiveList}
          activeListProps={{
            emptyMessage:
              marketplaceID == null
                ? `Select a marketplace to edit category memberships`
                : `There are no active category memberships in this marketplace`,
            mainHeader: `Active Category Memberships`,
            menuActions: [
              {
                perform: onPressClasses,
                text: `Classes`,
              },
            ],
            readOnly: true,
          }}
          availableListProps={{
            emptyMessage:
              marketplaceID == null
                ? `Select a marketplace to view available categories`
                : `There are no more categories available in this marketplace`,
            mainHeader: `Available Categories`,
          }}
          itemName="category"
          itemNamePlural="categories"
          deactivateEnabled
          {...curationLayoutProps}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrganisationCategoryLayout);

/*
 * Congifuration of the Apollo GraphQL client
 */
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { withClientState } from "apollo-link-state";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import { defaults, resolvers } from "../resolvers";
import config from "../config";
import Auth from "../services/Auth";

const cache = new InMemoryCache({
  cacheRedirects: {
    Organisation: {
      warehouse: (_obj, args, { getCacheKey }) =>
        getCacheKey({
          __typename: `Warehouse`,
          id: args.warehouseId,
        }),
    },
    Query: {
      getClass: (_obj, args, { getCacheKey }) =>
        getCacheKey({
          __typename: `Class`,
          id: args.id,
        }),
    },
  },
});

// Log errors from Apollo in the console
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      /* eslint-disable-next-line no-console */
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    /* eslint-disable-next-line no-console */
    console.warn(`[Network error]: ${networkError}`);
  }
});

// @ts-ignore
const uploadLink: ApolloLink = createUploadLink({
  uri: `${config.serverURL}/graphql`,
});

// Link to add auth information into the context
const auth = new Auth();
const authLink = setContext((operation, { headers }) => {
  const token = auth.getAccessToken();

  return {
    // Add the Auth0 auth service to the context
    authService: auth,

    // Add the access token to the Authorization header
    headers: {
      ...headers,
      Authorization: token ? token : ``,
    },
  };
});

const stateLink = withClientState({
  cache,
  defaults,
  resolvers,
});

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, authLink, stateLink, uploadLink]),
});

export default client;

import OrganisationClassLayout from "./OrganisationClassLayout";
import gql from "graphql-tag";
import { compose, withProps, withState } from "recompose";
import withCurationQueries from "../../../generic/curation/withCurationQueries";
import withActivateHandler from "../../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../../generic/curation/withDeactivateHandler";
import mergeOrganisationTradesInClass, {
  fragment as tradingClassFragment,
} from "../../../../../helpers/class/mergeOrganisationTradesInClass";

import splitOrganisationTradesInClass from "../../../../../helpers/class/splitOrganisationTradesInClass";

export const organisationClassFragments = {
  class: gql`
    fragment OrganisationClass on Class {
      id
      name
    }
  `,
};

export const GET_AVAILABLE_CLASSES = gql`
  query OrganisationClassesGetAvailableClasses($categoryID: String!) {
    category: getCategory(id: $categoryID) {
      id
      classList {
        ...OrganisationClass
      }
    }
  }
  ${organisationClassFragments.class}
`;

export const GET_ORGANISATION_CLASSES = gql`
  query OrganisationClassesGetOrganisationClasses(
    $organisationID: String!
    $marketplaceID: String!
    $categoryID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        activeClassList(
          marketplaceId: $marketplaceID
          categoryId: $categoryID
        ) {
          ...MergeOrganisationTradesInClass
        }
      }
    }
  }
  ${tradingClassFragment}
`;

export const ADD_TO_CLASS = gql`
  mutation OrganisationClassesAddToClass(
    $organisationID: String!
    $marketplaceID: String!
    $classID: String!
    $buyer: Boolean!
    $seller: Boolean!
  ) {
    addOrganisationBuysInClass: addOrganisationToClass(
      from: { id: $organisationID }
      to: { id: $classID }
      data: { marketplaceId: $marketplaceID, role: BUYER }
    ) @include(if: $buyer) {
      role
    }
    addOrganisationSellsInClass: addOrganisationToClass(
      from: { id: $organisationID }
      to: { id: $classID }
      data: { marketplaceId: $marketplaceID, role: SELLER }
    ) @include(if: $seller) {
      role
    }
  }
`;

export const REMOVE_ORGANISATION_TO_CLASS = gql`
  mutation OrganisationClassAffiliationsRemoveClassSubscriptionToOrganisation(
    $organisationID: String!
    $classID: String!
  ) {
    removeOrganisationToClass(
      from: { id: $organisationID }
      to: { id: $classID }
    )
  }
`;

export type EnhancedProps = {
  categoryID?: string;
  industryID?: string;
  marketplaceID?: string;
  onCategoryIDChange: (categoryID: string) => unknown;
  onIndustryMarketplaceIDChange: (arg0: {
    industryID: string;
    marketplaceID: string;
  }) => unknown;
  organisationID: string;
};

/**
 * Map (classID, props) to the variables for de/activate mutations
 * @param {string} classID ID of class being de/activated
 * @param {EnhancedProps} props Props passed to enhanced component
 * @return {any} Variables
 */
const mutationVariables = (
  classID,
  { organisationID, marketplaceID }: EnhancedProps,
  { buyer, seller }
) =>
  marketplaceID != null
    ? {
        buyer,
        classID,
        marketplaceID,
        organisationID,
        seller,
      }
    : undefined;

const enhancer = compose<Partial<any>, EnhancedProps>(
  withCurationQueries({
    activate: {
      getActiveItems: (activatedClass, extraData) =>
        extraData
          ? splitOrganisationTradesInClass(activatedClass, extraData)
          : [],
      mutation: ADD_TO_CLASS,
      optimisticResponse: {
        addOrganisationBuysInClass: {
          __typename: `OrganisationTradesInClass`,
          role: `BUYER`,
        },
        addOrganisationSellsInClass: {
          __typename: `OrganisationTradesInClass`,
          role: `SELLER`,
        },
      },
      variables: mutationVariables,
    },
    activeItems: {
      dataPath: `getSelf.organisation.activeClassList`,
      fragment: organisationClassFragments.class,
      idAccessPath: `Class.id`,
      query: GET_ORGANISATION_CLASSES,
      variables: ({ organisationID, marketplaceID, categoryID }) =>
        marketplaceID != null && categoryID != null
          ? {
              categoryID,
              marketplaceID,
              organisationID,
            }
          : undefined,
    },
    deactivate: {
      mutation: REMOVE_ORGANISATION_TO_CLASS,
      optimisticResponse: {
        removeOrganisationToClass: `SUCCESS`,
      },
      variables: mutationVariables,
    },
    activeListName: `organisation`,
    availableItems: {
      dataPath: `category.classList`,
      query: GET_AVAILABLE_CLASSES,
      variables: ({ categoryID }) =>
        categoryID != null ? { categoryID } : undefined,
    },
    itemLabel: `class`,
    itemLabelPlural: `classes`,
    itemTypeName: `Class`,
  }),
  withActivateHandler(
    `selectedAvailableItems`,
    `onSelectedAvailableItemsChange`
  ),
  withDeactivateHandler(`selectedActiveItems`, `onSelectedActiveItemsChange`),
  withState(`activateFormOpen`, `setActivateFormOpen`, false),
  withProps(({ activeItems }) => {
    // Merge roles of active items together
    if (activeItems) {
      return {
        activeItems: mergeOrganisationTradesInClass(activeItems),
      };
    } else {
      return {};
    }
  })
);

export default enhancer(OrganisationClassLayout);

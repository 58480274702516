import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { GridProps } from "@material-ui/core";

class GridContainer extends React.PureComponent<GridProps> {
  static muiName = `Grid`;

  render() {
    const { ...props } = this.props;

    return <Grid {...props} container />;
  }
}

export default GridContainer;

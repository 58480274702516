import * as React from "react";
import { compose, withHandlers, withState } from "recompose";
import ConfirmDialog from "../generic/ConfirmDialog";

export type Options = {
  // Map props and the dialog data to props for ConfirmDialog
  dialogProps: (props: any, data: any) => any;
  // Map props and to the function to call (with dialog data) when the dialog is confirmed
  getConfirmHandler: (props: any, data: any) => (data: any) => Promise<void>;
};

export type ProvidedProps = {
  handleOpenConfirm: () => void;
};

/**
 * Get HOC to add a confirmation dialog and handler props.
 * @param {Options} options any for specifying accept function and error message.
 * @returns HOC that will enhance the specified component.
 */
const withConfirmDialog = (options: Options) => (
  WrappedComponent: React.ComponentType<any>
) => {
  class WithConfirmDialog extends React.Component<any> {
    render() {
      const {
        confirmDialogData,
        handleAcceptConfirm,
        handleCloseConfirm,
        ...otherProps
      } = this.props;

      return (
        <React.Fragment>
          <WrappedComponent {...otherProps} />
          <ConfirmDialog
            {...options.dialogProps(this.props, confirmDialogData)}
            open={confirmDialogData !== undefined}
            onAccept={handleAcceptConfirm}
            onClose={handleCloseConfirm}
          />
        </React.Fragment>
      );
    }
  }

  const enhancer = compose<any, any>(
    withState(`confirmDialogData`, `setConfirmDialogData`, undefined),
    withHandlers({
      handleAcceptConfirm: (props) => async () => {
        // Close dialog immediately
        // @ts-ignore
        props.setConfirmDialogData(undefined);

        // Perform the action
        // @ts-ignore
        return await options.getConfirmHandler(props)(props.confirmDialogData);
      },
      handleCloseConfirm: ({ setConfirmDialogData }) => () =>
        setConfirmDialogData(undefined),
      handleOpenConfirm: ({ setConfirmDialogData }) => (data?: any) =>
        setConfirmDialogData(data === undefined ? true : data),
    })
  );

  return enhancer(WithConfirmDialog);
};

export default withConfirmDialog;

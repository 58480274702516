import * as React from "react";
import gql from "graphql-tag";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import { AddDestinationToItemTableAddress } from "./__generated__/AddDestinationToItemTableAddress";
import { Button } from "@material-ui/core";

export const addDestinationToItemTableFragments = {
  address: gql`
    fragment AddDestinationToItemTableAddress on Address {
      id
      businessName
      ...GetAddressStringAddress
    }
    ${addressFragment}
  `,
};

type Props = {
  className?: string;
  loading: boolean;
  addressList: AddDestinationToItemTableAddress[];
  onRowPressed: (addressID: string) => Promise<void>;
};

class AddDestinationTable extends React.PureComponent<Props> {
  render() {
    const { loading, addressList, onRowPressed } = this.props;

    const columns: Column<AddDestinationToItemTableAddress>[] = [
      {
        Header: `Business Name`,
        id: `businessName`,
      },
      {
        Header: `Delivery Address`,
        Cell: ({ row }) => getAddressString(row),
        id: `addr`,
      },
      {
        Header: null,
        id: "add",
        disableSortable: true,
        numeric: true,
        Cell: ({ row }) => (
          <Button
            color={"primary"}
            onClick={async (e) => {
              e.stopPropagation();
              await onRowPressed(row.id);
            }}
            variant={"outlined"}
          >
            Add
          </Button>
        ),
      },
    ];

    return (
      <DataTable
        loading={loading}
        columns={columns}
        data={addressList}
        idAccessor="id"
        emptyMessage="No unallocated destinations"
        onRowPress={onRowPressed}
      />
    );
  }
}

export default AddDestinationTable;

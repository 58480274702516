import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import WarehouseName, { warehouseNameFragments } from "../WarehouseTitle";
import LoadingScreen from "../../generic/LoadingScreen";
import WarehouseInventoryForm, {
  warehouseInventoryFormFragments,
} from "./WarehouseInventoryForm";
import { UpdateWarehouseProductInventoryFn } from "./WarehouseInventoryData";
import {
  WarehouseInventoryLayoutWarehouse,
  WarehouseInventoryLayoutWarehouse_stockLevelList,
} from "./__generated__/WarehouseInventoryLayoutWarehouse";

export const warehouseInventoryLayoutFragments = {
  warehouse: gql`
    fragment WarehouseInventoryLayoutWarehouse on Warehouse {
      id
      ...WarehouseNameWarehouse
      stockLevelList {
        ...WarehouseInventoryFormStockedProduct
      }
    }
    ${warehouseNameFragments.warehouse}
    ${warehouseInventoryFormFragments.warehouseProductStockLevel}
  `,
};

const styles = (theme) => ({
  table: {},
  form: {
    paddingLeft: theme.spacing(0.1),
    paddingRight: theme.spacing(0.1),
    paddingBottom: theme.spacing(1),
    display: `flex`,
    flexDirection: `column` as const,
    overflow: `auto`,
  },
});

type Props = StyleProps & {
  loading?: boolean;
  warehouse: WarehouseInventoryLayoutWarehouse;
  updateWarehouseProductInventory: UpdateWarehouseProductInventoryFn;
};

class WarehouseInventoryLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      warehouse,
      updateWarehouseProductInventory,
    } = this.props;

    const stockLevelList: Array<WarehouseInventoryLayoutWarehouse_stockLevelList> = (
      warehouse.stockLevelList || []
    )
      .filter(Boolean) // https://stackoverflow.com/a/44131503
      .map((item) => item);

    return (
      <>
        <WarehouseName warehouse={warehouse} />
        {!loading && warehouse && warehouse.stockLevelList && (
          <WarehouseInventoryForm
            className={classes.form}
            classes={classes}
            warehouseInventory={stockLevelList}
            updateWarehouseProductInventory={updateWarehouseProductInventory}
          />
        )}
        {loading && <LoadingScreen />}
      </>
    );
  }
}

export default withStyles(styles)(WarehouseInventoryLayout);

import * as React from "react";
import gql from "graphql-tag";
import NestedListQuerySelectField, {
  EnhancedProps as NestedListQuerySelectFieldProps,
} from "../generic/NestedListQuerySelectField";

export const GET_MARKETPLACE_LIST = gql`
  query MarketplaceSelectGetOrganisationMarketplaces($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        activeIndustryList {
          id
          name
          activeMarketplaceList(organisationId: $organisationID) {
            id
            name
          }
        }
      }
    }
  }
`;

type EnhancedProps = NestedListQuerySelectFieldProps & {
  organisationID: string;
};

const MarketplaceSelect = NestedListQuerySelectField({
  innerDataPath: `activeMarketplaceList`,
  itemPluralLabel: `marketplaces`,
  itemTitleLabel: `Marketplace`,
  query: GET_MARKETPLACE_LIST,
  rootDataPath: `getSelf.organisation.activeIndustryList`,
  variables: ({ organisationID }) => ({ organisationID }),
}) as React.ComponentType<EnhancedProps>;

export default MarketplaceSelect;

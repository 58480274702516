import gql from "graphql-tag";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../../generic/ListQuerySelectField";
import withSelection from "../../generic/withSelection";
import { compose } from "recompose";

export type EnhancedProps = ListQuerySelectFieldProps & {
  organisationID: string;
};

export const GET_CATALOGUE_VIEWS = gql`
  query CatalogueViewSelectGetOrganisation(
    $marketplaceID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        catalogueViewList(marketplaceId: $marketplaceID) {
          id
          name
        }
        id
      }
    }
  }
`;

const IndustrySelect = ListQuerySelectField({
  clearSelectionOnVariablesChange: true,
  dataPath: `getSelf.organisation.catalogueViewList`,
  itemPluralLabel: `catalogue views`,
  itemTitleLabel: `Catalogue View`,
  query: GET_CATALOGUE_VIEWS,
  variables: ({ selection: { activeMarketplaceID }, organisationID }) =>
    activeMarketplaceID != null
      ? {
          marketplaceID: activeMarketplaceID,
          organisationID,
        }
      : undefined,
});

const enhancer = compose<any, EnhancedProps>(withSelection);

export default enhancer(IndustrySelect);

import { compose, withProps } from "recompose";
import { get } from "lodash";

// Options for creating the HOC
type Options = {
  activeItemIDAccessPath?: string;
  availableItemIDAccessPath?: string;
};

export type EnhancedProps = {
  activeItems: any[];
  allAvailableItems: any[];
};

export type ProvidedProps = {
  availableItems: any[];
};

/**
 * HOC to add a filtered a list of available items to props with the active items filtered out.
 * @param {any} options Options for configuring queries.
 * @return Function for creating enhanced component.
 */
export default (options: Options) =>
  compose<ProvidedProps, EnhancedProps>(
    withProps(({ activeItems, allAvailableItems }) => {
      let availableItems = allAvailableItems;
      if (allAvailableItems && activeItems) {
        const getActiveItemID = (item) =>
          get(item, options.activeItemIDAccessPath || `id`);
        const getAvailableItemID = (item) =>
          get(item, options.availableItemIDAccessPath || `id`);
        // Filter active items from the available items list
        availableItems = allAvailableItems.filter(
          (item) =>
            !activeItems.find(
              (activeItem) =>
                getActiveItemID(activeItem) === getAvailableItemID(item)
            )
        );
      }

      return { availableItems };
    })
  );

import { Auth0DecodedHash, CheckSessionOptions, WebAuth } from "auth0-js";
import { promisify } from "es6-promisify";
import jwtDecode from "jwt-decode";
import config from "../../config";
import { callbackRoute, loggedOutRoute } from "../../ui/routes";

const MILLISECONDS_PER_SECOND = 1000;

const encodeState = <State>(state: State | null | undefined): string | void => {
  if (state == null) {
    return undefined;
  }
  try {
    return JSON.stringify(state);
  } catch (error) {
    return undefined;
  }
};

const decodeState = <State>(
  stringState: string | null | undefined
): State | undefined => {
  if (stringState == null) {
    return undefined;
  }
  try {
    return JSON.parse(stringState);
  } catch (error) {
    return undefined;
  }
};

export type IDToken = {
  email?: string;
  email_verified?: boolean;
  sub?: string;

  [key: string]: any;
};

export type AuthenticationResult<State> = {
  error?: Error;

  // Whether authentication was successful
  isAuthenticated: boolean;

  // Whether user email is verified
  isEmailVerified?: boolean;

  state?: State;
};

export default class Auth<State> {
  auth0: WebAuth;

  _renewTimeout: any | null | undefined;

  _parseHash: () => Promise<Auth0DecodedHash>;

  _checkSession: (options: CheckSessionOptions) => Promise<Auth0DecodedHash>;

  constructor() {
    this.auth0 = new WebAuth({
      audience: config.auth0.audience,
      clientID: config.auth0.clientID,
      domain: config.auth0.domain,
      redirectUri: `${config.publicURL}${callbackRoute()}`,
      responseType: `token id_token`,
      scope: `openid email`,
    });

    this._parseHash = promisify(this.auth0.parseHash.bind(this.auth0));
    this._checkSession = promisify(this.auth0.checkSession.bind(this.auth0));

    this._scheduleRenewal();
  }

  /**
   * @return {number} Time of token expiry (milliseconds since epoch).
   */
  get _expiryTime(): number | null | undefined {
    const expiryString = localStorage.getItem(
      this._getStorageKey(`expires_at`)
    );
    if (expiryString != null) {
      return JSON.parse(expiryString);
    } else {
      return null;
    }
  }

  /**
   * Authorize the user.
   * Attempts silent token retrieval, on failure redirects the user to Auth0 for authentication.
   * @param {State} state Application state to be passed through to Auth0 login.
   * @returns {Promise<AuthenticationResult>} Always resolves, isAuthenticated is true if authenticated silently.
   */
  async authorize(state?: State): Promise<AuthenticationResult<State>> {
    try {
      const isEmailVerified = await this._renewToken();

      if (!isEmailVerified) {
        throw new Error(`Email not verified`);
      }

      return {
        isAuthenticated: true,
        isEmailVerified: true,
        state,
      };
    } catch (error) {
      // Silent renewal failed, redirect to login
      this.auth0.authorize({
        state: encodeState(state),
      });

      return {
        error: error,
        isAuthenticated: false,
      };
    }
  }

  /**
   * Handle the callback response from Auth0.
   * @returns {Promise<AuthenticationResult>} Always resolves with AuthenticationResult.
   */
  async handleAuthentication(): Promise<AuthenticationResult<State>> {
    try {
      const authResult = await this._parseHash();
      if (!authResult) {
        throw new Error(`No authentication information`);
      }

      const isEmailVerified = this._checkEmailVerified(authResult);
      this._setSession(authResult);

      return {
        isAuthenticated: true,
        isEmailVerified,
        state: decodeState(authResult.state),
      };
    } catch (error) {
      return {
        error: error.errorDescription
          ? new Error(error.errorDescription)
          : error,
        isAuthenticated: false,
      };
    }
  }

  /**
   * Logout from Auth0 and clear user data.
   * @returns {Promise} Resolves after logging out.
   */
  logout() {
    this._cancelRenewal();

    // Clear Access Token and ID Token from local storage
    localStorage.removeItem(this._getStorageKey(`access_token`));
    localStorage.removeItem(this._getStorageKey(`id_token`));
    localStorage.removeItem(this._getStorageKey(`expires_at`));

    this.auth0.logout({
      returnTo: `${config.publicURL}${loggedOutRoute()}`,
    });
  }

  /**
   * @return {boolean} Whether the user has current authentication credentials.
   */
  isAuthenticated(): boolean {
    // Check whether the current time is past the Access Token's expiry time
    const expiryTime = this._expiryTime;

    return expiryTime ? new Date().getTime() < expiryTime : false;
  }

  /**
   * Decode and return the ID token if it is available.
   * @return {any} The decoded JWT, or undefined if not available or invalid.
   */
  getID(): IDToken | null | undefined {
    const idToken = localStorage.getItem(this._getStorageKey(`id_token`));

    return idToken ? this._decodeIDToken(idToken) : undefined;
  }

  /**
   * @return {?string} An access token to use for accessing APIs.
   */
  getAccessToken(): string | null | undefined {
    return this.isAuthenticated()
      ? localStorage.getItem(this._getStorageKey(`access_token`))
      : null;
  }

  /**
   * Attempt to renew session silently.
   * @return {Promise<boolean>} Resolves with whether the email is verified, rejects on error.
   */
  async _renewToken(): Promise<boolean> {
    // Attempt to renew session silently
    const authResult = await this._checkSession({});

    if (!this._checkEmailVerified(authResult)) {
      return false;
    }
    this._setSession(authResult);

    return true;
  }

  /**
   * @param {string} idToken ID token.
   * @return {any} ID token data, or undefined if the token is invalid.
   */
  _decodeIDToken(idToken: string): IDToken | null | undefined {
    try {
      return jwtDecode(idToken);
    } catch (error) {
      return undefined;
    }
  }

  /**
   * @param {Auth0DecodedHash} authResult Result from Auth0
   * @return {boolean} Whether the given auth result contains ID indicating email is verified.
   */
  _checkEmailVerified(authResult: Auth0DecodedHash): boolean {
    const idToken = authResult.idToken
      ? this._decodeIDToken(authResult.idToken)
      : undefined;

    return (idToken && idToken.email_verified) === true;
  }

  /**
   * Update the persistently stored user session details, and schedule renewal.
   * @param {Auth0DecodedHash} authResult The result of Auth0 authentication.
   * @return {void}
   */
  _setSession(authResult: Auth0DecodedHash) {
    const { expiresIn, accessToken, idToken } = authResult;
    if (expiresIn == null || accessToken == null || idToken == null) {
      throw new Error(`Invalid authentication result`);
    }

    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(
      expiresIn * MILLISECONDS_PER_SECOND + new Date().getTime()
    );
    localStorage.setItem(this._getStorageKey(`access_token`), accessToken);
    localStorage.setItem(this._getStorageKey(`id_token`), idToken);
    localStorage.setItem(this._getStorageKey(`expires_at`), expiresAt);

    this._scheduleRenewal();
  }

  /**
   * If there is a valid access token, schedule renewal at the expiry time.
   * @return {void}
   */
  _scheduleRenewal() {
    this._cancelRenewal();

    const expiryTime = this._expiryTime;
    if (expiryTime != null) {
      // Compute seconds until the token expires
      const expiresIn = expiryTime - new Date().getTime();

      if (expiresIn > 0) {
        this._renewTimeout = setTimeout(() => {
          this._renewTimeout = null;
          try {
            this._renewToken();
          } catch (error) {
            // Do nothing
          }
        }, expiresIn);
      }
    }
  }

  _cancelRenewal() {
    // Stop any existing timeout
    if (this._renewTimeout != null) {
      clearTimeout(this._renewTimeout);
      this._renewTimeout = null;
    }
  }

  /**
   * Get the local storage key to use for the given item
   * @param {string} item Name for item to store.
   * @return {string} Local storage key.
   */
  _getStorageKey(item: "access_token" | "id_token" | "expires_at") {
    return `${config.auth0.domain}:${item}`;
  }
}

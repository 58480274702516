import * as React from "react";
import ItemListWithMenu from "../generic/ItemListWithMenu";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../../components/generic/CallOnMount";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../components/generic/snackbar/withOpenSnackbar";
import {
  WarehouseListGetWarehouseList,
  WarehouseListGetWarehouseListVariables,
} from "./__generated__/WarehouseListGetWarehouseList";
import { compose } from "recompose";
import { SnackbarType } from "../../../__generated__/globalTypes";

export const warehouseListFragments = {
  warehouse: gql`
    fragment WarehouseListWarehouse on Warehouse {
      id
      name
    }
  `,
};

export const GET_WAREHOUSES = gql`
  query WarehouseListGetWarehouseList($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouseList {
          ...WarehouseListWarehouse
        }
      }
    }
  }
  ${warehouseListFragments.warehouse}
`;

class GetWarehouseListQuery extends Query<
  WarehouseListGetWarehouseList,
  WarehouseListGetWarehouseListVariables
> {}

type EnhancedProps = {
  onPressEdit: (warehouseID: string) => void;
  onPressEditInventory: (warehouseID: string) => void;
  onPressEditOperatingTimes: (warehouseID: string) => void;
  onPressEditServiceAreas: (warehouseID: string) => void;
  onPressView: (warehouseID: string) => void;
  organisationID: string;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
};

/**
 * Component to query organisation's warehouses and display results in a list.
 */
export class WarehouseList extends React.PureComponent<Props> {
  render() {
    const {
      onPressEdit,
      onPressEditInventory,
      onPressEditOperatingTimes,
      onPressEditServiceAreas,
      onPressView,
      openSnackbar,
      organisationID,
    } = this.props;

    return (
      <GetWarehouseListQuery
        query={GET_WAREHOUSES}
        variables={{
          organisationID,
        }}
      >
        {({ data, loading, error }) => {
          const warehouses =
            data &&
            !loading &&
            !error &&
            data.getSelf &&
            data.getSelf.organisation
              ? data.getSelf.organisation.warehouseList
              : null;
          const listItems = warehouses
            ? warehouses.map((warehouse) => ({
                id: warehouse.id,
                text: warehouse.name || ``,
              }))
            : [];

          return (
            <React.Fragment>
              <ItemListWithMenu
                listProps={{ dense: true }}
                items={listItems}
                menuActions={[
                  {
                    perform: onPressView,
                    text: `View`,
                  },
                  {
                    perform: onPressEdit,
                    text: `Edit Details`,
                  },
                  {
                    perform: onPressEditInventory,
                    text: `Edit Inventory`,
                  },
                  {
                    perform: onPressEditOperatingTimes,
                    text: `Edit Operating Hours`,
                  },
                  {
                    perform: onPressEditServiceAreas,
                    text: `Edit Service Areas`,
                  },
                ]}
                loading={loading}
              />
              {(error || (!warehouses && !loading)) && (
                <CallOnMount
                  callback={() =>
                    openSnackbar(
                      SnackbarType.error,
                      `Failed to load warehouses.`
                    )
                  }
                />
              )}
            </React.Fragment>
          );
        }}
      </GetWarehouseListQuery>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withOpenSnackbar);
export default enhancer(WarehouseList);

import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { StyleProps } from "../../types";
import { SPACING_PAPER } from "../../../../helpers/constants";

const styles = (theme: Theme) => ({
  head: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row" as const,
  },
  paper: {
    display: "flex",
    flexDirection: "column" as const,
    padding: theme.spacing(SPACING_PAPER),
  },
  select: {
    marginTop: theme.spacing(1),
  },
});

export type EnhancedProps = {
  // The currently selected filter value for each column
  filter: {
    [key: string]: any;
  };
  // Define columns that can be filtered on, and optionally specific values that can be chosen for the filter
  filterOptions: {
    [key: string]: {
      label: string;
      values: any[];
    };
  };
  onFilterChange: (columnID: string, value?: any) => void;
  onResetFilters: () => void;
};

type Props = StyleProps & EnhancedProps;

/**
 * Component displaying controls to select a filter.
 */
class FilterMenu extends React.PureComponent<Props> {
  _handleFilterChange(event: any, columnID: string) {
    const { onFilterChange } = this.props;

    const filterValue = event.target.value;
    if (filterValue === "_all") {
      onFilterChange(columnID, undefined);
    } else {
      onFilterChange(columnID, filterValue);
    }
  }

  render() {
    const { classes, filter, filterOptions, onResetFilters } = this.props;

    return (
      <Paper className={classes.paper}>
        <div className={classes.head}>
          <Typography variant="subtitle1">Filters</Typography>
          <Button color="primary" onClick={onResetFilters}>
            Reset
          </Button>
        </div>
        {Object.keys(filterOptions).map((columnID) => {
          const { label, values } = filterOptions[columnID];

          return (
            <TextField
              select
              className={classes.select}
              key={columnID}
              label={label}
              value={filter[columnID] != null ? filter[columnID] : "_all"}
              onChange={(event) => this._handleFilterChange(event, columnID)}
            >
              <MenuItem value="_all">All</MenuItem>
              {values.map((filterOption) => (
                <MenuItem key={filterOption} value={filterOption}>
                  {filterOption}
                </MenuItem>
              ))}
            </TextField>
          );
        })}
      </Paper>
    );
  }
}

export default withStyles(styles)(FilterMenu);

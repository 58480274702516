import * as yup from "yup";

const PHONE_NUMBER_LENGTH = 10;

export default yup
  .string()
  .matches(/^\d*$/, `Telephone number must only contain digits.`)
  .min(
    PHONE_NUMBER_LENGTH,
    `Telephone number must have ${PHONE_NUMBER_LENGTH} digits.`
  )
  .max(
    PHONE_NUMBER_LENGTH,
    `Telephone number must have ${PHONE_NUMBER_LENGTH} digits.`
  );

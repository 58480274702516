import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import GridContainer from "../../generic/grid/GridContainer";
import { StyleProps } from "../../types";
import AddDestinationTable, {
  addDestinationTableFragments,
} from "./AddDestinationTable";
import createAutosaveListForm from "../../generic/AutosaveListForm";
import DetailGridItem from "../../generic/grid/DetailGridItem";
import { AddDestinationTableOrderItem } from "./__generated__/AddDestinationTableOrderItem";
import { Add } from "@material-ui/icons";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";

export const addDestinationLayoutFragments = {
  orderItem: gql`
    fragment AddDestinationTableOrderItem on OrderItem {
      id
      description
      supplier {
        id
        name
      }
      quantity
      unallocated
      orderItemPartList {
        id
        ...AddDestinationTableOrderItemPart
      }
    }
    ${addDestinationTableFragments.orderItemPart}
  `,
};

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column" as const,
  },
  addDestinationForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    overflow: `auto`,
    marginTop: theme.spacing(1),
  },
  table: {
    alignSelf: `stretch`,
    margin: theme.spacing(0.1),
  },
  actionSpacer: {
    flex: 1,
  },
});

const AutosaveListForm = createAutosaveListForm({
  itemIDAccessor: `id`,
  itemSchema: yup.object().shape({
    quantity: yup
      .number()
      .typeError(`Quantity must be a number`)
      .nullable()
      .required()
      .positive()
      .label(`Quantity`),
  }),
  getValuesForItem: (item: any) => ({
    quantity: item.quantityAllocated != null ? item.quantityAllocated : 0,
  }),
});

type Props = StyleProps & {
  loading: boolean;
  orderItem?: AddDestinationTableOrderItem;
  organisationID: string;
  onAddDestinationPressed: () => void;
  removeDestinationFromOrderItem: (arg0: {
    addressID: string;
  }) => void | Promise<void>;
  onDismiss: () => void;
  onUpdateOrderItemDestinationQuantityPressed: (
    updateOrderItemParts: UpdateOrderItemPartInput[]
  ) => Promise<void>;
};

class AddDestinationLayout extends React.PureComponent<Props> {
  async updateItem(destinationID: any, fieldName: string, value: string) {
    const {
      onUpdateOrderItemDestinationQuantityPressed,
      orderItem: { id, orderItemPartList },
      organisationID,
    } = this.props;
    const valueToStore = Number(value);

    const destId: string = orderItemPartList.find((e) => e.id === destinationID)
      .destination.id;

    const updateOrderItemParts: UpdateOrderItemPartInput[] = [
      {
        organisation: { id: organisationID },
        orderItem: { id: id },
        destination: { id: destId },
        quantityAllocated: valueToStore,
      },
    ];

    if (valueToStore != null && !isNaN(valueToStore)) {
      return await onUpdateOrderItemDestinationQuantityPressed(
        updateOrderItemParts
      );
    }

    throw new Error(`Failed to parse field value`);
  }

  renderOrderItemDetails() {
    const { orderItem } = this.props;

    if (orderItem == null) {
      return null;
    }

    return (
      <GridContainer>
        <DetailGridItem title={"Supplier"} value={orderItem.supplier.name} />
        <DetailGridItem title={"Product"} value={orderItem.description} />
        <DetailGridItem title={"Quantity"} value={orderItem.quantity} />
        <DetailGridItem
          title={<strong>Unallocated</strong>}
          value={<strong>{orderItem.unallocated}</strong>}
        />
      </GridContainer>
    );
  }

  renderForm() {
    const {
      classes,
      orderItem,
      loading,
      removeDestinationFromOrderItem,
      ...otherProps
    } = this.props;
    const orderItemPartList =
      orderItem && orderItem.orderItemPartList
        ? orderItem.orderItemPartList
        : [];

    return (
      // @ts-ignore
      <AutosaveListForm
        className={classes.addDestinationForm}
        key={String(orderItemPartList.length + orderItem?.id ?? "")} // Reset state when items are added or removed
        items={orderItemPartList}
        itemName="destination"
        updateItem={this.updateItem.bind(this)}
        {...otherProps}
      >
        {/* @ts-ignore */}
        {({ getAutosaveListFieldProps }) => (
          <AddDestinationTable
            className={classes.table}
            orderItemPartList={orderItemPartList}
            loading={loading}
            getFieldProps={getAutosaveListFieldProps}
            removeDestinationFromOrderItem={removeDestinationFromOrderItem}
          />
        )}
      </AutosaveListForm>
    );
  }

  render() {
    const { classes, onAddDestinationPressed, onDismiss } = this.props;

    return (
      <>
        <DialogTitle>Assign Delivery Addresses</DialogTitle>

        <DialogContent className={classes.content}>
          {this.renderOrderItemDetails()}
          {this.renderForm()}
        </DialogContent>

        <DialogActions>
          <Button
            color={"primary"}
            onClick={onAddDestinationPressed}
            startIcon={<Add />}
            variant={"contained"}
          >
            Delivery Address
          </Button>
          <div className={classes.actionSpacer} />
          <Button onClick={onDismiss}>Done</Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(AddDestinationLayout);

import gql from "graphql-tag";
import { MergeOrganisationTradesInClass } from "./__generated__/MergeOrganisationTradesInClass";

export const fragment = gql`
  fragment MergeOrganisationTradesInClass on _OrganisationActiveClassList {
    Class {
      id
      name
    }
    role
  }
`;

export type TradingClass = {
  id: string;
  name: string;
  roles: string[];
};

/**
 * Merge a list of OrganisationTradesInClass relationships into a list of class with buyer and seller attributes.
 * @param {MergeOrganisationTradesInClass[]} relationships Relationship objects to be merged,
 *  including the MergeOrganisationTradesInClass fragment.
 * @return {TradingClass} The classes with boolean attributes for buyer and seller roles.
 */
const mergeOrganisationTradesInClass = (
  relationships: MergeOrganisationTradesInClass[]
): TradingClass[] => {
  const classes: TradingClass[] = [];
  const classByID: Map<string, TradingClass> = new Map();

  relationships.forEach((item) => {
    const { Class, role } = item;

    if (Class && role) {
      if (classByID.has(Class.id)) {
        ((classByID.get(Class.id) as any) as TradingClass).roles.push(role);
      } else {
        const newClass = {
          id: Class.id,
          name: Class.name,
          roles: [role],
        };
        classes.push(newClass);
        classByID.set(Class.id, newClass);
      }
    }
  });

  return classes;
};

export default mergeOrganisationTradesInClass;

import gql from "graphql-tag";
import { GetAddressStringAddress as Address } from "./__generated__/GetAddressStringAddress";

export const addressFragment = gql`
  fragment GetAddressStringAddress on Address {
    id
    businessName
    addressLine1
    addressLine2
    city
    postcode {
      id
      postcode
      country {
        id
        name
      }
      state
    }
  }
`;

const getAddressString = (address: Address) => {
  let result = address.addressLine1;
  if (address.addressLine2) {
    result += ` ${address.addressLine2}`;
  }
  if (address.city) {
    result += ` ${address.city}`;
  }
  if (address.postcode.state) {
    result += ` ${address.postcode.state}`;
  }
  result += ` ${address.postcode.postcode} ${address.postcode.country.name}`;

  return result;
};

export default getAddressString;

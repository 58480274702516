import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { GET_ADDRESSES } from "./AddressTableData";
import { addressTableFragments } from "./AddressTable";
import PostcodeInput from "../../generic/form/PostcodeInput";
import CountrySelect from "../../generic/CountrySelect";
import StateDisplay from "../../generic/StateDisplay";
import telephoneNumberSchema from "../../../../helpers/schema/telephoneNumber";
import postcodeSchema from "../../../../helpers/schema/postcode";
import GridContainer from "../../generic/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import { AddressFormAddress as Address } from "./__generated__/AddressFormAddress";
import { AddressFormCreateAddressVariables } from "./__generated__/AddressFormCreateAddress";
import { AddressFormUpdateAddressVariables } from "./__generated__/AddressFormUpdateAddress";
import { compose } from "recompose";

export const addressFormFragments = {
  address: gql`
    fragment AddressFormAddress on Address {
      id
      businessName
      addressLine1
      addressLine2
      city
      telephoneNumber
      postcode {
        id
        postcode
        country {
          id
          name
        }
        state
      }
    }
  `,
};

export const CREATE_ADDRESS = gql`
  mutation AddressFormCreateAddress(
    $businessName: String!
    $organisationID: String!
    $postcode: String!
    $country: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $telephoneNumber: String
  ) {
    createAddress(
      values: {
        businessName: $businessName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        telephoneNumber: $telephoneNumber
      }
      organisation: { id: $organisationID }
      postcode: { postcode: $postcode }
      country: { name: $country }
    ) {
      ...AddressFormAddress
      ...AddressTableAddress
    }
  }
  ${addressFormFragments.address}
  ${addressTableFragments.address}
`;

export const UPDATE_ADDRESS = gql`
  mutation AddressFormUpdateAddress(
    $id: String!
    $businessName: String!
    $postcode: String!
    $country: String!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $telephoneNumber: String
  ) {
    updateAddress(
      id: $id
      values: {
        businessName: $businessName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        telephoneNumber: $telephoneNumber
      }
      postcode: { postcode: $postcode }
      country: { name: $country }
    ) {
      ...AddressFormAddress
      ...AddressTableAddress
    }
  }
  ${addressFormFragments.address}
  ${addressTableFragments.address}
`;

export const GET_ADDRESS = gql`
  query AddressFormGetAddress($id: String!, $organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        address(id: $id) {
          id
          ...AddressFormAddress
        }
      }
    }
  }
  ${addressFormFragments.address}
`;

const schema = yup.object({
  businessName: yup.string().required().label(`Business Name`),
  addressLine1: yup.string().required().label(`Address Line 1`),
  addressLine2: yup.string().nullable().label(`Address Line 2`),
  city: yup.string().required().label(`City`),
  country: yup.string().required().label(`Country`),
  postcode: postcodeSchema.required().label(`Postcode`),
  telephoneNumber: telephoneNumberSchema.nullable(),
});

// Get the form values from data
const getValues = (address: Partial<Address> | null | undefined) => {
  if (address) {
    return {
      businessName: address.businessName,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      postcode: address.postcode && address.postcode.postcode,
      country: address.postcode && address.postcode.country.name,
      telephoneNumber: address.telephoneNumber,
    };
  } else {
    return undefined;
  }
};

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  organisationID: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  item?: Partial<Address>;
  createMutation: (address: AddressFormCreateAddressVariables) => Promise<void>;
  updateMutation: (address: AddressFormUpdateAddressVariables) => Promise<void>;
};

export class AddressForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      itemID,
      item,
      organisationID,
      createMutation,
      ...createUpdateFormProps
    } = this.props;

    return (
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        item={getValues(item)}
        itemType="address"
        itemTypeTitle="Address"
        itemNameAccessor="name"
        createMutation={(values) =>
          createMutation({
            ...values,
            organisationID,
          })
        }
        {...createUpdateFormProps}
      >
        {/* @ts-ignore */}
        {({ values }) => (
          <FieldList component={BodyContainer}>
            <GridContainer>
              <Grid item xs={12}>
                <CustomField
                  fullWidth
                  name="businessName"
                  label="Business Name"
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12}>
                <GridContainer spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      name="addressLine1"
                      label="Address (Line 1)"
                      component={TextInput}
                    />
                    <CustomField
                      fullWidth
                      name="addressLine2"
                      label="Address (Line 2)"
                      component={TextInput}
                    />
                    <CustomField
                      fullWidth
                      name="city"
                      label="City"
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      name="country"
                      label="Country"
                      component={CountrySelect}
                    />
                    <CustomField
                      fullWidth
                      name="postcode"
                      label="Postcode"
                      component={TextInput}
                      InputProps={{ inputComponent: PostcodeInput }}
                    />
                    <StateDisplay
                      fullWidth
                      postcodeString={values.postcode}
                      country={values.country}
                    />
                  </Grid>
                </GridContainer>
              </Grid>
            </GridContainer>
          </FieldList>
        )}
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createAddress`,
    createMutation: CREATE_ADDRESS,
    getItemAccessPath: `getSelf.organisation.address`,
    getQuery: GET_ADDRESS,
    getQueryVariables: ({ itemID, organisationID }) =>
      itemID
        ? {
            id: itemID,
            organisationID,
          }
        : undefined,
    listAccessPath: `getSelf.organisation.addressList`,
    listQuery: GET_ADDRESSES,
    listQueryVariables: ({ organisationID }) => ({ organisationID }),
    updateMutation: UPDATE_ADDRESS,
  })
);

export default enhancer(AddressForm);

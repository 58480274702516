import {
  addressesRoute,
  adminCategoriesRoute,
  adminClassesRoute,
  adminIndustriesRoute,
  adminMarketplacesRoute,
  adminServiceAreasFormRoute,
  adminServiceAreasRoute,
  adminUsersRoute,
  myNetworkRoute,
  customersRoute,
  cataloguesRoute,
  catalogueViewEditRoute,
  catalogueViewsRoute,
  freightRatesRoute,
  helpGuidesRoute,
  helpVideosRoute,
  inboxRoute,
  buyStepRoute,
  organisationDetailsRoute,
  organisationRoute,
  profileRoute,
  purchasesRoute,
  salesRoute,
  vendorsRoute,
  warehousesHoursRoute,
  warehousesInventoryRoute,
  warehousesRoute,
  warehousesServiceAreasRoute,
  warehousesViewRoute,
  organisationDashboardRoute,
  productsRoute,
  tradingRoute,
  logisticsRoute,
} from "../../../routes";

import { RouteProps } from "react-router-dom";

const GENERIC_ID = `:id`;

export interface IBreadcrumbConfig {
  path: string;
  render: () => string;
  matchOptions?: RouteProps;
}

export const breadcrumbList: IBreadcrumbConfig[] = [
  {
    render: () => `Profile`,
    path: profileRoute(),
  }, // Organisation
  {
    render: () => `Organisation`,
    path: organisationRoute(GENERIC_ID),
  },
  {
    render: () => `Dashboard`,
    path: organisationDashboardRoute(GENERIC_ID),
  },
  {
    render: () => `My Network`,
    path: myNetworkRoute(GENERIC_ID),
  },
  {
    render: () => `Details`,
    path: organisationDetailsRoute(GENERIC_ID),
  },
  {
    render: () => `Customers`,
    path: customersRoute(GENERIC_ID),
  },
  {
    render: () => `Vendors`,
    path: vendorsRoute(GENERIC_ID),
  },
  {
    render: () => `Inbox`,
    path: inboxRoute(GENERIC_ID),
  }, // Products
  {
    render: () => `Products`,
    path: productsRoute(GENERIC_ID),
  },
  {
    render: () => `Catalogues`,
    path: cataloguesRoute(GENERIC_ID),
  },
  {
    render: () => `Catalogue Views`,
    path: catalogueViewsRoute(GENERIC_ID),
  },
  {
    render: () => `Edit`,
    path: catalogueViewEditRoute(GENERIC_ID, GENERIC_ID),
  }, // Trading
  {
    render: () => `Trading`,
    path: tradingRoute(GENERIC_ID),
  },
  {
    render: () => `Buy`,
    path: buyStepRoute(GENERIC_ID, GENERIC_ID),
  },
  {
    render: () => `Purchases`,
    path: purchasesRoute(GENERIC_ID),
  },
  {
    render: () => `Sales`,
    path: salesRoute(GENERIC_ID),
  }, // Logistics
  {
    render: () => `Logistics`,
    path: logisticsRoute(GENERIC_ID),
  },
  {
    render: () => `Addresses`,
    path: addressesRoute(GENERIC_ID),
  },
  {
    render: () => `Warehouses`,
    path: warehousesRoute(GENERIC_ID),
  },
  {
    render: () => `View`,
    path: warehousesViewRoute(GENERIC_ID, GENERIC_ID),
  },
  {
    render: () => `Inventory`,
    path: warehousesInventoryRoute(GENERIC_ID, GENERIC_ID),
  },
  {
    render: () => `Operating Hours`,
    path: warehousesHoursRoute(GENERIC_ID, GENERIC_ID),
  },
  {
    render: () => `Service Areas`,
    path: warehousesServiceAreasRoute(GENERIC_ID, GENERIC_ID),
  },
  {
    render: () => `Freight Rates`,
    path: freightRatesRoute(GENERIC_ID),
  }, // Help
  {
    render: () => `User Guides`,
    path: helpGuidesRoute(),
  },
  {
    render: () => `User Videos`,
    path: helpVideosRoute(),
  }, // Admin
  {
    render: () => `User Management`,
    path: adminUsersRoute(),
  },
  {
    render: () => `Industries`,
    path: adminIndustriesRoute(),
  },
  {
    render: () => `Marketplaces`,
    path: adminMarketplacesRoute(),
  },
  {
    render: () => `Categories`,
    path: adminCategoriesRoute(),
  },
  {
    render: () => `Classes`,
    path: adminClassesRoute(),
  },
  {
    render: () => `Service Areas`,
    path: adminServiceAreasRoute(),
  },
  {
    render: () => `Create/Edit`,
    path: adminServiceAreasFormRoute(),
  },
];

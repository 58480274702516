import * as React from "react";
import { QueryResult, graphql } from "react-apollo";
import gql from "graphql-tag";
import { FormikBag, Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import { CustomField } from "../../generic/form/index";
import FileInput from "../../generic/form/FileInput";
import { compose } from "recompose";
import getGraphqlException from "../../../../helpers/getGraphqlException";
import UploadSummaryLink from "../../generic/uploadSummary/UploadSummaryLink";
import { UploadSummary } from "../../generic/uploadSummary/UploadSummaryText";
import { SnackbarType } from "../../../../__generated__/globalTypes";
import { CloudUpload } from "@material-ui/icons";
import { GET_INDUSTRIES } from "./IndustryList";
import UploadFormSubmitContainer from "../../generic/uploads/UploadFormSubmitContainer";

export const UPLOAD_INDUSTRIES = gql`
  mutation UploadIndustriesWithFile($file: Upload!) {
    createIndustriesWithFile(file: $file) {
      createdCount
      updatedCount
      errors
    }
  }
`;

type Props = {
  openSnackbar: OpenSnackbarFn;
  uploadIndustries: (file: any) => Promise<QueryResult<any>>;
};

type State = {
  uploadSummary?: UploadSummary;
};

type Values = {
  industriesFile: any;
};

export class IndustryUploadForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploadSummary: undefined,
    };
  }

  async _handleSubmit(
    values: Values,
    { resetForm, setSubmitting }: FormikBag<any, Values>
  ) {
    const { uploadIndustries, openSnackbar } = this.props;

    try {
      const successResult: QueryResult<any> = await uploadIndustries(
        values.industriesFile
      );
      // Show snackbar on success
      openSnackbar(SnackbarType.success, `Successfully uploaded industries`);
      setSubmitting(false);
      resetForm();
      // Store info about success
      this.setState({
        uploadSummary: {
          success:
            successResult &&
            successResult.data &&
            successResult.data.createIndustriesWithFile
              ? {
                  deleteCount:
                    successResult.data.createIndustriesWithFile.deletedCount,
                  updateCount:
                    successResult.data.createIndustriesWithFile.updatedCount,
                }
              : undefined,
        },
      });
    } catch (error) {
      openSnackbar(SnackbarType.error, `Failed to upload industries`);
      setSubmitting(false);
      // Store info about failure
      const exception = getGraphqlException(
        error,
        [`createIndustriesWithFile`],
        `BAD_USER_INPUT`
      );
      this.setState({
        uploadSummary:
          exception != null
            ? {
                failure: {
                  badRowCount: exception.badRowCount || 0,
                  errors: exception.errors || [],
                  totalRowCount: exception.totalRowCount || 0,
                },
              }
            : undefined,
      });
    }
  }

  render() {
    return (
      // @ts-ignore
      <Formik onSubmit={this._handleSubmit.bind(this)}>
        {(formikBag) => {
          return (
            <Form>
              <CustomField
                fullWidth
                name="industriesFile"
                component={FileInput}
                inputProps={{ accept: `.csv` }}
              />
              <UploadFormSubmitContainer>
                <Button
                  color="primary"
                  disabled={
                    formikBag.isSubmitting ||
                    !formikBag.dirty ||
                    !formikBag.values.industriesFile
                  }
                  startIcon={<CloudUpload />}
                  type="submit"
                  variant={"contained"}
                >
                  Upload
                </Button>
                <UploadSummaryLink
                  itemTitleText="Industry"
                  itemPluralText="industries"
                  uploadSummary={this.state.uploadSummary}
                />
              </UploadFormSubmitContainer>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props>, any>(
  withOpenSnackbar as any,
  graphql(UPLOAD_INDUSTRIES, {
    props: ({ mutate }) => ({
      uploadIndustries: (file: any) =>
        mutate({
          refetchQueries: () => [
            {
              query: GET_INDUSTRIES,
            },
          ],
          variables: {
            file,
          },
        }),
    }),
  })
);

export default enhancer(IndustryUploadForm);

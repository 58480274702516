import * as React from "react";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../generic/ListQuerySelectField";
import { GET_RATECARD_LIST } from "./Form";

export type EnhancedProps = ListQuerySelectFieldProps & {
  organisationID: string;
};

const RateCardSelect = ListQuerySelectField({
  clearSelectionOnVariablesChange: true,
  dataPath: `getSelf.organisation.rateCardList`,
  itemPluralLabel: `rate cards`,
  itemTitleLabel: `Rate Card`,
  query: GET_RATECARD_LIST,
  variables: ({ organisationID }) => ({
    organisationID,
  }),
}) as React.ComponentType<EnhancedProps>;

export default RateCardSelect;

import { MergeOrganisationTradesInClass as OrganisationTradesInClass } from "./__generated__/MergeOrganisationTradesInClass";

export type ClassT = {
  id: string;
  name: string;
};

export type Roles = {
  buyer: boolean;
  seller: boolean;
};

/**
 * Split a class and its roles into a list of 1 or 2 OrganisationTradesInClass relationships.
 * @param {ClassT} classObj Original class object with no role data
 * @param {Roles} roles Organisation roles for the class
 * @return {OrganisationTradesInClass} OrganisationTradesInClass relationship object
 */
const splitOrganisationTradesInClass = (
  classObj: ClassT,
  roles: Roles
): OrganisationTradesInClass[] => {
  const relationships: OrganisationTradesInClass[] = [];

  if (roles.buyer) {
    relationships.push({
      Class: {
        ...classObj,
        __typename: `Class`,
      },
      __typename: "_OrganisationActiveClassList",
      role: `BUYER`,
    });
  }

  if (roles.seller) {
    relationships.push({
      Class: {
        ...classObj,
        __typename: `Class`,
      },
      __typename: "_OrganisationActiveClassList",
      role: `SELLER`,
    });
  }

  return relationships;
};

export default splitOrganisationTradesInClass;

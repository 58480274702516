import * as React from "react";

type Props = {
  callback: () => void;
  children?: React.ReactNode;
};

/**
 * Simply calls the given callback function immediately upon mounting.
 */
class CallOnMount extends React.Component<Props> {
  componentDidMount() {
    this.props.callback();
  }

  render() {
    const { children } = this.props;

    return children === undefined ? null : this.props.children;
  }
}

export default CallOnMount;

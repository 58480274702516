import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import { match } from "react-router-dom";
import OrganisationDetailsForm from "../../components/organisation/OrganisationDetailsForm";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  match: match<MatchProps>;
};

class Home extends React.Component<Props> {
  render() {
    const { organisationID } = this.props.match.params;

    return (
      <MainPanel fullWidth title="Details">
        {organisationID && (
          <OrganisationDetailsForm organisationID={organisationID} />
        )}
      </MainPanel>
    );
  }
}

export default Home;

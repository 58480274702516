import { get, isEqual } from "lodash";

/**
 * Get an exception from an Apollo error that matches a given path and error code.
 * @param {any} error The error thrown by Apollo client.
 * @param {string[]} path The query path to check for an error.
 * @param {string} errorCode The error code string to check for.
 * @return {any | void} The exception object if found, otherwise undefined.
 */
const getGraphqlException = (
  error: any,
  path: string[],
  errorCode: string
): any | void => {
  if (!error.graphQLErrors) {
    return undefined;
  }
  const { graphQLErrors } = error;

  const matchingError = graphQLErrors.find(
    (gqlError) =>
      isEqual(path, gqlError.path) &&
      get(gqlError, `extensions.code`) === errorCode
  );
  const exception = get(matchingError, `extensions.exception`);

  return exception || undefined;
};

export default getGraphqlException;

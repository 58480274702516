import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import SubscribedOrganisationList from "../../generic/SubscribedOrganisationList";
import { SubscribedOrganisationRateCardPartialOrganisation as PartialOrganisation } from "./__generated__/SubscribedOrganisationRateCardPartialOrganisation";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import { DialogActions } from "@material-ui/core";
import { Close, Publish } from "@material-ui/icons";

const styles = () => ({
  list: {
    flex: 1,
  },
  root: {
    display: `flex`,
    flexDirection: `column` as const,
    justifyItems: `center`,
  },
});

type Props = StyleProps & {
  availableItems: PartialOrganisation[];
  availableItemsLoading: boolean;
  onActivatePressed: () => void;
  onClose: () => void;
  open: boolean;
  setSubscribingOrganisationID: (selectIds: string[]) => void;
  subscribingOrganisationID: string[];
};

class AddSubscribedOrganisationsDialog extends React.Component<Props> {
  render() {
    const {
      availableItems,
      availableItemsLoading,
      classes,
      onActivatePressed,
      onClose,
      open,
      setSubscribingOrganisationID,
      subscribingOrganisationID,
    } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Publish</DialogTitle>
        <DialogContent className={classes.root}>
          <SubscribedOrganisationList
            // @ts-ignore
            className={classes.list}
            emptyMessage="No available organisations"
            loading={availableItemsLoading}
            selectedIDs={subscribingOrganisationID}
            subscribedOrganisations={availableItems}
            title="Publish to organisations:"
            onSelectedIDsChange={setSubscribingOrganisationID}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} startIcon={<Close />}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant={"contained"}
            disabled={subscribingOrganisationID.length === 0}
            onClick={onActivatePressed}
            startIcon={<Publish />}
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AddSubscribedOrganisationsDialog);

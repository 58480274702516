import * as React from "react";
import TextInput from "./TextInput";
import { withStyles } from "@material-ui/core/styles";
import { CustomFieldProps } from "./CustomField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeStyleProps } from "../../types";
import StatusIcon, { FieldStatus } from "./StatusIcon";

const styles = () => ({});

type Props = CustomFieldProps<string> &
  ThemeStyleProps & {
    InputProps: any;
    state: FieldStatus;
  };

const TextInputWithState = ({ state, InputProps, ...rest }: Props) => (
  <TextInput
    {...(rest as any)}
    InputProps={{
      ...InputProps,
      endAdornment: (
        <InputAdornment position="end">
          <StatusIcon status={state} />
        </InputAdornment>
      ),
    }}
  />
);

export default withStyles(styles)(TextInputWithState);

import * as React from "react";
import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { compose, withHandlers } from "recompose";
import withMutation from "../../generic/graphql/withMutation";
import withConfirmDialog from "../../generic/withConfirmDialog";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";
import { Query } from "react-apollo";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import { get } from "lodash";

export const GET_ADDRESS = gql`
  query ConfirmDeleteAddressGetAddress(
    $addressID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        address(id: $addressID) {
          id
          ...GetAddressStringAddress
        }
      }
    }
  }
  ${addressFragment}
`;

export const DELETE_ADDRESS = gql`
  mutation ConfirmDeleteAddressDeleteAddress($addressID: String!) {
    deleteAddress(id: $addressID)
  }
`;

export type Options = {
  listQuery: DocumentNode;
};

export type EnhancedProps = {
  organisationID: string;
};

export type ProvidedProps = {
  onPressDelete: (addressID: string) => void;
};

const ConfirmDeleteAddressDialogText = ({ addressID, organisationID }: any) => (
  <Query
    query={GET_ADDRESS}
    variables={{
      addressID,
      organisationID,
    }}
    skip={addressID == null}
  >
    {({ data }) => {
      const address = get(data, `getSelf.organisation.address`);

      return (
        <React.Fragment>
          Do you really want to delete the address{" "}
          <strong>{address ? getAddressString(address) : ``}</strong>?
        </React.Fragment>
      );
    }}
  </Query>
);

export default (options: Options) =>
  compose<ProvidedProps, EnhancedProps>(
    withMutation(DELETE_ADDRESS, {
      mutationOptions: ({ organisationID }, [addressID]) => ({
        variables: { addressID },
        optimisticResponse: {
          deleteAddress: "SUCCESS",
        },
        update: deleteMutationUpdate({
          listQuery: options.listQuery,
          listDataAccessPath: `getSelf.organisation.addressList`,
          listQueryVariables: { organisationID },
          itemID: addressID,
        }),
      }),
      mapMutationToProps: (deleteAddress) => ({ deleteAddress }),
      errorMessage: `Failed to delete address`,
      successMessage: `Deleted address`,
    }),
    withConfirmDialog({
      getConfirmHandler: ({ deleteAddress }) => (addressID) =>
        deleteAddress(addressID),
      dialogProps: ({ organisationID }, addressID) => ({
        title: `Delete Address`,
        text: (
          <ConfirmDeleteAddressDialogText
            addressID={addressID}
            organisationID={organisationID}
          />
        ),
        severe: true,
      }),
    }),
    (withHandlers as any)({
      onPressDelete: ({ handleOpenConfirm }) => handleOpenConfirm,
    })
  );

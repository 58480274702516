import * as React from "react";
import { match, Redirect } from "react-router-dom";
import { cataloguesRoute, dashboardRoute } from "../index";

type MatchParams = {
  organisationID: string;
};

type Props = {
  match: match<MatchParams>;
};

const Products = function (props: Props) {
  const { organisationID } = props.match.params;

  if (organisationID == null) {
    return <Redirect to={dashboardRoute()} />;
  }

  return <Redirect to={cataloguesRoute(organisationID)} />;
};

export default Products;

import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { CreateCatalogueViewFormCreateCatalogueViewVariables } from "./__generated__/CreateCatalogueViewFormCreateCatalogueView";
import { compose } from "recompose";
import getOptimisticID from "../../../../helpers/getOptimisticID";
import withSelection, { Selection } from "../../generic/withSelection";
import { GET_CATALOGUE_VIEWS } from "./CatalogueViewSelect";

export const catalogueViewFormFragments = {
  catalogueView: gql`
    fragment CreateCatalogueViewFormCatalogueView on CatalogueView {
      id
      name
    }
  `,
};

export const CREATE_CATALOGUE_VIEW = gql`
  mutation CreateCatalogueViewFormCreateCatalogueView(
    $name: String!
    $marketplaceID: String!
    $organisationID: String!
  ) {
    createCatalogueView(
      values: { name: $name }
      marketplace: { id: $marketplaceID }
      organisation: { id: $organisationID }
    ) {
      ...CreateCatalogueViewFormCatalogueView
    }
  }
  ${catalogueViewFormFragments.catalogueView}
`;

const schema = yup.object({
  name: yup.string().required().label(`Name`),
});

type EnhancedProps = {
  BodyContainer: React.ElementType;
  onDismiss: (force?: boolean) => void;
  organisationID: string;
};

type Props = EnhancedProps & {
  createMutation: (
    catalogueView: CreateCatalogueViewFormCreateCatalogueViewVariables
  ) => Promise<void>;
  selection: Selection;
};

export class CreateCatalogueViewForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      createMutation,
      organisationID,
      selection: { activeMarketplaceID },
      ...createUpdateFormProps
    } = this.props;

    return (
      <CreateUpdateForm
        item={undefined}
        schema={schema}
        itemNameAccessor="name"
        itemType="catalogue view"
        itemTypeTitle="Catalogue View"
        createMutation={(values) =>
          createMutation({
            ...values,
            marketplaceID: activeMarketplaceID,
            organisationID,
          })
        }
        {...createUpdateFormProps}
      >
        <FieldList component={BodyContainer}>
          <CustomField
            fullWidth
            autoFocus
            name="name"
            label="Name"
            component={TextInput}
          />
        </FieldList>
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withSelection as any,
  withCreateUpdateQueries({
    createItemAccessPath: `createCatalogueView`,
    createMutation: CREATE_CATALOGUE_VIEW,
    createOptimisticResponse: ({ name }) => ({
      createCatalogueView: {
        __typename: `CatalogueView`,
        id: getOptimisticID(),
        name,
      },
    }),
    listAccessPath: `getSelf.organisation.catalogueViewList`,
    listQuery: GET_CATALOGUE_VIEWS,
    listQueryVariables: ({
      selection: { activeMarketplaceID },
      organisationID,
    }) => ({
      marketplaceID: activeMarketplaceID,
      organisationID,
    }),
  })
);

export default enhancer(CreateCatalogueViewForm);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import RateCardLayout from "../../components/rateCard/Layout";
import RateCardForm from "../../components/rateCard/Form";
import { Link, match, Route } from "react-router-dom";
import FormDialog from "../../components/generic/FormDialog";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import withQueryParams from "../../components/generic/withQueryParams";
import { compose } from "recompose";
import {
  freightRatesFormRoute,
  freightRatesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type QueryParams = {
  rateCardID?: string;
};

type MatchProps = {
  organisationID?: string;
};

type EnhancedProps = {
  history: H.History;
  location: Location;
  match: match<MatchProps>;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (arg0: Partial<QueryParams>) => void;
};

class RateCard extends React.PureComponent<Props> {
  handleRateCardIDChange(rateCardID?: string) {
    const { setQueryParams } = this.props;

    setQueryParams({ rateCardID });
  }

  render() {
    const {
      history,
      match,
      location,
      queryParams: { rateCardID },
    } = this.props;
    const { organisationID } = match.params;
    if (!organisationID) {
      return null;
    }

    return (
      <MainPanel title="Freight Rates">
        <RateCardLayout
          organisationID={organisationID}
          rateCardID={rateCardID}
          onPressEdit={() =>
            rateCardID &&
            history.push(
              freightRatesFormRoute(organisationID, rateCardID) +
                location.search
            )
          }
          onRateCardIDChange={this.handleRateCardIDChange.bind(this)}
        />
        <Route
          path={freightRatesFormRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            `${ROUTE_PARAMETERS.RATE_CARD_ID}?`
          )}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.rateCardID != null
                    ? formMatch.params.rateCardID
                    : undefined,
              }}
              onClose={() =>
                history.push(
                  freightRatesRoute(organisationID) + location.search
                )
              }
            >
              {({ handleClose, ...rest }) => (
                <RateCardForm
                  organisationID={organisationID}
                  onDismiss={handleClose}
                  {...rest}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          // @ts-ignore
          to={freightRatesFormRoute(organisationID) + location.search}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());

export default enhancer(RateCard);

import React from "react";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

import { StyleProps } from "../types";

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `row` as const,
    justifyContent: `flex-end`,
  },
  alignSelfStart: {
    alignSelf: `flex-start`,
  },
});

type Props = StyleProps & {
  backDisabled?: boolean;
  backText?: boolean;
  nextBadgeContent?: React.ReactNode;
  nextDisabled?: boolean;
  nextText?: string;
  alignSelfStart?: boolean;
  onBack?: () => Promise<void> | void;
  onNext?: () => Promise<void> | void;
};

const OrderActions = ({
  classes,
  backDisabled,
  backText,
  nextBadgeContent,
  nextDisabled,
  nextText,
  alignSelfStart,
  onNext,
  onBack,
}: Props) => {
  return (
    <div
      className={classnames(
        classes.buttonContainer,
        alignSelfStart && classes.alignSelfStart
      )}
    >
      {onBack != null && (
        <Button
          className={classes.button}
          disabled={backDisabled}
          onClick={() => onBack()}
          startIcon={<NavigateBefore />}
        >
          {backText || `Back`}
        </Button>
      )}

      {onNext && (
        <Badge badgeContent={nextBadgeContent} color={"secondary"}>
          <Button
            className={classes.button}
            color="primary"
            disabled={nextDisabled}
            variant="contained"
            onClick={() => onNext()}
            endIcon={<NavigateNext />}
          >
            {nextText || `Next`}
          </Button>
        </Badge>
      )}
    </div>
  );
};

export default withStyles(styles)(OrderActions);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { StyleProps } from "../../types";
import { ProvidedProps as ProductSearchDataProps } from "./Data";
import ProductSearchTable, { productSearchTableFragments } from "./Table";
import SearchInput from "../../generic/SearchInput";
import OrderActions from "../OrderActions";
import MainTitle from "../../app/MainTitle";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

export const SUPPLIER_SELECT_ARIA_LABEL = "Select supplier";
export const SUPPLIER_SELECT_INPUT_TEST_ID = "supplier-select-input";
export const ADD_TO_CART_TITLE = "Add to Cart";

export const WILDCARD_INSTRUCTIONS =
  "Use * to search for partial words. For example, 'te*' returns all products with words starting with 'te'.";

export const productSearchLayoutFragments = {
  catalogueViewProduct: gql`
    fragment ProductSearchLayoutCatalogueViewProduct on CatalogueViewProduct {
      Product {
        id
      }
      ...ProductSearchTableCatalogueViewProduct
    }
    ${productSearchTableFragments.catalogueViewProduct}
  `,
  supplier: gql`
    fragment ProductSearchLayoutSupplier on Supplier {
      id
      name
    }
  `,
};

const styles = (theme) => ({
  input: {
    alignSelf: `stretch`,
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  table: {
    alignSelf: `stretch`,
    marginTop: theme.margins.paper,
  },
  formControl: {
    flexGrow: 1,
  },
});

type Props = StyleProps & ProductSearchDataProps;

class ProductSearchLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      cart,
      loading,
      loadingSuppliers,
      products,
      suppliers,
      onPressAddToCart,
      onPressMoreDetails,
      onNext,
      searchText,
      selectedSupplier,
      setSelectedSupplier,
      setSearchText,
      updateSearch,
    } = this.props;

    const itemsInCart: number =
      cart != null && cart.productList ? cart.productList.length : 0;

    return (
      <>
        <MainTitle>{ADD_TO_CART_TITLE}</MainTitle>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justify="space-between"
          wrap="nowrap"
          spacing={3}
        >
          <Grid container item direction="row" alignItems="center">
            <Tooltip
              title={
                <Typography variant="body1">{WILDCARD_INSTRUCTIONS}</Typography>
              }
              placement="right-start"
            >
              <Info color="disabled" data-testid="wildcard-instructions-icon" />
            </Tooltip>
            <SearchInput
              className={classes.input}
              searchText={searchText}
              onSearchTextChange={setSearchText}
              onPerformSearch={updateSearch}
            />
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <FormControl className={classes.formControl}>
              <InputLabel shrink>Supplier</InputLabel>
              {!loadingSuppliers ? (
                <Select
                  value={selectedSupplier}
                  inputProps={{
                    "aria-label": SUPPLIER_SELECT_ARIA_LABEL,
                    "data-testid": SUPPLIER_SELECT_INPUT_TEST_ID,
                  }}
                  onChange={(
                    e: React.ChangeEvent<{ name?: string; value: string }>
                  ) => setSelectedSupplier(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {suppliers != null &&
                    suppliers.map((supplier) => (
                      <MenuItem key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Select
                  value=""
                  displayEmpty
                  renderValue={() => "..."}
                  onChange={(e) => e.preventDefault()}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>

        <ProductSearchTable
          className={classes.table}
          loading={loading}
          products={products}
          onPressAddToCart={onPressAddToCart}
          onPressMoreDetails={onPressMoreDetails}
        />

        <OrderActions
          onNext={onNext}
          nextDisabled={!itemsInCart}
          nextBadgeContent={itemsInCart}
        />
      </>
    );
  }
}

export default withStyles(styles)(ProductSearchLayout);

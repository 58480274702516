import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import gql from "graphql-tag";
import { OperatingTimesTableOperationHours } from "./__generated__/OperatingTimesTableOperationHours";
import OperatingTimesDay from "./OperatingTimesDay";

export const operatingTimesTableFragments = {
  warehouse: gql`
    fragment OperatingTimesTableOperationHours on OperationHours {
      id
      dayOfTheWeek
      openingTime
      closingTime
    }
  `,
};

export const daysOfTheWeek = [
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
  `Sunday`,
];

export type Props = {
  className?: string;
  operationHours: OperatingTimesTableOperationHours[];
  organisationID: string;
  warehouseID: string;
};

/**
 * Component to display a table with a row for each day of the week, with columns to define open
 * days and their operating hours.
 */
class OperatingTimesTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      operationHours,
      organisationID,
      warehouseID,
    } = this.props;

    return (
      <Paper className={className}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Open</TableCell>
              <TableCell align={`center`}>Times</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              /* Render OperatingTimesDay for each day of the week */
              daysOfTheWeek.map((dayOfTheWeek) => {
                const hours = operationHours.find(
                  (hours) => hours.dayOfTheWeek === dayOfTheWeek
                );

                return (
                  <OperatingTimesDay
                    key={dayOfTheWeek}
                    dayOfTheWeek={dayOfTheWeek}
                    operationHours={hours}
                    organisationID={organisationID}
                    warehouseID={warehouseID}
                  />
                );
              })
            }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default OperatingTimesTable;

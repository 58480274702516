import * as React from "react";
import ItemListWithSelection, {
  EnhancedProps as ItemListWithSelectionProps,
} from "./ItemListWithSelection";
import { withFilter } from "./ItemListWithFilter";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";

const ItemList: React.ComponentType<ItemListWithSelectionProps> = withFilter()(
  ItemListWithSelection as any
) as any;

const styles = () => ({
  list: {
    minWidth: 200, // Ensures that the empty message is readable
  },
});

type PartialOrganisation = {
  id: string;
  name: string;
  addressLine2?: string;
  __typename: "PartialOrganisation";
};

type Props = StyleProps & {
  className?: string;
  emptyMessage: string;
  loading: boolean;
  onSelectedIDsChange: (selectIDs: string[]) => void;
  selectedIDs: string[];
  subscribedOrganisations?: PartialOrganisation[];
  title?: string;
};

class SubscribedOrganisationList extends React.Component<Props> {
  getListItems(): { id: string; text: string }[] {
    return (
      this.props.subscribedOrganisations?.map((org) => ({
        id: org.id,
        text: `${org.name}${
          org.addressLine2 != null ? `, ${org.addressLine2}` : ""
        }`,
      })) || []
    );
  }

  render() {
    const {
      classes,
      emptyMessage,
      loading,
      onSelectedIDsChange,
      selectedIDs,
      title,
    } = this.props;

    return (
      <React.Fragment>
        <Typography className={classes.heading} variant="caption">
          {title}
        </Typography>
        <ItemList
          className={classes.list}
          emptyMessage={emptyMessage}
          items={this.getListItems()}
          loading={loading}
          selectedIDs={selectedIDs}
          onSelectedIDsChange={onSelectedIDsChange}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubscribedOrganisationList);

import * as React from "react";
import gql from "graphql-tag";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../../generic/ListQuerySelectField";

export type EnhancedProps = ListQuerySelectFieldProps & {
  onSelectedIDChange: (id: string) => unknown;
  industryID?: string;
  marketplaceID?: string;
  organisationID: string;
};

export const GET_CATEGORIES = gql`
  query OrganisationCategorySelectGetCategories(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      activeCategoryList(organisationId: $organisationID) {
        id
        name
      }
    }
  }
`;

const OrganisationCategorySelect = ListQuerySelectField({
  dataPath: `marketplace.activeCategoryList`,
  itemPluralLabel: `categories`,
  itemTitleLabel: `Category`,
  query: GET_CATEGORIES,
  variables: ({ organisationID, marketplaceID }) =>
    organisationID != null && marketplaceID != null
      ? {
          marketplaceID,
          organisationID,
        }
      : undefined,
}) as React.ComponentType<EnhancedProps>;

export default OrganisationCategorySelect;

import * as React from "react";
import DataTable from "../../generic/DataTable";
import gql from "graphql-tag";
import { ShippingBatchTableShippingBatch } from "./__generated__/ShippingBatchTableShippingBatch";
import { Button, withStyles } from "@material-ui/core";
import createItemListFromArray from "../../../../helpers/createItemListFromArray";
import { StyleProps } from "../../types";
import getStatusString from "../../../../helpers/shippingBatch/getStatusString";
import { formatUTCDate } from "../../../../helpers/formatDate";
import classNames from "classnames";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { ShippingBatchStatus } from "../../../../__generated__/globalTypes";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import { OpenInBrowser } from "@material-ui/icons";

const canConfirmReceived = (status: ShippingBatchStatus) =>
  status === ShippingBatchStatus.DISPATCHED;
const isReceived = (status: ShippingBatchStatus) =>
  status === ShippingBatchStatus.RECEIVED;

export const shippingBatchTableFragments = {
  shippingBatch: gql`
    fragment ShippingBatchTableShippingBatch on ShippingBatch {
      id
      batchNumber
      orderItemPartList {
        id
        orderItem {
          id
          description
        }
      }
      supplier {
        id
        name
        purchaseOrderNumber
      }
      destination {
        id
        ...GetAddressStringAddress
      }
      preferredDeliveryDate
      status
    }
    ${addressFragment}
  `,
};

type EnhancedProps = {
  loading?: boolean;
  shippingBatches: ShippingBatchTableShippingBatch[] | null;
  updateShippingBatchStatus: (arg0: string, arg1: string) => Promise<void>;
};

type Props = EnhancedProps &
  StyleProps & {
    className?: string;
  };

const styles = {
  table: {
    alignSelf: `stretch`,
  },
};

/**
 * Component to display orders in a table.
 */
export class ShippingBatchTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      classes,
      loading,
      shippingBatches,
      updateShippingBatchStatus,
    } = this.props;

    const columns: Column<ShippingBatchTableShippingBatch>[] = [
      {
        Header: `Batch Number`,
        id: `batchNumber`,
      },
      {
        Header: `Products`,
        id: `orderItemPartList`,
        Cell: ({ value }) =>
          createItemListFromArray(value, `orderItem.description`),
      },
      {
        Header: `Supplier`,
        id: `supplier.name`,
      },
      {
        Header: `Destination`,
        id: `destination.id`,
        disableSortable: true,
        Cell: ({ row }) => getAddressString(row.destination),
      },
      {
        Header: `ETA`,
        id: `preferredDeliveryDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `PO#`,
        id: `supplier.purchaseOrderNumber`,
      },
      {
        Header: `Status`,
        id: `status`,
        Cell: ({ value }) => getStatusString(value),
      },
      {
        Header: "",
        id: `received`,
        disableSortable: true,
        Cell: ({ row }) => (
          <>
            {!isReceived(row.status) && (
              <Button
                color={"primary"}
                disabled={!canConfirmReceived(row.status)}
                onClick={() => {
                  updateShippingBatchStatus(row.id, "RECEIVED");
                }}
                variant={"outlined"}
                startIcon={<OpenInBrowser />}
              >
                Receive
              </Button>
            )}
          </>
        ),
      },
    ];

    return (
      <DataTable
        className={classNames(classes.table, className)}
        loading={loading}
        data={shippingBatches || []}
        columns={columns}
        emptyMessage="No shipping batches"
      />
    );
  }
}

export default withStyles(styles)(ShippingBatchTable);

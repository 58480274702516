export const ERROR_NO_RECORDS_FOUND = `Operation Unsuccessful: No matching records found`;

export const DEFAULT_POLL_INTERVAL = 3000;

// Default timings for transitions
export const DEFAULT_ENTER_TIMEOUT = 100;
export const DEFAULT_EXIT_TIMEOUT = 500;

export const DEFAULT_TRANSITION_TIMEOUT: {
  enter: number;
  exit: number;
} = {
  enter: DEFAULT_ENTER_TIMEOUT,
  exit: DEFAULT_EXIT_TIMEOUT,
};

// Material Grid
type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export const GRID_FULL_WIDTH: GridSize = 12;
export const GRID_HALF_WIDTH: GridSize = 6;

// Spacing
export const SPACING_PAPER = 2;
export const SPACING_MAIN = 3;
export const SPACING_LIST_ITEM_NESTING = 4;
export const SPACING_FAB = 4;

import * as React from "react";
import { CustomField, FieldList } from "../../../generic/form/index";
import CheckboxField from "../../../generic/form/CheckboxField";
import { Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export type Values = {
  buyer: boolean;
  seller: boolean;
};

const initialValues = {
  buyer: false,
  seller: false,
};

type Props = {
  BodyContainer: React.ElementType;
  SubmitContainer: React.ElementType;
  TitleComponent: React.ElementType;
  onActivate: (arg0: Values) => void;
  onDismiss: () => void;
};

export class ActivateForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      SubmitContainer,
      TitleComponent,
      onActivate,
      onDismiss,
    } = this.props;

    return (
      <React.Fragment>
        <TitleComponent>Select Role</TitleComponent>
        <Formik initialValues={initialValues} onSubmit={onActivate}>
          {({ isSubmitting, dirty }) => (
            <Form>
              <BodyContainer>
                <Typography>
                  In the selected classes, my organisation is a:
                </Typography>
                <FieldList>
                  <CustomField
                    name="buyer"
                    label="Buyer"
                    component={CheckboxField}
                  />
                  <CustomField
                    name="seller"
                    label="Seller"
                    component={CheckboxField}
                  />
                </FieldList>
              </BodyContainer>
              <SubmitContainer>
                <Button type="button" onClick={onDismiss} startIcon={<Close />}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !dirty}
                >
                  Select
                </Button>
              </SubmitContainer>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

export default ActivateForm;

import * as React from "react";
import { Theme, withStyles, withTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import OrganisationDashboardDetails from "./OrganisationDashboardDetails";
import { ThemeStyleProps } from "../../types";
import LoadingScreen from "../../generic/LoadingScreen";
import { OrganisationDashboardDetailsOrganisation } from "./__generated__/OrganisationDashboardDetailsOrganisation";
import { compose } from "recompose";
import { SPACING_PAPER } from "../../../../helpers/constants";

const styles = (theme: Theme) => ({
  paper: {
    height: `100%`,
    padding: theme.spacing(SPACING_PAPER),
  },

  root: {
    flex: 1,
  },
});

type EnhancedProps = {
  loading?: boolean;
  organisation?: OrganisationDashboardDetailsOrganisation;
};

type Props = ThemeStyleProps & EnhancedProps;

class OrganisationDashboardLayout extends React.PureComponent<Props> {
  render() {
    const { classes, loading, organisation } = this.props;

    if (loading || !organisation) {
      return <LoadingScreen />;
    }

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <OrganisationDashboardDetails organisation={organisation} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(withStyles(styles), withTheme);

export default enhancer(OrganisationDashboardLayout);

import React from "react";
import { Route } from "react-router-dom";
import { buyStepOrderRoute, ROUTE_PARAMETERS } from "../../index";
import ConfirmationData from "../../../components/trading/confirmation/ConfirmationData";

type Props = {
  organisationID: string;
};

export const ConfirmationRoute = ({ organisationID }: Props) => (
  <Route
    path={buyStepOrderRoute(
      ROUTE_PARAMETERS.ORGANISATION_ID,
      ROUTE_PARAMETERS.STEP,
      ROUTE_PARAMETERS.ORDER_ID
    )}
  >
    {({ match: formMatch }) => {
      // Need to get order id because once on this screen, the order is detached.
      const orderID =
        formMatch && formMatch.params.orderID != null
          ? formMatch.params.orderID
          : ``;

      return (
        <ConfirmationData organisationID={organisationID} orderID={orderID} />
      );
    }}
  </Route>
);

import * as React from "react";
import gql from "graphql-tag";
import Button from "@material-ui/core/Button";
import { get, uniq } from "lodash";
import DataTable from "../../generic/DataTable";
import { withFiltering } from "../../generic/DataTable/WithFiltering";
import { ProductSearchTableCatalogueViewProduct as Product } from "./__generated__/ProductSearchTableCatalogueViewProduct";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { calculateTax } from "../../../../helpers/currencyCalculation";
import IconButton from "@material-ui/core/IconButton";
import { MoreHoriz } from "@material-ui/icons";
import displayProductAvailability, {
  displayProductAvailabilityFragment,
} from "../../../../helpers/product/displayProductAvailability";

const DataTableWithFiltering = withFiltering()(DataTable);

export const ENTER_SEARCH_QUERY = "Enter search query or select supplier";
export const NO_RESULTS = "No results found";

export const productSearchTableFragments = {
  catalogueViewProduct: gql`
    fragment ProductSearchTableCatalogueViewProduct on CatalogueViewProduct {
      Product {
        id
        description
        class {
          id
          name
          category {
            id
            name
          }
        }
        marketplace {
          id
          name
        }
        organisation {
          id
          name
        }
        quantityAvailable
        available
        ...DisplayProductAvailabilityProduct
      }
      price
      taxPercentage
    }
    ${displayProductAvailabilityFragment}
  `,
};

type Props = {
  className?: string;
  loading?: boolean;
  onPressAddToCart: (productID: string) => void;
  onPressMoreDetails: (productID: string) => void;
  products: Product[] | null | undefined;
};

const marketplaceAccessor = `Product.marketplace.name`;
const categoryAccessor = `Product.class.category.name`;
const classAccessor = `Product.class.name`;
const supplierAccessor = `Product.organisation.name`;

class ProductSearchTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      onPressAddToCart,
      onPressMoreDetails,
      products,
    } = this.props;

    const productList = products || [];

    const columns = [
      {
        Header: `Name`,
        id: `Product.description`,
      },
      {
        Header: `Marketplace`,
        id: marketplaceAccessor,
      },
      {
        Header: `Category`,
        id: categoryAccessor,
      },
      {
        Header: `Class`,
        id: classAccessor,
      },
      {
        Header: `Supplier`,
        id: supplierAccessor,
      },
      {
        Cell: ({ row }) => displayProductAvailability(row.Product),
        Header: `Availability`,
        id: "Product.available",
        disableSortable: true,
      },
      {
        Header: `Qty`,
        numeric: true,
        id: `Product.quantityAvailable`,
      },
      {
        Cell: ({ value }) => displayCurrency(value),
        Header: `Price`,
        id: `price`,
        numeric: true,
      },
      {
        Cell: ({ row }) =>
          row.taxPercentage != null && row.price != null
            ? displayCurrency(calculateTax(row.price, row.taxPercentage))
            : `N/A`,
        Header: `Tax`,
        id: `taxPercentage`,
        disableSortable: true,
        numeric: true,
      },
      {
        Cell: ({ id }) => (
          <IconButton onClick={() => onPressMoreDetails(id)}>
            <MoreHoriz titleAccess={"More Details"} />
          </IconButton>
        ),
        Header: "Details",
        id: `moreDetails`,
        disableSortable: true,
      },
      {
        Cell: ({ id, row }) => (
          <Button
            color={"primary"}
            disabled={!row.Product.available}
            onClick={() => onPressAddToCart(id)}
            variant={"outlined"}
          >
            Add to cart
          </Button>
        ),
        Header: null,
        id: `addToCart`,
        disableSortable: true,
      },
    ];

    return (
      <DataTableWithFiltering
        // @ts-ignore
        className={className}
        columns={columns}
        data={productList}
        emptyMessage={products == null ? ENTER_SEARCH_QUERY : NO_RESULTS}
        idAccessor="Product.id"
        loading={loading}
        filterOptions={{
          [marketplaceAccessor]: {
            label: `Marketplace`,
            values: uniq(
              productList.map((row) => get(row, marketplaceAccessor, `None`))
            ),
          },
          [categoryAccessor]: {
            label: `Category`,
            values: uniq(
              productList.map((row) => get(row, categoryAccessor, `None`))
            ),
          },
          [classAccessor]: {
            label: `Class`,
            values: uniq(
              productList.map((row) => get(row, classAccessor, `None`))
            ),
          },
        }}
      />
    );
  }
}

export default ProductSearchTable;

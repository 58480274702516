import * as React from "react";
import SelectField from "./form/SelectField";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import CallOnMount from "./CallOnMount";
import withOpenSnackbar, { OpenSnackbarFn } from "./snackbar/withOpenSnackbar";
import { flatten, get } from "lodash";
import { SnackbarType } from "../../../__generated__/globalTypes";

// Options for creating the HOC
export type Options = {
  innerDataPath: string;
  itemPluralLabel: string;
  itemTitleLabel: string;
  query: any;
  rootDataPath: string;
  variables?: (props: any) => any | void; // Query skipped if undefined is returned
};

export type EnhancedProps = {
  className?: string;
  onSelectedIDChange: (id: string, rootID?: string) => void;
  selectedID?: string;
};

/**
 * HOC attaching data from a query to a select field and handling errors.
 * @param {any} options Options for configuring list query.
 * @return Function for creating enhanced component.
 */
export const withSelectList = (options: Options) =>
  compose<any, any>((WrappedComponent) => {
    type Props = EnhancedProps & {
      error?: Error;
      loading: boolean;
      openSnackbar: OpenSnackbarFn;
      rootItems: Array<any> | null | undefined;
    };

    class ListQuerySelectField extends React.PureComponent<Props> {
      render() {
        const {
          className,
          error,
          loading,
          selectedID,
          openSnackbar,
          onSelectedIDChange,
          rootItems,
        } = this.props;

        // Map from inner item IDs to their parent root item ID
        const rootItemIDs = {};
        // Get options for each root item
        const optionLists = rootItems
          ? rootItems.map((root) => [
              {
                label: root.name,
                subheader: true,
                value: root.id,
              },
              ...get(root, options.innerDataPath).map((innerItem) => {
                rootItemIDs[innerItem.id] = root.id;

                return {
                  key: `${root.id}:${innerItem.id}`,
                  label: innerItem.name,
                  value: innerItem.id,
                };
              }),
            ])
          : [];
        // Flatten into a single list of options
        const selectOptions = flatten(optionLists);

        return (
          <React.Fragment>
            <WrappedComponent
              className={className}
              loading={loading}
              value={selectedID}
              label={options.itemTitleLabel}
              placeholder={`Select ${options.itemTitleLabel}`}
              options={selectOptions}
              onChange={(event) =>
                onSelectedIDChange(
                  event.target.value,
                  rootItemIDs[event.target.value]
                )
              }
            />
            {error && (
              <CallOnMount
                callback={() =>
                  openSnackbar(
                    SnackbarType.error,
                    `Failed to load ${options.itemPluralLabel}`
                  )
                }
              />
            )}
          </React.Fragment>
        );
      }
    }

    const enhancer = compose<Partial<Props>, any>(
      graphql(options.query, {
        options: (props) => ({
          variables: (options.variables && options.variables(props)) || {},
        }),
        props: ({ data }) => ({
          error: data.error,
          loading: data.loading,
          rootItems:
            !data.loading &&
            !data.error &&
            get(data, options.rootDataPath, null),
        }),
        skip: (props) =>
          options.variables ? options.variables(props) == null : false,
      }),
      withOpenSnackbar
    );

    return enhancer(ListQuerySelectField);
  });

/**
 * Function creating a component wrapping SelectField.
 * @param {any} options Options for configuring list query.
 * @return Function for creating enhanced component.
 */
export default (options: Options) => withSelectList(options)(SelectField);

import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DataTable from ".";
import { EnhancedProps as DataTableProps } from "./BaseDataTable";

export type MenuAction = {
  perform: (itemID: string) => void;
  text: string;
};

export type Props<Row> = DataTableProps<Row> & {
  menuActions: MenuAction[];
};

type State = {
  menuAnchor?: HTMLElement | null | undefined;
  menuItemID?: string | null | undefined;
};

/**
 * HOC to add a menu column to DataTable
 * @param {React.ComponentType} DataTableComponent DataTable or compatible component.
 * @returns {React.ComponentType} New list component.
 */
export const withMenu = <Row,>(
  DataTableComponent: React.ComponentType<DataTableProps<Row>>
) => {
  class DataTableWithMenu extends React.PureComponent<Props<Row>, State> {
    constructor(props: Props<Row>) {
      super(props);

      this.state = {
        menuAnchor: undefined,
        menuItemID: undefined,
      };
    }

    handleMenuButtonPressed(buttonElement: HTMLElement, itemID: string) {
      this.setState({
        menuAnchor: buttonElement,
        menuItemID: itemID,
      });
    }

    handleMenuClosed() {
      this.setState({
        menuAnchor: undefined,
      });
    }

    handleActionPressed(action: MenuAction) {
      const { menuItemID } = this.state;

      if (menuItemID) {
        action.perform(menuItemID);
      }
      this.setState({ menuAnchor: undefined });
    }

    getColumns() {
      const { columns } = this.props;

      return [
        ...columns,
        {
          id: `withMenu`,
          Header: ``,
          cellProps: {
            padding: `checkbox`,
          },
          Cell: ({ id }) => (
            <IconButton
              onClick={(event) =>
                this.handleMenuButtonPressed(event.currentTarget, id)
              }
            >
              <MenuIcon />
            </IconButton>
          ),
          compact: true,
        },
      ];
    }

    render() {
      const { menuActions, ...dataTableProps } = this.props;
      const { menuAnchor } = this.state;

      return (
        <React.Fragment>
          <DataTableComponent {...dataTableProps} columns={this.getColumns()} />
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={this.handleMenuClosed.bind(this)}
          >
            {menuActions.map((action, index) => (
              <MenuItem
                key={index}
                onClick={() => this.handleActionPressed(action)}
              >
                {action.text}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    }
  }

  return DataTableWithMenu;
};

export default (withMenu as any)(DataTable);

import moment from "moment-timezone";

moment.tz.setDefault(`Australia/Sydney`);

/**
 * Handle datetime strings represented by an ISO8601 format. Present in localtime
 */

/**
 * Convert ISO8601 date string to localtime date
 * @param {string} dateTimeString the date in ISO8601 format
 * @return {string} The date in localtime, or null if date is invalid.
 */
export const formatUTCDate = (
  dateTimeString?: string | null | undefined
): string | null | undefined => {
  if (dateTimeString == null) {
    return null;
  }

  if (typeof dateTimeString !== typeof `String`) {
    return null;
  }

  if (!/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\d[Zz]$/.test(dateTimeString)) {
    return dateTimeString;
  }

  const tz = moment.tz.guess();
  const dateTime = moment.utc(dateTimeString);

  return moment.tz(dateTime, tz).format(`Do MMM YYYY`);
};

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Breadcrumbs as MaterialBreadcrumb,
  Link,
  Typography,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { IBreadcrumb, useBreadcrumbs } from "./useBreadcrumbs";

interface Props {
  className?: string;
}

export const Breadcrumbs = ({ className }: Props) => {
  const crumbs: IBreadcrumb[] = useBreadcrumbs();
  const lastCrumb: IBreadcrumb | typeof undefined = crumbs.pop();

  return (
    <MaterialBreadcrumb
      className={className}
      separator={<NavigateNext fontSize={`small`} />}
    >
      {crumbs.map((crumb: IBreadcrumb) => (
        <Link
          key={crumb.path}
          component={RouterLink}
          to={crumb.path}
          color="inherit"
        >
          {crumb.render()}
        </Link>
      ))}

      {lastCrumb != null && (
        <Typography color={`textPrimary`}>{lastCrumb.render()}</Typography>
      )}
    </MaterialBreadcrumb>
  );
};

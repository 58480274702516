import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "./ConfirmDialog";

const styles = () => ({
  dialog: {},
});

type ChildProps = {
  BodyContainer: React.ElementType;
  SubmitContainer: React.ElementType;
  TitleComponent: React.ElementType;
  handleClose: (force?: boolean, result?: any) => void;
  onDirtyChange: (dirty: boolean) => void;
};

type Props = StyleProps & {
  childProps?: any;
  children: (props: ChildProps) => React.ReactNode;
  onClose: (result?: any) => void;
  open: boolean;
};

type State = {
  childProps?: any;
  confirmingClose: boolean;
  dirty: boolean;
};

class FormDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      childProps: undefined,
      confirmingClose: false,
      dirty: false,
    };
  }

  componentDidMount() {
    const { childProps } = this.props;
    if (childProps) {
      this.setState({ childProps });
    }
  }

  componentDidUpdate() {
    const { childProps, open } = this.props;
    if (open && childProps !== this.state.childProps) {
      this.setState({ childProps });
    }
  }

  handleDirtyChange(dirty: boolean) {
    this.setState({ dirty });
  }

  handleRequestClose(force?: boolean, result?: any) {
    const { onClose } = this.props;
    const { dirty } = this.state;

    if (!dirty || force === true) {
      onClose(result);
    } else {
      this.setState({ confirmingClose: true });
    }
  }

  handleCancelClose() {
    this.setState({ confirmingClose: false });
  }

  handleAcceptClose() {
    const { onClose } = this.props;
    onClose();
    this.setState({ confirmingClose: false });
  }

  render() {
    const { classes, children, open } = this.props;
    const { childProps, confirmingClose } = this.state;
    const onRequestClose = this.handleRequestClose.bind(this);

    return (
      <React.Fragment>
        <Dialog
          maxWidth={"md"}
          classes={{ paper: classes.dialog }}
          open={open}
          onClose={() => onRequestClose()}
          scroll={"paper"}
        >
          {children({
            BodyContainer: DialogContent,
            SubmitContainer: DialogActions,
            TitleComponent: DialogTitle,
            handleClose: onRequestClose,
            onDirtyChange: this.handleDirtyChange.bind(this),
            ...childProps,
          })}
        </Dialog>
        <ConfirmDialog
          open={confirmingClose}
          title="Are you sure you want to close?"
          text="Your changes will be lost."
          onClose={this.handleCancelClose.bind(this)}
          onAccept={this.handleAcceptClose.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FormDialog);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SPACING_MAIN } from "../../../helpers/constants";

const styles = (theme) => ({
  root: {
    alignItems: `center`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    justifyContent: `center`,
    padding: theme.spacing(SPACING_MAIN),
  },
});

type Props = StyleProps & {
  children?: React.ReactNode;
};

const LoadingScreen = ({ classes, children }: Props) => (
  <div className={classes.root}>
    <CircularProgress />
    {children}
  </div>
);

export default withStyles(styles)(LoadingScreen);

import * as React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, DialogContentText } from "@material-ui/core";
import FormDialog from "./FormDialog";
import { CustomField, FieldList } from "./form";
import TextInput from "./form/TextInput";
import CallOnChange from "./CallOnChange";
import { Close, Email } from "@material-ui/icons";

export type EmailAddress = {
  email: string;
};

const schema = yup.object({
  email: yup.string().required().email().label(`Email`),
});

const generateInitialValues = (): EmailAddress => ({
  email: "",
});

type Props = {
  open: boolean;
  onClose: (result?: EmailAddress) => Promise<void> | void;
  text?: string;
  title: string;
};

export const InviteUserByEmailDialog = ({
  open,
  onClose,
  text,
  title,
}: Props): React.ReactElement => {
  return (
    <FormDialog open={open} onClose={onClose}>
      {({
        TitleComponent,
        BodyContainer,
        SubmitContainer,
        handleClose,
        onDirtyChange,
      }) => (
        <>
          <Formik
            onSubmit={(result) => handleClose(true, result)}
            initialValues={generateInitialValues()}
            validationSchema={schema}
          >
            {(formikBag) => (
              <Form>
                <TitleComponent>{title}</TitleComponent>

                <BodyContainer>
                  {text && <DialogContentText>{text}</DialogContentText>}
                  <FieldList>
                    <CustomField
                      fullWidth
                      name="email"
                      label="Email"
                      component={TextInput}
                      autoFocus={true}
                    />
                  </FieldList>

                  <CallOnChange
                    value={formikBag.dirty}
                    onChange={onDirtyChange}
                  />
                </BodyContainer>

                <SubmitContainer>
                  <Button
                    onClick={() => handleClose(false)}
                    startIcon={<Close />}
                  >
                    cancel
                  </Button>
                  <Button
                    color={"primary"}
                    disabled={formikBag.isSubmitting || !formikBag.dirty}
                    startIcon={<Email />}
                    type="submit"
                    variant={"contained"}
                  >
                    Invite
                  </Button>
                </SubmitContainer>
              </Form>
            )}
          </Formik>
        </>
      )}
    </FormDialog>
  );
};

import * as React from "react";
import AutosaveForm, {
  EnhancedProps as AutosaveFormProps,
} from "./form/AutosaveForm";
import { get, mapValues } from "lodash";
import * as yup from "yup";

export type ChildProps = {
  getAutosaveListFieldProps: (itemID: string, name: string) => any;
};

export type EnhancedProps = Partial<AutosaveFormProps<any>> & {
  className?: string;
  items: any[];
  updateItem(itemID: string, fieldName: string, value: any): Promise<void>;
  children: (props: ChildProps) => React.ReactElement;
};

export type Options = {
  itemIDAccessor: string;
  getValuesForItem: (item: any) => any;
  itemSchema: any;
};

const createAutosaveListForm = (options: Options) => {
  // Get string to identify field for a specific item
  const getField = (itemID: string, fieldName: string): string =>
    `${itemID}.${fieldName}`;
  // Get the field name and item ID from a field string
  const getFieldInfo = (
    field: string
  ): { name: string; itemID: string } | null | undefined => {
    const dotIndex = field.indexOf(`.`);
    if (dotIndex > 0) {
      return {
        name: field.substring(dotIndex + 1),
        itemID: field.substring(0, dotIndex),
      };
    } else {
      return undefined;
    }
  };
  // Get the form values from data
  const getFormValues = (itemList: any[]) => {
    const values = {};
    itemList.forEach((item) => {
      const itemID = get(item, options.itemIDAccessor);
      if (itemID) {
        values[itemID] = options.getValuesForItem(item);
      }
    });

    return values;
  };

  const schema = yup.lazy((obj: any) =>
    yup.object().shape(mapValues(obj, () => options.itemSchema))
  );

  /**
   * Component to manage field state and autosaving for the items in a shopping cart.
   */
  class AutosaveListForm extends React.PureComponent<EnhancedProps> {
    updateField(field: string, value: number) {
      const { updateItem } = this.props;

      const info = getFieldInfo(field);
      if (info) {
        if (value != null) {
          return updateItem(info.itemID, info.name, value);
        }
      }

      return Promise.reject(new Error(`Failed to parse field value`));
    }

    render() {
      const { className, children, items, ...otherProps } = this.props;
      const values = getFormValues(items);

      return (
        // @ts-ignore
        <AutosaveForm
          className={className}
          item={values}
          schema={schema}
          updateField={this.updateField.bind(this)}
          hasChanged={(fieldName, fieldValue) =>
            get(values, fieldName) !== fieldValue
          }
          {...otherProps}
        >
          {({ getAutosaveFieldProps }) =>
            children({
              getAutosaveListFieldProps: (itemID: string, name: string) => {
                const field = getField(itemID, name);

                return {
                  ...getAutosaveFieldProps(field),
                  name: field,
                };
              },
            })
          }
        </AutosaveForm>
      );
    }
  }

  return AutosaveListForm;
};

export default createAutosaveListForm;

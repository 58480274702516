import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { CustomFieldProps } from "./CustomField";
import { omit } from "lodash";
import { StyleProps } from "../../types";
import classnames from "classnames";

const styles = (theme) => ({
  input: {},

  inputNotFullWidth: {
    width: theme.dimensions.textFieldWidth,
  },
});

type Props = CustomFieldProps<string> &
  StyleProps & {
    fullWidth?: boolean;
  };

const TextInput = ({ classes, error, field, fullWidth, ...rest }: Props) => (
  <TextField
    className={classnames(
      classes.input,
      !fullWidth && classes.inputNotFullWidth
    )}
    error={error != null}
    helperText={error || ` `}
    fullWidth={fullWidth}
    {...field}
    value={field.value || ``}
    {...omit(rest, `touched`)}
  />
);

export default withStyles(styles)(TextInput);

import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { omit } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose, defaultProps } from "recompose";
import userHasRoles from "../../../helpers/user/hasRoles";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import CallOnMount from "../generic/CallOnMount";
import { dashboardRoute, loginRoute } from "../../routes";
import { SnackbarType } from "../../../__generated__/globalTypes";

export const GET_AUTH = gql`
  query PrivateRouteGetAuth {
    auth: getAuth @client {
      isLoggedIn
      roles
    }
  }
`;

type EnhancedProps = RouteProps & {
  errorMessage?: string | null;
  requiredRoles?: string[];
  unauthorizedPath?: string;
};

type Props = EnhancedProps & {
  errorMessage: string | null;
  isLoggedIn: boolean;
  loading: boolean;
  openSnackbar: OpenSnackbarFn;
  roles: string[];
  unauthorizedPath: string;
};

/**
 * Wraps the react router Route component to provide access control based on user's roles.
 * If the user is not logged in, they are redirected to '/login'.
 * If the user is missing a required role, they are redirected to unauthorizedPath, which defaults to '/'.
 * An error is shown upon redirection due to missing roles if errorMessage is set, which it is by default.
 * @param {Props} props props
 * @return {Node} node
 */
const PrivateRoute = ({
  errorMessage,
  isLoggedIn,
  loading,
  openSnackbar,
  path,
  requiredRoles,
  roles,
  unauthorizedPath,
  ...rest
}: Props) => {
  const hasAccess = isLoggedIn && userHasRoles(roles, requiredRoles);

  if (hasAccess) {
    // Behave exactly like a normal Route
    return <Route path={path} {...rest} />;
  } else if (!loading) {
    const redirectPath = isLoggedIn ? unauthorizedPath : loginRoute();
    const snackbarMessage = isLoggedIn ? errorMessage : null;

    // Route that renders Redirect
    return (
      <Route
        path={path}
        {...omit(rest, `component`, `children`)}
        render={({ location }) => (
          <React.Fragment>
            {snackbarMessage !== null && (
              <CallOnMount
                callback={() =>
                  openSnackbar(SnackbarType.error, snackbarMessage)
                }
              />
            )}
            <Redirect
              to={{
                pathname: redirectPath,
                state: { referrer: location.pathname },
              }}
            />
          </React.Fragment>
        )}
      />
    );
  }

  return null;
};

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql(GET_AUTH, {
    // @ts-ignore
    props: ({ data: { auth, loading } }) => ({
      isLoggedIn: auth ? auth.isLoggedIn : false,
      loading,
      roles: (auth && auth.roles) || [],
    }),
  }),
  defaultProps({
    errorMessage: `Access denied`,
    unauthorizedPath: dashboardRoute(),
  }),
  withOpenSnackbar
);

export default enhancer(PrivateRoute);

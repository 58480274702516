import * as React from "react";
import gql from "graphql-tag";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import IconButton from "@material-ui/core/IconButton";
import CustomField from "../../generic/form/CustomField";
import TextInputWithState from "../../generic/form/TextInputWithState";
import { withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import { AddDestinationTableOrderItemPart } from "./__generated__/AddDestinationTableOrderItemPart";

export const addDestinationTableFragments = {
  orderItemPart: gql`
    fragment AddDestinationTableOrderItemPart on OrderItemPart {
      id
      destination {
        id
        businessName
        ...GetAddressStringAddress
      }
      quantityAllocated
    }
    ${addressFragment}
  `,
};

const styles = () => ({
  input: {
    minWidth: `2em`,
    width: `100%`,
  },
});

type Props = {
  className?: string;
  loading: boolean;
  classes: any;
  orderItemPartList: AddDestinationTableOrderItemPart[];
  getFieldProps: any;
  removeDestinationFromOrderItem: (arg0: {
    addressID: string;
  }) => void | Promise<void>;
};

class AddDestinationTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      classes,
      orderItemPartList,
      removeDestinationFromOrderItem,
      getFieldProps,
    } = this.props;

    const columnsWithActions: Column<AddDestinationTableOrderItemPart>[] = [
      {
        Header: `Business Name`,
        id: `destination.businessName`,
      },
      {
        Header: `Delivery Address`,
        Cell: ({ row }) => getAddressString(row.destination),
        id: `destination.id`,
      },
      {
        Cell: ({ row }) => (
          <CustomField
            fullWidth
            component={TextInputWithState}
            type="number"
            InputProps={{
              inputProps: {
                className: classes.input,
              },
            }}
            {...getFieldProps(row.id, `quantity`)}
          />
        ),
        Header: `Qty`,
        id: `quantityAllocated`,
      },
      {
        Header: ``,
        Cell: ({ row }) => (
          <IconButton
            title={"delete"}
            onClick={() =>
              removeDestinationFromOrderItem({ addressID: row.destination.id })
            }
          >
            <DeleteIcon />
          </IconButton>
        ),
        cellProps: {
          padding: "checkbox",
        },
        disableSortable: true,
        id: `actions`,
      },
    ];

    return (
      <DataTable
        className={className}
        loading={loading}
        columns={columnsWithActions}
        data={orderItemPartList}
        idAccessor="id"
        emptyMessage="No delivery addresses added"
      />
    );
  }
}

export default withStyles(styles)(AddDestinationTable);

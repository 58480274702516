import * as React from "react";
import Layout from "./ui/Layout";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { CustomThemeProvider } from "./ui/theme";
import apolloClient from "./apollo/client";

class App extends React.Component {
  render() {
    return (
      <CustomThemeProvider>
        <BrowserRouter>
          <ApolloProvider client={apolloClient}>
            <Layout />
          </ApolloProvider>
        </BrowserRouter>
      </CustomThemeProvider>
    );
  }
}

export default App;

import * as React from "react";
import AutosaveForm from "../generic/form/AutosaveForm";
import * as yup from "yup";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import { CustomField } from "../generic/form/index";
import FieldList from "../generic/form/FieldList";
import TextInputWithState from "../generic/form/TextInputWithState";
import telephoneNumberSchema from "../../../helpers/schema/telephoneNumber";
import { ProfileFormUser } from "./__generated__/ProfileFormUser";
import { ProfileUpdateSelfVariables } from "./__generated__/ProfileUpdateSelf";

const UPDATE_SELF = gql`
  mutation ProfileUpdateSelf($fullName: String, $telephoneNumber: String) {
    updateSelf(
      values: { fullName: $fullName, telephoneNumber: $telephoneNumber }
    ) {
      id
      fullName
      telephoneNumber
    }
  }
`;

export const profileFormFragments = {
  user: gql`
    fragment ProfileFormUser on User {
      id
      fullName
      telephoneNumber
    }
  `,
};

type EnhancedProps = {
  user: ProfileFormUser;
};

type Props = EnhancedProps & {
  updateSelf: (arg0: ProfileUpdateSelfVariables) => Promise<any>;
};

const schema = yup.object({
  fullName: yup.string().required().nullable().label(`Name`),
  telephoneNumber: telephoneNumberSchema
    .required()
    .nullable()
    .label(`Telephone number`),
});

export class ProfileForm extends React.PureComponent<Props> {
  render() {
    const { user, updateSelf } = this.props;

    return (
      <AutosaveForm
        item={user}
        itemName="profile"
        schema={schema}
        updateField={(fieldName, fieldValue) =>
          updateSelf({
            [fieldName]: fieldValue,
            // @ts-ignore
            id: user.id,
          })
        }
        hasChanged={(fieldName, fieldValue) => user[fieldName] !== fieldValue}
      >
        {({ getAutosaveFieldProps }) => (
          <FieldList>
            <CustomField
              name="fullName"
              label="Full Name"
              component={TextInputWithState}
              {...getAutosaveFieldProps(`fullName`)}
            />
            <CustomField
              name="telephoneNumber"
              label="Telephone Number"
              component={TextInputWithState}
              {...getAutosaveFieldProps(`telephoneNumber`)}
            />
          </FieldList>
        )}
      </AutosaveForm>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql(UPDATE_SELF, {
    props: ({ mutate }) => ({
      updateSelf: (user: ProfileUpdateSelfVariables) =>
        mutate({ variables: user }),
    }),
  })
);

export default enhancer(ProfileForm);

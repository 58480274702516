import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import ClassCuration from "./ClassCuration";
import MarketplaceSelect from "../MarketplaceSelect";
import CategorySelect from "../CategorySelect";
import ClassList from "./ClassList";
import FloatingActionButton from "../../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import UploadForm from "./UploadForm";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    alignItems: `stretch`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
  select: {
    flex: 1,
    margin: theme.spacing(1),
  },
  selectContainer: {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `row` as const,
  },
});

type Props = StyleProps & {
  categoryID?: string;
  marketplaceID?: string;
  onCategoryIDChange: (marketplaceID: string) => void;
  onMarketplaceIDChange: (marketplaceID: string) => void;
};

class ClassLayout extends React.PureComponent<Props> {
  render() {
    const {
      categoryID,
      classes,
      marketplaceID,
      onCategoryIDChange,
      onMarketplaceIDChange,
    } = this.props;

    return (
      <>
        <ClassCuration categoryID={categoryID}>
          {({
            classList,
            classListLoading,
            editingClassID,
            onClassChanged,
            onClassCreated,
            onPressAdd,
            onPressDelete,
            setEditingClassID,
          }) => (
            <div className={classes.root}>
              <div className={classes.selectContainer}>
                <MarketplaceSelect
                  className={classes.select}
                  selectedID={marketplaceID}
                  onSelectedIDChange={onMarketplaceIDChange}
                />
                <CategorySelect
                  className={classes.select}
                  marketplaceID={marketplaceID}
                  selectedID={categoryID}
                  onSelectedIDChange={onCategoryIDChange}
                />
              </div>
              <ClassList
                classList={classList}
                loading={classListLoading}
                editingClassID={editingClassID}
                setEditingClassID={setEditingClassID}
                onClassChanged={onClassChanged}
                onClassCreated={onClassCreated}
                onPressDelete={onPressDelete}
              />
              <FloatingActionButton
                disabled={categoryID == null}
                onClick={onPressAdd}
              >
                <AddIcon />
              </FloatingActionButton>
            </div>
          )}
        </ClassCuration>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="caption">Upload Categories</Typography>
            <UploadForm categoryID={categoryID} />
          </Paper>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ClassLayout);

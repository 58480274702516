import * as React from "react";
import MainPanel from "../../../components/app/MainPanel";
import { match } from "react-router-dom";
import WarehouseInventoryData from "../../../components/warehouse/inventory/WarehouseInventoryData";
import WarehouseInventoryLayout from "../../../components/warehouse/inventory/WarehouseInventoryLayout";

type MatchProps = {
  organisationID?: string;
  warehouseID?: string;
};

type Props = {
  match: match<MatchProps>;
};

export default class Inventory extends React.Component<Props> {
  render() {
    const { match } = this.props;
    const { organisationID, warehouseID } = match.params;

    return (
      <MainPanel fullWidth>
        {organisationID && warehouseID && (
          <WarehouseInventoryData
            organisationID={organisationID}
            warehouseID={warehouseID}
          >
            {({ loading, warehouse, updateWarehouseProductInventory }) => (
              <WarehouseInventoryLayout
                loading={loading}
                warehouse={warehouse}
                updateWarehouseProductInventory={
                  updateWarehouseProductInventory
                }
              />
            )}
          </WarehouseInventoryData>
        )}
      </MainPanel>
    );
  }
}

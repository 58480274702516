import * as React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/auth/PrivateRoute";
import NotFound from "./routes/NotFound";
import Welcome from "./routes/Welcome";
import Profile from "./routes/Profile";
import Login from "./routes/authentication/Login";
import Callback from "./routes/authentication/Callback";
import EmailUnverified from "./routes/authentication/EmailUnverified";
import Unauthenticated from "./routes/authentication/Unauthenticated";
import LoggedOut from "./routes/authentication/LoggedOut";
import UserManagement from "./routes/admin/UserManagement";
import Industry from "./routes/admin/Industry";
import Marketplace from "./routes/admin/Marketplace";
import Categories from "./routes/admin/Categories";
import Classes from "./routes/admin/Classes";
import ProductCatalogueViewEdit from "./routes/products/CatalogueViewEdit";
import ProductCatalogueViewList from "./routes/products/CatalogueViewList";
import ProductOverview from "./routes/products/Overview";
import ServiceArea from "./routes/admin/ServiceArea";
import ServiceAreaCreateEdit from "./routes/admin/ServiceAreaCreateEdit";
import OrganisationDashboard from "./routes/organisation/Dashboard";
import OrganisationDetails from "./routes/organisation/Details";
import OrganisationAffiliations from "./routes/organisation/Affiliations";
import OrganisationSales from "./routes/trading/Sales";
import WarehouseInventory from "./routes/logistics/warehouses/Inventory";
import WarehouseOperatingTimes from "./routes/logistics/warehouses/OperatingTimes";
import WarehouseOverview from "./routes/logistics/warehouses/Overview";
import WarehouseDetails from "./routes/logistics/warehouses/Details";
import WarehouseServiceAreas from "./routes/logistics/warehouses/ServiceAreas";
import Address from "./routes/logistics/Address";
import Order from "./routes/trading/Order";
import RateCard from "./routes/logistics/RateCard";
import Root from "./components/app/Root";
import TopBar from "./components/app/TopBar";
import MenuData from "./components/app/MenuData";
import DrawerMenu from "./components/app/DrawerMenu";
import SnackbarContainer from "./components/generic/snackbar/SnackbarContainer";
import Purchases from "./routes/trading/Purchases";
import {
  addressesRoute,
  adminCategoriesRoute,
  adminClassesRoute,
  adminIndustriesRoute,
  adminMarketplacesRoute,
  adminServiceAreasFormRoute,
  adminServiceAreasRoute,
  adminUsersRoute,
  myNetworkTypeRoute,
  customersRoute,
  callbackRoute,
  cataloguesRoute,
  catalogueViewEditRoute,
  catalogueViewsRoute,
  dashboardRoute,
  freightRatesRoute,
  helpGuidesRoute,
  helpVideosRoute,
  inboxRoute,
  loggedOutRoute,
  loginRoute,
  buyStepRoute,
  organisationDashboardRoute,
  organisationDetailsRoute,
  organisationRoute,
  profileRoute,
  purchasesRoute,
  ROUTE_PARAMETERS,
  salesRoute,
  vendorsRoute,
  unauthenticatedRoute,
  unverifiedRoute,
  warehousesHoursRoute,
  warehousesInventoryRoute,
  warehousesRoute,
  warehousesServiceAreasRoute,
  warehousesViewRoute,
  productsRoute,
  tradingRoute,
  logisticsRoute,
} from "./routes";
import Unimplemented from "./components/app/Unimplemented";
import Organisation from "./routes/organisation/Organisation";
import Products from "./routes/products/Products";
import Trading from "./routes/trading/Trading";
import Logistics from "./routes/logistics/Logistics";

type State = {
  drawerOpen: boolean;
};

class Layout extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      drawerOpen: false,
    };
  }

  setDrawerMenuOpen(open: boolean) {
    this.setState({
      drawerOpen: open,
    });
  }

  render() {
    return (
      <Root>
        <Switch>
          {/* Authentication */}

          <Route path={loginRoute()} component={Login} />
          <Route path={callbackRoute()} component={Callback} />
          <Route
            path={unverifiedRoute(ROUTE_PARAMETERS.EMAIL)}
            component={EmailUnverified}
          />
          <Route path={unauthenticatedRoute()} component={Unauthenticated} />
          <Route path={loggedOutRoute()} component={LoggedOut} />
          <Route>
            <React.Fragment>
              <MenuData>
                {({ organisationID, organisationRole }) => (
                  <React.Fragment>
                    <TopBar onPressMenu={() => this.setDrawerMenuOpen(true)} />
                    <DrawerMenu
                      open={this.state.drawerOpen}
                      organisationID={organisationID}
                      organisationRole={organisationRole}
                      onClose={() => this.setDrawerMenuOpen(false)}
                    />
                  </React.Fragment>
                )}
              </MenuData>
              <SnackbarContainer />

              <Switch>
                <PrivateRoute
                  exact
                  path={dashboardRoute()}
                  component={Welcome}
                  requiredRoles={[`user`]}
                  unauthorizedPath={profileRoute()}
                  errorMessage={null}
                />
                <PrivateRoute path={profileRoute()} component={Profile} />
                {/* Admin */}
                <PrivateRoute
                  path={adminUsersRoute()}
                  component={UserManagement}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminIndustriesRoute()}
                  component={Industry}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminMarketplacesRoute()}
                  component={Marketplace}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminCategoriesRoute()}
                  component={Categories}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminClassesRoute()}
                  component={Classes}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminServiceAreasFormRoute(
                    `${ROUTE_PARAMETERS.SERVICE_AREA_ID}?`
                  )}
                  component={ServiceAreaCreateEdit}
                  requiredRoles={[`admin`]}
                />
                <PrivateRoute
                  path={adminServiceAreasRoute()}
                  component={ServiceArea}
                  requiredRoles={[`admin`]}
                />
                {/* Organisation */}
                <PrivateRoute
                  path={organisationDashboardRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID
                  )}
                  component={OrganisationDashboard}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={myNetworkTypeRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    `${ROUTE_PARAMETERS.AFFILIATION_TYPE}?`
                  )}
                  component={OrganisationAffiliations}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={organisationDetailsRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID
                  )}
                  component={OrganisationDetails}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={customersRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Unimplemented}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={vendorsRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Unimplemented}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={inboxRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Unimplemented}
                  requiredRoles={[`user`]}
                />
                {/* Products */}
                <PrivateRoute
                  path={cataloguesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={ProductOverview}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={catalogueViewEditRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.CATALOGUE_VIEW_ID
                  )}
                  component={ProductCatalogueViewEdit}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={catalogueViewsRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={ProductCatalogueViewList}
                  requiredRoles={[`user`]}
                />
                {/* Trading */}
                <PrivateRoute
                  path={buyStepRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.STEP
                  )}
                  component={Order}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={purchasesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Purchases}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={salesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={OrganisationSales}
                  requiredRoles={[`user`]}
                />
                {/* Logistics */}
                <PrivateRoute
                  path={addressesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Address}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={warehousesViewRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.WAREHOUSE_ID
                  )}
                  component={WarehouseDetails}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={warehousesInventoryRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.WAREHOUSE_ID
                  )}
                  component={WarehouseInventory}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={warehousesHoursRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.WAREHOUSE_ID
                  )}
                  component={WarehouseOperatingTimes}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={warehousesServiceAreasRoute(
                    ROUTE_PARAMETERS.ORGANISATION_ID,
                    ROUTE_PARAMETERS.WAREHOUSE_ID
                  )}
                  component={WarehouseServiceAreas}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={warehousesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={WarehouseOverview}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={freightRatesRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={RateCard}
                  requiredRoles={[`user`]}
                />
                {/* Help */}
                <PrivateRoute
                  path={helpGuidesRoute()}
                  component={Unimplemented}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={helpVideosRoute()}
                  component={Unimplemented}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={organisationRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Organisation}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={productsRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Products}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={tradingRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Trading}
                  requiredRoles={[`user`]}
                />
                <PrivateRoute
                  path={logisticsRoute(ROUTE_PARAMETERS.ORGANISATION_ID)}
                  component={Logistics}
                  requiredRoles={[`user`]}
                />
                <Route component={NotFound} />
              </Switch>
            </React.Fragment>
          </Route>
        </Switch>
      </Root>
    );
  }
}

export default Layout;

import * as React from "react";
import gql from "graphql-tag";
import { compose, withState } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { GET_SHIPPING_BATCHES } from "./ShippingData";
import AddShippingCarrierLayout, {
  addShippingCarrierLayoutFragments,
} from "./AddShippingCarrierLayout";
import { AddShippingCarrierDataGetShippingBatch_getSelf_shoppingCart_order_shippingBatch } from "./__generated__/AddShippingCarrierDataGetShippingBatch";

export const UPDATE_RATE_CARD_FOR_SHIPPING_BATCH = gql`
  mutation UpdateRatecardForShippingBatch(
    $shippingBatchID: String!
    $rateCardRateID: String!
  ) {
    updateRateCardForShippingBatch(
      shippingBatch: { id: $shippingBatchID }
      rateCardRate: { id: $rateCardRateID }
    )
  }
`;

export const GET_SHIPPING_BATCH = gql`
  query AddShippingCarrierDataGetShippingBatch(
    $organisationID: String!
    $shippingBatchID: String!
  ) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        order {
          id
          shippingBatch(id: $shippingBatchID) {
            id
            ...AddShippingCarrierLayoutShippingBatch
          }
        }
      }
    }
  }
  ${addShippingCarrierLayoutFragments.shippingBatch}
`;

type EnhancedProps = {
  organisationID: string;
  shippingBatchID?: string;
  onDismiss: () => void;
  onCancelPressed: () => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  shippingBatch: AddShippingCarrierDataGetShippingBatch_getSelf_shoppingCart_order_shippingBatch;
  selectedId?: string;
  setSelectedId: (arg0: string | void) => void;
  onAssignCarrierRate: (arg0: string) => void;
};

class AddShippingCarrierData extends React.Component<Props> {
  async onDismiss() {
    // If a rate card was selected - run mutation
    if (this.props.selectedId && this.props.selectedId !== ``) {
      await this.props.onAssignCarrierRate(this.props.selectedId);
    }
    this.props.setSelectedId(undefined);
    this.props.onDismiss();
  }

  render() {
    const {
      loading,
      shippingBatch,
      selectedId,
      setSelectedId,
      onCancelPressed,
    } = this.props;

    return (
      <AddShippingCarrierLayout
        loading={loading}
        shippingBatch={shippingBatch}
        selectedID={selectedId}
        onDismiss={this.onDismiss.bind(this)}
        onCancel={onCancelPressed}
        onSelectedIDChange={(id) => setSelectedId(id)}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withState(`selectedId`, `setSelectedId`, undefined),
  withQuery(GET_SHIPPING_BATCH, {
    dataPaths: {
      shippingBatch: `getSelf.shoppingCart.order.shippingBatch`,
    },
    errorMessage: `Failed to load shipping batch`,
    loadingProp: `loading`,
    options: ({ organisationID, shippingBatchID }: EnhancedProps) => ({
      variables: {
        organisationID,
        shippingBatchID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ shippingBatchID, organisationID }) =>
      shippingBatchID == null || organisationID == null,
  }),
  withMutation(UPDATE_RATE_CARD_FOR_SHIPPING_BATCH, {
    mapMutationToProps: (onAssignCarrierRate: any) => ({ onAssignCarrierRate }),
    errorMessage: `Failed to update carrier rate for shipping batch.`,
    mutationOptions: (
      { shippingBatchID, organisationID },
      [rateCardRateID]
    ) => ({
      variables: {
        shippingBatchID,
        rateCardRateID,
      },
      refetchQueries: [
        {
          query: GET_SHIPPING_BATCHES,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        updateRateCardForShippingBatch: `SUCCESS`,
      },
    }),
    throwOnError: true,
  })
);

export default enhancer(AddShippingCarrierData);

import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import MainTitle from "../../app/MainTitle";
import DestinationTable, { destinationsTableFragments } from "./Table";
import OrderActions from "../OrderActions";
import { DestinationLayoutOrderItem } from "./__generated__/DestinationLayoutOrderItem";
import AddDestinationToAllData from "./AddDestinationToAllData";
import { Grid } from "@material-ui/core";

export const destinationsLayoutFragments = {
  orderItem: gql`
    fragment DestinationLayoutOrderItem on OrderItem {
      id
      ...DestinationsTableOrderItem
    }
    ${destinationsTableFragments.orderItem}
  `,
};

const styles = (theme) => ({
  root: {
    flex: 1,
    display: `flex`,
    flexDirection: `column` as const,
    paddingTop: theme.spacing(1),
  },
  table: {
    alignSelf: `stretch`,
  },
  footerContainer: {
    width: "100%",
  },
});

type Props = StyleProps & {
  loading: boolean;
  productList: DestinationLayoutOrderItem[];
  destinationsAllocated: boolean;
  organisationID: string;
  onBack: () => void;
  onEditPressed: (orderItemID: string) => Promise<void> | void;
  onNext: () => void;
};

class DestinationLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      productList,
      destinationsAllocated,
      organisationID,
      onBack,
      onNext,
      onEditPressed,
    } = this.props;

    const nextDisabled = !destinationsAllocated;

    return (
      <>
        <MainTitle>Assign Delivery Addresses</MainTitle>

        <DestinationTable
          className={classes.table}
          loading={loading}
          productList={productList}
          onEditPressed={onEditPressed}
        />

        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          className={classes.footerContainer}
        >
          <AddDestinationToAllData
            organisationID={organisationID}
            orderItems={productList}
          />

          <OrderActions
            nextDisabled={nextDisabled}
            alignSelfStart
            onNext={onNext}
            onBack={onBack}
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(DestinationLayout);

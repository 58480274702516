import * as React from "react";
import MainPanel from "../components/app/MainPanel";

class NotFound extends React.Component {
  render() {
    return <MainPanel title="404 - Not Found" />;
  }
}

export default NotFound;

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import ActiveMarketplaceList from "./ActiveMarketplaceList";
import IndustrySelect from "../IndustrySelect";
import { ActiveMarketplaceListMarketplace as ActiveMarketplace } from "./__generated__/ActiveMarketplaceListMarketplace";
import { MarketplaceCurationGetMarketplace_marketplace as Marketplace } from "./__generated__/MarketplaceCurationGetMarketplace";
import ConfirmDeleteMarketplace from "./ConfirmDeleteMarketplace";
import UploadForm from "./UploadForm";

const styles = (theme) => ({
  industrySelect: {
    marginBottom: theme.spacing(1),
  },

  root: {
    flex: 1,
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `column` as const,
    alignItems: `stretch`,
  },
});

type Props = StyleProps & {
  setDeletingMarketplaceID: (marketplaceID: string | typeof undefined) => void;
  marketplace: Marketplace | null | undefined;
  loadingMarketplace: boolean;
  deleteMarketplace: (marketplaceID: string) => Promise<void>;
  industryID?: string;
  marketplaces: ActiveMarketplace[] | null;
  loadingMarketplaces: boolean;
  onIndustryIDChange: (id: string) => void;
  onPressCategories: (marketplaceID: string) => void;
  onPressEdit: (marketplaceID: string) => void;
};

class MarketplaceLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      setDeletingMarketplaceID,
      marketplace,
      industryID,
      marketplaces,
      loadingMarketplaces,
      onIndustryIDChange,
      onPressCategories,
      deleteMarketplace,
      onPressEdit,
    } = this.props;

    return (
      <div className={classes.root}>
        <IndustrySelect
          className={classes.industrySelect}
          selectedID={industryID}
          onSelectedIDChange={onIndustryIDChange}
        />
        <ActiveMarketplaceList
          marketplaces={marketplaces}
          loading={loadingMarketplaces}
          onPressCategories={onPressCategories}
          onPressDelete={(marketplaceID) => {
            setDeletingMarketplaceID(marketplaceID);
          }}
          onPressEdit={onPressEdit}
        />
        <ConfirmDeleteMarketplace
          marketplaceID={marketplace && marketplace.id}
          name={marketplace && marketplace.name}
          onAccept={(marketplaceID) => {
            deleteMarketplace(marketplaceID);
            setDeletingMarketplaceID(undefined);
          }}
          onClose={() => {
            setDeletingMarketplaceID(undefined);
          }}
        />
        <UploadForm industryID={industryID} />
      </div>
    );
  }
}

export default withStyles(styles)(MarketplaceLayout);

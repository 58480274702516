import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import DataTable from "./index";
import { Column, EnhancedProps as DataTableProps } from "./BaseDataTable";

export type Props<Row> = DataTableProps<Row> & {
  onSelectedIDsChange: (selectedIDs: string[]) => void;
  selectedIDs: string[];
};

/**
 * Function to create HOC to add a checkbox column to DataTable.
 * @returns HOC to wrap DataTable.
 */
export const withSelection = <Row,>() => (
  DataTableComponent: React.ComponentType<any>
) => {
  class DataTableWithSelection extends React.PureComponent<Props<Row>> {
    handleToggle(id: string) {
      const { selectedIDs, onSelectedIDsChange } = this.props;

      const newSelectedIDs = selectedIDs.includes(id)
        ? selectedIDs.filter((selectedID) => selectedID !== id)
        : [...selectedIDs, id];
      onSelectedIDsChange(newSelectedIDs);
    }

    render() {
      const { columns, selectedIDs, ...dataTableProps } = this.props;

      const enhancedColumns: Column<Row>[] = [
        {
          Cell: ({ id }) => (
            <Checkbox
              checked={selectedIDs.includes(id)}
              onClick={() => this.handleToggle(id)}
            />
          ),
          Header: null,
          disableSortable: true,
          cellProps: {
            padding: `checkbox`,
          },
          id: `_checkbox`,
        },
        ...columns,
      ];

      return (
        <DataTableComponent columns={enhancedColumns} {...dataTableProps} />
      );
    }
  }

  return DataTableWithSelection;
};

export default withSelection()(DataTable);

/*
 * Functions for resolving client-side GraphQL queries.
 *
 * The resolver map is split up into files by related feature.
 * Each file exports object with defaults and resolvers properties.
 */
import { merge } from "lodash";
import auth from "./auth";
import selection from "./selection";
import ui from "./ui";

export const { defaults, resolvers } = merge(auth, selection, ui);

/**
 * Handle times represented by a string in 24-hour 'HHMM' format.
 */
const TIME_PART_LENGTH = 2;

/**
 * Convert HHMM time string to 24-hour format HH:MM.
 * @param {string} time The time in HHMM format.
 * @return {string} The time in 24 hour HH:MM format, or null if time is invalid.
 */
export const format24Hour = (time: string): string | null | undefined => {
  // If already correct format
  if (/^\d\d:\d\d$/.test(time)) {
    return time;
  }

  // If invalid
  if (!/^\d\d\d\d$/.test(time)) {
    return null;
  }

  // Add colon character at index TIME_PART_LENGTH
  return `${time.substr(0, TIME_PART_LENGTH)}:${time.substr(TIME_PART_LENGTH)}`;
};

/**
 * Reformat HH:MM time string to HHMM.
 * @param {string} time Time in format HH:MM.
 * @return {string} Time in format HHMM.
 */
export const parse24Hour = (time: string): string | null | undefined => {
  if (!/^\d\d:\d\d$/.test(time)) {
    return null;
  } else {
    // Remove the character at index TIME_PART_LENGTH (colon)
    return time.substr(0, TIME_PART_LENGTH) + time.substr(TIME_PART_LENGTH + 1);
  }
};

import { graphql } from "react-apollo";
import activateItemUpdate from "../../../../apollo/updateFunctions/activateItemUpdate";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";
import withOpenSnackbar from "../snackbar/withOpenSnackbar";
import { compose, withHandlers } from "recompose";
import { SnackbarType } from "../../../../__generated__/globalTypes";

// Options for creating the HOC
type Options = {
  activate: {
    // Map activated item from cache and data passed to mutation to an array of items to add to active items cache
    getActiveItems?: (item: any, extraData?: any) => any[];
    mutation: any;
    optimisticResponse: any;
    variables: (
      itemID: string,
      props: any,
      extraData: any
    ) => any | null | undefined;
  };
  activeItems: {
    dataPath: string;
    fragment: any;
    idAccessPath?: string;
    query: any;
    variables: (props: any) => any | void;
  };
  activeListName: string;
  deactivate?: {
    mutation: any;
    optimisticResponse: any;
    refetchQueries?: (ownProps: any) => any[];
    variables: (
      itemID: string,
      props: any,
      extraData: any
    ) => any | null | undefined;
  };
  itemLabel: string;
  itemLabelPlural: string;
  itemTypeName: string;
};

export type ProvidedProps = {
  activateItems: (itemIDs: string[], extraData?: any) => Promise<void>;
  deactivateItems: (itemIDs: string[], extraData?: any) => Promise<void>;
};

/**
 * HOC managing mutations for the curation of a list of active items.
 * @param {any} options Options for configuring mutations.
 * @return Function for creating enhanced component.
 */
const withCurationMutations = (options: Options) =>
  compose<any, any>(
    withOpenSnackbar,
    graphql(options.activate.mutation, {
      props: ({ mutate, ownProps }) => ({
        activateItem: (itemID: string, extraData?: any) => {
          const { getActiveItems } = options.activate;
          const variables = options.activate.variables(
            itemID,
            ownProps,
            extraData == null ? {} : extraData
          );
          if (variables == null) {
            return Promise.resolve();
          }

          return mutate({
            optimisticResponse: options.activate.optimisticResponse,
            update: activateItemUpdate({
              activatedItemFragment: options.activeItems.fragment,
              activatedItemID: itemID,
              activatedItemTypeName: options.itemTypeName,
              activeItemsDataPath: options.activeItems.dataPath,
              activeItemsQuery: options.activeItems.query,
              activeItemsVariables: options.activeItems.variables(ownProps),
              getActiveItems: getActiveItems
                ? (item) => getActiveItems && getActiveItems(item, extraData)
                : undefined,
            }),
            variables: variables || {},
          });
        },
      }),
    }),
    options.deactivate != null
      ? graphql(options.deactivate.mutation, {
          props: ({ mutate, ownProps }) => ({
            deactivateItem: (itemID: string, extraData?: any) => {
              const variables =
                options.deactivate &&
                options.deactivate.variables(
                  itemID,
                  ownProps,
                  extraData == null ? {} : extraData
                );
              if (variables == null) {
                return Promise.resolve();
              }

              return mutate({
                optimisticResponse:
                  options.deactivate && options.deactivate.optimisticResponse,
                refetchQueries:
                  options.deactivate &&
                  options.deactivate.refetchQueries != null
                    ? options.deactivate.refetchQueries(ownProps)
                    : undefined,
                update: deleteMutationUpdate({
                  itemID,
                  itemIDAccessPath: options.activeItems.idAccessPath,
                  listDataAccessPath: options.activeItems.dataPath,
                  listQuery: options.activeItems.query,
                  listQueryVariables: options.activeItems.variables(ownProps),
                }),
                variables: variables || {},
              });
            },
          }),
        })
      : (component) => component,
    (withHandlers as any)({
      activateItems: ({ activateItem, openSnackbar }) => async (
        ids: string[],
        extraData: any
      ) => {
        try {
          // Perform activate mutation for each specified item
          await Promise.all(
            ids.map((itemID) => activateItem(itemID, extraData))
          );
          openSnackbar(
            SnackbarType.success,
            `Added ${
              ids.length === 1 ? options.itemLabel : options.itemLabelPlural
            } to ${options.activeListName}`
          );
        } catch (error) {
          openSnackbar(
            SnackbarType.error,
            `Failed to add ${options.itemLabelPlural} to ${options.activeListName}`
          );
        }
      },
      deactivateItems: ({ deactivateItem, openSnackbar }) => async (
        ids: string[],
        extraData: any
      ) => {
        try {
          // Perform deactivate mutation for each specified item
          await Promise.all(
            ids.map((itemID) => deactivateItem(itemID, extraData))
          );
          openSnackbar(
            SnackbarType.success,
            `Removed ${
              ids.length === 1 ? options.itemLabel : options.itemLabelPlural
            } from ${options.activeListName}`
          );
        } catch (error) {
          openSnackbar(
            SnackbarType.error,
            `Failed to remove ${options.itemLabelPlural} from ${options.activeListName}`
          );
        }
      },
    })
  );

export default withCurationMutations;

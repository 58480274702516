import * as React from "react";
import ActiveMarketplaceSelectionQuery from "./SelectionQuery";
import ActiveMarketplaceSelectField from "./ActiveMarketplaceSelectField";

export type Props = {
  className?: string;
  organisationID: string;
};

class ActiveMarketplaceSelectData extends React.Component<Props> {
  render() {
    const { className, organisationID } = this.props;

    return (
      <ActiveMarketplaceSelectionQuery>
        {({ selectedID, onSelectedIDChange }) => (
          <ActiveMarketplaceSelectField
            className={className}
            onSelectedIDChange={onSelectedIDChange}
            organisationID={organisationID}
            selectedID={selectedID}
          />
        )}
      </ActiveMarketplaceSelectionQuery>
    );
  }
}

export default ActiveMarketplaceSelectData;

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

const styles = () => ({});

type Props = StyleProps & {
  children: React.ReactNode;
  heading?: string;
};

const MenuList = ({ children, heading }: Props) => (
  <List
    component="nav"
    subheader={
      heading ? (
        <ListSubheader color={"inherit"}>{heading}</ListSubheader>
      ) : undefined
    }
  >
    {children}
  </List>
);

export default withStyles(styles)(MenuList);

import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomFieldProps } from "./CustomField";
import { omit } from "lodash";

type Props = CustomFieldProps<string>;

const CheckboxField = ({ field, ...rest }: Props) => (
  // @ts-ignore
  <FormControlLabel
    control={<Checkbox {...field} value={undefined} />}
    {...omit(rest, [`classes`, `touched`])}
  />
);

export default CheckboxField;

import * as React from "react";
import { compose } from "recompose";
import gql from "graphql-tag";
import withQuery from "../../generic/graphql/withQuery";
import { DEFAULT_POLL_INTERVAL } from "../../../../helpers/constants";
import SalesLayout, { SalesLayoutFragments } from "./SalesLayout";
import { SalesDataGetShippingBatchSaleListQuery_self_organisation_shippingBatchSaleList as ShippingBatchSale } from "./__generated__/SalesDataGetShippingBatchSaleListQuery";

export const GET_SHIPPING_BATCH_SALE_LIST_QUERY = gql`
  query SalesDataGetShippingBatchSaleListQuery(
    $organisationID: String!
    $itemsPerPage: Int!
    $itemsOffset: Int!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        shippingBatchSaleList(first: $itemsPerPage, offset: $itemsOffset) {
          id
          ...ShippingBatchSalesTableShippingBatch
        }
      }
    }
  }
  ${SalesLayoutFragments.shippingBatch}
`;

type EnhancedProps = {
  organisationID: string;
  onPressShippingBatch: (arg0: string) => void;
  itemsPerPage: number;
  pageOffset: number;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = EnhancedProps & {
  loading: boolean;
  shippingBatchSalesList: ShippingBatchSale[];
};

class SalesData extends React.PureComponent<Props> {
  render() {
    const {
      loading,
      shippingBatchSalesList,
      onPressShippingBatch,
      pageOffset,
      itemsPerPage,
      handlePageChange,
      handleRowsPerPageChange,
    } = this.props;

    return (
      <SalesLayout
        loading={loading}
        shippingBatchSalesList={shippingBatchSalesList}
        onPressShippingBatch={onPressShippingBatch}
        pageOffset={pageOffset}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_SHIPPING_BATCH_SALE_LIST_QUERY, {
    dataPaths: {
      shippingBatchSalesList: `self.organisation.shippingBatchSaleList`,
    },
    loadingProp: `loading`,
    options: ({ organisationID, itemsPerPage, pageOffset }: EnhancedProps) => ({
      variables: {
        organisationID,
        itemsPerPage,
        itemsOffset: pageOffset * itemsPerPage,
      },
      fetchPolicy: `network-only`,
      pollInterval: DEFAULT_POLL_INTERVAL,
    }),
    errorMessage: `Could not fetch sales`,
    skip: ({ organisationID }: EnhancedProps) => organisationID == null,
  })
);

export default enhancer(SalesData);

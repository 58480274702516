import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { StyleProps } from "../types";
import ProductDetailDialog, {
  productDetailsFragment,
} from "./ProductDetailDialog";
import {
  ProductDetailDialogGetOwnOrganisationProductDetails,
  ProductDetailDialogGetOwnOrganisationProductDetails_self_organisation_product as ProductDetails,
  ProductDetailDialogGetOwnOrganisationProductDetailsVariables,
} from "./__generated__/ProductDetailDialogGetOwnOrganisationProductDetails";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { compose } from "recompose";
import CallOnMount from "../generic/CallOnMount";
import { SnackbarType } from "../../../__generated__/globalTypes";

export const GET_OWN_ORGANISATION_PRODUCT_DETAILS = gql`
  query ProductDetailDialogGetOwnOrganisationProductDetails(
    $organisationID: String!
    $productID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        product(id: $productID) {
          ...ProductDetailDialogProduct
        }
      }
    }
  }
  ${productDetailsFragment}
`;

class GetOwnOrganisationProductDetailsQuery extends Query<
  ProductDetailDialogGetOwnOrganisationProductDetails,
  ProductDetailDialogGetOwnOrganisationProductDetailsVariables
> {}

type EnhancedProps = {
  onClose: () => void;
  open: boolean;
  organisationID: string;
  productID: string | null | undefined;
};

type Props = EnhancedProps &
  StyleProps & {
    openSnackbar: OpenSnackbarFn;
  };

export class ProductDetailData extends React.Component<Props> {
  handleError() {
    const { onClose, openSnackbar } = this.props;
    openSnackbar(SnackbarType.error, `Failed to load product`);
    onClose();
  }

  handleNotFound() {
    const { onClose, openSnackbar } = this.props;
    openSnackbar(
      SnackbarType.error,
      `Product could not be found for your organisation`
    );
    onClose();
  }

  render() {
    const { onClose, open, productID, organisationID } = this.props;

    return (
      <GetOwnOrganisationProductDetailsQuery
        query={GET_OWN_ORGANISATION_PRODUCT_DETAILS}
        variables={{
          organisationID,
          productID: productID || ``,
        }}
        skip={productID == null}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          const product: ProductDetails | null =
            data &&
            data.self &&
            data.self.organisation &&
            data.self.organisation.product
              ? data.self.organisation.product
              : null;
          const notFound = !error && !loading && !product;

          return (
            <React.Fragment>
              {error && open && (
                <CallOnMount callback={() => this.handleError()} />
              )}
              {notFound && open && (
                <CallOnMount callback={() => this.handleNotFound()} />
              )}
              <ProductDetailDialog
                open={open}
                product={product}
                onClose={onClose}
              />
            </React.Fragment>
          );
        }}
      </GetOwnOrganisationProductDetailsQuery>
    );
  }
}

const enhancer = compose<Partial<Props>, Partial<EnhancedProps>>(
  withOpenSnackbar
);

export default enhancer(ProductDetailData);

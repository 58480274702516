import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import getListItems from "../../generic/getListItems";
import ItemList from "../../generic/ItemListWithFilter";
import { StyleProps } from "../../types";
import { WarehouseServiceAreasServiceArea as ServiceArea } from "./__generated__/WarehouseServiceAreasServiceArea";

const styles = () => ({
  root: {
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
});

export type EnhancedProps = {
  loading?: boolean;
  onSelectServiceArea: (id: string) => void;
  serviceAreas?: ServiceArea[];
};

type Props = StyleProps & EnhancedProps;

/**
 * Filterable list of service areas to choose from
 */
class AddServiceAreaList extends React.PureComponent<Props> {
  render() {
    const { classes, loading, onSelectServiceArea, serviceAreas } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="subtitle1">Add Service Area</Typography>
        <ItemList
          autoFocus
          loading={loading}
          items={getListItems(serviceAreas, { default: [] })}
          listItemProps={(item) => ({
            button: true,
            onClick: () => onSelectServiceArea(item.id),
          })}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddServiceAreaList);

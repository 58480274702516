import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, Route } from "react-router-dom";
import { SPACING_LIST_ITEM_NESTING } from "../../../../helpers/constants";
import { SvgIconComponent } from "@material-ui/icons";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(SPACING_LIST_ITEM_NESTING),
  },
  icon: {
    color: theme.palette.primary.main,
  },
});

type Props = StyleProps & {
  Icon?: SvgIconComponent;
  activeOnlyWhenExact?: boolean;
  linkPath: string;
  nested?: boolean;
  onClick?: () => void;
  text: string;
};

/**
 * MUI ListItem that wraps the React-Router Link and appears selected when linkPath is active.
 * @returns {React.Node} Node
 */
const MenuItem = ({
  Icon,
  activeOnlyWhenExact,
  classes,
  linkPath,
  nested,
  onClick,
  text,
}: Props) => (
  <Route exact={activeOnlyWhenExact} path={linkPath}>
    {({ match }) => (
      <ListItem
        dense
        button
        className={nested ? classes.nested : undefined}
        selected={Boolean(match)}
        component={Link}
        to={linkPath}
        onClick={onClick}
      >
        {Icon && (
          <ListItemIcon className={classes.icon}>
            <Icon color={"inherit"} />
          </ListItemIcon>
        )}
        <ListItemText primary={text} />
      </ListItem>
    )}
  </Route>
);

export default withStyles(styles)(MenuItem);

import { graphql } from "react-apollo";
import { compose } from "recompose";
import createMutationUpdate from "../../../apollo/updateFunctions/createMutationUpdate";
import deleteMutationUpdate from "../../../apollo/updateFunctions/deleteMutationUpdate";
import { get } from "lodash";

/* eslint-disable no-unused-vars */
type Props = {
  createMutation: (values: any) => Promise<unknown>;
  deleteMutation: (itemID: string) => Promise<unknown>;
  item?: any;
  loadItemError?: Error;
  loadingItem?: boolean;
  updateMutation: (values: any & { id: string }) => Promise<unknown>;
};

export type Options = {
  createItemAccessPath: string;
  createMutation: any;
  createOptimisticResponse?: (item: any, props: any) => any;
  deleteMutation?: any;
  deleteOptimisticResponse?: (itemID: string, props: any) => any;
  getItemAccessPath?: string;
  getQuery?: any; // Query to retreive current item, skipped if variables undefined
  getQueryVariables?: (props: any) => any | void;
  listAccessPath?: string;
  listQuery?: any; // Query to update after creation
  listQueryVariables?: (props: any) => any | void;
  updateMutation?: any;
  updateOptimisticResponse?: (item: any, props: any) => any;
};

/**
 * HOC to connect a form to the queries required for creating and updating an item.
 * @param {any} options Object specifying the queries to connect.
 * @return {HOC} Function to enhance a form.
 */
const withCreateUpdateQueries = (options: Options) =>
  compose<any, any>(
    options.getQuery
      ? graphql(options.getQuery, {
          options: (props: any) => ({
            variables: options.getQueryVariables
              ? options.getQueryVariables(props)
              : {},
          }),
          props: ({ data }) => {
            const { error, loading } = data;
            const item = get(data, options.getItemAccessPath, undefined);

            return {
              item,
              loadItemError: error,
              loadingItem: loading,
            };
          },
          skip: (props: any) =>
            (options.getQueryVariables &&
              options.getQueryVariables(props) == null) ||
            false,
        })
      : (component) => component,
    graphql(options.createMutation, {
      props: ({ mutate, ownProps }) => ({
        createMutation: (item: any) =>
          mutate({
            optimisticResponse: options.createOptimisticResponse
              ? options.createOptimisticResponse(item, ownProps)
              : undefined,
            update:
              options.listQuery && options.listAccessPath
                ? createMutationUpdate({
                    itemDataAccessPath: options.createItemAccessPath,
                    listDataAccessPath: options.listAccessPath,
                    listQuery: options.listQuery,
                    listQueryVariables: options.listQueryVariables
                      ? options.listQueryVariables(ownProps)
                      : {},
                  })
                : undefined,
            variables: item,
          }),
      }),
    }),
    options.updateMutation
      ? graphql(options.updateMutation, {
          props: ({ mutate, ownProps }) => ({
            updateMutation: (item: any) =>
              mutate({
                optimisticResponse: options.updateOptimisticResponse
                  ? options.updateOptimisticResponse(item, ownProps)
                  : undefined,
                variables: item,
              }),
          }),
        })
      : (component) => component,
    options.deleteMutation
      ? graphql(options.deleteMutation, {
          props: ({ mutate, ownProps }) => ({
            deleteMutation: (itemID) =>
              mutate({
                optimisticResponse: options.deleteOptimisticResponse
                  ? options.deleteOptimisticResponse(itemID, ownProps)
                  : undefined,
                update:
                  options.listQuery && options.listAccessPath
                    ? deleteMutationUpdate({
                        itemID,
                        listDataAccessPath: options.listAccessPath,
                        listQuery: options.listQuery,
                        listQueryVariables: options.listQueryVariables
                          ? options.listQueryVariables(ownProps)
                          : {},
                      })
                    : undefined,
                variables: { id: itemID },
              }),
          }),
        })
      : (component) => component
  );

export default withCreateUpdateQueries;

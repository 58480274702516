import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import ArrowRightIcon from "@material-ui/icons/ArrowForward";
import ArrowLeftIcon from "@material-ui/icons/ArrowBack";
import ItemsActionButton from "../../generic/ItemsActionButton";
import ItemList, { Item as ListItem } from "../../generic/ItemList";
import ItemListWithSelection from "../../generic/ItemListWithSelection";

const listItemMap = (item: { id: string; name: string }) => ({
  id: item.id,
  text: item.name,
});

const styles = (theme) => ({
  list: {
    flex: 1,
    maxHeight: `60vh`,
  },

  panel: {
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },

  root: {
    alignItems: `stretch`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `row` as const,
  },

  separator: {
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `column` as const,
    justifyContent: `center`,
    margin: theme.spacing(1),
  },
});

type Props = StyleProps & {
  ActiveListComponent: React.ComponentType<any>;
  AvailableListComponent: React.ComponentType<any>;
  activateEnabled: boolean;
  activeItems?: any[];
  activeItemsListMap: (item: any) => ListItem;
  activeItemsLoading?: boolean;
  activeListHeader?: React.ReactNode;
  activeListProps?: any;
  availableItems?: any[];
  availableItemsListMap: (item: any) => ListItem;
  availableItemsLoading?: boolean;
  availableListHeader?: React.ReactNode;
  availableListProps?: any;
  deactivateEnabled?: boolean;
  itemName: string;
  itemNamePlural: string;
  onActivatePressed: () => void;
  onDeactivatePressed?: () => void;
  onSelectedActiveItemsChange?: (selectIDs: string[]) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  selectedActiveItems?: string[];
  selectedAvailableItems: string[];
};

class CurationLayout extends React.PureComponent<Props> {
  static defaultProps = {
    ActiveListComponent: ItemList,
    AvailableListComponent: ItemListWithSelection,
    activeItemsListMap: listItemMap,
    availableItemsListMap: listItemMap,
  };

  render() {
    const {
      ActiveListComponent,
      AvailableListComponent,
      activateEnabled,
      activeItems,
      activeItemsListMap,
      activeItemsLoading,
      activeListHeader,
      activeListProps,
      availableItems,
      availableItemsListMap,
      availableItemsLoading,
      availableListHeader,
      availableListProps,
      classes,
      deactivateEnabled,
      itemName,
      itemNamePlural,
      onActivatePressed,
      onDeactivatePressed,
      onSelectedActiveItemsChange,
      onSelectedAvailableItemsChange,
      selectedActiveItems,
      selectedAvailableItems,
    } = this.props;

    const availableListItems = availableItems
      ? availableItems.map(availableItemsListMap)
      : [];
    const activeListItems = activeItems
      ? activeItems.map(activeItemsListMap)
      : [];

    return (
      <div className={classes.root}>
        <div className={classes.panel}>
          {availableListHeader || null}
          <AvailableListComponent
            className={classes.list}
            listProps={{ dense: true }}
            items={availableListItems}
            loading={availableItemsLoading}
            selectedIDs={selectedAvailableItems}
            onSelectedIDsChange={onSelectedAvailableItemsChange}
            {...availableListProps}
          />
        </div>
        <div className={classes.separator}>
          <ItemsActionButton
            Icon={ArrowRightIcon}
            disabled={selectedAvailableItems.length === 0 || !activateEnabled}
            actionText="Activate"
            itemName={itemName}
            itemNamePlural={itemNamePlural}
            count={selectedAvailableItems.length}
            performAction={onActivatePressed}
          />
          {onDeactivatePressed && (
            <ItemsActionButton
              Icon={ArrowLeftIcon}
              disabled={
                !selectedActiveItems ||
                selectedActiveItems.length === 0 ||
                !deactivateEnabled
              }
              actionText="Deactivate"
              itemName={itemName}
              itemNamePlural={itemNamePlural}
              count={selectedActiveItems ? selectedActiveItems.length : 0}
              performAction={onDeactivatePressed}
            />
          )}
        </div>
        <div className={classes.panel}>
          {activeListHeader || null}

          <ActiveListComponent
            className={classes.list}
            listProps={{ dense: true }}
            items={activeListItems}
            loading={activeItemsLoading}
            selectedIDs={selectedActiveItems}
            onSelectedIDsChange={onSelectedActiveItemsChange}
            {...activeListProps}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CurationLayout);

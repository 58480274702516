import * as React from "react";
import SelectField from "./form/SelectField";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import CallOnMount from "./CallOnMount";
import withOpenSnackbar, { OpenSnackbarFn } from "./snackbar/withOpenSnackbar";
import { get, isEqual } from "lodash";
import { SnackbarType } from "../../../__generated__/globalTypes";

const defaultGetSelectOption = (item) => ({
  label: item.name,
  value: item.id,
});

// Options for creating the HOC
type Options = {
  clearSelectionOnVariablesChange?: boolean;
  dataPath: string;
  getSelectOption?: (item: any) => { label: string; value: string };
  itemPluralLabel: string;
  itemTitleLabel: string;
  query: any;
  variables?: (props: any) => any | void; // Query skipped if undefined is returned
};

export type EnhancedProps = {
  className?: string;
  onSelectedIDChange: (id?: string) => void;
  selectedID?: string;
};

/**
 * HOC attaching data from a query to a select field and handling errors.
 * @param {any} options Options for configuring list query.
 * @return Function for creating enhanced component.
 */
export const withSelectList = (options: Options) =>
  compose<any, any>((WrappedComponent) => {
    type Props = EnhancedProps & {
      error?: Error;
      items: Array<any> | null | undefined;
      loading: boolean;
      openSnackbar: OpenSnackbarFn;
    };

    class ListQuerySelectField extends React.PureComponent<Props> {
      componentDidUpdate(prevProps: Props) {
        const { clearSelectionOnVariablesChange, variables } = options;
        if (
          clearSelectionOnVariablesChange &&
          variables &&
          !isEqual(variables(prevProps), variables(this.props))
        ) {
          this.props.onSelectedIDChange();
        }
      }

      render() {
        const {
          className,
          error,
          items,
          loading,
          selectedID,
          openSnackbar,
          onSelectedIDChange,
        } = this.props;

        const selectOptions = items
          ? items.map(options.getSelectOption || defaultGetSelectOption)
          : [];

        return (
          <React.Fragment>
            <WrappedComponent
              className={className}
              loading={loading}
              value={selectedID}
              label={options.itemTitleLabel}
              placeholder={`Select ${options.itemTitleLabel}`}
              options={selectOptions}
              onChange={(event) => onSelectedIDChange(event.target.value)}
            />
            {error && (
              <CallOnMount
                callback={() =>
                  openSnackbar(
                    SnackbarType.error,
                    `Failed to load ${options.itemPluralLabel}`
                  )
                }
              />
            )}
          </React.Fragment>
        );
      }
    }

    const enhancer = compose<Partial<Props>, any>(
      graphql(options.query, {
        options: (props) => ({
          variables: (options.variables && options.variables(props)) || {},
        }),
        props: ({ data }) => ({
          error: data.error,
          items:
            !data.loading && !data.error && get(data, options.dataPath, null),
          loading: data.loading,
        }),
        skip: (props) =>
          options.variables ? options.variables(props) == null : false,
      }),
      withOpenSnackbar
    );

    return enhancer(ListQuerySelectField);
  });

/**
 * Function creating a component wrapping SelectField.
 * @param {any} options Options for configuring list query.
 * @return Function for creating enhanced component.
 */
export default (options: Options) => withSelectList(options)(SelectField);

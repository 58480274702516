import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import { compose, defaultProps } from "recompose";
import { StyleProps } from "../../types";
import classnames from "classnames";

const typeIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

const styles = (theme) => ({
  content: {
    height: theme.dimensions.snackbarHeight,
  },
  error: {
    backgroundColor: theme.statusPalette.error,
  },
  info: {
    backgroundColor: theme.statusPalette.info,
  },
  message: {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `row` as const,
  },
  success: {
    backgroundColor: theme.statusPalette.success,
  },
  typeIcon: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  warning: {
    backgroundColor: theme.statusPalette.warning,
  },
});

export type InfoType = "error" | "warning" | "info" | "success";

type EnhancedProps = {
  children?: React.ReactNode;
  duration?: number;
  message: string;
  onClose: () => void;
  open: boolean;
  type: InfoType;
};

type Props = StyleProps &
  (EnhancedProps & {
    duration: number;
  });

class InfoSnackbar extends React.Component<Props> {
  render() {
    const {
      classes,
      children,
      duration,
      message,
      onClose,
      open,
      type,
    } = this.props;

    const Icon = typeIcon[type];

    return (
      <Snackbar
        anchorOrigin={{
          horizontal: `right`,
          vertical: `bottom`,
        }}
        open={open}
        autoHideDuration={duration}
        ClickAwayListenerProps={{
          mouseEvent: `onMouseDown`,
        }}
        onClose={onClose}
      >
        <SnackbarContent
          className={classnames(classes.content, classes[type])}
          message={
            <span className={classes.message}>
              <Icon className={classes.typeIcon} />
              {children || message}
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  defaultProps({
    duration: 6000,
  }),
  withStyles(styles)
);
export default enhancer(InfoSnackbar);

import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import CatalogueViewEditData from "../../components/product/catalogueView/EditData";
import { match } from "react-router-dom";
import * as H from "history";
import { catalogueViewsRoute } from "../index";

type MatchProps = {
  organisationID?: string;
  catalogueViewID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

class CatalogueViewEdit extends React.PureComponent<Props> {
  render() {
    const { history, match } = this.props;
    const { organisationID, catalogueViewID } = match.params;

    return (
      <MainPanel title="Edit Catalogue View">
        {organisationID && catalogueViewID && (
          <CatalogueViewEditData
            catalogueViewID={catalogueViewID}
            organisationID={organisationID}
            onDismiss={() => history.push(catalogueViewsRoute(organisationID))}
          />
        )}
      </MainPanel>
    );
  }
}

export default CatalogueViewEdit;

import gql from "graphql-tag";
import { compose } from "recompose";
import withMutation from "../../../generic/graphql/withMutation";
import { InviteNewUserToCatalogueViewLayout } from "./InviteNewUserToCatalogueViewLayout";

export const INVITE_NEW_USER_TO_CATALOGUE_SUCCESS = (email: string): string =>
  `Successfully invited ${email} to the product catalogue`;
export const INVITE_NEW_USER_TO_CATALOGUE_FAIL = (email: string): string =>
  `Unable to invite '${email}' to the product catalogue`;

export const INVITE_NEW_USER_MUTATION = gql`
  mutation InviteNewUserToCatalogueViewDataInviteNewUser(
    $email: String!
    $catalogueViewID: String!
  ) {
    inviteNewUser(emailAddress: $email, productCatalogueId: $catalogueViewID)
  }
`;

type EnhancedProps = {
  catalogueViewID: string;
  open: boolean;
  onClose: () => Promise<void> | void;
};

type Props = EnhancedProps & {
  onSubmit: (email: string) => void | Promise<void>;
};

export const InviteNewUserToCatalogueViewData = compose<Props, EnhancedProps>(
  withMutation(INVITE_NEW_USER_MUTATION, {
    errorMessage: (error, props, args) =>
      INVITE_NEW_USER_TO_CATALOGUE_FAIL(args[0]),
    mapMutationToProps: (onSubmit) => ({ onSubmit }),
    mutationOptions: ({ catalogueViewID }, [email]) => ({
      optimisticResponse: {
        inviteNewUser: "SUCCESS",
      },
      variables: {
        catalogueViewID,
        email,
      },
    }),
    successMessage: (result, props, args) =>
      INVITE_NEW_USER_TO_CATALOGUE_SUCCESS(args[0]),
  })
)(InviteNewUserToCatalogueViewLayout);

import * as React from "react";
import DataTable from "../../generic/DataTable/WithRadioSelection";
import gql from "graphql-tag";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { displayLeadTime } from "../../../../helpers/displayLeadTime";
import { AddShippingCarrierRateCardOptions } from "./__generated__/AddShippingCarrierRateCardOptions";

export const addShippingCarrierTableFragments = {
  rateCardRateOptions: gql`
    fragment AddShippingCarrierRateCardOptions on RateCardRateOptions {
      RateCardRate {
        id
        leadTime
        service
        rateCard {
          id
          provider {
            id
            name
          }
        }
      }
      shippingBatchShippingPrice
    }
  `,
};

const columns: Column<AddShippingCarrierRateCardOptions>[] = [
  {
    Header: `Service`,
    id: `RateCardRate.service`,
  },
  {
    Header: `Carrier`,
    id: `RateCardRate.rateCard.provider.name`,
  },
  {
    Cell: ({ value }) => displayLeadTime(value),
    Header: `Lead Time`,
    id: `RateCardRate.leadTime`,
  },
  {
    Header: `Price`,
    id: `shippingBatchShippingPrice`,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
];

type Props = {
  className?: string;
  loading: boolean;
  availableCarrierRates: AddShippingCarrierRateCardOptions[];
  selectedID?: string;
  onSelectedIDChange: any; // TODO: make better type
};

class AddDestinationTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      availableCarrierRates,
      onSelectedIDChange,
      selectedID,
    } = this.props;

    return (
      <DataTable
        className={className}
        loading={loading}
        emptyMessage="No rates available for this shipping batch"
        columns={columns}
        data={availableCarrierRates || []}
        idAccessor="RateCardRate.id"
        selectedID={selectedID}
        onSelectedIDChange={onSelectedIDChange}
      />
    );
  }
}

export default AddDestinationTable;

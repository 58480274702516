import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import OrderStepper from "../../components/trading/OrderStepper";
import { buyStepOrderRoute, buyStepRoute } from "../index";
import {
  ORDER_STEP_CART,
  ORDER_STEP_CONFIRMATION,
  ORDER_STEP_DESTINATIONS,
  ORDER_STEP_REVIEW,
  ORDER_STEP_SHIPPING,
  OrderStep,
} from "../../../helpers/trading/orderStep";
import { DestinationsRoute } from "./order/destinations";
import { ShippingRoute } from "./order/shipping";
import { ConfirmationRoute } from "./order/confirmation";
import { ReviewRoute } from "./order/review";
import { CartRoute } from "./order/cart";
import { match } from "react-router";

type MatchProps = {
  organisationID?: string;
  step?: string;
};

type Props = {
  match: match<MatchProps>;
  history: H.History;
};

class Order extends React.Component<Props> {
  render() {
    const { history } = this.props;

    const { organisationID, step } = this.props.match.params;

    if (organisationID == null || step == null) {
      return null;
    }

    const orderStep: OrderStep = OrderStep.getById(step);

    const onNext = () =>
      history.push(
        buyStepRoute(organisationID, OrderStep.getNextStep(orderStep).id)
      );

    const onBack = () =>
      history.push(
        buyStepRoute(organisationID, OrderStep.getPreviousStep(orderStep).id)
      );

    return (
      <MainPanel>
        <OrderStepper step={OrderStep.getStepNumber(orderStep)} />

        {orderStep === OrderStep.getById(ORDER_STEP_CART) && (
          <CartRoute organisationID={organisationID} onNext={onNext} />
        )}

        {orderStep === OrderStep.getById(ORDER_STEP_REVIEW) && (
          <ReviewRoute
            organisationID={organisationID}
            onBack={onBack}
            onNext={onNext}
          />
        )}

        {orderStep === OrderStep.getById(ORDER_STEP_DESTINATIONS) && (
          <DestinationsRoute
            organisationID={organisationID}
            onNext={onNext}
            onBack={onBack}
          />
        )}

        {orderStep === OrderStep.getById(ORDER_STEP_SHIPPING) && (
          <ShippingRoute
            organisationID={organisationID}
            onNext={(orderID: string) =>
              history.push(
                buyStepOrderRoute(
                  organisationID,
                  ORDER_STEP_CONFIRMATION,
                  orderID
                )
              )
            }
            onBack={onBack}
          />
        )}

        {orderStep === OrderStep.getById(ORDER_STEP_CONFIRMATION) && (
          <ConfirmationRoute organisationID={organisationID} />
        )}
      </MainPanel>
    );
  }
}

export default Order;

import { get } from "lodash";
import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";

type Options = {
  itemDataAccessPath: string; // Path within data from create mutation to access the new item
  listDataAccessPath: string; // Path within data from list query to access the list to mutate
  listItemFromData?: (arg0: any) => any; // Optional function mapping mutation data to the new list item
  listQuery: any; // Query document for query with result containing the list to update
  listQueryVariables?: any; // Variables to pass to list query, update skipped if not defined
};

/**
 * Create an update function that will add an item to a list in Apollo cache when a create mutation is performed.
 * @param {any} options Configuration for the query to update.
 * @return {void}
 */
const createMutationUpdate = (options: Options) => (
  store: InMemoryCache,
  result: FetchResult
) => {
  if (options.listQueryVariables == null) {
    return;
  }
  try {
    // Read the current list of items
    const listQuery = {
      query: options.listQuery,
      variables: options.listQueryVariables,
    };
    const data = store.readQuery(listQuery);
    const list: Array<any> = get(data, options.listDataAccessPath);
    // Get the new item from mutation result
    const { listItemFromData } = options;
    const mutationItem = get(result.data, options.itemDataAccessPath);
    const newItem =
      mutationItem != null && listItemFromData
        ? listItemFromData(mutationItem)
        : mutationItem;

    if (list && newItem != null) {
      list.push(newItem);
      // Write the modified data to the cache
      store.writeQuery({
        ...listQuery,
        data,
      });
    }
  } catch (error) {
    // Leave cache empty
  }
};

export default createMutationUpdate;

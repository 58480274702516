import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { CustomFieldProps } from "./CustomField";
import { omit } from "lodash";
import { StyleProps } from "../../types";
import classnames from "classnames";

const styles = (theme) => ({
  input: {},

  inputNotFullWidth: {
    width: theme.dimensions.textFieldWidth,
  },
});

type Props = CustomFieldProps<string> &
  StyleProps & {
    fullWidth?: boolean;
  };

/**
 * MUI Field for single file input. Target's value for onChange event is a file.
 * @param {Props} props Props
 * @return {React.Node} File input
 */
export const FileInput = ({
  field,
  fullWidth,
  error,
  classes,
  ...rest
}: Props) => (
  <TextField
    className={classnames(
      classes.input,
      !fullWidth && classes.inputNotFullWidth
    )}
    error={error != null}
    helperText={error || ` `}
    fullWidth={fullWidth}
    InputLabelProps={{
      disableAnimation: true,
      shrink: true,
    }}
    type="file"
    onChange={(event) => {
      field.onChange({
        target: {
          id: event.target.id,
          name: event.target.name,
          // @ts-ignore
          value: event.target.files[0],
        },
      });
    }}
    {...omit(rest, `touched`)}
  />
);

export default withStyles(styles)(FileInput);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { StyleProps } from "../types";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { SPACING_PAPER } from "../../../helpers/constants";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(SPACING_PAPER),
  },
  root: {
    left: 0,
    position: `absolute` as const,
    top: -theme.spacing(1),
    zIndex: theme.zIndex.modal,
  },
});

type Props = StyleProps & {
  onChange: (value: string) => void;
  onClose: (proceed?: boolean) => void;
  open: boolean;
  textFieldProps?: any;
  value: string;
};

class InlineTextDialog extends React.PureComponent<Props> {
  handleInputKeyPressed(event: KeyboardEvent) {
    const { onClose } = this.props;

    if (
      event.key === `Enter` ||
      event.key === `Tab` ||
      event.key === `Escape`
    ) {
      onClose(event.key !== `Escape`);
      event.preventDefault();
    }
  }

  render() {
    const {
      classes,
      onClose,
      onChange,
      open,
      value,
      textFieldProps,
    } = this.props;
    if (!open) {
      return null;
    }

    return (
      <ClickAwayListener
        mouseEvent="onClick"
        onClickAway={() => onClose(false)}
      >
        <Fade in={open}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TextField
                autoFocus
                value={value}
                onChange={(event) => onChange(event.target.value)}
                onKeyDown={this.handleInputKeyPressed.bind(this)}
                {...textFieldProps}
              />
            </Paper>
          </div>
        </Fade>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(InlineTextDialog);

import * as React from "react";
import createAutosaveListForm from "../../generic/AutosaveListForm";
import * as yup from "yup";
import { StyleProps } from "../../types";
import WarehouseInventoryTable, {
  warehouseInventoryTableFragments,
} from "./WarehouseInventoryTable";
import { UpdateWarehouseProductInventoryFn } from "./WarehouseInventoryData";
import gql from "graphql-tag";
import { WarehouseInventoryFormStockedProduct } from "./__generated__/WarehouseInventoryFormStockedProduct";

export const warehouseInventoryFormFragments = {
  warehouseProductStockLevel: gql`
    fragment WarehouseInventoryFormStockedProduct on _WarehouseStockLevelList {
      ...WarehouseInventoryTableStockedProduct
    }
    ${warehouseInventoryTableFragments.warehouseProductStockLevel}
  `,
};

const AutosaveListForm = createAutosaveListForm({
  itemIDAccessor: `Product.id`,
  itemSchema: yup.object().shape({
    quantity: yup
      .number()
      .typeError(`Quantity must be a number`)
      .nullable()
      .required()
      .min(0)
      .integer()
      .label(`Quantity`),
  }),
  getValuesForItem: (item: { quantity: number }) => ({
    quantity: item.quantity,
  }),
});

export type Props = StyleProps & {
  className?: string;
  warehouseInventory: Array<WarehouseInventoryFormStockedProduct>;
  updateWarehouseProductInventory: UpdateWarehouseProductInventoryFn;
};

export default class WarehouseInventoryForm extends React.PureComponent<Props> {
  updateItem(itemID: string, fieldName: string, value: string) {
    const { updateWarehouseProductInventory } = this.props;

    const updateValue = Number(value);

    if (
      fieldName === `quantity` &&
      updateValue != null &&
      !isNaN(updateValue)
    ) {
      return updateWarehouseProductInventory({
        productID: itemID,
        quantity: updateValue,
      });
    }

    return Promise.reject(new Error(`Quantity invalid`));
  }

  render() {
    const {
      className,
      classes,
      warehouseInventory,
      updateWarehouseProductInventory,
    } = this.props;

    return (
      <AutosaveListForm
        className={className}
        key={String(warehouseInventory.length)} // Reset state when items are loaded
        items={warehouseInventory}
        itemName="product"
        // @ts-ignore
        updateWarehouseProductInventory={updateWarehouseProductInventory}
        updateItem={this.updateItem.bind(this)}
      >
        {/* @ts-ignore*/}
        {({ getAutosaveListFieldProps }) => (
          <WarehouseInventoryTable
            className={classes.table}
            warehouseInventory={warehouseInventory}
            getFieldProps={getAutosaveListFieldProps}
          />
        )}
      </AutosaveListForm>
    );
  }
}

import * as React from "react";
import DataTable from "../../generic/DataTable";
import gql from "graphql-tag";
import { ShoppingCartTableIncludesProductListFragment as ShoppingCartIncludesProduct } from "./__generated__/ShoppingCartTableIncludesProductListFragment";
import CustomField from "../../generic/form/CustomField";
import TextInputWithState from "../../generic/form/TextInputWithState";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { displayTax } from "../../../../helpers/formatCurrency";

export const shoppingCartIncludesProductListFragment = gql`
  fragment ShoppingCartTableIncludesProductListFragment on _ShoppingCartProductList {
    Product {
      id
      description
    }
    quantity
    amounts {
      price {
        display
        value
        currency
      }
      tax
      taxPercentage
      totalTax {
        display
        value
        currency
      }
      totalPrice {
        display
        value
        currency
      }
    }
  }
`;

type Props = {
  className?: string;
  loading: boolean;
  productList: ShoppingCartIncludesProduct[];
  getFieldProps: (productID: string, name: string) => any;
  onDeletePressed: (product: string) => Promise<void>;
};

class ShoppingCartTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      productList,
      getFieldProps,
      onDeletePressed,
    } = this.props;

    const columns = [
      {
        Header: `Name`,
        id: `Product.description`,
      },
      {
        Header: `Qty`,
        id: `quantity`,
        Cell: ({ id }) => (
          <CustomField
            fullWidth
            component={TextInputWithState}
            type="number"
            inputProps={{
              min: 1,
            }}
            {...getFieldProps(id, `quantity`)}
          />
        ),
      },
      {
        Header: `Unit Price`,
        id: `amounts[0].price`,
        numeric: true,
      },
      {
        Header: `Tax Percentage`,
        id: `amounts[0].taxPercentage`,
        Cell: ({ row }) =>
          row && row.amounts[0] && row.amounts[0].taxPercentage
            ? displayTax(row.amounts[0].taxPercentage)
            : `N/A`,
        numeric: true,
      },
      {
        Header: `Total Tax`,
        id: `amounts[0].totalTax`,
        numeric: true,
      },
      {
        Header: `Total Price`,
        id: `amounts[0].totalPrice`,
        numeric: true,
      },
      {
        Header: ``,
        id: `delete`,
        Cell: ({ id }) => (
          <IconButton onClick={() => onDeletePressed(id)}>
            <DeleteIcon />
          </IconButton>
        ),
        disableSortable: true,
      },
    ];

    return (
      <DataTable
        className={className}
        loading={loading}
        columns={columns}
        data={productList}
        idAccessor="Product.id"
        emptyMessage="No items in cart"
      />
    );
  }
}

export default ShoppingCartTable;

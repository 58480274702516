import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { StyleProps } from "../../types";
import MainTitle from "../../app/MainTitle";
import ShippingTable, { shippingTableFragments } from "./ShippingTable";
import OrderActions from "../OrderActions";
import { ShippingLayoutOrder_shippingBatchList } from "./__generated__/ShippingLayoutOrder";

export const shippingLayoutFragments = {
  order: gql`
    fragment ShippingLayoutOrder on Order {
      id
      status
      isFullyCarried
      shippingBatchList {
        ...ShippingTableShippingBatch
      }
    }
    ${shippingTableFragments.shippingBatch}
  `,
};

const styles = () => ({
  table: {
    alignSelf: `stretch`,
  },
});

type Props = StyleProps & {
  loading: boolean;
  shippingBatchList: ShippingLayoutOrder_shippingBatchList[];
  isFullyCarried: boolean;
  onBack: () => void;
  onEditPressed: (orderItemID: string) => void;
  onNext: () => Promise<void>;
};

class ShippingLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      shippingBatchList,
      isFullyCarried,
      onBack,
      onNext,
      onEditPressed,
    } = this.props;

    const nextEnabled = !loading && isFullyCarried;

    return (
      <>
        <MainTitle>Shipping Batches</MainTitle>

        <ShippingTable
          className={classes.table}
          loading={loading}
          shippingBatchList={shippingBatchList}
          onEditPressed={onEditPressed}
        />

        <OrderActions
          nextDisabled={!nextEnabled}
          nextText={`Confirm`}
          onNext={onNext}
          onBack={onBack}
        />
      </>
    );
  }
}

export default withStyles(styles)(ShippingLayout);

import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { CategoryFormCreateCategoryVariables } from "./__generated__/CategoryFormCreateCategory";
import { CategoryFormGetCategory_category as Category } from "./__generated__/CategoryFormGetCategory";
import { CategoryFormUpdateCategoryVariables } from "./__generated__/CategoryFormUpdateCategory";
import { compose } from "recompose";
import { GET_CATEGORIES } from "./CategoryCuration";

export const categoryFormFragments = {
  category: gql`
    fragment CategoryFormCategory on Category {
      id
      name
      description
    }
  `,
};

export const CREATE_CATEGORY = gql`
  mutation CategoryFormCreateCategory(
    $marketplaceID: String!
    $name: String!
    $description: String
  ) {
    createCategory(
      values: { name: $name, description: $description }
      marketplace: { id: $marketplaceID }
    ) {
      ...CategoryFormCategory
    }
  }
  ${categoryFormFragments.category}
`;

export const UPDATE_CATEGORY = gql`
  mutation CategoryFormUpdateCategory(
    $id: String!
    $name: String
    $description: String
  ) {
    updateCategory(
      id: $id
      values: { name: $name, description: $description }
    ) {
      ...CategoryFormCategory
    }
  }
  ${categoryFormFragments.category}
`;

export const GET_CATEGORY = gql`
  query CategoryFormGetCategory($id: String!) {
    category: getCategory(id: $id) {
      ...CategoryFormCategory
    }
  }
  ${categoryFormFragments.category}
`;

const schema = yup.object({
  description: yup.string().label(`Description`).nullable(),
  name: yup.string().required().label(`Name`),
});

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  marketplaceID?: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  item: Partial<Category>;
  createMutation: (
    category: CategoryFormCreateCategoryVariables
  ) => Promise<void>;
  updateMutation: (
    category: CategoryFormUpdateCategoryVariables
  ) => Promise<void>;
};

export class CategoryForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      itemID,
      marketplaceID,
      createMutation,
      ...createUpdateFormProps
    } = this.props;

    return (
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        itemType="category"
        itemTypeTitle="Category"
        itemNameAccessor="name"
        createMutation={(values) =>
          createMutation({
            ...values,
            marketplaceID,
          })
        }
        {...createUpdateFormProps}
      >
        <FieldList component={BodyContainer}>
          <CustomField
            fullWidth
            autoFocus={itemID == null}
            name="name"
            label="Name"
            component={TextInput}
          />
          <CustomField
            fullWidth
            multiline
            name="description"
            label="Description"
            component={TextInput}
          />
        </FieldList>
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createCategory`,
    createMutation: CREATE_CATEGORY,
    getItemAccessPath: `category`,
    getQuery: GET_CATEGORY,
    getQueryVariables: ({ itemID }) => (itemID ? { id: itemID } : undefined),
    listQuery: GET_CATEGORIES,
    updateMutation: UPDATE_CATEGORY,
    // Enable list of categories to be update in UI immediately on change
    listQueryVariables: ({ marketplaceID }: { marketplaceID: string }) => ({
      marketplaceID,
    }),
    listAccessPath: `marketplace.categoryList`,
  })
);

export default enhancer(CategoryForm);

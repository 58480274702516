/**
 * Application configuration determined from the environment.
 */
const config = {
  auth0: {
    audience: (process.env.REACT_APP_AUTH0_AUDIENCE as any) as string,
    clientID: (process.env.REACT_APP_AUTH0_CLIENT_ID as any) as string,
    customClaimsNamespace: (process.env
      .REACT_APP_AUTH0_CLAIMS_NAMESPACE as any) as string,
    domain: (process.env.REACT_APP_AUTH0_DOMAIN as any) as string,
  },
  publicURL: (process.env.REACT_APP_PUBLIC_URL as any) as string,
  serverURL: (process.env.REACT_APP_SERVER_URL as any) as string,
};

// Check that all config values are not undefined
const allValuesSet = (configObject: any): boolean => {
  if (configObject === undefined) {
    return false;
  } else if (typeof configObject === `object`) {
    // Check all nested property values are non-null and have their properties set
    for (const configValue of Object.values(configObject)) {
      if (!allValuesSet(configValue)) {
        return false;
      }
    }
  }

  return true;
};
if (!allValuesSet(config)) {
  throw new Error(
    `Configuration values missing. Check that the environment is configured correctly.`
  );
}

export default config;

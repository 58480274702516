import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import FailedIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeStyleProps } from "../../types";
import classnames from "classnames";
import { compose } from "recompose";

export type FieldStatus = "normal" | "saving" | "saved" | "failed";

const stateIcons = {
  failed: FailedIcon,
  normal: `div`,
  saved: SuccessIcon,
  saving: CircularProgress,
};

const styles = (theme) => ({
  failed: {
    color: theme.statusPalette.error,
  },
  icon: {
    width: theme.dimensions.textFieldProgressSize,
  },
  saved: {
    color: theme.statusPalette.success,
  },
  saving: {},
});

export type EnhancedProps = {
  status: FieldStatus;
};

type Props = ThemeStyleProps & EnhancedProps;

class StatusIcon extends React.PureComponent<Props> {
  render() {
    const { classes, status, theme, ...rest } = this.props;
    const Icon = stateIcons[status];

    return (
      <Icon
        className={classnames(classes[status], classes.icon)}
        size={theme.dimensions.textFieldProgressSize}
        {...rest}
      />
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withStyles(styles, {
    withTheme: true,
  })
);

export default enhancer(StatusIcon);

import { get } from "lodash";

const statusMap = {
  PENDING: `Pending`,
  IN_PROGRESS: `In Progress`,
  COMPLETE: `Complete`,
};

const getStatusString = (status: string) => get(statusMap, status, status);
export default getStatusString;

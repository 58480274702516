import { compose } from "recompose";
import gql from "graphql-tag";
import withQuery from "../../generic/graphql/withQuery";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import { StyleProps } from "../../types";
import ProductDetailDialog, {
  productDetailsFragment,
} from "../../product/ProductDetailDialog";
import { ProductSearchCatalogProductDetails_getSelf_organisation_productFromSubscribedCatalogueViews_Product } from "./__generated__/ProductSearchCatalogProductDetails";

export const GET_CATALOG_PRODUCT_DETAILS = gql`
  query ProductSearchCatalogProductDetails(
    $organisationID: String!
    $productID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        productFromSubscribedCatalogueViews(id: $productID) {
          Product {
            id
            ...ProductDetailDialogProduct
          }
        }
      }
    }
  }
  ${productDetailsFragment}
`;

type EnhancedProps = {
  onClose: () => void;
  open: boolean;
  organisationID: string;
  productID?: string | null | undefined;
};

type Props = EnhancedProps &
  StyleProps & {
    product:
      | ProductSearchCatalogProductDetails_getSelf_organisation_productFromSubscribedCatalogueViews_Product
      | null
      | undefined;
    openSnackbar: OpenSnackbarFn;
  };

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withOpenSnackbar,
  withQuery(GET_CATALOG_PRODUCT_DETAILS, {
    dataPaths: {
      product: `getSelf.organisation.productFromSubscribedCatalogueViews.Product`,
    },
    errorMessage: `Failed to load product`,
    loadingProp: `loading`,
    options: ({ organisationID, productID }) => ({
      variables: {
        organisationID,
        productID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ productID }) => productID == null,
  })
);

export default enhancer(ProductDetailDialog);

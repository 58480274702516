import * as React from "react";
import gql from "graphql-tag";
import {
  EnhancedProps as NestedListQuerySelectFieldProps,
  withSelectList,
} from "../../generic/NestedListQuerySelectField";
import AutoSelectField from "./AutoSelectField";

export const GET_MARKETPLACE_LIST = gql`
  query ActiveMarketplaceSelectGetOrganisationMarketplaces(
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        activeIndustryList {
          id
          name
          activeMarketplaceList(organisationId: $organisationID) {
            id
            name
          }
        }
      }
    }
  }
`;

type EnhancedProps = NestedListQuerySelectFieldProps & {
  organisationID: string;
};

const MarketplaceSelect = withSelectList({
  innerDataPath: `activeMarketplaceList`,
  itemPluralLabel: `active marketplaces`,
  itemTitleLabel: "Active Marketplace",
  query: GET_MARKETPLACE_LIST,
  rootDataPath: `getSelf.organisation.activeIndustryList`,
  variables: ({ organisationID }) => ({ organisationID }),
})(AutoSelectField) as React.ComponentType<EnhancedProps>;

export default MarketplaceSelect;

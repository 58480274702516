import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import { StateDisplayPostcode } from "./__generated__/StateDisplayPostcode";
import TextInputWithState from "./form/TextInputWithState";
import { omit } from "lodash";
import {
  StateDisplayGetPostcode,
  StateDisplayGetPostcodeVariables,
} from "./__generated__/StateDisplayGetPostcode";

const EXPECTED_POSTCODE_LENGTH = 4;

export const stateDisplayFragments = {
  postcode: gql`
    fragment StateDisplayPostcode on Postcode {
      id
      state
    }
  `,
};

export const GET_POSTCODE = gql`
  query StateDisplayGetPostcode($postcodeString: String!, $country: String!) {
    postcode: getPostcode(
      postcode: $postcodeString
      country: { name: $country }
    ) {
      ...StateDisplayPostcode
    }
  }
  ${stateDisplayFragments.postcode}
`;

type EnhancedProps = {
  country: string;
  postcodeString: string;
  state?: string | null | undefined;
  fullWidth?: boolean;
};

type Props = EnhancedProps & {
  loading?: boolean;
  postcode?: StateDisplayPostcode;
};

/**
 * Queries and displays the state for a given country and postcode.
 * If state is provided, that is used instead.
 */
export class StateDisplay extends React.PureComponent<Props> {
  render() {
    const { loading, postcode, state, ...rest } = this.props;

    const loadedPostcode = !loading && postcode ? postcode.state : ``;

    return (
      <TextInputWithState
        disabled
        label="State"
        // @ts-ignore
        field={{ value: state === undefined ? loadedPostcode : state || `` }}
        state={loading ? `saving` : `normal`}
        {...omit(rest, [`country`, `postcodeString`])}
      />
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  graphql<
    EnhancedProps,
    StateDisplayGetPostcode,
    StateDisplayGetPostcodeVariables
  >(GET_POSTCODE, {
    options: ({ postcodeString, country }) => ({
      variables: {
        country,
        postcodeString,
      },
    }),
    // @ts-ignore
    props: ({ data: { postcode, loading } }) => ({
      loading,
      postcode,
    }),
    skip: ({ postcodeString, country, state }) =>
      state !== undefined ||
      !postcodeString ||
      !country ||
      postcodeString.trim().length < EXPECTED_POSTCODE_LENGTH,
  })
);

export default enhancer(StateDisplay);

import * as React from "react";

type Props = {
  inputRef: (ref: any) => any;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  pattern: RegExp;
};

/**
 * An input component that ignores changes that don't match a given pattern.
 * @param {Props} props Props
 * @returns {React.Node} element
 */
const RestrictedInput = ({ inputRef, onChange, pattern, ...other }: Props) => (
  <input
    ref={(ref) => inputRef(ref)}
    onChange={(event) => {
      if (pattern.test(event.target.value)) {
        onChange(event);
      }
    }}
    {...other}
  />
);

export default RestrictedInput;

import * as React from "react";

type Props<V> = {
  children?: React.ReactNode;
  onChange?: (value: V) => void;
  value: V;
};

/**
 * Simply calls the given onChange function when the value prop changes.
 */
class CallOnChange<V> extends React.Component<Props<V>> {
  componentDidUpdate({ value: prevValue }: Props<V>) {
    const { onChange, value } = this.props;
    if (onChange && prevValue !== value) {
      onChange(value);
    }
  }

  render() {
    const { children } = this.props;

    return children === undefined ? null : this.props.children;
  }
}

export default CallOnChange;

import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { StyleProps } from "../../types";
import AddDestinationToItemTable, {
  addDestinationToItemTableFragments,
} from "./AddDestinationToItemTable";
import { AddDestinationToItemLayoutAddress } from "./__generated__/AddDestinationToItemLayoutAddress";
import { UpdateOrderItemPartInput } from "../../../../__generated__/globalTypes";

export const addDestinationToItemLayoutFragments = {
  address: gql`
    fragment AddDestinationToItemLayoutAddress on Address {
      id
      ...AddDestinationToItemTableAddress
    }
    ${addDestinationToItemTableFragments.address}
  `,
};

const styles = () => ({
  content: {
    display: "flex",
    flexDirection: "column" as const,
  },
  table: {
    flex: 1,
  },
});

type Props = StyleProps & {
  addressList: AddDestinationToItemLayoutAddress[];
  organisationID: string;
  orderItemID: string | null | undefined;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onAddDestinationPressed: (
    updateOrderItemParts: UpdateOrderItemPartInput[]
  ) => Promise<void>;
};

class AddDestinationToItemLayout extends React.PureComponent<Props> {
  async handleAddDestination(addressID: string) {
    const { onAddDestinationPressed, organisationID, orderItemID } = this.props;

    // Create OrderItemPartialInput array (single input)
    if (!!orderItemID) {
      const updateOrderItemParts: UpdateOrderItemPartInput[] = [
        {
          organisation: { id: organisationID },
          orderItem: { id: orderItemID },
          destination: { id: addressID },
        },
      ];
      await onAddDestinationPressed(updateOrderItemParts);
    }
  }
  render() {
    const { classes, loading, addressList, open, onClose } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add Delivery Address</DialogTitle>

        <DialogContent className={classes.content}>
          <AddDestinationToItemTable
            addressList={addressList}
            className={classes.table}
            loading={loading}
            onRowPressed={async (addressID: string) => {
              await this.handleAddDestination(addressID);
              onClose();
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AddDestinationToItemLayout);

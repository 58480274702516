import * as React from "react";
import { QueryResult, graphql } from "react-apollo";
import gql from "graphql-tag";
import { FormikBag, Form, Formik } from "formik";
import Button from "@material-ui/core/Button";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import { CustomField } from "../../generic/form/index";
import FileInput from "../../generic/form/FileInput";
import { compose } from "recompose";
import getGraphqlException from "../../../../helpers/getGraphqlException";
import UploadSummaryLink from "../../generic/uploadSummary/UploadSummaryLink";
import { UploadSummary } from "../../generic/uploadSummary/UploadSummaryText";
import { SnackbarType } from "../../../../__generated__/globalTypes";
import { CloudUpload } from "@material-ui/icons";
import UploadFormSubmitContainer from "../../generic/uploads/UploadFormSubmitContainer";

export const UPLOAD_CATEGORIES = gql`
  mutation UploadCategoriesForMarketplace(
    $marketplaceID: String!
    $file: Upload!
  ) {
    createCategoriesForMarketplaceWithFile(
      marketplace: { id: $marketplaceID }
      file: $file
    ) {
      createdCount
      updatedCount
      errors
    }
  }
`;

export const GET_CATEGORIES = gql`
  query OrganisationCategoriesGetAvailableCategories($marketplaceID: String!) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      categoryList {
        id
        name
      }
    }
  }
`;

type Props = {
  openSnackbar: OpenSnackbarFn;
  marketplaceID: string;
  uploadCategories: (
    marketplaceID: string,
    file: any
  ) => Promise<QueryResult<any>>;
};

type State = {
  uploadSummary?: UploadSummary;
};

type Values = {
  categoriesFile: any;
};

export class CategoryUploadForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploadSummary: undefined,
    };
  }

  async _handleSubmit(
    values: Values,
    { resetForm, setSubmitting }: FormikBag<any, Values>
  ) {
    const { uploadCategories, openSnackbar, marketplaceID } = this.props;

    if (marketplaceID != null) {
      try {
        const successResult: QueryResult<any> = await uploadCategories(
          marketplaceID,
          values.categoriesFile
        );
        // Show snackbar on success
        openSnackbar(SnackbarType.success, `Successfully uploaded categories`);
        setSubmitting(false);
        resetForm();
        // Store info about success
        this.setState({
          uploadSummary: {
            success:
              successResult &&
              successResult.data &&
              successResult.data.createCategoriesForMarketplaceWithFile
                ? {
                    deleteCount:
                      successResult.data.createCategoriesForMarketplaceWithFile
                        .deletedCount,
                    updateCount:
                      successResult.data.createCategoriesForMarketplaceWithFile
                        .updatedCount,
                  }
                : undefined,
          },
        });
      } catch (error) {
        openSnackbar(SnackbarType.error, `Failed to upload categories`);
        setSubmitting(false);
        // Store info about failure
        const exception = getGraphqlException(
          error,
          [`createCategoriesForMarketplaceWithFile`],
          `BAD_USER_INPUT`
        );
        this.setState({
          uploadSummary:
            exception != null
              ? {
                  failure: {
                    badRowCount: exception.badRowCount || 0,
                    errors: exception.errors || [],
                    totalRowCount: exception.totalRowCount || 0,
                  },
                }
              : undefined,
        });
      }
    }
  }

  render() {
    const { marketplaceID } = this.props;
    return (
      // @ts-ignore
      <Formik onSubmit={this._handleSubmit.bind(this)}>
        {(formikBag) => {
          const buttonIsDisabled =
            formikBag.isSubmitting ||
            !formikBag.dirty ||
            !formikBag.values.categoriesFile ||
            marketplaceID == null;
          return (
            <Form>
              <CustomField
                fullWidth
                name="categoriesFile"
                component={FileInput}
                inputProps={{ accept: `.csv` }}
              />
              <UploadFormSubmitContainer>
                <Button
                  color="primary"
                  disabled={buttonIsDisabled}
                  startIcon={<CloudUpload />}
                  type="submit"
                  variant={"contained"}
                >
                  Upload
                </Button>
                <UploadSummaryLink
                  itemTitleText="Category"
                  itemPluralText="categories"
                  uploadSummary={this.state.uploadSummary}
                />
              </UploadFormSubmitContainer>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props>, any>(
  withOpenSnackbar as any,
  graphql(UPLOAD_CATEGORIES, {
    props: ({ mutate }) => ({
      uploadCategories: (marketplaceID: string, file: any) =>
        mutate({
          refetchQueries: () => [
            {
              query: GET_CATEGORIES,
              variables: {
                marketplaceID,
              },
            },
          ],
          variables: {
            file,
            marketplaceID,
          },
        }),
    }),
  })
);

export default enhancer(CategoryUploadForm);

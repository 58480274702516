let count = 0;

/**
 * Get an ID suitable for use in an optimistic response before actual data is loaded.
 * @return {string} ID string, unique in current environment.
 */
const getOptimisticID = (): string => {
  const id = `unknown_${count}`;
  ++count;

  return id;
};

export default getOptimisticID;

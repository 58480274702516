import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import * as H from "history";
import withQueryParams from "../../components/generic/withQueryParams";
import { compose } from "recompose";
import OrganisationAffiliationsTabs, {
  AffiliationType,
  tabs,
} from "../../components/organisation/affiliations/OrganisationAffiliationsTabs";
import OrganisationIndustryAffiliations from "../../components/organisation/affiliations/industry/OrganisationIndustryAffiliations";
import OrganisationMarketplaceAffiliations from "../../components/organisation/affiliations/marketplace/OrganisationMarketplaceAffiliations";
import OrganisationCategoryAffiliations from "../../components/organisation/affiliations/category/OrganisationCategoryAffiliations";
import OrganisationClassAffiliations from "../../components/organisation/affiliations/class/OrganisationClassAffiliations";
import { myNetworkTypeRoute } from "../index";
import { match } from "react-router-dom";

type QueryParams = {
  categoryID?: string;
  industryID?: string;
  marketplaceID?: string;
};

type Params = {
  affiliationType: string | undefined;
  organisationID: string | undefined;
};

type EnhancedProps = {
  history: H.History;
  location: H.Location;
  match: match<Params>;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (params: Partial<QueryParams>, basePath?: string) => void;
};

class Affiliations extends React.Component<Props> {
  componentDidMount() {
    this.ensureValidAffiliationType();
  }

  componentDidUpdate(prevProps: Props) {
    const { affiliationType } = this.props.match.params;

    if (
      (prevProps.match.params as Params).affiliationType !== affiliationType
    ) {
      this.ensureValidAffiliationType();
    }
  }

  updateAffiliationType(
    affiliationType: AffiliationType,
    selections?: Partial<QueryParams>,
    replace?: boolean
  ) {
    const {
      history,
      match,
      location: { search },
      setQueryParams,
    } = this.props;
    const { organisationID } = match.params;

    const basePath = myNetworkTypeRoute(organisationID || ``, affiliationType);

    if (selections) {
      setQueryParams(selections, basePath);
    } else if (replace) {
      history.replace(`${basePath}${search}`);
    } else {
      history.push(`${basePath}${search}`);
    }
  }

  ensureValidAffiliationType() {
    const { match } = this.props;
    const { affiliationType } = match.params;

    // @ts-ignore
    if (!tabs.includes(affiliationType)) {
      this.updateAffiliationType(tabs[0], undefined, true);
    }
  }

  render() {
    const {
      match,
      queryParams: { categoryID, industryID, marketplaceID },
      setQueryParams,
    } = this.props;
    const { affiliationType, organisationID } = match.params as Params;

    // @ts-ignore
    if (!tabs.includes(affiliationType)) {
      return null;
    }

    return (
      <MainPanel fullWidth title="My Network">
        <OrganisationAffiliationsTabs
          selectedTab={affiliationType as any}
          onSelectedTabChange={this.updateAffiliationType.bind(this)}
        />
        {organisationID &&
          ((affiliationType === `industry` && (
            <OrganisationIndustryAffiliations
              organisationID={organisationID}
              onPressMarketplaces={(industryID: string) =>
                this.updateAffiliationType(`marketplace`, { industryID })
              }
            />
          )) ||
            (affiliationType === `marketplace` && (
              <OrganisationMarketplaceAffiliations
                organisationID={organisationID}
                industryID={industryID}
                onIndustryIDChange={(industryID: string) =>
                  setQueryParams({ industryID })
                }
                onPressCategories={(marketplaceID: string) =>
                  this.updateAffiliationType(`category`, { marketplaceID })
                }
              />
            )) ||
            (affiliationType === `category` && (
              <OrganisationCategoryAffiliations
                organisationID={organisationID}
                industryID={industryID}
                marketplaceID={marketplaceID}
                onIndustryMarketplaceIDChange={({
                  industryID,
                  marketplaceID,
                }: {
                  industryID: string;
                  marketplaceID: string;
                }) =>
                  setQueryParams({
                    industryID,
                    marketplaceID,
                  })
                }
                onPressClasses={(categoryID: string) =>
                  this.updateAffiliationType(`class`, { categoryID })
                }
              />
            )) ||
            (affiliationType === `class` && (
              <OrganisationClassAffiliations
                organisationID={organisationID}
                industryID={industryID}
                marketplaceID={marketplaceID}
                categoryID={categoryID}
                onCategoryIDChange={(categoryID: string) =>
                  setQueryParams({ categoryID })
                }
                onIndustryMarketplaceIDChange={({
                  industryID,
                  marketplaceID,
                }: {
                  industryID: string;
                  marketplaceID: string;
                }) =>
                  setQueryParams({
                    industryID,
                    marketplaceID,
                  })
                }
              />
            )))}
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());
export default enhancer(Affiliations);

import * as React from "react";
import ItemListWithMenu from "../../generic/ItemListWithMenu";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../../../components/generic/CallOnMount";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../../components/generic/snackbar/withOpenSnackbar";
import { ServiceAreaListGetServiceAreaList } from "./__generated__/ServiceAreaListGetServiceAreaList";
import { compose } from "recompose";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const serviceAreaListFragments = {
  serviceArea: gql`
    fragment ServiceAreaListServiceArea on ServiceArea {
      name
    }
  `,
};

export const GET_SERVICE_AREAS = gql`
  query ServiceAreaListGetServiceAreaList {
    serviceAreaList: getServiceAreaList {
      id
      ...ServiceAreaListServiceArea
    }
  }
  ${serviceAreaListFragments.serviceArea}
`;

class GetServiceAreaListQuery extends Query<
  ServiceAreaListGetServiceAreaList,
  null
> {}

type EnhancedProps = {
  onPressEdit: (serviceAreaID: string) => void;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
};

/**
 * Component to query service areas and display results in a list.
 */
export class ServiceAreaList extends React.PureComponent<Props> {
  render() {
    const { onPressEdit, openSnackbar } = this.props;

    return (
      <GetServiceAreaListQuery query={GET_SERVICE_AREAS}>
        {({ data, loading, error }) => {
          const serviceAreas =
            data && !loading && !error && data.serviceAreaList
              ? data.serviceAreaList
              : null;
          const listItems = serviceAreas
            ? serviceAreas.map((serviceArea) => ({
                id: serviceArea.id,
                text: serviceArea.name || ``,
              }))
            : [];

          return (
            <React.Fragment>
              <ItemListWithMenu
                listProps={{ dense: true }}
                items={listItems}
                menuActions={[
                  {
                    perform: onPressEdit,
                    text: `Edit`,
                  },
                ]}
                loading={loading}
              />
              {(error || (!serviceAreas && !loading)) && (
                <CallOnMount
                  callback={() =>
                    openSnackbar(
                      SnackbarType.error,
                      `Failed to load service areas`
                    )
                  }
                />
              )}
            </React.Fragment>
          );
        }}
      </GetServiceAreaListQuery>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withOpenSnackbar);
export default enhancer(ServiceAreaList);

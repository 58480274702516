import * as React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Redirect } from "react-router";
import CallOnMount from "../generic/CallOnMount";
import {
  Login as LoginMutationType,
  LoginVariables,
} from "./__generated__/Login";

export const LOGIN = gql`
  mutation Login($referrer: String) {
    login(referrer: $referrer) @client {
      loginReturnPath
    }
  }
`;

class LoginMutation extends Mutation<LoginMutationType, LoginVariables> {}

type Props = {
  referrer: string | null | undefined;
};

const PerformLogin = ({ referrer }: Props) => (
  <LoginMutation mutation={LOGIN}>
    {(login, { data: loginData, loading: loginLoading }) => {
      const returnPath =
        (loginData && loginData.login && loginData.login.loginReturnPath) ||
        null;

      return (
        <CallOnMount callback={() => login({ variables: { referrer } })}>
          {!loginLoading && returnPath && <Redirect to={returnPath} />}
        </CallOnMount>
      );
    }}
  </LoginMutation>
);

export default PerformLogin;

import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Item } from "../../generic/ItemList";
import ItemListWithHeaders, {
  EnhancedProps as ItemListWithHeadersProps,
} from "../../generic/ItemListWithHeaders";

export type Role = "buyer" | "seller";

export type ItemWithRoles = Item & {
  roles: {
    [Role: string]: boolean;
  };
};

export type Props = ItemListWithHeadersProps & {
  items: ItemWithRoles[];
  onRoleChanged?: (itemID: string, role: Role, enabled: boolean) => void;
  readOnly?: boolean;
};

const ROLE_CONTROL_WIDTH = 48;

/**
 * HOC to add organisation role controls to an item list.
 * @param {React.ComponentType} ItemListComponent ItemList or compatible component.
 * @returns {React.ComponentType} New list component.
 */
export const withRoles = (
  ItemListComponent: React.ComponentType<ItemListWithHeadersProps>
) => {
  class ItemListWithRoles extends React.PureComponent<Props> {
    getSecondaryActions() {
      const { onRoleChanged, readOnly, secondaryActions } = this.props;

      return [
        ...(secondaryActions || []),
        {
          header: `Buyer`,
          render: ({ item }: { item: ItemWithRoles }) => (
            <Checkbox
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              disabled={readOnly}
              checked={item.roles.buyer}
              onClick={(event) =>
                onRoleChanged &&
                // @ts-ignore
                onRoleChanged(item.id, `buyer`, event.target.checked)
              }
            />
          ),
          width: ROLE_CONTROL_WIDTH,
        },
        {
          header: `Seller`,
          render: ({ item }: { item: ItemWithRoles }) => (
            <Checkbox
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              disabled={readOnly}
              checked={item.roles.seller}
              onClick={(event) =>
                onRoleChanged &&
                // @ts-ignore
                onRoleChanged(item.id, `seller`, event.target.checked)
              }
            />
          ),
          width: ROLE_CONTROL_WIDTH,
        },
      ];
    }

    render() {
      const { items, ...itemListProps } = this.props;

      return (
        <ItemListComponent
          {...itemListProps}
          items={items as any}
          secondaryActions={this.getSecondaryActions() as any}
        />
      );
    }
  }

  return ItemListWithRoles;
};

export default withRoles(ItemListWithHeaders);

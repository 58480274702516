/*
 * Resolvers handling local UI state.
 */
import { Resolver } from "../apollo/types";
import gql from "graphql-tag";
import { GetSelection } from "./__generated__/GetSelection";

export type Selection = {
  activeMarketplaceID: string | null | undefined;
};

export const STORAGE_KEY = `irp:selection`;

/**
 * Default selections.
 *
 * The cache should not be initialised with this data, the first query needs to be
 * handled by the getAuth query resolver.
 */
export const defaults = {
  getSelection: {
    __typename: `Selection`,
    activeMarketplaceID: null,
  } as Selection,
};

/**
 * Persist the selection in local storage.
 * @param {Selection} selection Current selection
 * @return {void}
 */
const persist = (selection: Selection): void => {
  const value = btoa(JSON.stringify(selection));

  localStorage.setItem(STORAGE_KEY, value);
};

/**
 * Restore persisted selection.
 * @return {Selection} Persisted selection or the defaults if not available.
 */
const restore = (): Selection => {
  const encoded = localStorage.getItem(STORAGE_KEY);
  if (encoded != null) {
    try {
      return JSON.parse(atob(encoded));
    } catch (error) {
      // Invalid persisted data
    }
  }

  return defaults.getSelection;
};

export const resolvers = {
  Mutation: {
    setActiveMarketplaceID: ((obj, { id }, { cache }) => {
      const query = gql`
        query GetSelection {
          getSelection @client {
            activeMarketplaceID
          }
        }
      `;
      const previous: GetSelection = cache.readQuery({ query }) as any;
      const getSelection = {
        ...previous.getSelection,
        activeMarketplaceID: id,
      };
      cache.writeData({ data: { getSelection } });
      persist(getSelection);

      return getSelection;
    }) as Resolver<{ id: string | null | undefined }, Selection>,
  },

  Query: {
    getSelection: (() => restore()) as Resolver<any, Selection>,
  },
};

export default {
  resolvers,
};

import gql from "graphql-tag";
import { GetUserStatusUser } from "./__generated__/GetUserStatusUser";

type User = GetUserStatusUser & {
  organisationList: Array<any>;
};

export type UserStatus = "Approved" | "Pending" | "New";
export const approvedUserRole = `user`;

export const getUserStatusFragment = gql`
  fragment GetUserStatusUser on User {
    fullName
    telephoneNumber
    organisationList {
      id
      name
    }
  }
`;

export const isProfileCompleted = (user: User) =>
  Boolean(user.fullName && user.telephoneNumber) &&
  user.organisationList.length > 0;

const getUserStatus = (
  user: User,
  roles: ReadonlyArray<string | null | undefined>
): UserStatus => {
  if (roles.includes(approvedUserRole)) {
    return `Approved`;
  } else if (isProfileCompleted(user)) {
    return `Pending`;
  } else {
    return `New`;
  }
};

export default getUserStatus;

import React from "react";

import MainTitle from "../app/MainTitle";
import { OperationHoursDataAddress } from "./__generated__/OperationHoursDataAddress";

type Props = {
  address?: OperationHoursDataAddress;
};

const AddressName = ({ address }: Props) => (
  <MainTitle>
    {address ? `${address.addressLine1}, ${address.city}` : `Address`}
  </MainTitle>
);

export default AddressName;

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import {
  WithSelectionGetSelection,
  WithSelectionGetSelection_getSelection as WithSelectionSelection,
} from "./__generated__/WithSelectionGetSelection";
import { compose } from "recompose";

export type Selection = Partial<WithSelectionSelection>;
export type ProvidedProps = {
  selection: Selection;
};

const GET_SELECTION = gql`
  query WithSelectionGetSelection {
    getSelection @client {
      activeMarketplaceID
    }
  }
`;

const withSelection = compose<Partial<ProvidedProps>, any>(
  graphql<any, WithSelectionGetSelection>(GET_SELECTION, {
    // @ts-ignore
    props: ({ data }) => ({
      selection: data && data.getSelection ? data.getSelection : {},
    }),
  })
);

export default withSelection;

import * as React from "react";
import DataTable from "./index";
import { EnhancedProps as DataTableProps } from "./BaseDataTable";
import { withFilterMenu } from "./WithFilterMenu";
import { get } from "lodash";
import { compose } from "recompose";

export type Props<Row> = DataTableProps<Row> & {
  // Define columns that can be filtered on, and optionally specific values that can be chosen for the filter
  filterOptions: {
    [key: string]: {
      label: string;
      values: any[];
    };
  };
};

type State = {
  // The currently selected filter value for each column
  filter: {
    [key: string]: any;
  };
};

/**
 * Function to create HOC to handle filter state and filter the data of DataTable.
 * @returns HOC to wrap DataTable.
 */
export const withFilteredData = <Row,>() => (
  DataTableComponent: React.ComponentType<any>
) => {
  class DataTableWithFiltering extends React.PureComponent<Props<Row>, State> {
    constructor(props: Props<Row>) {
      super(props);

      this.state = {
        filter: {},
      };
    }

    _filterPredicate(row: Row) {
      const { filter } = this.state;

      for (const columnID of Object.keys(filter)) {
        // If a filter is defined for the column, and it doesn't match row
        if (
          filter[columnID] != null &&
          get(row, columnID) !== filter[columnID]
        ) {
          return false;
        }
      }

      return true;
    }

    _handleFilterChange(columnID: string, value?: any) {
      this.setState({
        filter: {
          ...this.state.filter,
          [columnID]: value,
        },
      });
    }

    _handleResetFilters() {
      this.setState({ filter: {} });
    }

    render() {
      const { data, ...dataTableProps } = this.props;
      const { filter } = this.state;
      const filteredData = data.filter(this._filterPredicate.bind(this));

      return (
        <DataTableComponent
          {...dataTableProps}
          data={filteredData}
          filter={filter}
          onFilterChange={this._handleFilterChange.bind(this)}
          onResetFilters={this._handleResetFilters.bind(this)}
        />
      );
    }
  }

  return DataTableWithFiltering;
};

export const withFiltering = <Row,>() =>
  compose<any, Props<Row>>(withFilteredData(), withFilterMenu());

export default withFiltering()(DataTable);

import * as React from "react";
import MenuList from "./MenuList";
import MenuItem from "./MenuItem";
import RequireRoles from "../../auth/RequireRoles";
import Divider from "@material-ui/core/Divider";
import NestedMenuItem from "./NestedMenuItem";
import UsersIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import MarketplacesIcon from "@material-ui/icons/AttachMoney";
import CategoryIcon from "@material-ui/icons/Category";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ShareIcon from "@material-ui/icons/Share";
import MapIcon from "@material-ui/icons/Map";
import {
  addressesRoute,
  adminCategoriesRoute,
  adminIndustriesRoute,
  adminMarketplacesRoute,
  adminServiceAreasRoute,
  adminUsersRoute,
  buyStepRoute,
  cataloguesRoute,
  catalogueViewsRoute,
  customersRoute,
  freightRatesRoute,
  helpGuidesRoute,
  helpVideosRoute,
  inboxRoute,
  myNetworkRoute,
  organisationDashboardRoute,
  organisationDetailsRoute,
  purchasesRoute,
  ROUTE_PARAMETERS,
  salesRoute,
  vendorsRoute,
  warehousesRoute,
} from "../../../routes";
import {
  AccountBox,
  Assignment,
  AttachMoneySharp,
  BallotOutlined,
  BusinessCenterSharp,
  ContactMail,
  DashboardSharp,
  Help,
  HomeWork,
  LocalLibrary,
  LocalOfferSharp,
  LocalShipping,
  NotificationsActive,
  Receipt,
  Shop,
  ShoppingCartSharp,
  StorefrontSharp,
  VideoLibrary,
  ViewListOutlined,
} from "@material-ui/icons";
import IconWithBadgeData from "./IconWithBadgeData";
import { NotificationType } from "../../../../helpers/notifications";

const menuHeadings = {
  organisation: `organisation`,
  products: `products`,
  trading: `trading`,
  logistics: `logistics`,
  help: `help`,
};

type Props = {
  onClose: () => void;
  organisationID?: string;
  organisationRole?: string;
  markNotificationAsRead: (notification: NotificationType) => Promise<void>;
};

type State = {
  selectedMenu: string | null | undefined;
};

class Menu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMenu: undefined,
    };
  }

  setSelectMenu(selectedMenu: string): void {
    this.setState((prev) =>
      prev.selectedMenu === selectedMenu
        ? { selectedMenu: undefined }
        : { selectedMenu }
    );
  }

  render(): JSX.Element {
    const { onClose, organisationID, markNotificationAsRead } = this.props;
    const { selectedMenu } = this.state;

    return (
      <React.Fragment>
        <MenuList>
          {organisationID != null && (
            <RequireRoles roles={[`user`]}>
              <NestedMenuItem
                openPath={[
                  organisationDashboardRoute(organisationID),
                  myNetworkRoute(organisationID),
                  organisationDetailsRoute(organisationID),
                  customersRoute(organisationID),
                  vendorsRoute(organisationID),
                  inboxRoute(organisationID),
                ]}
                text="Organisation"
                Icon={BusinessIcon}
                open={selectedMenu === menuHeadings.organisation}
                onClick={() => this.setSelectMenu(menuHeadings.organisation)}
              >
                <MenuItem
                  nested
                  linkPath={organisationDashboardRoute(organisationID)}
                  text="Dashboard"
                  Icon={DashboardSharp}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={myNetworkRoute(organisationID)}
                  text="My Network"
                  Icon={ShareIcon}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={organisationDetailsRoute(organisationID)}
                  text="Details"
                  Icon={AccountBox}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={customersRoute(organisationID)}
                  text="Customers"
                  Icon={BusinessCenterSharp}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={vendorsRoute(organisationID)}
                  text="Vendors"
                  Icon={StorefrontSharp}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={inboxRoute(organisationID)}
                  text="Inbox"
                  Icon={NotificationsActive}
                  onClick={onClose}
                />
              </NestedMenuItem>

              <NestedMenuItem
                openPath={[
                  cataloguesRoute(organisationID),
                  catalogueViewsRoute(organisationID),
                ]}
                text="Products"
                Icon={Assignment}
                open={selectedMenu === menuHeadings.products}
                onClick={() => this.setSelectMenu(menuHeadings.products)}
              >
                <MenuItem
                  nested
                  linkPath={cataloguesRoute(organisationID)}
                  text="Catalogue"
                  Icon={ViewListOutlined}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={catalogueViewsRoute(organisationID)}
                  text="Prices"
                  Icon={LocalOfferSharp}
                  onClick={onClose}
                />
              </NestedMenuItem>

              <NestedMenuItem
                openPath={[
                  buyStepRoute(organisationID, ROUTE_PARAMETERS.STEP),
                  purchasesRoute(organisationID),
                  salesRoute(organisationID),
                ]}
                text="Trading"
                Icon={() => (
                  <IconWithBadgeData
                    notificationTypes={[
                      NotificationType.SUPPLIER_SHIPPING_BATCH_STATUS_CHANGE,
                      NotificationType.BUYER_SHIPPING_BATCH_STATUS_CHANGE,
                    ]}
                    Icon={Shop}
                  />
                )}
                open={selectedMenu === menuHeadings.trading}
                onClick={() => this.setSelectMenu(menuHeadings.trading)}
              >
                <MenuItem
                  nested
                  linkPath={buyStepRoute(organisationID, ROUTE_PARAMETERS.STEP)}
                  text="Buy"
                  Icon={ShoppingCartSharp}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={purchasesRoute(organisationID)}
                  text="Purchases"
                  Icon={() => (
                    <IconWithBadgeData
                      notificationTypes={[
                        NotificationType.BUYER_SHIPPING_BATCH_STATUS_CHANGE,
                      ]}
                      Icon={Receipt}
                    />
                  )}
                  onClick={async () => {
                    await onClose();
                    await markNotificationAsRead(
                      NotificationType.BUYER_SHIPPING_BATCH_STATUS_CHANGE
                    );
                  }}
                />
                <MenuItem
                  nested
                  linkPath={salesRoute(organisationID)}
                  text="Sales"
                  Icon={() => (
                    <IconWithBadgeData
                      notificationTypes={[
                        NotificationType.SUPPLIER_SHIPPING_BATCH_STATUS_CHANGE,
                      ]}
                      Icon={AttachMoneySharp}
                    />
                  )}
                  onClick={async () => {
                    onClose();
                    await markNotificationAsRead(
                      NotificationType.SUPPLIER_SHIPPING_BATCH_STATUS_CHANGE
                    );
                  }}
                />
              </NestedMenuItem>

              <NestedMenuItem
                openPath={[
                  addressesRoute(organisationID),
                  warehousesRoute(organisationID),
                  freightRatesRoute(organisationID),
                ]}
                text="Logistics"
                Icon={LocalShipping}
                open={selectedMenu === menuHeadings.logistics}
                onClick={() => this.setSelectMenu(menuHeadings.logistics)}
              >
                <MenuItem
                  nested
                  linkPath={addressesRoute(organisationID)}
                  text="Delivery Addresses"
                  Icon={ContactMail}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={warehousesRoute(organisationID)}
                  text="Warehouses"
                  Icon={HomeWork}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={freightRatesRoute(organisationID)}
                  text="Freight Rates"
                  Icon={BallotOutlined}
                  onClick={onClose}
                />
              </NestedMenuItem>

              <NestedMenuItem
                openPath={[helpGuidesRoute(), helpVideosRoute()]}
                text="Help"
                Icon={Help}
                open={selectedMenu === menuHeadings.help}
                onClick={() => this.setSelectMenu(menuHeadings.help)}
              >
                <MenuItem
                  nested
                  linkPath={helpGuidesRoute()}
                  text="User Guides"
                  Icon={LocalLibrary}
                  onClick={onClose}
                />
                <MenuItem
                  nested
                  linkPath={helpVideosRoute()}
                  text="User Videos"
                  Icon={VideoLibrary}
                  onClick={onClose}
                />
              </NestedMenuItem>
            </RequireRoles>
          )}
        </MenuList>

        <RequireRoles roles={[`admin`]}>
          <Divider />
          <MenuList heading="Admin">
            <MenuItem
              linkPath={adminUsersRoute()}
              text="Users"
              Icon={() => (
                <IconWithBadgeData
                  notificationTypes={[
                    NotificationType.ADMIN_NEW_USER_FOR_APPROVAL,
                  ]}
                  Icon={UsersIcon}
                />
              )}
              onClick={async () => {
                await onClose();
                await markNotificationAsRead(
                  NotificationType.ADMIN_NEW_USER_FOR_APPROVAL
                );
              }}
            />
            <MenuItem
              linkPath={adminIndustriesRoute()}
              text="Industry"
              Icon={BusinessCenterIcon}
              onClick={onClose}
            />
            <MenuItem
              linkPath={adminMarketplacesRoute()}
              text="Marketplace"
              Icon={MarketplacesIcon}
              onClick={onClose}
            />
            <MenuItem
              linkPath={adminCategoriesRoute()}
              text="Categories"
              Icon={CategoryIcon}
              onClick={onClose}
            />
            <MenuItem
              linkPath={adminServiceAreasRoute()}
              text="Service Areas"
              Icon={MapIcon}
              onClick={onClose}
            />
          </MenuList>
        </RequireRoles>
      </React.Fragment>
    );
  }
}

export default Menu;

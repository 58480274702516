/*
 * Resolvers handling local UI state.
 */
import { Resolver } from "../apollo/types";
import gql from "graphql-tag";
import { GetSnackbar } from "./__generated__/GetSnackbar";
import { SnackbarType } from "../__generated__/globalTypes";

export type Snackbar = {
  message: string;
  open: boolean;
  type: SnackbarType;
};

/**
 * Default UI state.
 */
export const defaults = {
  getSnackbar: {
    __typename: `Snackbar`,
    message: ``,
    open: false,
    type: `info`,
  } as Snackbar,
};

export const resolvers = {
  Mutation: {
    closeSnackbar: ((obj, vars, { cache }) => {
      const query = gql`
        query GetSnackbar {
          getSnackbar @client {
            message
            type
          }
        }
      `;
      const previous: GetSnackbar = cache.readQuery({ query }) as any;
      const getSnackbar = {
        ...previous.getSnackbar,
        open: false,
      };
      cache.writeData({ data: { getSnackbar } });

      return getSnackbar;
    }) as Resolver<null, Snackbar>,

    openSnackbar: ((obj, { message, type }, { cache }) => {
      const getSnackbar = {
        ...defaults.getSnackbar,
        message,
        open: true,
        type,
      };
      cache.writeData({ data: { getSnackbar } });

      return getSnackbar;
    }) as Resolver<{ message: string; type: SnackbarType }, Snackbar>,
  },
};

export default {
  defaults,
  resolvers,
};

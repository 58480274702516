import withQuery from "../generic/graphql/withQuery";
import { compose } from "recompose";
import RateCardTable from "./RateCardTable";
import gql from "graphql-tag";
import { RateCardTableGetRateCard_self_organisation_rateCard_rates as RateCardRate } from "./__generated__/RateCardTableGetRateCard";

export const RateCardFragment = {
  rateCardRate: gql`
    fragment RateCardTableRateCardRate on RateCardRate {
      id
      service
      origin {
        name
      }
      destination {
        name
      }
      leadTime
      minimumPrice
      basicPrice
      range1Price
      range2Price
      range3Price
      range4Price
      range5Price
    }
  `,
};

export const GET_RATE_CARD = gql`
  query RateCardTableGetRateCard(
    $organisationID: String!
    $rateCardID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        rateCard(id: $rateCardID) {
          id
          name
          rates {
            ...RateCardTableRateCardRate
          }
        }
      }
    }
  }
  ${RateCardFragment.rateCardRate}
`;

type EnhancedProps = {
  className?: string;
  organisationID?: string;
  rateCardID?: string;
};

type Props = EnhancedProps & {
  rateCardRates: ReadonlyArray<RateCardRate>;
  loadingRateCard: boolean;
};

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withQuery(GET_RATE_CARD, {
    dataPaths: {
      rateCardRates: `self.organisation.rateCard.rates`,
    },
    errorMessage: `Failed to load rate card`,
    loadingProp: `loadingRateCard`,
    skip: ({ rateCardID }) => rateCardID == null,
  })
);

export default enhancer(RateCardTable);

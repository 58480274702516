import * as React from "react";
import WarehouseList from "./WarehouseList";
import WarehouseUploadForm from "./UploadForm";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";
import Typography from "@material-ui/core/Typography";
import { SPACING_PAPER } from "../../../helpers/constants";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(SPACING_PAPER),
    marginTop: theme.margins.paper,
    marginBottom: theme.dimensions.snackbarHeight * 2,
  },
  container: {
    display: `flex`,
    flexDirection: `column` as const,
    alignSelf: `stretch`,
    flex: 1,
  },
});

type Props = StyleProps & {
  onPressEdit: (warehouseID: string) => void;
  onPressEditInventory: (warehouseID: string) => void;
  onPressEditOperatingTimes: (warehouseID: string) => void;
  onPressEditServiceAreas: (warehouseID: string) => void;
  onPressView: (warehouseID: string) => void;
  organisationID: string;
};

class OverviewLayout extends React.Component<Props> {
  render() {
    const {
      classes,
      onPressEdit,
      onPressEditInventory,
      onPressEditOperatingTimes,
      onPressEditServiceAreas,
      onPressView,
      organisationID,
    } = this.props;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <WarehouseList
            organisationID={organisationID}
            onPressEdit={onPressEdit}
            onPressEditInventory={onPressEditInventory}
            onPressEditOperatingTimes={onPressEditOperatingTimes}
            onPressEditServiceAreas={onPressEditServiceAreas}
            onPressView={onPressView}
          />

          <Paper className={classes.paper}>
            <Typography variant="caption">
              Upload Warehouse Stock Levels
            </Typography>
            <WarehouseUploadForm organisationID={organisationID} />
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OverviewLayout);

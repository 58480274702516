import * as React from "react";
import RateCardSelect from "./RateCardSelect";
import RateCardTableData from "./RateCardTableData";
import SubscribedOrganisation from "./subscriptions/SubscribedOrganisationData";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { StyleProps } from "../types";
import { Edit } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
  table: {
    alignSelf: `stretch`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxHeight: `40vh`,
  },
});

type Props = StyleProps & {
  rateCardID?: string;
  organisationID: string;
  onRateCardIDChange: (catalogueViewID?: string) => void;
  onPressEdit: (catalogueViewID: string) => void;
};

class RateCardLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      organisationID,
      rateCardID,
      onPressEdit,
      onRateCardIDChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <RateCardSelect
          organisationID={organisationID}
          selectedID={rateCardID}
          onSelectedIDChange={onRateCardIDChange}
        />

        <RateCardTableData
          className={classes.table}
          organisationID={organisationID}
          rateCardID={rateCardID}
        />

        <Button
          color={"primary"}
          variant={"outlined"}
          disabled={rateCardID == null}
          onClick={() => onPressEdit(rateCardID)}
          startIcon={<Edit />}
        >
          Edit
        </Button>

        <SubscribedOrganisation
          organisationID={organisationID}
          rateCardID={rateCardID}
        />
      </div>
    );
  }
}

export default withStyles(styles)(RateCardLayout);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../../types";
import CurationLayout from "../../../generic/curation/CurationLayout";
import IndustrySelect from "../IndustrySelect";
import ItemListWithHeaders from "../../../generic/ItemListWithHeaders";
import {
  EnhancedProps as ItemListWithSelectionProps,
  withSelection,
} from "../../../generic/ItemListWithSelection";
import ItemListWithMenu from "../../../generic/ItemListWithMenu";
import { OrganisationMarketplace as Organisation } from "./__generated__/OrganisationMarketplace";

const ItemListWithSelection: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithHeaders as any
);
const ActiveList: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithMenu as any
);

const styles = (theme) => ({
  select: {
    marginBottom: theme.spacing(1),
  },
});

type Props = StyleProps & {
  activeItems?: Organisation[];
  activeItemsLoading?: boolean;
  availableItems?: Organisation[];
  availableItemsLoading?: boolean;
  industryID?: string;
  onActivatePressed: () => Promise<void>;
  onIndustryIDChange: (id: string) => void;
  onPressCategories: (marketplaceID: string) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  organisationID: string;
  selectedAvailableItems: string[];
  onDeactivatePressed: () => Promise<void>;
  deactivateEnabled?: boolean;
};

class OrganisationMarketplaceLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      industryID,
      onIndustryIDChange,
      onPressCategories,
      organisationID,
      ...curationLayoutProps
    } = this.props;

    return (
      <React.Fragment>
        <IndustrySelect
          className={classes.select}
          organisationID={organisationID}
          selectedID={industryID}
          onSelectedIDChange={onIndustryIDChange}
        />
        <CurationLayout
          activateEnabled
          AvailableListComponent={ItemListWithSelection}
          ActiveListComponent={ActiveList}
          activeListProps={{
            emptyMessage:
              industryID == null
                ? `Select an industry to edit marketplace memberships`
                : `There are no active marketplace memberships in this industry`,
            mainHeader: `Active Marketplace Memberships`,
            menuActions: [
              {
                perform: onPressCategories,
                text: `Categories`,
              },
            ],
          }}
          availableListProps={{
            emptyMessage:
              industryID == null
                ? `Select an industry to view available marketplaces`
                : `There are no more marketplaces available in this industry`,
            mainHeader: `Available Marketplaces`,
          }}
          itemName="marketplace"
          itemNamePlural="marketplaces"
          deactivateEnabled
          {...curationLayoutProps}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OrganisationMarketplaceLayout);

import ShoppingCartData from "../../../components/trading/shoppingCart/Data";
import React from "react";

type Props = {
  organisationID: string;
  onNext: () => void;
  onBack: () => void;
};

export const ReviewRoute = ({ organisationID, onBack, onNext }: Props) => (
  <ShoppingCartData
    organisationID={organisationID}
    onBack={onBack}
    onNext={onNext}
  />
);

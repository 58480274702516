import React from "react";
import {
  EmailAddress,
  InviteUserByEmailDialog,
} from "../../../generic/InviteUserByEmailDialog";

export const INVITE_NEW_USER_TO_CATALOGUE_VIEW_TITLE =
  "Invite new organisation";
export const INVITE_NEW_USER_TO_CATALOGUE_VIEW_MESSAGE =
  "Invite a new organisation to begin trading. When they respond and join, they will gain access to this product catalogue.";

type Props = {
  open: boolean;
  onClose: () => Promise<void> | void;
  onSubmit: (email: string) => Promise<void> | void;
};

export const InviteNewUserToCatalogueViewLayout = ({
  open,
  onClose,
  onSubmit,
}: Props): React.ReactElement => {
  const handleClose = async (email?: EmailAddress) => {
    if (email != null) {
      onSubmit(email.email);
    }
    onClose();
  };

  return (
    <InviteUserByEmailDialog
      open={open}
      onClose={handleClose}
      title={INVITE_NEW_USER_TO_CATALOGUE_VIEW_TITLE}
      text={INVITE_NEW_USER_TO_CATALOGUE_VIEW_MESSAGE}
    />
  );
};

import gql from "graphql-tag";
import { compose } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import OrderTable, { orderTableFragments } from "./OrderTable";
import { DEFAULT_POLL_INTERVAL } from "../../../../helpers/constants";

export const GET_ORDERS = gql`
  query OrderTableGetOrder(
    $organisationID: String!
    $itemsPerPage: Int!
    $itemsOffset: Int!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        purchasedOrderList(first: $itemsPerPage, offset: $itemsOffset) {
          id
          ...OrderTableOrder
        }
      }
    }
  }
  ${orderTableFragments.order}
`;

type EnhancedProps = {
  organisationID: string;
  onPressOrder: (orderID: string) => void;
  itemsPerPage: number;
  pageOffset: number;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const enhancer = compose<any, EnhancedProps>(
  withQuery(GET_ORDERS, {
    dataPaths: {
      orders: `getSelf.organisation.purchasedOrderList`,
    },
    errorMessage: `Failed to load orders`,
    loadingProp: `loading`,
    options: ({ organisationID, itemsPerPage, pageOffset }) => ({
      variables: {
        organisationID,
        itemsPerPage,
        itemsOffset: pageOffset * itemsPerPage,
      },
      fetchPolicy: `network-only`,
      pollInterval: DEFAULT_POLL_INTERVAL,
    }),
    skip: ({ organisationID }) => organisationID == null,
  })
);
export default enhancer(OrderTable);

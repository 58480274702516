import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Close, Unsubscribe } from "@material-ui/icons";

type Props = {
  onClose: () => void;
  onUnsubscribedPressed: (reassign: boolean) => void;
  open: boolean;
};

class RemoveSubscriptionDialog extends React.Component<Props> {
  render() {
    const { onClose, onUnsubscribedPressed, open } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Unpublish Selected</DialogTitle>
        <DialogActions>
          <Button onClick={onClose} startIcon={<Close />}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              onUnsubscribedPressed(false);
              onClose();
            }}
            startIcon={<Unsubscribe />}
          >
            Unpublish
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RemoveSubscriptionDialog;

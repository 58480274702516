import { get, omit } from "lodash";
import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";

type Options = {
  itemDataAccessPath: string; // Path within data from update mutation to access the new item
  listDataAccessPath: string; // Path within data from list query to access the list to mutate
  listItemFromData?: (arg0: any) => any; // Optional function mapping mutation data to object properties to update
  listItemPredicate: (item: any) => boolean; // Predicate used to find the item within the list
  listQuery: any; // Query document for query with result containing the list to update
  listQueryVariables?: any; // Variables to pass to list query, update skipped if not defined
};

/**
 * Create an update function that will update properties of an item in a list in Apollo cache when an update mutation is performed.
 * This is only needed in cases where an object with no unique ID is being updated (it needs to be identified by where is is queried from).
 * @param {any} options Configuration for the query to update.
 * @return {void}
 */
const updateMutationUpdate = (options: Options) => (
  store: InMemoryCache,
  result: FetchResult
) => {
  if (options.listQueryVariables == null) {
    return;
  }
  try {
    // Read the current list of objects
    const listQuery = {
      query: options.listQuery,
      variables: options.listQueryVariables,
    };
    const data = store.readQuery(listQuery);
    const list: Array<any> = get(data, options.listDataAccessPath);
    // Get the item to be updated
    const item = list.find(options.listItemPredicate);
    // Get the new properties to update
    const resultData = get(result.data, options.itemDataAccessPath);
    const newProperties =
      resultData != null && options.listItemFromData
        ? options.listItemFromData(resultData)
        : resultData;
    if (item && result.data) {
      // Add the updated values (leaving __typename unchanged)
      Object.assign(item, omit(newProperties, `__typename`));

      // Write the updated list back to the cache
      store.writeQuery({
        ...listQuery,
        data,
      });
    }
  } catch (error) {
    // Leave cache unchanged
  }
};

export default updateMutationUpdate;

import * as React from "react";
import ConfirmDialog from "../../generic/ConfirmDialog";
import { compose, withPropsOnChange } from "recompose";

type Props = {
  // ID of category to be deleted
  categoryID: string | null | undefined;
  name?: string;
  onAccept: (categoryID: string) => void;
  onClose: () => void;
};

class ConfirmDeleteCategory extends React.PureComponent<Props> {
  render() {
    const { categoryID, name, onAccept, onClose } = this.props;

    return (
      <ConfirmDialog
        severe
        open={categoryID != null}
        text={
          <React.Fragment>
            Do you really want to delete the category
            <strong>{name ? ` ${name}` : ``}</strong>?
          </React.Fragment>
        }
        title="Delete Category"
        onAccept={() => categoryID != null && onAccept(categoryID)}
        onClose={onClose}
      />
    );
  }
}

export default compose<Props, Props>(
  withPropsOnChange(
    // Update name only when categoryID is set
    (_prevProps, { categoryID }) => categoryID != null,
    ({ name }) => ({ name })
  )
)(ConfirmDeleteCategory);

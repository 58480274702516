import gql from "graphql-tag";
import { AddressTableAddress as Address } from "./__generated__/AddressTableAddress";
import { compose } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import AddressTable, { addressTableFragments } from "./AddressTable";
import withConfirmDeleteAddress from "./withConfirmDeleteAddress";

export const GET_ADDRESSES = gql`
  query AddressListGetAddressList($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        addressList {
          id
          ...AddressTableAddress
        }
      }
    }
  }
  ${addressTableFragments.address}
`;

type EnhancedProps = {
  organisationID: string;
  onPressEdit: (addressID: string) => void;
  onPressEditOperatingTimes: (addressID: string) => void;
};

type Props = EnhancedProps & {
  addresses: Address[] | null;
  loading: boolean;
  onPressDelete: (addressID: string) => void;
};

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withQuery(GET_ADDRESSES, {
    dataPaths: {
      addresses: `getSelf.organisation.addressList`,
    },
    errorMessage: `Failed to load addresses`,
    loadingProp: `loading`,
    options: ({ organisationID }) => ({
      variables: { organisationID },
    }),
  }),
  withConfirmDeleteAddress({
    listQuery: GET_ADDRESSES,
  })
);
export default enhancer(AddressTable);

import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../../../components/generic/CallOnMount";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../../components/generic/snackbar/withOpenSnackbar";
import { compose, withState } from "recompose";
import ConfirmDeleteClass from "./ConfirmDeleteClass";
import getOptimisticID from "../../../../helpers/getOptimisticID";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import {
  ClassCurationGetCategory,
  ClassCurationGetCategory_category_classList as ClassCurationClass,
  ClassCurationGetCategoryVariables,
} from "./__generated__/ClassCurationGetCategory";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const classCurationFragments = {
  class: gql`
    fragment ClassCurationClass on Class {
      id
      name
    }
  `,
};

export const GET_CATEGORY = gql`
  query ClassCurationGetCategory($categoryID: String!) {
    category: getCategory(id: $categoryID) {
      id
      classList {
        ...ClassCurationClass
      }
    }
  }
  ${classCurationFragments.class}
`;

class GetCategoryQuery extends Query<
  ClassCurationGetCategory,
  ClassCurationGetCategoryVariables
> {}

const GET_CLASS = gql`
  query ClassCurationGetClass($id: String!) {
    class: getClass(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_CLASS = gql`
  mutation ClassCurationDeleteClass($id: String!) {
    deleteClass(id: $id) {
      id
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation ClassCurationUpdateClass($id: String!, $name: String!) {
    updateClass(id: $id, values: { name: $name }) {
      ...ClassCurationClass
    }
  }
  ${classCurationFragments.class}
`;

export const CREATE_CLASS = gql`
  mutation ClassCurationCreateClass($name: String!, $categoryID: String!) {
    createClass(values: { name: $name }, category: { id: $categoryID }) {
      ...ClassCurationClass
    }
  }
  ${classCurationFragments.class}
`;

// Props provided to the child function
export type ChildProps = {
  classList?: ClassCurationClass[];
  classListLoading: boolean;
  editingClassID: string | null | undefined;
  onClassChanged: (classID: string, name: string) => void;
  onClassCreated: (name: string) => void;
  onPressAdd: () => void;
  onPressDelete: (classID: string) => void;
  setEditingClassID: (classID: string | null | undefined) => void;
};

type EnhancedProps = {
  categoryID?: string;
  children: (props: ChildProps) => React.ReactElement;
};

type Props = EnhancedProps & {
  createMutation: (arg0: { categoryID: string; name: string }) => void;
  deleteMutation: (classID: string) => void;
  deletingClassID?: string;
  editingClassID: string | null | undefined;
  item: ClassCurationClass | null | undefined;
  openSnackbar: OpenSnackbarFn;
  setDeletingClassID: (classID?: string) => void;
  setEditingClassID: (classID: string | null | undefined) => void;
  updateMutation: (arg0: { id: string; name: string }) => void;
};

/**
 * Component to query the classes in a category and provide editing, passed as props to child component function.
 */
export class ClassCuration extends React.PureComponent<Props> {
  async handleDeleteClass(classID: string) {
    const {
      deleteMutation,
      item,
      openSnackbar,
      setDeletingClassID,
    } = this.props;
    const name = item ? item.name : ``;

    setDeletingClassID(undefined);
    try {
      await deleteMutation(classID);
      openSnackbar(
        SnackbarType.success,
        `Successfully deleted class "${name}"`
      );
    } catch (error) {
      openSnackbar(SnackbarType.error, `Failed to delete class "${name}"`);
    }
  }

  async handleUpdateClass(classID: string, name: string) {
    const { updateMutation, openSnackbar } = this.props;

    try {
      await updateMutation({
        id: classID,
        name,
      });
      openSnackbar(SnackbarType.success, `Successfully saved class "${name}"`);
    } catch (error) {
      openSnackbar(SnackbarType.error, `Failed to save class "${name}"`);
    }
  }

  async handleCreateClass(name: string) {
    const { categoryID, createMutation, openSnackbar } = this.props;
    if (categoryID == null) {
      return;
    }

    try {
      await createMutation({
        categoryID,
        name,
      });
      openSnackbar(
        SnackbarType.success,
        `Successfully created class "${name}"`
      );
    } catch (error) {
      openSnackbar(SnackbarType.error, `Failed to create class "${name}"`);
    }
  }

  render() {
    const {
      categoryID,
      children,
      deletingClassID,
      editingClassID,
      item,
      openSnackbar,
      setDeletingClassID,
      setEditingClassID,
    } = this.props;

    return (
      <GetCategoryQuery
        query={GET_CATEGORY}
        skip={categoryID == null}
        variables={{ categoryID: categoryID || `` }}
      >
        {({ data, loading, error }) => {
          const category =
            categoryID && data && !loading && !error ? data.category : null;

          return (
            <React.Fragment>
              {children({
                classList: category ? category.classList : undefined,
                classListLoading: categoryID != null && loading,
                editingClassID,
                onClassChanged: this.handleUpdateClass.bind(this),
                onClassCreated: this.handleCreateClass.bind(this),
                onPressAdd: () => setEditingClassID(null),
                onPressDelete: setDeletingClassID,
                setEditingClassID,
              })}
              {error && (
                <CallOnMount
                  callback={() =>
                    openSnackbar(SnackbarType.error, `Failed to load classes`)
                  }
                />
              )}
              <ConfirmDeleteClass
                classID={deletingClassID}
                // @ts-ignore
                name={item && item.name}
                onAccept={this.handleDeleteClass.bind(this)}
                onClose={() => setDeletingClassID(undefined)}
              />
            </React.Fragment>
          );
        }}
      </GetCategoryQuery>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withOpenSnackbar,
  withState(`deletingClassID`, `setDeletingClassID`, undefined),
  withState(`editingClassID`, `setEditingClassID`, undefined),
  withCreateUpdateQueries({
    createItemAccessPath: `createClass`,
    createMutation: CREATE_CLASS,
    createOptimisticResponse: ({ name }) => ({
      createClass: {
        __typename: `Class`,
        id: getOptimisticID(),
        name,
      },
    }),
    deleteMutation: DELETE_CLASS,
    deleteOptimisticResponse: (id) => ({
      deleteClass: {
        __typename: `Class`,
        id,
      },
    }),
    getItemAccessPath: `class`,
    getQuery: GET_CLASS,
    getQueryVariables: ({ deletingClassID }) =>
      deletingClassID != null ? { id: deletingClassID } : undefined,
    listAccessPath: `category.classList`,
    listQuery: GET_CATEGORY,
    listQueryVariables: ({ categoryID }) =>
      categoryID ? { categoryID } : undefined,
    updateMutation: UPDATE_CLASS,
    updateOptimisticResponse: ({ id, name }) => ({
      updateClass: {
        __typename: `Class`,
        id,
        name,
      },
    }),
  })
);
export default enhancer(ClassCuration);

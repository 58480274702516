import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { StyleProps } from "../../types";
import CurationLayout from "../../generic/curation/CurationLayout";
import getListItems from "../../generic/getListItems";
import ItemListWithHeaders, {
  EnhancedProps as ItemListWithHeadersProps,
} from "../../generic/ItemListWithHeaders";
import {
  EnhancedProps as ItemListWithSelectionProps,
  withSelection,
} from "../../generic/ItemListWithSelection";
import { withFilter } from "../../generic/ItemListWithFilter";
import IncludedProductsForm, {
  UpdateCatalogueViewIncludesProductFn,
} from "./IncludedProductsForm";
import { compose } from "recompose";
import withConfirmDialog from "../../generic/withConfirmDialog";
import EditForm from "./EditForm";
import { CatalogueViewEditProduct as Product } from "./__generated__/CatalogueViewEditProduct";
import { CatalogueViewEditGetCatalogueViewProducts_getSelf_organisation_catalogueView_includesProductList as IncludesProduct } from "./__generated__/CatalogueViewEditGetCatalogueViewProducts";
import { CatalogueViewEditGetCatalogueViewSubscriptions_getSelf_organisation_catalogueViewList_subscribedOrganisationList as SubscribedOrganisations } from "./__generated__/CatalogueViewEditGetCatalogueViewSubscriptions";
import { CatalogueViewEditFormCatalogueView } from "./__generated__/CatalogueViewEditFormCatalogueView";
import { Delete } from "@material-ui/icons";

const AvailableItemsList = compose(
  withSelection as any,
  (withFilter as any)()
)(ItemListWithHeaders) as React.ComponentType<
  ItemListWithHeadersProps & ItemListWithSelectionProps
>;

const styles = (theme) => ({
  deleteButton: {
    alignSelf: `flex-end`,
    marginTop: theme.spacing(1),
  },
  includedProductsForm: {
    display: `flex`,
    flex: 1,
    maxHeight: `65vh`,
  },
  productTable: {
    flex: 1,
  },
  root: {
    alignItems: `stretch`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
  subscribedOrganisations: {
    flex: 1,
    marginBottom: theme.spacing(1),
  },
});

export type EnhancedProps = {
  activeItems?: IncludesProduct[];
  activeItemsLoading?: boolean;
  availableItems?: Product[];
  availableItemsLoading?: boolean;
  catalogueView: CatalogueViewEditFormCatalogueView | null | undefined;
  deleteCatalogueView: (id: string) => Promise<void>;
  onActivatePressed: () => Promise<void>;
  onDismiss: () => void;
  onSelectedActiveItemsChange: (selectIDs: string[]) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  organisationID?: string;
  selectedActiveItems: string[];
  selectedAvailableItems: string[];
  updateCatalogueViewIncludesProduct: UpdateCatalogueViewIncludesProductFn;
};

type Props = StyleProps &
  EnhancedProps & {
    handleOpenConfirm: () => void;
    subscribedOrganisations: SubscribedOrganisations[];
    subscribedOrganisationsLoading: boolean;
  };

class OrganisationIndustryLayout extends React.PureComponent<Props> {
  render() {
    const {
      catalogueView,
      classes,
      handleOpenConfirm,
      updateCatalogueViewIncludesProduct,
      ...curationLayoutProps
    } = this.props;

    return (
      <>
        <EditForm catalogueView={catalogueView} />
        <CurationLayout
          activateEnabled
          deactivateEnabled
          AvailableListComponent={AvailableItemsList}
          ActiveListComponent={IncludedProductsForm}
          availableListProps={{
            emptyMessage: `There are no available products`,
            mainHeader: `Products available`,
          }}
          activeListHeader={
            <Typography gutterBottom variant="caption">
              Products in view
            </Typography>
          }
          activeListProps={{
            className: classes.includedProductsForm,
            tableProps: {
              className: classes.productTable,
            },
            updateCatalogueViewIncludesProduct,
          }}
          itemName="product"
          itemNamePlural="products"
          {...curationLayoutProps}
          activeItemsListMap={(item) => item}
          availableItemsListMap={(item: {
            description: string;
            id: string;
          }) => ({
            id: item.id,
            text: item.description,
          })}
        />
        <Button
          className={classes.deleteButton}
          color="secondary"
          onClick={handleOpenConfirm}
          startIcon={<Delete />}
        >
          Delete
        </Button>
      </>
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(
  withStyles(styles),
  withConfirmDialog({
    dialogProps: ({
      catalogueView,
      subscribedOrganisations,
      subscribedOrganisationsLoading,
      classes,
    }) => ({
      children: (
        <ItemListWithHeaders
          className={classes.subscribedOrganisations}
          emptyMessage="No subscribed Organisations"
          items={getListItems(subscribedOrganisations, { default: [] })}
          loading={subscribedOrganisationsLoading}
          mainHeader="The following Organisations will be reassigned to the default catalogue view:"
        />
      ),
      severe: true,
      text: (
        <React.Fragment>
          Do you really want to delete the catalogue view
          <strong>{catalogueView ? ` ${catalogueView.name}` : ``}</strong>?
        </React.Fragment>
      ),
      title: `Delete Catalogue View`,
    }),
    // @ts-ignore
    errorMessage: () => `Failed to delete catalogue view`,
    getConfirmHandler: ({
      catalogueView,
      deleteCatalogueView,
      onDismiss,
    }) => () => {
      onDismiss();

      return deleteCatalogueView(catalogueView && catalogueView.id);
    },
    // @ts-ignore
    successMessage: () => `Successfully deleted catalogue view`,
  })
);
export default enhancer(OrganisationIndustryLayout);

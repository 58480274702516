import * as React from "react";
import SelectField from "../generic/form/SelectField";

export type Country = "Australia" | "New Zealand";

type EnhancedProps = {
  error: string | null | undefined;
  field: {
    onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    value: Country | null | undefined;
  };
  touched?: boolean;
};

type Props = EnhancedProps;

const countries = [`Australia`, `New Zealand`];

class CountrySelect extends React.PureComponent<Props> {
  render() {
    const { field, error, ...props } = this.props;

    return (
      <SelectField
        {...props}
        {...field}
        label="Country"
        placeholder="Select Country"
        options={countries.map((country) => ({
          label: country,
          value: country,
        }))}
        error={error != null}
        helperText={error || ` `}
      />
    );
  }
}

export default CountrySelect;

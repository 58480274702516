import * as React from "react";
import ItemListWithMenu from "../../generic/ItemListWithMenu";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import CallOnMount from "../../../components/generic/CallOnMount";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../../components/generic/snackbar/withOpenSnackbar";
import { IndustryListGetIndustryList } from "./__generated__/IndustryListGetIndustryList";
import { compose } from "recompose";
import { SnackbarType } from "../../../../__generated__/globalTypes";
import UploadForm from "./UploadForm";

export const industryListFragments = {
  industry: gql`
    fragment IndustryListIndustry on Industry {
      id
      name
    }
  `,
};

export const GET_INDUSTRIES = gql`
  query IndustryListGetIndustryList {
    industries: getIndustryList {
      ...IndustryListIndustry
    }
  }
  ${industryListFragments.industry}
`;
class GetIndustryListQuery extends Query<IndustryListGetIndustryList, null> {}

type EnhancedProps = {
  onPressEdit: (industryID: string) => void;
  onPressMarketplaces: (industryID: string) => void;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
};

/**
 * Component to query industries and display results in a list.
 */
export class IndustryList extends React.PureComponent<Props> {
  render() {
    const { onPressEdit, onPressMarketplaces, openSnackbar } = this.props;

    return (
      <>
        <GetIndustryListQuery query={GET_INDUSTRIES}>
          {({ data, loading, error }) => {
            const industries =
              data && !loading && !error ? data.industries : null;
            const listItems = industries
              ? industries.map((industry) => ({
                  id: industry.id,
                  text: industry.name,
                }))
              : [];

            return (
              <React.Fragment>
                <ItemListWithMenu
                  listProps={{ dense: true }}
                  items={listItems}
                  menuActions={[
                    {
                      perform: onPressEdit,
                      text: `Edit`,
                    },
                    {
                      perform: onPressMarketplaces,
                      text: `Marketplaces`,
                    },
                  ]}
                  loading={loading}
                />
                {error && (
                  <CallOnMount
                    callback={() =>
                      openSnackbar(
                        SnackbarType.error,
                        `Failed to load industries.`
                      )
                    }
                  />
                )}
              </React.Fragment>
            );
          }}
        </GetIndustryListQuery>
        <UploadForm />
      </>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withOpenSnackbar);
export default enhancer(IndustryList);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import ClassLayout from "../../components/admin/class/ClassLayout";
import withQueryParams from "../../components/generic/withQueryParams";
import { compose } from "recompose";

type QueryParams = {
  categoryID?: string;
  marketplaceID?: string;
};

type EnhancedProps = {
  history: H.History;
  location: Location;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (arg0: Partial<QueryParams>) => void;
};

class Categories extends React.PureComponent<Props> {
  render() {
    const {
      queryParams: { categoryID, marketplaceID },
      setQueryParams,
    } = this.props;

    return (
      <MainPanel title="Classes">
        <ClassLayout
          categoryID={categoryID}
          marketplaceID={marketplaceID}
          onCategoryIDChange={(categoryID) => {
            setQueryParams({ categoryID });
          }}
          onMarketplaceIDChange={(marketplaceID) => {
            setQueryParams({
              categoryID: undefined,
              marketplaceID,
            });
          }}
        />
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());

export default enhancer(Categories);

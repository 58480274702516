import * as React from "react";
import gql from "graphql-tag";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import createItemListFromArray from "../../../../helpers/createItemListFromArray";
import { ConfirmedOrderTableShippingBatch as ShippingBatchType } from "./__generated__/ConfirmedOrderTableShippingBatch";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { formatUTCDate } from "../../../../helpers/formatDate";

export const confirmedOrderTableFragments = {
  shippingBatch: gql`
    fragment ConfirmedOrderTableShippingBatch on ShippingBatch {
      id
      supplier {
        id
        name
      }
      orderItemPartList {
        orderItem {
          id
          description
        }
      }
      origin {
        id
        name
      }
      destination {
        id
        ...GetAddressStringAddress
      }
      carrierRate {
        id
        carrier {
          id
          name
        }
      }
      cost {
        orderItemParts
        orderItemPartsTax
        shipping
        total
      }
      preferredDeliveryDate
    }
    ${addressFragment}
  `,
};

const columns: Column<ShippingBatchType>[] = [
  {
    Header: `Supplier`,
    id: `supplier.name`,
    compact: true,
  },
  {
    Cell: ({ row }) =>
      createItemListFromArray(row.orderItemPartList, `orderItem.description`),
    Header: `Product(s)`,
    id: `orderItemPartList[0].orderItem.id`,
  },
  {
    Header: `Origin`,
    id: `origin.name`,
  },
  {
    Header: `Delivery Address`,
    id: `destination.id`,
    Cell: ({ row }) => getAddressString(row.destination),
  },
  {
    Header: `Carrier`,
    id: `carrierRate.carrier.name`,
    compact: true,
  },
  {
    Header: `Item(s) Cost`,
    id: `cost.orderItemParts`,
    compact: true,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Item(s) Tax`,
    id: `cost.orderItemPartsTax`,
    compact: true,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Shipping`,
    id: `cost.shipping`,
    compact: true,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `Total`,
    id: `cost.total`,
    compact: true,
    numeric: true,
    Cell: ({ value }) => displayCurrency(value),
  },
  {
    Header: `ETA`,
    id: `preferredDeliveryDate`,
    compact: true,
    Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
  },
];

type Props = {
  className?: string;
  loading: boolean;
  shippingBatchList: ShippingBatchType[];
};

class ConfirmedOrderTable extends React.PureComponent<Props> {
  render() {
    const { className, loading, shippingBatchList } = this.props;

    return (
      <DataTable
        className={className}
        loading={loading}
        columns={columns}
        data={shippingBatchList}
        idAccessor="id"
        emptyMessage={`Order not found`}
      />
    );
  }
}

export default ConfirmedOrderTable;

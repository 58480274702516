import * as React from "react";
import gql from "graphql-tag";
import Checkbox from "@material-ui/core/Checkbox";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { CustomField, TextInputWithState } from "../../generic/form";
import { WarehouseInventoryTableStockedProduct } from "./__generated__/WarehouseInventoryTableStockedProduct";
import { displayLeadTime } from "../../../../helpers/displayLeadTime";

export const warehouseInventoryTableFragments = {
  warehouseProductStockLevel: gql`
    fragment WarehouseInventoryTableStockedProduct on _WarehouseStockLevelList {
      Product {
        id
        sku
        description
        leadTime
      }
      quantity
      orderToPromise
    }
  `,
};

type Props = {
  className?: string;
  warehouseInventory?: Array<WarehouseInventoryTableStockedProduct>;
  getFieldProps: (productID: string, name: string) => any;
};

class WarehouseInventoryTable extends React.Component<Props> {
  render() {
    const { className, warehouseInventory, getFieldProps } = this.props;

    const columns: Column<WarehouseInventoryTableStockedProduct>[] = [
      {
        Header: `SKU`,
        id: `Product.sku`,
      },
      {
        Header: `Product`,
        id: `Product.description`,
      },
      {
        Header: `Lead Time`,
        Cell: ({ value }) => displayLeadTime(value),
        id: `Product.leadTime`,
      },
      {
        Header: `Order To Promise`,
        id: `orderToPromise`,
        Cell: ({ value }: { value: boolean }): React.ReactNode => (
          <Checkbox disabled checked={value} />
        ),
        cellProps: {
          padding: "checkbox",
        },
      },
      {
        Header: `Quantity`,
        id: `quantity`,
        Cell: ({ id }) => (
          <CustomField
            fullWidth
            component={TextInputWithState}
            type="number"
            inputProps={{
              min: 0,
            }}
            {...getFieldProps(id, `quantity`)}
          />
        ),
      },
    ];

    return (
      <DataTable
        className={className}
        columns={columns}
        data={warehouseInventory || []}
        emptyMessage={`No Products Stocked`}
        idAccessor={`Product.id`}
      />
    );
  }
}

export default WarehouseInventoryTable;

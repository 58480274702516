import gql from "graphql-tag";
import SubscribedOrganisationLayout, {
  EnhancedProps as LayoutProps,
} from "./SubscribedOrganisationLayout";
import withSelection from "../../generic/withSelection";
import withCurationQueries from "../../generic/curation/withCurationQueries";
import withActivateHandler from "../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../generic/curation/withDeactivateHandler";
import { compose, withState } from "recompose";

// Define fragments to be used by queries
const subscribedOrganisationDataFragments = {
  rateCardDetails: gql`
    fragment SubscribedOrganisationRateCardDetails on RateCard {
      id
      name
    }
  `,
  partialOrganisation: gql`
    fragment SubscribedOrganisationRateCardPartialOrganisation on PartialOrganisation {
      id
      name
    }
  `,
};

// Define queries to be used by withCurationQueries
const GET_PUBLIC_ORGANISATION_LIST = gql`
  query SubscribedOrganisationDataRateCardGetPublicOrganisationList {
    publicOrganisationList: getPublicOrganisationList {
      ...SubscribedOrganisationRateCardPartialOrganisation
    }
  }
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

export const GET_SUBSCRIBED_ORGANISATIONS_TO_RATE_CARD = gql`
  query SubscribedOrganisationDataGetSubscribedOrganisationsToRateCard(
    $rateCardID: String!
    $organisationID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        rateCard(id: $rateCardID) {
          ...SubscribedOrganisationRateCardDetails
          subscribedOrganisationList {
            ...SubscribedOrganisationRateCardPartialOrganisation
          }
        }
      }
    }
  }
  ${subscribedOrganisationDataFragments.rateCardDetails}
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

const ADD_RATE_CARD_TO_ORGANISATION = gql`
  mutation SubscribedOrganisationDataSubscribeOrganisationToRateCard(
    $rateCardID: String!
    $organisationID: String!
  ) {
    addRateCardToOrganisation(
      from: { id: $rateCardID }
      to: { id: $organisationID }
    ) {
      ...SubscribedOrganisationRateCardPartialOrganisation
    }
  }
  ${subscribedOrganisationDataFragments.partialOrganisation}
`;

const REMOVE_RATE_CARD_TO_ORGANISATION = gql`
  mutation SubscribedOrganisationDataRemoveOrganisationsSubscriptionToRateCard(
    $rateCardID: String!
    $organisationID: String!
  ) {
    removeRateCardToOrganisation(
      from: { id: $rateCardID }
      to: { id: $organisationID }
    )
  }
`;

export type EnhancedProps = {
  rateCardID: string | null | undefined;
  organisationID: string;
};

const enhancer = compose<LayoutProps, EnhancedProps>(
  withSelection,
  withCurationQueries({
    activate: {
      mutation: ADD_RATE_CARD_TO_ORGANISATION,
      optimisticResponse: {
        addRateCardToOrganisation: {
          __typename: `PartialOrganisation`,
          id: `id`,
          name: `name`,
        },
      },
      variables: (organisationID, { rateCardID }) =>
        rateCardID != null
          ? {
              rateCardID,
              organisationID,
            }
          : undefined,
    },
    activeItems: {
      dataPath: `self.organisation.rateCard.subscribedOrganisationList`,
      fragment: subscribedOrganisationDataFragments.partialOrganisation,
      query: GET_SUBSCRIBED_ORGANISATIONS_TO_RATE_CARD,
      variables: ({ rateCardID, organisationID }) =>
        rateCardID != null
          ? {
              rateCardID,
              organisationID,
            }
          : undefined,
    },
    activeListName: `rate card's publish list`,
    availableItems: {
      dataPath: `publicOrganisationList`,
      query: GET_PUBLIC_ORGANISATION_LIST,
      variables: () => ({}),
    },
    deactivate: {
      mutation: REMOVE_RATE_CARD_TO_ORGANISATION,
      optimisticResponse: {
        removeRateCardToOrganisation: `SUCCESS`,
      },
      variables: (organisationID, { rateCardID }) =>
        rateCardID != null
          ? {
              rateCardID,
              organisationID,
            }
          : undefined,
    },
    itemLabel: `selected organisation`,
    itemLabelPlural: `selected organisations`,
    itemTypeName: `PartialOrganisation`,
  }),
  withActivateHandler(
    `subscribingOrganisationID`,
    `setSubscribingOrganisationID`
  ),
  withDeactivateHandler(
    `unsubscribingOrganisationID`,
    `setUnsubscribingOrganisationID`
  ),
  withState(
    `addSubscriptionsDialogOpen`,
    `setAddSubscriptionsDialogOpen`,
    false
  ),
  withState(
    `confirmUnsubscribeDialogOpen`,
    `setConfirmUnsubscribeDialogOpen`,
    false
  )
);

export default enhancer(SubscribedOrganisationLayout);

import * as React from "react";
import { Column, SortDirection } from "./BaseDataTable";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

interface Props<Row> {
  column: Column<Row>;
  onSortPress?: (event: React.SyntheticEvent, columnID: string) => void;
  sortColumn?: string;
  sortDirection?: SortDirection;
}

/**
 * Table cell for a column header.
 */
class ColumnHeader<Row> extends React.PureComponent<Props<Row>> {
  render(): React.ReactElement {
    const { column, onSortPress, sortColumn, sortDirection } = this.props;

    return (
      <TableCell
        align={column.numeric ? `right` : `left`}
        {...column.headerCellProps}
        style={column.compact ? { width: 1 } : undefined}
      >
        {!column.disableSortable ? (
          <Tooltip
            title="Sort"
            placement={column.numeric ? `bottom-end` : `bottom-start`}
          >
            <TableSortLabel
              active={column.id === sortColumn}
              direction={sortDirection}
              onClick={
                onSortPress
                  ? (event) => onSortPress(event, column.id)
                  : undefined
              }
            >
              {column.Header}
            </TableSortLabel>
          </Tooltip>
        ) : (
          column.Header
        )}
      </TableCell>
    );
  }
}

export default ColumnHeader;

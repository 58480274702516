import * as React from "react";
import { graphql } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import { Form, Formik, FormikBag } from "formik";
import Button from "@material-ui/core/Button";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { CustomField } from "../generic/form/index";
import FileInput from "../generic/form/FileInput";
import { compose } from "recompose";
import getGraphqlException from "../../../helpers/getGraphqlException";
import UploadFormSubmitContainer from "./UploadFormSubmitContainer";
import UploadSummaryLink from "../generic/uploadSummary/UploadSummaryLink";
import { UploadSummary } from "../generic/uploadSummary/UploadSummaryText";
import { WarehouseUploadFormUploadWarehouseStockLevels } from "./__generated__/WarehouseUploadFormUploadWarehouseStockLevels";
import { SnackbarType } from "../../../__generated__/globalTypes";
import { CloudUpload } from "@material-ui/icons";

export const UPLOAD_WAREHOUSE_PRODUCT_STOCK_LEVELS = gql`
  mutation WarehouseUploadFormUploadWarehouseStockLevels(
    $organisationID: String!
    $file: Upload!
  ) {
    updateOwnWarehouseProductStockLevel(
      organisation: { id: $organisationID }
      warehouseProductStockLevelFile: $file
    ) {
      updateCount
    }
  }
`;

export type EnhancedProps = {
  organisationID: string | null | undefined;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
  uploadWarehouseProductStockLevels: (
    organisationID: string,
    file: any
  ) => Promise<ApolloQueryResult<any>>; //TODO: fix this return type
};

type State = {
  uploadSummary?: UploadSummary;
};

type Values = {
  warehouseProductStockLevelFile: any;
};

export class WarehouseProductStockLevelUploadForm extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      uploadSummary: undefined,
    };
  }

  async _handleSubmit(
    values: Values,
    { resetForm, setSubmitting }: FormikBag<Values, Props>
  ) {
    const {
      uploadWarehouseProductStockLevels,
      openSnackbar,
      organisationID,
    } = this.props;

    if (organisationID != null) {
      try {
        const successResult: ApolloQueryResult<WarehouseUploadFormUploadWarehouseStockLevels> = await uploadWarehouseProductStockLevels(
          organisationID,
          values.warehouseProductStockLevelFile
        );
        // Show snackbar on success
        openSnackbar(
          SnackbarType.success,
          `Successfully uploaded warehouse product stock levels`
        );
        setSubmitting(false);
        resetForm();
        // Store info about success
        this.setState({
          uploadSummary: {
            success:
              successResult &&
              successResult.data &&
              successResult.data.updateOwnWarehouseProductStockLevel
                ? {
                    updateCount:
                      successResult.data.updateOwnWarehouseProductStockLevel
                        .updateCount,
                  }
                : undefined,
          },
        });
      } catch (error) {
        openSnackbar(
          SnackbarType.error,
          `Failed to upload warehouse product stock levels`
        );
        setSubmitting(false);
        // Store info about failure
        const exception = getGraphqlException(
          error,
          [`updateOwnWarehouseProductStockLevel`],
          `BAD_USER_INPUT`
        );
        this.setState({
          uploadSummary:
            exception != null
              ? {
                  failure: {
                    badRowCount: exception.badRowCount || 0,
                    errors: exception.errors || [],
                    totalRowCount: exception.totalRowCount || 0,
                  },
                }
              : undefined,
        });
      }
    }
  }

  render() {
    return (
      // @ts-ignore
      <Formik onSubmit={this._handleSubmit.bind(this)}>
        {(formikBag) => (
          <Form>
            <CustomField
              fullWidth
              name="warehouseProductStockLevelFile"
              component={FileInput}
              inputProps={{ accept: `.csv` }}
            />
            <UploadFormSubmitContainer>
              <Button
                color="primary"
                disabled={
                  formikBag.isSubmitting ||
                  !formikBag.dirty ||
                  !formikBag.values.warehouseProductStockLevelFile
                }
                startIcon={<CloudUpload />}
                type="submit"
                variant={"contained"}
              >
                Upload
              </Button>
              <UploadSummaryLink
                itemTitleText="Warehouse Product Stock Level Upload"
                itemPluralText="stock levels"
                uploadSummary={this.state.uploadSummary}
              />
            </UploadFormSubmitContainer>
          </Form>
        )}
      </Formik>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withOpenSnackbar as any,
  graphql(UPLOAD_WAREHOUSE_PRODUCT_STOCK_LEVELS, {
    props: ({ mutate }) => ({
      uploadWarehouseProductStockLevels: (organisationID: string, file: any) =>
        mutate({
          variables: {
            file,
            organisationID,
          },
        }),
    }),
  })
);

export default enhancer(WarehouseProductStockLevelUploadForm);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import CategoryCuration from "../../components/admin/category/CategoryCuration";
import CategoryForm from "../../components/admin/category/CategoryForm";
import { Link, Route } from "react-router-dom";
import FormDialog from "../../components/generic/FormDialog";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import withQueryParams from "../../components/generic/withQueryParams";
import { compose } from "recompose";
import {
  adminCategoriesFormRoute,
  adminCategoriesRoute,
  adminClassesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type QueryParams = {
  industryID?: string;
  marketplaceID?: string;
};

type EnhancedProps = {
  history: H.History;
  location: Location;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (arg0: Partial<QueryParams>) => void;
};

class Categories extends React.PureComponent<Props> {
  render() {
    const {
      history,
      location,
      queryParams: { industryID, marketplaceID },
      setQueryParams,
    } = this.props;

    return (
      <MainPanel title="Categories">
        <CategoryCuration
          industryID={industryID}
          marketplaceID={marketplaceID}
          onIndustryIDChange={(industryID) => {
            setQueryParams({
              industryID,
              marketplaceID: undefined,
            });
          }}
          onMarketplaceIDChange={(marketplaceID) => {
            setQueryParams({ marketplaceID });
          }}
          onPressEdit={(categoryID) =>
            history.push(adminCategoriesFormRoute(categoryID) + location.search)
          }
          onPressClasses={(categoryID) =>
            history.push(
              `${adminClassesRoute()}?marketplaceID=${
                marketplaceID || ``
              }&categoryID=${categoryID}`
            )
          }
        />
        <Route
          path={adminCategoriesFormRoute(`${ROUTE_PARAMETERS.CATEGORY_ID}?`)}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.categoryID != null
                    ? formMatch.params.categoryID
                    : undefined,
              }}
              onClose={() =>
                history.push(adminCategoriesRoute() + location.search)
              }
            >
              {({ handleClose, ...rest }) => (
                <CategoryForm
                  {...rest}
                  marketplaceID={marketplaceID}
                  onDismiss={handleClose}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          disabled={marketplaceID == null}
          // @ts-ignore
          to={adminCategoriesFormRoute() + location.search}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());

export default enhancer(Categories);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import ServiceAreaList from "../../components/admin/serviceArea/ServiceAreaList";
import { Link } from "react-router-dom";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import { adminServiceAreasFormRoute } from "../index";

type Props = {
  history: H.History;
};

class ServiceArea extends React.PureComponent<Props> {
  render() {
    const { history } = this.props;

    return (
      <MainPanel title="Service Areas">
        <ServiceAreaList
          onPressEdit={(serviceAreaID) =>
            history.push(adminServiceAreasFormRoute(serviceAreaID))
          }
        />
        <FloatingActionButton
          component={Link}
          // @ts-ignore
          to={adminServiceAreasFormRoute()}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

export default ServiceArea;

import * as React from "react";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { StyleProps } from "../types";
import ItemList, { EnhancedProps as ItemListProps, Item } from "./ItemList";
import { compose, withState } from "recompose";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) => ({
  search: {
    flexShrink: 0,
    marginBottom: theme.spacing(1),
  },
});

export type EnhancedProps = ItemListProps & {
  autoFocus?: boolean;
};

type Props = EnhancedProps &
  StyleProps & {
    filterText: string;
    onFilterTextChange: (filterText: string) => void;
  };

type Options = {
  filterPredicate?: (filterText: string) => (item: Item) => boolean;
};

const defaultFilterPredicate = (filterText: string) => (item: Item) => {
  const text = item.text.toLowerCase();

  return !filterText || text.includes(filterText.toLowerCase());
};

/**
 * Function to create HOC to add filter box to ItemList.
 * @param {any} options Filter configuration options.
 * @returns {React.ComponentType} New list component.
 */
export const withFilter = (options?: Options) => (
  ItemListComponent: React.ComponentType<ItemListProps>
) => {
  const filterPredicate =
    options && options.filterPredicate
      ? options.filterPredicate
      : defaultFilterPredicate;

  class ItemListWithFilter extends React.PureComponent<Props> {
    render() {
      const {
        autoFocus,
        classes,
        items,
        filterText,
        onFilterTextChange,
        ...itemListProps
      } = this.props;
      const filteredItems = items.filter(filterPredicate(filterText));

      return (
        <React.Fragment>
          <TextField
            fullWidth
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label="Search"
            className={classes.search}
            value={filterText}
            onChange={(event) => onFilterTextChange(event.target.value)}
          />
          <ItemListComponent {...itemListProps} items={filteredItems} />
        </React.Fragment>
      );
    }
  }

  const enhancer = compose<Partial<Props>, EnhancedProps>(
    withStyles(styles),
    withState(`filterText`, `onFilterTextChange`, ``)
  );

  return enhancer(ItemListWithFilter);
};

export default withFilter()(ItemList);

import * as React from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

type Props = {
  className?: string;
  onPerformSearch: () => void;
  onSearchTextChange: (searchText: string) => void;
  searchText: string;
};

export const INPUT_ARIA_LABEL = "Search products by text";

// Key code for the enter key
const ENTER_KEY_CODE = 13;

class SearchInput extends React.PureComponent<Props> {
  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      this.props.onPerformSearch();
    }
  }

  render() {
    const {
      className,
      searchText,
      onPerformSearch,
      onSearchTextChange,
    } = this.props;

    return (
      <Input
        inputProps={{ "aria-label": INPUT_ARIA_LABEL }}
        className={className}
        value={searchText}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={onPerformSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        onChange={(event) => {
          onSearchTextChange(event.target.value);
        }}
        onKeyDown={this.handleKeyDown.bind(this)}
        placeholder="Search Products"
      />
    );
  }
}

export default SearchInput;

import * as React from "react";
import DataTable from "../../generic/DataTable";
import gql from "graphql-tag";
import { OrderTableOrder } from "./__generated__/OrderTableOrder";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import { formatUTCDate } from "../../../../helpers/formatDate";
import getStatusString from "../../../../helpers/order/getStatusString";
import IconButton from "@material-ui/core/IconButton";
import { MoreHoriz } from "@material-ui/icons";
import { Column } from "../../generic/DataTable/BaseDataTable";

export const orderTableFragments = {
  order: gql`
    fragment OrderTableOrder on Order {
      id
      orderNumber
      confirmationDate
      status
    }
  `,
};

type EnhancedProps = {
  loading: boolean;
  orders: OrderTableOrder[] | null;
  onPressOrder: (orderID: string) => void;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (newRowsPerPage) => void;
  pageOffset: number;
  itemsPerPage: number;
};

type Props = EnhancedProps & StyleProps;

const styles = {
  table: {
    alignSelf: `stretch`,
  },
};

/**
 * Component to display orders in a table.
 */
export class OrderTable extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      orders,
      onPressOrder,
      handlePageChange,
      handleRowsPerPageChange,
      pageOffset,
      itemsPerPage,
    } = this.props;

    const columns: Column<OrderTableOrder>[] = [
      {
        Header: `Order Number`,
        id: `orderNumber`,
      },
      {
        Header: `Confirmed`,
        id: `confirmationDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `Status`,
        id: `status`,
        Cell: ({ value }) => getStatusString(value),
      },
      {
        Header: "More",
        id: `more`,
        disableSortable: true,
        compact: true,
        Cell: ({ row }) => (
          <IconButton
            title={"More Details"}
            onClick={(e) => {
              e.stopPropagation();
              onPressOrder(row.id);
            }}
          >
            <MoreHoriz />
          </IconButton>
        ),
      },
    ];

    return (
      <DataTable
        className={classes.table}
        data={orders || []}
        columns={columns}
        emptyMessage="No purchases"
        idAccessor={`id`}
        loading={loading}
        onRowPress={onPressOrder}
        pagination={{
          handlePageChange,
          handleRowsPerPageChange,
          pageOffset,
          itemsPerPage: itemsPerPage,
        }}
      />
    );
  }
}

export default withStyles(styles)(OrderTable);

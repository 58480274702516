import * as React from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import OperationHoursTable from "./OperationHoursTable";
import AddressName from "./AddressName";
import { StyleProps } from "../types";
import { OperationHoursDataAddress } from "./__generated__/OperationHoursDataAddress";

const styles = () => ({
  container: {
    minWidth: "500px",
    width: "50vw",
    padding: "12px",
  },
  table: {
    alignSelf: `stretch`,
    overflow: `auto`,
  },
});

type Props = StyleProps & {
  address: OperationHoursDataAddress;
  organisationId: string;
};

export class OperationHoursLayout extends React.PureComponent<Props> {
  render() {
    const { classes, organisationId, address } = this.props;

    return (
      <Grid container direction="column" className={classes.container}>
        <AddressName address={address} />
        <OperationHoursTable
          className={classes.table}
          operationHours={address.operationHoursList}
          organisationID={organisationId}
          addressID={address.id}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(OperationHoursLayout);

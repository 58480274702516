import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import FileInput from "../../generic/form/FileInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { GET_SERVICE_AREAS, serviceAreaListFragments } from "./ServiceAreaList";
import { ServiceAreaFormCreateServiceAreaVariables } from "./__generated__/ServiceAreaFormCreateServiceArea";
import { ServiceAreaFormGetServiceArea_serviceArea as ServiceArea } from "./__generated__/ServiceAreaFormGetServiceArea";
import { ServiceAreaFormUpdateServiceAreaVariables } from "./__generated__/ServiceAreaFormUpdateServiceArea";
import CountrySelect from "../../generic/CountrySelect";
import { compose } from "recompose";
import { get } from "lodash";
import PostcodeRangeTable, {
  postcodeRangeFragments,
} from "./PostcodeRangeTable";

export const serviceAreaFormFragments = {
  serviceArea: gql`
    fragment ServiceAreaFormServiceArea on ServiceArea {
      id
      name
      postcodeRangeList {
        country {
          name
        }
        ...PostcodeRangeTablePostcodeRange
      }
    }
    ${postcodeRangeFragments.postcodeRange}
  `,
};

export const CREATE_SERVICE_AREA = gql`
  mutation ServiceAreaFormCreateServiceArea(
    $name: String!
    $country: String!
    $postcodeFile: Upload!
  ) {
    createServiceArea(
      values: { name: $name }
      country: { name: $country }
      postcodeFile: $postcodeFile
    ) {
      ...ServiceAreaFormServiceArea
      ...ServiceAreaListServiceArea
    }
  }
  ${serviceAreaFormFragments.serviceArea}
  ${serviceAreaListFragments.serviceArea}
`;

export const UPDATE_SERVICE_AREA = gql`
  mutation ServiceAreaFormUpdateServiceArea(
    $id: String!
    $name: String!
    $country: String!
    $postcodeFile: Upload!
  ) {
    updateServiceArea(
      id: $id
      values: { name: $name }
      country: { name: $country }
      postcodeFile: $postcodeFile
    ) {
      ...ServiceAreaFormServiceArea
      ...ServiceAreaListServiceArea
    }
  }
  ${serviceAreaFormFragments.serviceArea}
  ${serviceAreaListFragments.serviceArea}
`;

export const GET_SERVICE_AREA = gql`
  query ServiceAreaFormGetServiceArea($id: String!) {
    serviceArea: getServiceArea(id: $id) {
      ...ServiceAreaFormServiceArea
    }
  }
  ${serviceAreaFormFragments.serviceArea}
`;

const schema = yup.object({
  country: yup.string().required().label(`Country`),
  name: yup.string().required().label(`Name`),
  postcodeFile: yup.mixed().required().nullable().label(`Postcodes`),
});

// Get the form values from data
const getValues = (serviceArea: Partial<ServiceArea> | null | undefined) => {
  if (serviceArea) {
    return {
      country: get(serviceArea, `postcodeRangeList[0].country.name`, null),
      name: serviceArea.name,
    };
  } else {
    return undefined;
  }
};

type EnhancedProps = {
  onDismiss?: () => void;
  serviceAreaID: string | null | undefined;
};

type Props = EnhancedProps & {
  createMutation: (
    serviceArea: ServiceAreaFormCreateServiceAreaVariables
  ) => Promise<void>;
  item?: Partial<ServiceArea>;
  loadItemError?: Error;
  loadingItem?: boolean;
  updateMutation: (
    serviceArea: ServiceAreaFormUpdateServiceAreaVariables
  ) => Promise<void>;
};

export class ServiceAreaForm extends React.PureComponent<Props> {
  render() {
    const { item, serviceAreaID, ...createUpdateFormProps } = this.props;

    return (
      <div>
        <CreateUpdateForm
          itemID={serviceAreaID || undefined}
          schema={schema}
          itemType="Service Area"
          itemNameAccessor="name"
          item={getValues(item)}
          {...createUpdateFormProps}
        >
          <FieldList>
            <CustomField
              fullWidth
              autoFocus={serviceAreaID == null}
              name="name"
              label="Name"
              component={TextInput}
            />
            <CustomField
              fullWidth
              name="country"
              label="Country"
              component={CountrySelect}
            />
            <CustomField
              fullWidth
              name="postcodeFile"
              label="Postcodes"
              component={FileInput}
              inputProps={{ accept: `.csv` }}
            />
          </FieldList>
        </CreateUpdateForm>
        {item && <PostcodeRangeTable postcodeRanges={item.postcodeRangeList} />}
      </div>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createServiceArea`,
    createMutation: CREATE_SERVICE_AREA,
    getItemAccessPath: `serviceArea`,
    getQuery: GET_SERVICE_AREA,
    getQueryVariables: ({ serviceAreaID }) =>
      serviceAreaID ? { id: serviceAreaID } : undefined,
    listAccessPath: `serviceAreaList`,
    listQuery: GET_SERVICE_AREAS,
    updateMutation: UPDATE_SERVICE_AREA,
  })
);

export default enhancer(ServiceAreaForm);

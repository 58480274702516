import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import DataTableWithMenu from "../../generic/DataTable/WithMenu";
import { StyleProps } from "../../types";
import { AddressTableAddress as Address } from "./__generated__/AddressTableAddress";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";

export const addressTableFragments = {
  address: gql`
    fragment AddressTableAddress on Address {
      ...GetAddressStringAddress
    }
    ${addressFragment}
  `,
};

type Props = StyleProps & {
  addresses: Address[] | null;
  loading: boolean;
  onPressEdit: (addressID: string) => void;
  onPressDelete: (addressID: string) => void;
  onPressEditOperatingTimes: (addressID: string) => void;
};

const styles = () => ({
  table: {
    flex: 1,
    alignSelf: `stretch`,
  },
});

class AddressTable extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      addresses,
      loading,
      onPressEdit,
      onPressDelete,
      onPressEditOperatingTimes,
    } = this.props;

    const columns = [
      {
        id: `businessName`,
        Header: `Business Name`,
      },
      {
        id: `addressLine1`,
        Header: `Address`,
        Cell: ({ row }) => getAddressString(row),
      },
    ];

    return (
      <DataTableWithMenu
        className={classes.table}
        idAccessor="id"
        columns={columns}
        data={addresses || []}
        loading={loading}
        emptyMessage="No addresses found"
        menuActions={[
          {
            perform: onPressEdit,
            text: `Edit Address`,
          },
          {
            perform: onPressEditOperatingTimes,
            text: `Edit Operating Hours`,
          },
          {
            perform: onPressDelete,
            text: `Delete`,
          },
        ]}
      />
    );
  }
}

export default withStyles(styles)(AddressTable);

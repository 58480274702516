import * as React from "react";
import * as H from "history";
import MainPanel from "../../../components/app/MainPanel";
import ServiceAreas from "../../../components/warehouse/ServiceAreas";
import { match } from "react-router-dom";
import { warehousesRoute } from "../../index";

type MatchProps = {
  organisationID?: string;
  warehouseID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

class WarehouseServiceAreas extends React.PureComponent<Props> {
  render() {
    const { history, match } = this.props;
    const { organisationID, warehouseID } = match.params;

    return (
      <MainPanel>
        {organisationID && warehouseID && (
          <ServiceAreas
            organisationID={organisationID}
            warehouseID={warehouseID}
            onDismiss={() => history.push(warehousesRoute(organisationID))}
          />
        )}
      </MainPanel>
    );
  }
}

export default WarehouseServiceAreas;

import * as H from "history";

import { matchPath, useLocation } from "react-router-dom";
import { breadcrumbList, IBreadcrumbConfig } from "./breadcrumbConfig";

const DEFAULT_MATCH_OPTIONS = { exact: true };

export interface IBreadcrumb {
  path: string;
  render: () => string;
}

const getBreadcrumbs = ({
  breadcrumbConfigList,
  location,
}: {
  location: H.Location;
  breadcrumbConfigList: IBreadcrumbConfig[];
}) => {
  const matches: IBreadcrumb[] = [];
  const { pathname } = location;

  pathname
    .replace(/\/$/, ``) // Remove trailing '/'
    .split(`/`)
    .reduce((previous: string, current: string) => {
      const pathSection = `${previous}/${current}`;

      const matchedBreadcrumb:
        | IBreadcrumbConfig
        | typeof undefined = breadcrumbConfigList.find(
        ({ matchOptions, path }) =>
          matchPath(pathSection, {
            ...(matchOptions || DEFAULT_MATCH_OPTIONS),
            path,
          })
      );

      if (matchedBreadcrumb != null) {
        matches.push({
          render: matchedBreadcrumb.render,
          path: pathSection,
        });
      }

      return pathSection;
    });

  return matches;
};

export const useBreadcrumbs = (): IBreadcrumb[] => {
  const location = useLocation();
  return getBreadcrumbs({
    location,
    breadcrumbConfigList: breadcrumbList,
  });
};

import React from "react";

import { Route, useHistory } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import ProductSearchData from "../../../components/trading/productSearch/Data";
import {
  buyAddProductToCartRoute,
  buyProductDetailsRoute,
  buyStepRoute,
  ROUTE_PARAMETERS,
} from "../../index";
import AddProductData from "../../../components/trading/addProduct/Data";
import AddProductDetails from "../../../components/trading/addProduct/Details";
import { ORDER_STEP_CART } from "../../../../helpers/trading/orderStep";
import ProductDetailDialogData from "../../../components/trading/productDetailDialog/Data";
import { Close } from "@material-ui/icons";

type Props = {
  organisationID: string;
  onNext: () => void;
};

export const CartRoute = ({ organisationID, onNext }: Props) => {
  const history = useHistory();

  return (
    <>
      <ProductSearchData
        onPressAddToCart={(productID: string) =>
          history.push(buyAddProductToCartRoute(organisationID, productID))
        }
        onPressMoreDetails={(productID: string) =>
          history.push(buyProductDetailsRoute(organisationID, productID))
        }
        onNext={onNext}
        organisationID={organisationID}
      />

      <Route
        path={buyProductDetailsRoute(
          ROUTE_PARAMETERS.ORGANISATION_ID,
          ROUTE_PARAMETERS.PRODUCT_ID
        )}
        exact
      >
        {({ match: productMatch }) => (
          <>
            <ProductDetailDialogData
              open={productMatch != null}
              organisationID={organisationID}
              productID={
                productMatch ? productMatch.params.productID : undefined
              }
              onClose={() =>
                history.push(buyStepRoute(organisationID, ORDER_STEP_CART))
              }
            />
          </>
        )}
      </Route>

      <Route
        path={buyAddProductToCartRoute(
          ROUTE_PARAMETERS.ORGANISATION_ID,
          ROUTE_PARAMETERS.PRODUCT_ID
        )}
      >
        {({ match: productMatch }) =>
          organisationID != null ? (
            <AddProductData
              organisationID={organisationID}
              productID={productMatch ? productMatch.params.productID : null}
            >
              {({
                addProductToCart,
                catalogueViewProduct,
                loading,
                quantity,
                quantityInputValue,
                setQuantityInputValue,
              }: any) => {
                const onClose = () =>
                  history.push(buyStepRoute(organisationID, ORDER_STEP_CART));

                return (
                  <Dialog open={productMatch != null} onClose={onClose}>
                    <DialogTitle>Add product to cart</DialogTitle>

                    <DialogContent>
                      <AddProductDetails
                        catalogueViewProduct={catalogueViewProduct}
                        loading={loading}
                        quantity={quantity}
                        quantityInputValue={quantityInputValue}
                        setQuantityInputValue={setQuantityInputValue}
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={onClose} startIcon={<Close />}>
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant={"contained"}
                        disabled={quantity <= 0}
                        onClick={() => {
                          onClose();
                          addProductToCart();
                        }}
                      >
                        Add to cart
                      </Button>
                    </DialogActions>
                  </Dialog>
                );
              }}
            </AddProductData>
          ) : null
        }
      </Route>
    </>
  );
};

import * as React from "react";
import gql from "graphql-tag";
import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import MainTitle from "../../app/MainTitle";
import ConfirmedOrderTable, {
  confirmedOrderTableFragments,
} from "./ConfirmedOrderTable";
import { ConfirmationLayoutOrder as OrderType } from "./__generated__/ConfirmationLayoutOrder";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { formatUTCDate } from "../../../../helpers/formatDate";
import getStatusString from "../../../../helpers/order/getStatusString";
import GridContainer from "../../generic/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import {
  GRID_FULL_WIDTH,
  GRID_HALF_WIDTH,
} from "../../../../helpers/constants";
import { Link } from "react-router-dom";
import { tradingRoute } from "../../../routes";

export const confirmationLayoutFragments = {
  order: gql`
    fragment ConfirmationLayoutOrder on Order {
      id
      status
      orderNumber
      confirmationDate
      cost {
        total
      }
      shippingBatchList {
        ...ConfirmedOrderTableShippingBatch
      }
    }
    ${confirmedOrderTableFragments.shippingBatch}
  `,
};

const styles = (theme) => ({
  table: {
    alignSelf: `stretch`,
    marginTop: theme.spacing(1),
  },
  rightAlign: {
    alignSelf: `flex-end`,
    marginTop: theme.margins.paper,
  },
});

type Props = StyleProps & {
  loading: boolean;
  organisationID: string;
  order?: OrderType;
};

class ConfirmationLayout extends React.PureComponent<Props> {
  renderOrderDetails() {
    const { order } = this.props;

    return (
      <GridContainer>
        <OrderConfirmationGridItem
          title={`Order Number`}
          value={order && order.orderNumber ? order.orderNumber : ``}
        />
        <OrderConfirmationGridItem
          title={`Status`}
          value={order && order.status ? getStatusString(order.status) : ``}
        />
        <OrderConfirmationGridItem
          title={`Confirmation Date`}
          value={
            order && order.confirmationDate
              ? formatUTCDate(order.confirmationDate) || ``
              : ``
          }
        />
      </GridContainer>
    );
  }

  renderOrderCost() {
    const { order, classes } = this.props;

    return (
      <div className={classes.rightAlign}>
        <Typography component={"span"} variant="subtitle1">
          Total:
        </Typography>{" "}
        <Typography component={"span"} variant="subtitle1">
          {order && order.cost && order.cost.total
            ? displayCurrency(order.cost.total)
            : ``}
        </Typography>
      </div>
    );
  }

  render() {
    const { classes, loading, order, organisationID } = this.props;

    return (
      <>
        <MainTitle>Order Confirmation</MainTitle>

        {this.renderOrderDetails()}

        <ConfirmedOrderTable
          className={classes.table}
          loading={loading}
          shippingBatchList={order ? order.shippingBatchList : []}
        />

        {this.renderOrderCost()}

        <Button
          component={Link}
          className={classes.rightAlign}
          color={"primary"}
          variant={"contained"}
          to={tradingRoute(organisationID)}
        >
          Done
        </Button>
      </>
    );
  }
}

type OrderConfirmationGridItemProps = {
  title: string;
  value: string;
};

const OrderConfirmationGridItem = ({
  title,
  value,
}: OrderConfirmationGridItemProps) => (
  <>
    <Grid item xs={GRID_FULL_WIDTH} sm={GRID_HALF_WIDTH}>
      <Typography variant="subtitle1">{title}:</Typography>
    </Grid>
    <Grid item xs={GRID_FULL_WIDTH} sm={GRID_HALF_WIDTH}>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  </>
);

export default withStyles(styles)(ConfirmationLayout);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import IndustryList from "../../components/admin/industry/IndustryList";
import { Link, Route } from "react-router-dom";
import FormDialog from "../../components/generic/FormDialog";
import IndustryForm from "../../components/admin/industry/IndustryForm";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import {
  adminIndustriesFormRoute,
  adminIndustriesRoute,
  adminMarketplacesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type Props = {
  history: H.History;
};

class Industry extends React.PureComponent<Props> {
  render() {
    const { history } = this.props;

    return (
      <MainPanel title="Industries">
        <IndustryList
          onPressEdit={(industryID) =>
            history.push(adminIndustriesFormRoute(industryID))
          }
          onPressMarketplaces={(industryID) =>
            history.push(`${adminMarketplacesRoute()}?industryID=${industryID}`)
          }
        />
        <Route
          path={adminIndustriesFormRoute(`${ROUTE_PARAMETERS.INDUSTRY_ID}?`)}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.industryID != null
                    ? formMatch.params.industryID
                    : undefined,
              }}
              onClose={() => history.push(adminIndustriesRoute())}
            >
              {({ handleClose, ...rest }) => (
                <IndustryForm onDismiss={handleClose} {...rest} />
              )}
            </FormDialog>
          )}
        </Route>
        {/* @ts-ignore*/}
        <FloatingActionButton component={Link} to={adminIndustriesFormRoute()}>
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

export default Industry;

import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { StyleProps } from "../types";
import GridContainer from "../generic/grid/GridContainer";
import LoadingScreen from "../generic/LoadingScreen";
import DetailGridItem from "../generic/grid/DetailGridItem";
import { GRID_FULL_WIDTH, SPACING_PAPER } from "../../../helpers/constants";
import { displayTax } from "../../../helpers/formatCurrency";
import { displayLeadTime } from "../../../helpers/displayLeadTime";
import displayProductAvailability, {
  displayProductAvailabilityFragment,
} from "../../../helpers/product/displayProductAvailability";
import { ProductDetailDialogProduct as ProductDetails } from "./__generated__/ProductDetailDialogProduct";

export const productDetailsFragment = gql`
  fragment ProductDetailDialogProduct on Product {
    availableAsOrderToPromise
    countryOfOrigin
    description
    inStock
    leadTime
    manufacturer
    make
    measurementDescription
    model
    quantityAvailable
    sku
    supplierReference
    standardPrice {
      display
      currency
      value
    }
    taxCode {
      id
      amount
    }
    unitOfMeasure
    volume
    weight
    ...DisplayProductAvailabilityProduct
  }
  ${displayProductAvailabilityFragment}
`;

const styles = (theme) => ({
  section: {
    paddingBottom: theme.spacing(SPACING_PAPER),
  },
});

type EnhancedProps = {
  onClose: () => void;
  open: boolean;
  product: ProductDetails | null | undefined;
};

type Props = EnhancedProps & StyleProps;

class ProductDetailDialog extends React.PureComponent<Props> {
  renderContent() {
    const { product, classes } = this.props;
    if (product == null) {
      return null;
    }

    return (
      <React.Fragment>
        <GridContainer className={classes.section}>
          <Grid item xs={GRID_FULL_WIDTH}>
            <Typography variant="h6">Product Overview</Typography>
          </Grid>
          <DetailGridItem title="Stock Keeping Unit" value={product.sku} />
          <DetailGridItem
            title="Supplier Stock Code"
            value={product.supplierReference}
          />
          <DetailGridItem
            title="Standard Price (ex. tax)"
            value={product.standardPrice && product.standardPrice.display}
          />
          <DetailGridItem
            title="GST Status"
            value={displayTax(product.taxCode && product.taxCode.amount)}
          />
          <DetailGridItem
            title="Availability"
            value={displayProductAvailability(product)}
          />
          <DetailGridItem
            title="Quantity Available"
            value={String(product.quantityAvailable || "")}
          />
          <DetailGridItem
            title="Lead Time"
            value={displayLeadTime(product.leadTime)}
          />
        </GridContainer>

        <GridContainer className={classes.section}>
          <Grid item xs={GRID_FULL_WIDTH}>
            <Typography variant="h6">Product Specifications</Typography>
          </Grid>

          <DetailGridItem title="Weight (kg)" value={String(product.weight)} />
          <DetailGridItem
            title={
              <>
                Volume (m<sup>3</sup>)
              </>
            }
            value={String(product.volume)}
          />
          <DetailGridItem
            title="Unit Of Measure"
            value={product.unitOfMeasure}
          />
          <DetailGridItem
            title="Measurement Description"
            value={product.measurementDescription}
          />
        </GridContainer>

        <GridContainer className={classes.section}>
          <Grid item xs={GRID_FULL_WIDTH}>
            <Typography variant="h6">Product Origin</Typography>
          </Grid>

          <DetailGridItem
            title="Country of Origin"
            value={product.countryOfOrigin}
          />
          <DetailGridItem title="Make" value={product.make} />
          <DetailGridItem title="Manufacturer" value={product.manufacturer} />
          <DetailGridItem title="Model" value={product.model} />
        </GridContainer>
      </React.Fragment>
    );
  }

  render() {
    const { onClose, open, product } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {product ? product.description : `Loading Product...`}
        </DialogTitle>
        <DialogContent>
          {!product && <LoadingScreen />}
          {this.renderContent()}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ProductDetailDialog);

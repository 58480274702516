import gql from "graphql-tag";
import { compose } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import { DEFAULT_POLL_INTERVAL } from "../../../../helpers/constants";
import IconWithBadge from "./IconWithBadge";
import { SvgIconComponent } from "@material-ui/icons";
import { NotificationType } from "../../../../helpers/notifications";

export const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCountByType($notificationTypes: [String!]) {
    getNotificationCountByType(notificationTypes: $notificationTypes)
  }
`;

interface EnhancedProps {
  notificationTypes: NotificationType[];
  Icon: SvgIconComponent;
}

interface Props extends EnhancedProps {
  notificationCount: number;
  loading: boolean;
}

const enhancer = compose<Props, EnhancedProps>(
  withQuery(GET_NOTIFICATION_COUNT, {
    dataPaths: {
      notificationCount: `getNotificationCountByType`,
    },
    errorMessage: `Failed to get Notification count`,
    loadingProp: `loading`,
    options: ({ notificationTypes }) => ({
      variables: { notificationTypes },
      fetchPolicy: `network-only`,
      pollInterval: DEFAULT_POLL_INTERVAL,
    }),
  })
);

export default enhancer(IconWithBadge);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import ShippingBatchSalesTable, {
  shippingBatchSalesTableFragments,
} from "./ShippingBatchSalesTable";
import { ShippingBatchSalesTableShippingBatch } from "./__generated__/ShippingBatchSalesTableShippingBatch";

export const SalesLayoutFragments = {
  shippingBatch: shippingBatchSalesTableFragments.shippingBatch,
};

const styles = () => ({
  table: {
    alignSelf: `stretch`,
  },
});

type Props = StyleProps & {
  loading: boolean;
  shippingBatchSalesList?: ShippingBatchSalesTableShippingBatch[];
  onPressShippingBatch: (arg0: string) => void;
  itemsPerPage: number;
  pageOffset: number;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

class SalesLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      shippingBatchSalesList,
      onPressShippingBatch,
      pageOffset,
      itemsPerPage,
      handlePageChange,
      handleRowsPerPageChange,
    } = this.props;

    return (
      <ShippingBatchSalesTable
        className={classes.table}
        loading={loading}
        shippingBatchSalesList={
          shippingBatchSalesList ? shippingBatchSalesList : []
        }
        onPressShippingBatch={onPressShippingBatch}
        pageOffset={pageOffset}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    );
  }
}

export default withStyles(styles)(SalesLayout);

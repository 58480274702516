import * as React from "react";
import gql from "graphql-tag";
import { compose, withHandlers, withState } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import { AddProductDataGetProduct_getSelf_organisation_productFromSubscribedCatalogueViews as CatalogueViewProduct } from "./__generated__/AddProductDataGetProduct";
import { catalogueViewProductFragment } from "./Details";
import { ERROR_NO_RECORDS_FOUND } from "../../../../helpers/constants";
import { get } from "lodash";
import { GET_CART } from "../productSearch/Data";

const INITIAL_QUANTITY = 0;
const INITIAL_QUANTITY_INPUT_VALUE = `0`;

export const GET_PRODUCT = gql`
  query AddProductDataGetProduct(
    $organisationID: String!
    $productID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        productFromSubscribedCatalogueViews(id: $productID) {
          ...AddProductDetailsCatalogueViewProduct
        }
      }
    }
  }
  ${catalogueViewProductFragment}
`;

export const ADD_PRODUCT_TO_CART = gql`
  mutation AddProductDataAddProductToCart(
    $organisationID: String!
    $productID: String!
    $quantity: Int!
  ) {
    addProductToShoppingCart(
      product: { id: $productID }
      organisation: { id: $organisationID }
      data: { quantity: $quantity }
    ) {
      quantity
    }
  }
`;

export type ChildProps = {
  loading: boolean;
  catalogueViewProduct: CatalogueViewProduct | null;
  quantity: number;
  quantityInputValue: string;
  setQuantityInputValue: (quantity: string) => void;
  addProductToCart: () => Promise<void>;
};

export type EnhancedProps = {
  organisationID: string;
  productID: string | null | undefined;
  children: (props: ChildProps) => React.ReactNode;
};

export type ProvidedProps = EnhancedProps & ChildProps;

class AddProductData extends React.PureComponent<ProvidedProps> {
  componentDidUpdate(prevProps: ProvidedProps) {
    if (this.props.productID !== prevProps.productID) {
      this.props.setQuantityInputValue(INITIAL_QUANTITY_INPUT_VALUE);
    }
  }

  render() {
    const {
      children,
      catalogueViewProduct,
      loading,
      quantity,
      quantityInputValue,
      setQuantityInputValue,
      addProductToCart,
    } = this.props;

    return children({
      catalogueViewProduct,
      loading,
      quantity,
      quantityInputValue,
      setQuantityInputValue,
      addProductToCart,
    });
  }
}

const enhancer = compose<Partial<ProvidedProps>, EnhancedProps>(
  withQuery(GET_PRODUCT, {
    dataPaths: {
      catalogueViewProduct: `getSelf.organisation.productFromSubscribedCatalogueViews`,
    },
    errorMessage: `Failed to load product`,
    loadingProp: `loading`,
    options: ({ organisationID, productID }) => ({
      variables: {
        organisationID,
        productID,
      },
      fetchPolicy: `no-cache`,
    }),
    skip: ({ productID }) => productID == null,
  }),
  withState(`quantity`, `setQuantity`, INITIAL_QUANTITY),
  withState(
    `quantityInputValue`,
    `setQuantityInputValue`,
    INITIAL_QUANTITY_INPUT_VALUE
  ),
  withHandlers({
    setQuantityInputValue: ({ setQuantity, setQuantityInputValue }) => (
      quantityInputValue: string
    ) => {
      setQuantityInputValue(quantityInputValue);

      const value = Number(quantityInputValue);

      // Handle value change - default to 0 if non-positive number given
      if (value >= 0) {
        setQuantity(value);
      } else {
        setQuantity(INITIAL_QUANTITY);
      }
    },
  }),
  withMutation(ADD_PRODUCT_TO_CART, {
    mapMutationToProps: (addProductToCart) => ({ addProductToCart }),
    mutationOptions: ({ organisationID, productID, quantity }) => ({
      variables: {
        organisationID,
        productID,
        quantity,
      },
      optimisticResponse: {
        addProductToShoppingCart: {
          quantity: 10,
          __typename: `ShoppingCartIncludesProduct`,
        },
      },
      refetchQueries: [
        {
          query: GET_CART,
          variables: {
            organisationID,
          },
        },
      ],
    }),
    successMessage: `Successfully added product to cart`,
    errorMessage: (error) =>
      get(error, `graphQLErrors[0].message`) === ERROR_NO_RECORDS_FOUND
        ? `Failed to add to cart, stock may not be available`
        : `Failed to add to cart, please try again`,
  })
);

export default enhancer(AddProductData);

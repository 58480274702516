import * as React from "react";
import { withStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { StyleProps } from "../../types";
import Hidden from "@material-ui/core/Hidden";
import MenuData from "./MenuData";

const styles = (theme) => ({
  drawer: {
    height: `100%`,
  },
  drawerPaper: {
    position: `relative` as const,
    width: theme.dimensions.drawerWidth,
  },
  toolbarBuffer: theme.mixins.toolbar,
});

type Props = StyleProps & {
  onClose: () => void;
  open: boolean;
  organisationID?: string;
  organisationRole?: string;
};

const DrawerMenu = ({
  classes,
  onClose,
  open,
  organisationID,
  organisationRole,
}: Props) => {
  const drawerProps = {
    className: classes.drawer,
    classes: {
      paper: classes.drawerPaper,
    },
  };

  const menu = (
    <React.Fragment>
      <div className={classes.toolbarBuffer} />
      <MenuData
        organisationID={organisationID}
        organisationRole={organisationRole}
        onClose={onClose}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          {...drawerProps}
          anchor="left"
          ModalProps={{
            keepMounted: true,
          }}
          open={open}
          variant="temporary"
          onClose={onClose}
        >
          {menu}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer {...drawerProps} variant="permanent">
          {menu}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default withStyles(styles)(DrawerMenu);

import gql from "graphql-tag";
import withQuery from "../../generic/graphql/withQuery";
import { compose } from "recompose";
import WarehouseStockedProductsTable, {
  warehouseStockedProductTableFragments,
} from "./WarehouseStockedProductsTableLayout";
import { WarehouseStockedProductsTableGetWarehouseProductStockLevels_getSelf_organisation_warehouse_stockLevelList as StockLevelList } from "./__generated__/WarehouseStockedProductsTableGetWarehouseProductStockLevels";

export const ERROR_MESSAGE = (warehouseName?: string) =>
  `Failed to load${warehouseName ? ` "${warehouseName}"` : ``} warehouse.`;

export const GET_WAREHOUSE_PRODUCT_STOCK_LEVELS = gql`
  query WarehouseStockedProductsTableGetWarehouseProductStockLevels(
    $organisationID: String!
    $warehouseID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          id
          stockLevelList {
            ...WarehouseStockedProductTableStockedProduct
          }
        }
      }
    }
  }
  ${warehouseStockedProductTableFragments.warehouseProductStockLevel}
`;

type EnhancedProps = {
  className?: string;
  organisationID: string;
  warehouseID: string;
  warehouseName?: string;
};

type Props = EnhancedProps & {
  stockLevelsLoading: boolean;
  stockLevels: StockLevelList[] | null | undefined;
};

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withQuery(GET_WAREHOUSE_PRODUCT_STOCK_LEVELS, {
    dataPaths: {
      stockLevels: `getSelf.organisation.warehouse.stockLevelList`,
    },
    errorMessage: (error, props) => ERROR_MESSAGE(props.warehouseName),
    loadingProp: `stockLevelsLoading`,
  })
);

export default enhancer(WarehouseStockedProductsTable);

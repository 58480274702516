import * as React from "react";
import {
  EnhancedProps as DataTableProps,
  SortDirection,
} from "./BaseDataTable";
import { get } from "lodash";

export type Props<Row> = DataTableProps<Row>;

type State = {
  sortColumn?: string;
  sortDirection?: SortDirection;
};

/**
 * Function to create HOC to handle sorted column state and sorting the data of DataTable.
 * @returns HOC to wrap DataTable.
 */
export const withSorting = <Row,>() => (
  DataTableComponent: React.ComponentType<any>
) => {
  class DataTableWithSorting extends React.PureComponent<Props<Row>, State> {
    constructor(props: Props<Row>) {
      super(props);

      this.state = {};
    }

    _handleSortPress(_event: any, columnID: string) {
      let direction: SortDirection = "asc";
      if (
        columnID === this.state.sortColumn &&
        this.state.sortDirection === `asc`
      ) {
        direction = `desc`;
      }
      this.setState({
        sortColumn: columnID,
        sortDirection: direction,
      });
    }

    _sortCompare(rowA: any, rowB: any) {
      const { sortColumn, sortDirection } = this.state;

      // Get the values for column being sorted on
      let valueA = get(rowA, sortColumn);
      let valueB = get(rowB, sortColumn);

      // Check for an object with a separate value key
      if (get(valueA, `value`)) {
        valueA = get(valueA, `value`);
        valueB = get(valueB, `value`);
      }

      const valueAIsNumber = !isNaN(Number(valueA));
      const valueBIsNumber = !isNaN(Number(valueB));
      if (valueAIsNumber && valueBIsNumber) {
        valueA = Number(valueA);
        valueB = Number(valueB);
      }

      // Compare the values
      let comparison = 0;
      if (valueA == null && valueB == null) {
        comparison = 0;
      } else if (valueA == null || valueA < valueB) {
        comparison = -1;
      } else if (valueB == null || valueA > valueB) {
        comparison = 1;
      }

      // Swap value if order is descending
      return sortDirection === `desc` ? -comparison : comparison;
    }

    render() {
      const { data, ...dataTableProps } = this.props;
      const { sortColumn, sortDirection } = this.state;
      const sortedData = sortColumn
        ? data.slice().sort(this._sortCompare.bind(this))
        : data;

      return (
        <DataTableComponent
          {...dataTableProps}
          data={sortedData}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSortPress={this._handleSortPress.bind(this)}
        />
      );
    }
  }

  return DataTableWithSorting;
};

import * as React from "react";
import { match, Route } from "react-router-dom";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import SalesData from "../../components/organisation/sales/SalesData";
import ShippingBatchSaleDialogData from "../../components/organisation/sales/ShippingBatchSaleDialogData";
import {
  ROUTE_PARAMETERS,
  salesRoute,
  salesShippingBatchRoute,
} from "../index";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  history: H.History;
  location: H.Location;
  match: match<MatchProps>;
};

interface State {
  pageOffset: number;
  itemsPerPage: number;
}

class ProductOverview extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageOffset: 0,
      itemsPerPage: 10,
    };
  }

  render() {
    const { history, match, location } = this.props;

    const { organisationID } = match.params;
    if (organisationID == null) {
      return null;
    }

    return (
      <MainPanel title="Sales">
        <SalesData
          organisationID={organisationID}
          onPressShippingBatch={(shippingBatchID: string) =>
            history.push(
              salesShippingBatchRoute(organisationID, shippingBatchID)
            )
          }
          pageOffset={this.state.pageOffset}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={(event, newPage) => {
            this.setState({ pageOffset: newPage });
          }}
          handleRowsPerPageChange={(event) => {
            this.setState({
              itemsPerPage: parseInt(event.target.value, 10),
              pageOffset: 0,
            });
          }}
        />

        <Route
          path={salesShippingBatchRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            ROUTE_PARAMETERS.SHIPPING_BATCH_ID
          )}
        >
          {({ match: salesMatch }) => {
            const shippingBatchID =
              salesMatch && salesMatch.params.shippingBatchID;

            return (
              <ShippingBatchSaleDialogData
                shippingBatchID={shippingBatchID}
                organisationID={organisationID}
                onClose={() =>
                  history.push(salesRoute(organisationID) + location.search)
                }
              />
            );
          }}
        </Route>
      </MainPanel>
    );
  }
}

export default ProductOverview;

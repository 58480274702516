import gql from "graphql-tag";
import { AddRolesToUserUpdateUser as User } from "./__generated__/AddRolesToUserUpdateUser";
import { InMemoryCache } from "apollo-cache-inmemory";

const userFragment = gql`
  fragment AddRolesToUserUpdateUser on User {
    id
    roles {
      id
    }
  }
`;

const addRolesToUserUpdate = (userID: string, roleID: string) => (
  store: InMemoryCache
) => {
  // Read an existing user from the cache
  const user: User | null | undefined = store.readFragment({
    fragment: userFragment,
    id: `User:${userID}`,
  });
  if (user) {
    if (!user.roles) {
      user.roles = [];
    }
    // Add the user role on to the user
    user.roles.push({
      __typename: `Role`,
      id: roleID,
    });
    store.writeFragment({
      data: user,
      fragment: userFragment,
      id: `User:${userID}`,
    });
  }
};

export default addRolesToUserUpdate;

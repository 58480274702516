import * as React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  fieldList: {
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `column` as const,
    flexWrap: `wrap` as const,
  },
});

type Props = {
  classes: {
    [className: string]: string;
  };
  component?: React.ElementType;
};

const FieldList = ({ classes, component, ...rest }: Props) => {
  const Component = component || `div`;

  return <Component className={classes.fieldList} {...rest} />;
};

export default withStyles(styles)(FieldList);

import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import UserManagementTable, {
  userManagementTableFragments,
} from "../user/UserManagementTable";
import ConfirmDialog from "../../generic/ConfirmDialog";
import { UserManagementDataUsers_users as User } from "./__generated__/UserManagementDataUsers";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";
import { approvedUserRole } from "../../../../helpers/user/getStatus";
import addRolesToUserUpdate from "../../../../apollo/updateFunctions/user/addRolesToUser";
import removeRolesFromUserUpdate from "../../../../apollo/updateFunctions/user/removeRolesFromUser";
import withQuery from "../../generic/graphql/withQuery";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const GET_USER_LIST = gql`
  query UserManagementDataUsers {
    users: getUserList {
      id
      ...UserManagementTableUser
    }
  }
  ${userManagementTableFragments.user}
`;

const APPROVE_USER = gql`
	mutation UserManagementApproveUser($id: String!) {
		addRolesToUser(userId: $id, role: "${approvedUserRole}")
	}
`;

const UNAPPROVE_USER = gql`
	mutation UserManagementUnapproveUser($id: String!) {
		removeRolesFromUser(userId: $id, role: "${approvedUserRole}")
	}
`;

const DELETE_USER = gql`
  mutation UserManagementDeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

type UserActionType = "approve" | "unapprove" | "delete";

type OuterProps = {
  onPressEdit: (id: string) => void;
};

type Props = OuterProps & {
  loadingUsers: boolean;
  onApproveUser: (id: string) => Promise<void>;
  onDeleteUser: (id: string) => Promise<void>;
  onUnapproveUser: (id: string) => Promise<void>;
  openSnackbar: OpenSnackbarFn;
  users: Array<User> | null | undefined;
};

type State = {
  dialog: {
    open: boolean;
    type: UserActionType;
    user: Partial<User>;
  };
};

export class UserManagementData extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dialog: {
        open: false,
        type: `approve`,
        user: {},
      },
    };
  }

  _handleUserActionRequest(type: UserActionType, user: User) {
    this.setState({
      dialog: {
        open: true,
        type,
        user,
      },
    });
  }

  async _handleDialogAction(type: UserActionType, user: User) {
    this._handleCloseDialog();
    const {
      onApproveUser,
      onUnapproveUser,
      onDeleteUser,
      openSnackbar,
    } = this.props;

    // Get handler depending on action type
    let handler: (id: string) => void = () => undefined;
    switch (type) {
      case `approve`:
        handler = onApproveUser;
        break;
      case `unapprove`:
        handler = onUnapproveUser;
        break;
      case `delete`:
        handler = onDeleteUser;
        break;
      default:
    }

    const userName = user.fullName || user.username;
    try {
      await handler(user.id);
      openSnackbar(
        SnackbarType.success,
        `Successfuly ${type}d user ${userName}`
      );
    } catch (error) {
      openSnackbar(SnackbarType.error, `Failed to ${type} user ${userName}`);
    }
  }

  _handleCloseDialog() {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: false,
      },
    });
  }

  render() {
    const { users, loadingUsers, onPressEdit } = this.props;
    const { dialog } = this.state;

    return (
      <React.Fragment>
        <UserManagementTable
          users={users || []}
          loading={loadingUsers}
          onApproveUser={(user) =>
            this._handleUserActionRequest(`approve`, user)
          }
          onUnapproveUser={(user) =>
            this._handleUserActionRequest(`unapprove`, user)
          }
          onDeleteUser={(user) => this._handleUserActionRequest(`delete`, user)}
          onPressEdit={onPressEdit}
        />
        <ConfirmDialog
          open={dialog.open}
          title={`${
            dialog.type[0].toUpperCase() + dialog.type.substring(1)
          } User Account`}
          onAccept={() =>
            this._handleDialogAction(dialog.type, dialog.user as User)
          }
          onClose={this._handleCloseDialog.bind(this)}
          text={`Are you sure you want to ${dialog.type} user ${
            dialog.user.fullName || dialog.user.username
          }?`}
        />
      </React.Fragment>
    );
  }
}

const enhancer = compose<Partial<Props>, OuterProps>(
  withQuery(GET_USER_LIST, {
    dataPaths: {
      users: `users`,
    },
    errorMessage: `Failed to load user accounts`,
    loadingProp: `loadingUsers`,
  }),
  graphql(APPROVE_USER, {
    props: ({ mutate }) => ({
      onApproveUser: (id: string) =>
        mutate({
          optimisticResponse: { addRolesToUser: `` },
          update: addRolesToUserUpdate(id, approvedUserRole),
          variables: { id },
        }),
    }),
  }),
  graphql(UNAPPROVE_USER, {
    props: ({ mutate }) => ({
      onUnapproveUser: (id: string) =>
        mutate({
          optimisticResponse: { removeRolesFromUser: `` },
          update: removeRolesFromUserUpdate(id, approvedUserRole),
          variables: { id },
        }),
    }),
  }),
  graphql(DELETE_USER, {
    options: {},
    props: ({ mutate }) => ({
      onDeleteUser: (id: string) =>
        mutate({
          update: deleteMutationUpdate({
            itemID: id,
            listDataAccessPath: `users`,
            listQuery: GET_USER_LIST,
            listQueryVariables: {},
          }),
          variables: { id },
        }),
    }),
  }),
  withOpenSnackbar
);

export default enhancer(UserManagementData);

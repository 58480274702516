import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StyleProps } from "../../types";
import ShoppingCartForm from "./Form";
import { ShoppingCartDataGetShoppingCart_getSelf_shoppingCart_productList as ShoppingCartIncludesProductList } from "./__generated__/ShoppingCartDataGetShoppingCart";
import OrderActions from "../OrderActions";
import MainTitle from "../../app/MainTitle";

const styles = (theme) => ({
  table: {
    margin: theme.spacing(0.2),
  },
  form: {
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `column` as const,
    overflow: `auto`,
  },
  total: {
    alignSelf: `flex-end`,
    padding: theme.spacing(1),
  },
});

type Props = StyleProps & {
  loading: boolean;
  productList: ShoppingCartIncludesProductList[];
  total: any;
  // TODO should be Currency
  onBack: () => void;
  onNext: () => void;
  updateShoppingCartProductQuantity: (
    productID: string,
    quantity: number
  ) => Promise<void>;
  removeProductFromShoppingCart: (productID: string) => Promise<void>;
};

class ShoppingCartLayout extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      productList,
      total,
      updateShoppingCartProductQuantity,
      removeProductFromShoppingCart,
      onBack,
      onNext,
    } = this.props;

    const nextDisabled: boolean = productList.length <= 0;

    return (
      <>
        <MainTitle>Review Cart</MainTitle>

        <ShoppingCartForm
          loading={loading}
          classes={classes}
          className={classes.form}
          productList={productList}
          updateShoppingCartProductQuantity={updateShoppingCartProductQuantity}
          removeProductFromShoppingCart={removeProductFromShoppingCart}
        />

        <Typography variant="subtitle1" className={classes.total}>
          Total: {total.display}
        </Typography>

        <OrderActions
          nextDisabled={nextDisabled}
          onNext={onNext}
          onBack={onBack}
        />
      </>
    );
  }
}

export default withStyles(styles)(ShoppingCartLayout);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { StyleProps } from "../../types";
import UserControl from "../UserControl";
import logo from "../../../assets/indeco-logo-app-bar.png";

const styles = (theme) => ({
  appBar: {},

  logo: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    objectFit: "contain" as const,
  },

  menuIconHide: {
    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },

  spacing: {
    flexGrow: 2,
  },

  title: {
    marginRight: theme.spacing(1),
  },
});

type Props = StyleProps & {
  onPressMenu: () => void;
};

const TopBar = ({ classes, onPressMenu }: Props) => (
  <AppBar className={classes.appBar} position="absolute" color={"primary"}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        className={classes.menuIconHide}
        onClick={onPressMenu}
      >
        <MenuIcon color="inherit" />
      </IconButton>
      <img
        className={classes.logo}
        src={logo}
        alt="INDECO LOGO"
        height="60px"
      />
      <div className={classes.spacing} />
      <UserControl />
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(TopBar);

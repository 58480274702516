import * as React from "react";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, withTheme } from "@material-ui/core";
import { ThemeStyleProps } from "../../types";
import { compose } from "recompose";

export type Option = {
  key?: string;
  label: string;
  subheader?: boolean;
  value: string;
};

export type EnhancedProps = {
  loading?: boolean;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  options: Option[];
  placeholder?: string;
  value: string | null | undefined;
  label?: string;
  error?: boolean;
  helperText?: string;
  size?: "medium" | "small";
};

type Props = ThemeStyleProps & EnhancedProps;

const styles = () => ({
  subheading: {
    pointerEvents: `none` as const,
  },
});

class SelectField extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      loading,
      options,
      placeholder,
      theme,
      value,
      size,
      ...fieldProps
    } = this.props;
    const emptyItem = loading ? `Loading` : placeholder;
    const hasValue =
      value && options.findIndex((option) => option.value === value) !== -1;

    return (
      // @ts-ignore
      <TextField
        select
        value={value && hasValue ? value : `_`}
        InputProps={{
          startAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress
                color={"inherit"}
                size={theme.dimensions.textFieldProgressSize}
              />
            </InputAdornment>
          ) : undefined,
        }}
        SelectProps={{ displayEmpty: emptyItem != null }}
        size={size}
        {...fieldProps}
      >
        {emptyItem && (
          <MenuItem disabled value="_">
            {emptyItem}
          </MenuItem>
        )}
        {options.map((option) =>
          option.subheader ? (
            <ListSubheader
              disableSticky
              color={"inherit"}
              className={classes.subheading}
              key={option.value}
            >
              {option.label}
            </ListSubheader>
          ) : (
            <MenuItem key={option.key || option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        )}
      </TextField>
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(withTheme, withStyles(styles));

export default enhancer(SelectField);

import * as React from "react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { UserControlLogout } from "./__generated__/UserControlLogout";
import { UserControlGetAuth } from "./__generated__/UserControlGetAuth";
import UserMenu from "./TopBar/UserMenu";

export const GET_AUTH = gql`
  query UserControlGetAuth {
    auth: getAuth @client {
      isLoggedIn
      email
    }
  }
`;

class GetAuthQuery extends Query<UserControlGetAuth> {}

export const LOGOUT = gql`
  mutation UserControlLogout {
    logout @client {
      isLoggedIn
    }
  }
`;
class LogoutMutation extends Mutation<UserControlLogout> {}

class UserControl extends React.Component {
  render() {
    return (
      <GetAuthQuery query={GET_AUTH}>
        {({ data, loading }) => {
          if (loading || !data || !data.auth) {
            return null;
          }
          const {
            auth: { isLoggedIn, email },
          } = data;

          if (isLoggedIn && email) {
            return (
              <LogoutMutation mutation={LOGOUT}>
                {(logout) => (
                  <UserMenu
                    email={email}
                    onLogout={() => {
                      logout({});
                    }}
                  />
                )}
              </LogoutMutation>
            );
          }

          return null;
        }}
      </GetAuthQuery>
    );
  }
}

export default UserControl;

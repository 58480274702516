import * as React from "react";
import DataTable from "../../generic/DataTable";
import { Column } from "../../generic/DataTable/BaseDataTable";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import createItemListFromArray from "../../../../helpers/createItemListFromArray";
import gql from "graphql-tag";
import { displayCurrency } from "../../../../helpers/formatCurrency";
import { ShippingTableShippingBatch } from "./__generated__/ShippingTableShippingBatch";
import { displayLeadTime } from "../../../../helpers/displayLeadTime";
import { Button } from "@material-ui/core";

export const shippingTableFragments = {
  shippingBatch: gql`
    fragment ShippingTableShippingBatch on ShippingBatch {
      id
      cost {
        shipping
      }
      preferredDeliveryDate
      leadTime
      carrierRate {
        id
        leadTime
        carrier {
          id
          name
        }
      }
      supplier {
        id
        name
      }
      origin {
        id
        name
      }
      destination {
        id
        ...GetAddressStringAddress
      }
      orderItemPartList {
        id
        orderItem {
          id
          description
        }
      }
    }
    ${addressFragment}
  `,
};

type Props = {
  className?: string;
  loading: boolean;
  shippingBatchList: ShippingTableShippingBatch[];
  onEditPressed: (orderItemID: string) => void;
};

class ShippingTable extends React.PureComponent<Props> {
  render() {
    const { className, loading, shippingBatchList, onEditPressed } = this.props;

    const columns: Column<ShippingTableShippingBatch>[] = [
      {
        Header: `Supplier`,
        id: `supplier.name`,
        compact: true,
      },
      {
        Cell: ({ row }) =>
          createItemListFromArray(
            row.orderItemPartList,
            `orderItem.description`
          ),
        Header: `Product`,
        id: `orderItemPartList[0].orderItem.id`,
      },
      {
        Header: `Origin`,
        id: `origin.name`,
      },
      {
        Header: `Delivery Address`,
        id: `destination.id`,
        Cell: ({ row }) => getAddressString(row.destination),
      },
      {
        Header: `Carrier`,
        id: `carrierRate.carrier.name`,
        compact: true,
      },
      {
        Header: `ETA`,
        id: `leadTime`,
        compact: true,
        Cell: ({ value }) => displayLeadTime(value),
      },
      {
        Header: `Price`,
        id: `cost.shipping`,
        compact: true,
        numeric: true,
        Cell: ({ value }) => displayCurrency(value),
      },
      {
        Header: null,
        id: "select",
        disableSortable: true,
        numeric: true,
        Cell: ({ row }) => (
          <Button
            color={"primary"}
            onClick={async (e) => {
              e.stopPropagation();
              await onEditPressed(row.id);
            }}
            variant={"outlined"}
          >
            Select
          </Button>
        ),
      },
    ];

    return (
      <DataTable
        className={className}
        loading={loading}
        columns={columns}
        data={shippingBatchList}
        idAccessor="id"
        emptyMessage="No shipping batches were created"
        onRowPress={onEditPressed}
      />
    );
  }
}

export default ShippingTable;

import { DocumentNode } from "graphql";
import { graphql, MutationOpts, OperationOption } from "react-apollo";
import { compose, withProps } from "recompose";
import withOpenSnackbar from "../../generic/snackbar/withOpenSnackbar";
import { omit } from "lodash";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export type MutationFunction = (
  ...args: any
) => Promise<{
  error?: any;
  result?: any;
}>;

export type Options = OperationOption<any, any, any, any> & {
  /*
   * Message to show on error, as either a string or a function mapping the error (null if due to null result),
   * props and args (passed to the function) to a string
   */
  errorMessage?: string | ((error: any, props: any, args: any[]) => string);

  /*
   * Function to add the resulting function to props. The function can optionally accept arguments which will be available
   * for computing options.
   */
  mapMutationToProps: (func: MutationFunction) => any;

  /*
   * Same as graphql HOC options property, except that if a function is provided it will receive provided function args
   * as the second argument.
   */
  mutationOptions?:
    | MutationOpts<any>
    | ((props: any, args: any[]) => MutationOpts<any>);
  // Message to show on success, as either a string or a function mapping the result, props and args to a string.
  successMessage?: string | ((result: any, props: any, args: any[]) => string);
  // If true, mutation errors will be rethrown. Default is for errors to be caught and not thrown.
  throwOnError?: boolean;
};

/**
 * HOC to perform a graphql mutation and handle success and errors using a snackbar.
 * The resulting function will return an object with optional 'error' and 'result' properties.
 * @param {DocumentNode} mutation The mutation to perform.
 * @param {Options} options Options for configuring the mutation, supports all of Apollo's graphql HOC options.
 * @returns {HOC} HOC that will enhance the specified function prop.
 */
const withMutation = (mutation: DocumentNode, options: Options) =>
  compose(
    withOpenSnackbar,
    graphql(mutation, {
      ...omit(
        options,
        `errorMessage`,
        `mapMutationToProps`,
        `mutationOptions`,
        `successMessage`
      ),
      props: (props) => ({
        withMutationFunction: (...args: any[]) =>
          props.mutate({
            ...(typeof options.mutationOptions === `function`
              ? options.mutationOptions(props.ownProps, args)
              : options.mutationOptions),
          }),
        ...(options.props && options.props(props)),
      }),
    }),
    withProps((props) => {
      const mutationFunction: MutationFunction = async (...args) => {
        const skipped =
          typeof options.skip === `function`
            ? options.skip(props)
            : options.skip;
        if (skipped) {
          // Do nothing if skip is true
          return {};
        }

        // @ts-ignore
        const { openSnackbar, withMutationFunction } = props;
        try {
          // Perform the mutation
          const result = await withMutationFunction(...args);
          // Handle success
          const successMessage =
            typeof options.successMessage === `function`
              ? options.successMessage(result, props, args)
              : options.successMessage;
          if (successMessage) {
            openSnackbar(SnackbarType.success, successMessage);
          }

          return { result };
        } catch (error) {
          // Handle failure
          const errorMessage =
            typeof options.errorMessage === `function`
              ? options.errorMessage(error, props, args)
              : options.errorMessage;
          if (errorMessage) {
            openSnackbar(SnackbarType.error, errorMessage);
          }

          if (options.throwOnError) {
            throw error;
          }

          return { error };
        }
      };

      // Return props including mutationFunction according to the mapMutationToProps option
      return options.mapMutationToProps(mutationFunction);
    })
  );

export default withMutation;

import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import OperationHoursRowData from "./OperationHoursRowData";
import { OperationHoursRowDataOperationHours } from "./__generated__/OperationHoursRowDataOperationHours";
import { daysOfTheWeek } from "../warehouse/OperatingTimes/OperatingTimesTable";

export type Props = {
  className?: string;
  operationHours: OperationHoursRowDataOperationHours[];
  organisationID: string;
  addressID: string;
};

/**
 * Component to display a table with a row for each day of the week, with columns to define open
 * days and their operating hours.
 */
class OperationHoursTable extends React.PureComponent<Props> {
  render() {
    const { className, operationHours, organisationID, addressID } = this.props;

    return (
      <Paper className={className}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Open</TableCell>
              <TableCell align={`center`}>Times</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              /* Render OperatingTimesRowData for each day of the week */
              daysOfTheWeek.map((dayOfTheWeek) => {
                const hours = operationHours.find(
                  (hours) => hours.dayOfTheWeek === dayOfTheWeek
                );

                return (
                  <OperationHoursRowData
                    key={dayOfTheWeek}
                    dayOfTheWeek={dayOfTheWeek}
                    operationHours={hours}
                    organisationID={organisationID}
                    addressID={addressID}
                  />
                );
              })
            }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default OperationHoursTable;

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { StyleProps } from "../../types";

const styles = () => ({
  spacer: {
    flex: 1,
  },
});

type EnhancedProps = {
  LeftItems?: React.ReactNode;
  RightItems?: React.ReactNode;
};

type Props = StyleProps & EnhancedProps;

class EnhancedToolbar extends React.PureComponent<Props> {
  render() {
    const { classes, LeftItems, RightItems } = this.props;

    return (
      <Toolbar>
        {LeftItems}
        <div className={classes.spacer} />
        {RightItems}
      </Toolbar>
    );
  }
}

export default withStyles(styles)(EnhancedToolbar);

import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../types";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
  root: {},
});

type EnhancedProps = {
  // The currently selected filter value for each key
  filter: {
    [key: string]: any;
  };
  onClearFilter: (columnID: string) => void;
};

type Props = StyleProps & EnhancedProps;

/**
 * Component to display chips representing values for filtering.
 * Chips are removable, which clears the corresponding filter.
 */
class FilterChips extends React.PureComponent<Props> {
  render() {
    const { classes, filter, onClearFilter } = this.props;

    const values = Object.keys(filter)
      .map((columnID) => ({
        columnID,
        value: filter[columnID],
      }))
      .filter((filterValue) => filterValue.value != null);

    return (
      <div className={classes.root}>
        {values.length > 0 && (
          <Typography variant={`caption`}>Filters:</Typography>
        )}
        {values.map((filterValue) => (
          <Chip
            className={classes.chip}
            key={filterValue.columnID}
            label={filterValue.value}
            onDelete={() => {
              onClearFilter(filterValue.columnID);
            }}
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(FilterChips);

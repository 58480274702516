import CategoryLayout from "./CategoryLayout";
import gql from "graphql-tag";
import { compose, withState } from "recompose";
import { categoryListFragments } from "./CategoryList";
import withQuery from "../../generic/graphql/withQuery";
import { CategoryCurationGetCategory_category as Category } from "./__generated__/CategoryCurationGetCategory";
import withMutation from "../../generic/graphql/withMutation";
import deleteMutationUpdate from "../../../../apollo/updateFunctions/deleteMutationUpdate";

export const GET_CATEGORIES = gql`
  query CategoryCurationGetMarketplace($marketplaceID: String!) {
    marketplace: getMarketplace(id: $marketplaceID) {
      id
      categoryList {
        ...CategoryListCategory
      }
    }
  }
  ${categoryListFragments.category}
`;

export const GET_CATEGORY = gql`
  query CategoryCurationGetCategory($categoryID: String!) {
    category: getCategory(id: $categoryID) {
      ...CategoryListCategory
    }
  }
  ${categoryListFragments.category}
`;

export const DELETE_ACTIVE_CATEGORY = gql`
  mutation MarketplaceCurationDeleteCategory($categoryID: String!) {
    category: deleteCategory(id: $categoryID) {
      id
      name
    }
  }
`;

export type EnhancedProps = {
  industryID?: string;
  marketplaceID?: string;
  onIndustryIDChange: (id: string) => void;
  onMarketplaceIDChange: (id: string) => void;
  onPressClasses: (categoryID: string) => void;
  onPressEdit: (categoryID: string) => void;
};

export type ProvidedProps = EnhancedProps & {
  setDeletingCategoryID: (categoryID: string | typeof undefined) => void;
  category: Category | null | undefined;
  loadingCategories?: boolean;
  deleteCategory: (categoryID: string) => Promise<unknown>;
  categories: Category[] | null;
};

const enhancer = compose<Partial<ProvidedProps>, EnhancedProps>(
  withQuery(GET_CATEGORIES, {
    dataPaths: {
      categories: `marketplace.categoryList`,
    },
    loadingProp: `loadingCategories`,
    errorMessage: `Failed to load categories`,
    skip: ({ marketplaceID }) => marketplaceID == null,
  }),
  withMutation(DELETE_ACTIVE_CATEGORY, {
    errorMessage: `Failed to delete category`,
    mapMutationToProps: (deleteCategory) => ({ deleteCategory }),
    mutationOptions: ({ marketplaceID }, [categoryID]) => ({
      optimisticResponse: {
        category: {
          id: categoryID,
          name: `description`,
          __typename: `Category`,
        },
      },
      update: deleteMutationUpdate({
        itemID: categoryID,
        itemIDAccessPath: `id`,
        listDataAccessPath: `marketplace.categoryList`,
        listQuery: GET_CATEGORIES,
        listQueryVariables: {
          marketplaceID,
        },
      }),
      variables: {
        categoryID,
      },
    }),
    successMessage: (result) =>
      `Successfully deleted category ${result.data.category.name || ``}`,
  }),
  withState(`deletingCategoryID`, `setDeletingCategoryID`, undefined),
  withQuery(GET_CATEGORY, {
    dataPaths: {
      category: `category`,
    },
    skip: ({ deletingCategoryID }) => deletingCategoryID == null,
    options: ({ deletingCategoryID }) => ({
      variables: {
        categoryID: deletingCategoryID,
      },
    }),
    errorMessage: `Failed to get Category`,
    loadingProp: `loadingCategory`,
  })
);

export default (enhancer as any)(CategoryLayout);

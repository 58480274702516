import * as React from "react";
import Button from "@material-ui/core/Button";
import AddSubscribedOrganisationsDialog from "./AddSubscribedOrganisationsDialogData";
import RemoveSubscriptionDialog from "./RemoveSubscriptionDialog";
import SubscribedOrganisationList from "../../../generic/SubscribedOrganisationList";
import { SubscribedOrganisationCatalogueViewDetails as CatalogueViewDetails } from "./__generated__/SubscribedOrganisationCatalogueViewDetails";
import { SubscribedOrganisationPartialOrganisation as PartialOrganisation } from "./__generated__/SubscribedOrganisationPartialOrganisation";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../../../types";
import { Publish, Unsubscribe } from "@material-ui/icons";

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: `flex`,
    flexDirection: `row` as const,
    marginTop: theme.spacing(1),
  },
  root: {
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
    marginTop: theme.spacing(1),
  },
});

export type EnhancedProps = {
  catalogueViewID: string | null | undefined;
  activeItems?: PartialOrganisation[];
  activeItemsLoading: boolean;
  addSubscriptionsDialogOpen: boolean;
  availableItems: PartialOrganisation[];
  availableItemsLoading: boolean;
  catalogueViewDetails?: CatalogueViewDetails;
  confirmUnsubscribeDialogOpen: boolean;
  onActivatePressed: () => void;
  onDeactivatePressed: () => void;
  setAddSubscriptionsDialogOpen: (dialogOpen: boolean) => void;
  setConfirmUnsubscribeDialogOpen: (dialogOpen: boolean) => void;
  setSubscribingOrganisationID: (selectIds: string[]) => void;
  setUnsubscribingOrganisationID: (selectIds: string[]) => void;
  subscribingOrganisationID: string[];
  unsubscribingOrganisationID: string[];
};

type Props = EnhancedProps & StyleProps;

/**
 * This Class will be wrapped with SubscribedOrganisationData which will provide
 * all the curation functionality for each organisation.
 */
class SubscribedOrganisationLayout extends React.Component<Props> {
  render() {
    const {
      activeItems,
      activeItemsLoading,
      addSubscriptionsDialogOpen,
      availableItems,
      availableItemsLoading,
      catalogueViewDetails,
      catalogueViewID,
      classes,
      confirmUnsubscribeDialogOpen,
      setAddSubscriptionsDialogOpen,
      setConfirmUnsubscribeDialogOpen,
      setUnsubscribingOrganisationID,
      setSubscribingOrganisationID,
      subscribingOrganisationID,
      unsubscribingOrganisationID,
      onDeactivatePressed,
      onActivatePressed,
    } = this.props;

    return (
      <div className={classes.root}>
        <SubscribedOrganisationList
          emptyMessage={
            catalogueViewID == null
              ? `Select a catalogue view`
              : `Catalogue view has not been published`
          }
          selectedIDs={unsubscribingOrganisationID}
          subscribedOrganisations={activeItems}
          loading={activeItemsLoading}
          title="Catalogue view has been published to:"
          onSelectedIDsChange={setUnsubscribingOrganisationID}
        />

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            color="secondary"
            disabled={unsubscribingOrganisationID.length === 0}
            onClick={() => setConfirmUnsubscribeDialogOpen(true)}
            startIcon={<Unsubscribe />}
          >
            Unpublish
          </Button>

          <AddSubscribedOrganisationsDialog
            availableItems={availableItems}
            availableItemsLoading={availableItemsLoading}
            catalogueViewID={catalogueViewID}
            setSubscribingOrganisationID={setSubscribingOrganisationID}
            subscribingOrganisationID={subscribingOrganisationID}
            open={addSubscriptionsDialogOpen}
            onActivatePressed={onActivatePressed}
            onClose={() => setAddSubscriptionsDialogOpen(false)}
          />

          <Button
            className={classes.button}
            color={"primary"}
            disabled={catalogueViewID == null}
            onClick={() => setAddSubscriptionsDialogOpen(true)}
            startIcon={<Publish />}
            variant="outlined"
          >
            Publish
          </Button>
        </div>

        <RemoveSubscriptionDialog
          open={confirmUnsubscribeDialogOpen}
          isDefault={
            (catalogueViewDetails && catalogueViewDetails.isGeneratedDefault) ||
            false
          }
          onUnsubscribedPressed={onDeactivatePressed}
          onClose={() => setConfirmUnsubscribeDialogOpen(false)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SubscribedOrganisationLayout);

import * as React from "react";
import DataTable from "./index";
import { EnhancedProps as DataTableProps } from "./BaseDataTable";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterMenu from "./FilterMenu";
import FilterChips from "./FilterChips";

export type Props<Row> = DataTableProps<Row> & {
  // The currently selected filter value for each column
  filter: {
    [key: string]: any;
  };
  // Define columns that can be filtered on, and optionally specific values that can be chosen for the filter
  filterOptions: {
    [key: string]: {
      label: string;
      values: any[];
    };
  };
  onFilterChange: (columnID: string, value?: any) => void;
  onResetFilters: () => void | Promise<void>;
};

type State = {
  menuAnchorEl?: HTMLButtonElement;
  menuOpen: boolean;
};

/**
 * Function to create HOC to add a filter button and menu to DataTable.
 * Enhanced component is does not store current filter and actual filtering of data is left to the user.
 * @returns HOC to wrap DataTable.
 */
export const withFilterMenu = () => (
  DataTableComponent: React.ComponentType<any>
) => {
  class DataTableWithFilterMenu<Row> extends React.PureComponent<
    Props<Row>,
    State
  > {
    constructor(props: Props<Row>) {
      super(props);
      this.state = {
        menuOpen: false,
      };
    }

    _handleFilterClick(event: React.SyntheticEvent<HTMLButtonElement>) {
      this.setState({
        menuAnchorEl: event.currentTarget,
        menuOpen: true,
      });
    }

    _handleMenuClose() {
      this.setState({
        menuAnchorEl: undefined,
        menuOpen: false,
      });
    }

    render() {
      const {
        data,
        filter,
        filterOptions,
        onFilterChange,
        onResetFilters,
        ...dataTableProps
      } = this.props;
      const { menuAnchorEl, menuOpen } = this.state;

      return (
        <React.Fragment>
          <DataTableComponent
            {...dataTableProps}
            data={data}
            ToolbarRightItems={
              <Tooltip title="Filter">
                <IconButton onClick={this._handleFilterClick.bind(this)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            }
            ToolbarLeftItems={
              <FilterChips
                filter={filter}
                onClearFilter={(columnID) =>
                  onFilterChange(columnID, undefined)
                }
              />
            }
          />
          <Popover
            open={menuOpen}
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              horizontal: `right`,
              vertical: `bottom`,
            }}
            transformOrigin={{
              horizontal: `right`,
              vertical: `top`,
            }}
            onClose={this._handleMenuClose.bind(this)}
          >
            <FilterMenu
              filter={filter}
              filterOptions={filterOptions}
              onFilterChange={onFilterChange}
              onResetFilters={onResetFilters}
            />
          </Popover>
        </React.Fragment>
      );
    }
  }

  return DataTableWithFilterMenu;
};

export default withFilterMenu()(DataTable);

import gql from "graphql-tag";
import ListQuerySelectField, {
  EnhancedProps as ListQuerySelectFieldProps,
} from "../generic/ListQuerySelectField";

export type EnhancedProps = ListQuerySelectFieldProps;

export const GET_INDUSTRY_LIST = gql`
  query IndustrySelectGetIndustryList {
    industries: getIndustryList {
      id
      name
    }
  }
`;

const IndustrySelect = ListQuerySelectField({
  dataPath: `industries`,
  itemPluralLabel: `industries`,
  itemTitleLabel: `Industry`,
  query: GET_INDUSTRY_LIST,
});

export default IndustrySelect;

import * as React from "react";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { get } from "lodash";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import gql from "graphql-tag";
import LoadingScreen from "../../generic/LoadingScreen";
import CallOnMount from "../../generic/CallOnMount";
import { InventoryGetWarehouse_getSelf_organisation_warehouse } from "./__generated__/InventoryGetWarehouse";
import withMutation from "../../generic/graphql/withMutation";
import { ERROR_NO_RECORDS_FOUND } from "../../../../helpers/constants";
import { warehouseInventoryLayoutFragments } from "./WarehouseInventoryLayout";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const GET_WAREHOUSE = gql`
  query InventoryGetWarehouse($warehouseID: String!, $organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          id
          ...WarehouseInventoryLayoutWarehouse
        }
      }
    }
  }
  ${warehouseInventoryLayoutFragments.warehouse}
`;

export const UPDATE_WAREHOUSE_PRODUCT_INVENTORY = gql`
  mutation UpdateWarehouseProductInventory(
    $organisationID: String!
    $warehouseID: String!
    $productID: String!
    $quantity: Int!
  ) {
    updateWarehouseProductInventory(
      organisation: $organisationID
      warehouse: $warehouseID
      product: $productID
      quantity: $quantity
    ) {
      quantity
    }
  }
`;

export type UpdateWarehouseProductInventoryFn = (values: {
  productID: string;
  quantity: number;
}) => Promise<void>;

export type ChildProps = {
  loading: boolean;
  warehouse: InventoryGetWarehouse_getSelf_organisation_warehouse;
  updateWarehouseProductInventory: UpdateWarehouseProductInventoryFn;
};

type EnhancedProps = {
  children: (arg0: ChildProps) => React.ReactNode;
  organisationID: string;
  warehouseID: string;
};

type Props = EnhancedProps &
  ChildProps & {
    error?: Error;
    openSnackbar: OpenSnackbarFn;
  };

export class WarehouseInventoryData extends React.PureComponent<Props> {
  render() {
    const {
      children,
      error,
      loading,
      openSnackbar,
      warehouse,
      updateWarehouseProductInventory,
    } = this.props;

    if (loading) {
      return <LoadingScreen />;
    } else if (error || !warehouse) {
      return (
        <CallOnMount
          callback={() =>
            openSnackbar(
              SnackbarType.error,
              `Failed to load warehouse inventory`
            )
          }
        />
      );
    }

    return children({
      loading,
      warehouse,
      updateWarehouseProductInventory,
    });
  }
}

const enhancer = compose<any, EnhancedProps>(
  graphql(GET_WAREHOUSE, {
    // @ts-ignore
    options: ({ organisationID, warehouseID }) => ({
      fetchPolicy: `network-only`,
      variables: {
        organisationID,
        warehouseID,
      },
    }),
    props: ({ data }) => {
      // @ts-ignore
      const { error, loading, getSelf } = data;

      return {
        error,
        loading,
        warehouse:
          !error && !loading
            ? get(getSelf, `organisation.warehouse`, undefined)
            : undefined,
      };
    },
  }),
  withMutation(UPDATE_WAREHOUSE_PRODUCT_INVENTORY, {
    // @ts-ignore
    mapMutationToProps: (
      updateWarehouseProductInventory: UpdateWarehouseProductInventoryFn
    ) => ({ updateWarehouseProductInventory }),
    mutationOptions: (
      { organisationID, warehouseID },
      [{ productID, quantity }]
    ) => ({
      variables: {
        organisationID,
        warehouseID,
        productID,
        quantity,
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE,
          variables: {
            organisationID,
            warehouseID,
          },
        },
      ],
      optimisticResponse: {
        updateWarehouseProductInventory: {
          __typename: `WarehouseInventoryResult`,
          quantity: 1,
        },
      },
    }),
    throwOnError: true,
    errorMessage: (error) =>
      get(error, `graphQLErrors[0].message`) === ERROR_NO_RECORDS_FOUND
        ? `Failed to update quantity, product may not be stocked`
        : `Failed to update quantity, please try again`,
  }),
  withOpenSnackbar
);

export default enhancer(WarehouseInventoryData);

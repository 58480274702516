import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import WarehouseName from "../WarehouseTitle";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import { WarehouseServiceAreasServiceArea as ServiceArea } from "./__generated__/WarehouseServiceAreasServiceArea";
import { WarehouseServiceAreasWarehouse as Warehouse } from "./__generated__/WarehouseServiceAreasWarehouse";
import ServiceAreaList from "./ServiceAreaList";
import AddServiceAreaList from "./AddServiceAreaList";
import FloatingActionButton from "../../generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDialog from "../../generic/ConfirmDialog";
import { SPACING_PAPER } from "../../../../helpers/constants";

const styles = (theme) => ({
  addButton: {
    alignSelf: `flex-start`,
  },
  addDialogPaper: {
    display: `flex`,
    flexDirection: `column` as const,
    height: theme.dimensions.staticDialogHeight,
    padding: theme.spacing(SPACING_PAPER),
  },
  root: {
    alignItems: `stretch`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
});

export type EnhancedProps = {
  activateItems: (ids: string[], data: { warehouseID: string }) => void;
  activeItems?: ServiceArea[];
  activeItemsLoading?: boolean;
  addDialogOpen: boolean;
  availableItems?: ServiceArea[];
  availableItemsLoading?: boolean;
  deactivateItems: (ids: string[], data: { warehouseID: string }) => void;
  removingServiceAreaID?: string;
  setAddDialogOpen: (open: boolean) => void;
  setRemovingServiceAreaID: (id?: string) => void;
  warehouse?: Warehouse;
  warehouseID: string;
};

type Props = EnhancedProps & {
  classes: {
    [className: string]: string;
  };
};

/**
 * Component to display a list of warehouse service areas with a dialog to add additional service areas.
 */
class WarehouseServiceAreasLayout extends React.PureComponent<Props> {
  handleSelectServiceArea(id: string) {
    const { activateItems, warehouseID, setAddDialogOpen } = this.props;

    activateItems([id], { warehouseID });
    setAddDialogOpen(false);
  }

  handleAcceptRemoveServiceArea() {
    const {
      deactivateItems,
      removingServiceAreaID,
      setRemovingServiceAreaID,
      warehouseID,
    } = this.props;

    if (removingServiceAreaID != null) {
      deactivateItems([removingServiceAreaID], { warehouseID });
      setRemovingServiceAreaID(undefined);
    }
  }

  render() {
    const {
      activeItems,
      activeItemsLoading,
      addDialogOpen,
      availableItems,
      availableItemsLoading,
      classes,
      removingServiceAreaID,
      setAddDialogOpen,
      setRemovingServiceAreaID,
      warehouse,
    } = this.props;

    return (
      <>
        <WarehouseName warehouse={warehouse} />
        <div className={classes.root}>
          <ServiceAreaList
            loading={activeItemsLoading}
            serviceAreas={activeItems}
            onPressDelete={(id) => setRemovingServiceAreaID(id)}
          />
          <FloatingActionButton onClick={() => setAddDialogOpen(true)}>
            <AddIcon />
          </FloatingActionButton>
          <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
            <Paper className={classes.addDialogPaper}>
              <AddServiceAreaList
                loading={availableItemsLoading}
                serviceAreas={availableItems}
                onSelectServiceArea={this.handleSelectServiceArea.bind(this)}
              />
            </Paper>
          </Dialog>
          <ConfirmDialog
            open={removingServiceAreaID != null}
            title="Remove Warehouse Service Area"
            text={`Do you really want to remove the service area?`}
            onAccept={this.handleAcceptRemoveServiceArea.bind(this)}
            onClose={() => setRemovingServiceAreaID(undefined)}
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(WarehouseServiceAreasLayout);

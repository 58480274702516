import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../../generic/form/index";
import TextInput from "../../generic/form/TextInput";
import CreateUpdateForm from "../../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../../generic/withCreateUpdateQueries";
import { GET_INDUSTRIES, industryListFragments } from "./IndustryList";
import { IndustryFormCreateIndustryVariables } from "./__generated__/IndustryFormCreateIndustry";
import { IndustryFormGetIndustry_industry as Industry } from "./__generated__/IndustryFormGetIndustry";
import { IndustryFormUpdateIndustryVariables } from "./__generated__/IndustryFormUpdateIndustry";
import { compose } from "recompose";

export const industryFormFragments = {
  industry: gql`
    fragment IndustryFormIndustry on Industry {
      id
      name
      description
    }
  `,
};

export const CREATE_INDUSTRY = gql`
  mutation IndustryFormCreateIndustry($name: String!, $description: String) {
    createIndustry(values: { name: $name, description: $description }) {
      ...IndustryFormIndustry
      ...IndustryListIndustry
    }
  }
  ${industryFormFragments.industry}
  ${industryListFragments.industry}
`;

export const UPDATE_INDUSTRY = gql`
  mutation IndustryFormUpdateIndustry(
    $id: String!
    $name: String
    $description: String
  ) {
    updateIndustry(
      id: $id
      values: { name: $name, description: $description }
    ) {
      ...IndustryFormIndustry
      ...IndustryListIndustry
    }
  }
  ${industryFormFragments.industry}
  ${industryListFragments.industry}
`;

export const GET_INDUSTRY = gql`
  query IndustryFormGetIndustry($id: String!) {
    industry: getIndustry(id: $id) {
      ...IndustryFormIndustry
    }
  }
  ${industryFormFragments.industry}
`;

const schema = yup.object({
  description: yup.string().label(`Description`).nullable(),
  name: yup.string().required().label(`Name`),
});

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  onDismiss: (force?: boolean) => void;
};

type Props = EnhancedProps & {
  createMutation: (
    industry: IndustryFormCreateIndustryVariables
  ) => Promise<void>;
  item?: Partial<Industry>;
  updateMutation: (
    industry: IndustryFormUpdateIndustryVariables
  ) => Promise<void>;
};

export class IndustryForm extends React.PureComponent<Props> {
  render() {
    const { BodyContainer, itemID, ...createUpdateFormProps } = this.props;

    return (
      // @ts-ignore
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        itemType="industry"
        itemTypeTitle="Industry"
        itemNameAccessor="name"
        {...createUpdateFormProps}
      >
        <FieldList component={BodyContainer}>
          <CustomField
            fullWidth
            autoFocus={itemID == null}
            name="name"
            label="Name"
            component={TextInput}
          />
          <CustomField
            fullWidth
            multiline
            name="description"
            label="Description"
            component={TextInput}
          />
        </FieldList>
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createIndustry`,
    createMutation: CREATE_INDUSTRY,
    getItemAccessPath: `industry`,
    getQuery: GET_INDUSTRY,
    getQueryVariables: ({ itemID }) => (itemID ? { id: itemID } : undefined),
    listAccessPath: `industries`,
    listQuery: GET_INDUSTRIES,
    updateMutation: UPDATE_INDUSTRY,
  })
);

export default enhancer(IndustryForm);

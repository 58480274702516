import * as React from "react";
import AutosaveForm from "../../generic/form/AutosaveForm";
import * as yup from "yup";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import { CustomField } from "../../generic/form/index";
import FieldList from "../../generic/form/FieldList";
import TextInputWithState from "../../generic/form/TextInputWithState";
import { CatalogueViewEditFormCatalogueView } from "./__generated__/CatalogueViewEditFormCatalogueView";
import { CatalogueViewUpdateCatalogueViewVariables } from "./__generated__/CatalogueViewUpdateCatalogueView";

export const catalogueViewEditFormFragments = {
  catalogueView: gql`
    fragment CatalogueViewEditFormCatalogueView on CatalogueView {
      id
      name
    }
  `,
};

const UPDATE_CATALOGUE_VIEW = gql`
  mutation CatalogueViewUpdateCatalogueView($id: String!, $name: String) {
    updateCatalogueView(id: $id, values: { name: $name }) {
      id
      name
    }
  }
`;

type EnhancedProps = {
  catalogueView: CatalogueViewEditFormCatalogueView | null | undefined;
};

type Props = EnhancedProps & {
  updateCatalogueView: (
    values: CatalogueViewUpdateCatalogueViewVariables
  ) => Promise<void>;
};

const schema = yup.object({
  name: yup.string().required().label(`Name`),
});

export class CatalogueViewEditForm extends React.PureComponent<Props> {
  render() {
    const { catalogueView, updateCatalogueView } = this.props;

    return (
      <AutosaveForm
        key={catalogueView ? catalogueView.id : null} // Reset state if catalogue view changes
        item={catalogueView}
        itemName="catalogue view"
        schema={schema}
        updateField={(fieldName, fieldValue) => {
          if (catalogueView) {
            return updateCatalogueView({
              id: catalogueView.id,
              name: fieldValue,
            });
          } else {
            return Promise.reject(
              new Error(`Can't update catalogue view before it is loaded`)
            );
          }
        }}
        hasChanged={(fieldName, fieldValue) =>
          catalogueView != null &&
          fieldName === `name` &&
          catalogueView.name !== fieldValue
        }
      >
        {({ getAutosaveFieldProps }) => (
          <FieldList>
            <CustomField
              name="name"
              label="Name"
              component={TextInputWithState}
              {...getAutosaveFieldProps(`name`)}
            />
          </FieldList>
        )}
      </AutosaveForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  graphql(UPDATE_CATALOGUE_VIEW, {
    props: ({ mutate }) => ({
      updateCatalogueView: (
        values: CatalogueViewUpdateCatalogueViewVariables
      ) =>
        mutate({
          variables: values,
        }),
    }),
  })
);

export default enhancer(CatalogueViewEditForm);

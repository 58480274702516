import * as React from "react";
import gql from "graphql-tag";
import { compose } from "recompose";
import { debounce } from "lodash";

import OperatingHoursRowLayout from "../warehouse/OperatingTimes/OperatingTimesRow";
import withCreateUpdateQueries from "../generic/withCreateUpdateQueries";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { FieldStatus } from "../generic/form/StatusIcon";
import getOptimisticID from "../../../helpers/getOptimisticID";
import { format24Hour, parse24Hour } from "../../../helpers/formatTime";
import {
  AddressInput,
  CreateOperationHoursInput,
  SnackbarType,
  UpdateOperationHoursInput,
} from "../../../__generated__/globalTypes";
import { CreateOperationHoursForAddressVariables } from "./__generated__/CreateOperationHoursForAddress";
import { UpdateOperationHoursForAddressVariables } from "./__generated__/UpdateOperationHoursForAddress";
import { OperationHoursRowDataOperationHours } from "./__generated__/OperationHoursRowDataOperationHours";

const SAVE_DEBOUNCE_TIME = 100;

export const operationHoursRowDataFragments = {
  operationHours: gql`
    fragment OperationHoursRowDataOperationHours on OperationHours {
      id
      dayOfTheWeek
      openingTime
      closingTime
    }
  `,
};

const CREATE_OPERATION_HOURS_FOR_ADDRESS = gql`
  mutation CreateOperationHoursForAddress(
    $values: CreateOperationHoursInput!
    $address: AddressInput!
  ) {
    createOperationHoursForAddress(values: $values, address: $address) {
      ...OperationHoursRowDataOperationHours
    }
  }
  ${operationHoursRowDataFragments.operationHours}
`;

const UPDATE_OPERATION_HOURS_FOR_ADDRESS = gql`
  mutation UpdateOperationHoursForAddress(
    $id: String!
    $values: UpdateOperationHoursInput!
  ) {
    updateOperationHoursForAddress(id: $id, values: $values) {
      ...OperationHoursRowDataOperationHours
    }
  }
  ${operationHoursRowDataFragments.operationHours}
`;

const DELETE_OPERATION_HOURS_FOR_ADDRESS = gql`
  mutation DeleteOperationHoursForAddress($id: String!) {
    deleteOperationHoursForAddress(id: $id) {
      id
    }
  }
`;

export type EnhancedProps = {
  dayOfTheWeek: string;
  operationHours?: OperationHoursRowDataOperationHours;
  organisationID: string;
  addressID: string;
};

type Props = EnhancedProps & {
  createMutation: (
    arg0: CreateOperationHoursForAddressVariables
  ) => Promise<void>;
  deleteMutation: (id: string) => Promise<void>;
  openSnackbar: OpenSnackbarFn;
  updateMutation: (
    arg0: UpdateOperationHoursForAddressVariables
  ) => Promise<void>;
};

type State = {
  closingTime: string;
  open: boolean;
  openingTime: string;
  status: FieldStatus;
};

/**
 * Component to manage the state and updates to a day's operation hours.
 */
export class OperationHoursRowData extends React.PureComponent<Props, State> {
  saveChanges: () => void;

  constructor(props: Props) {
    super(props);

    // @ts-ignore
    this.state = {
      ...this.getInitialState(),
      status: `normal`,
    };
    this.saveChanges = debounce(
      this.saveChangesImmediately.bind(this),
      SAVE_DEBOUNCE_TIME
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { operationHours } = this.props;
    const { operationHours: prevOperationHours } = prevProps;
    const { open } = this.state;
    const { open: prevOpen } = prevState;

    // Reinitialise state if operationHours is set/unset or the ID changes
    if (
      operationHours !== prevOperationHours &&
      (operationHours == null ||
        prevOperationHours == null ||
        operationHours.id !== prevOperationHours.id)
    ) {
      // @ts-ignore
      this.setState(this.getInitialState());
    }

    // Save when open is changed
    if (open !== prevOpen) {
      this.saveChanges();
    }
  }

  /**
   * @return {State} The initial state depending on operationHours prop.
   */
  getInitialState(): Partial<State> {
    const { operationHours } = this.props;
    if (operationHours) {
      return {
        closingTime: format24Hour(operationHours.closingTime) || ``,
        open: true,
        openingTime: format24Hour(operationHours.openingTime) || ``,
      };
    } else {
      return {
        closingTime: ``,
        open: false,
        openingTime: ``,
      };
    }
  }

  handleOpenChange(open: boolean) {
    this.setState({
      open,
      status: `normal`,
    });
  }

  handleTimesChange(openingTime: string, closingTime: string) {
    this.setState({
      closingTime,
      openingTime,
      status: `normal`,
    });
  }

  /**
   * Perform required mutation to save current state, if it is valid.
   * @return {void}
   */
  async saveChangesImmediately() {
    const {
      createMutation,
      dayOfTheWeek,
      deleteMutation,
      openSnackbar,
      operationHours,
      updateMutation,
      addressID,
    } = this.props;
    const { closingTime, open, openingTime } = this.state;

    const closingTimeParsed = parse24Hour(closingTime);
    const openingTimeParsed = parse24Hour(openingTime);

    if (closingTimeParsed == null || openingTimeParsed == null) {
      return;
    }

    // Determine which mutation to run
    let performMutation, mutationVariables;

    if (operationHours && !open) {
      // Remove the existing operation hours
      performMutation = deleteMutation;
      mutationVariables = operationHours.id as string;
    } else if (open) {
      if (!operationHours) {
        // Create operation hours
        performMutation = createMutation;
        const values: CreateOperationHoursInput = {
          dayOfTheWeek,
          openingTime: openingTimeParsed,
          closingTime: closingTimeParsed,
        };
        const address: AddressInput = {
          id: addressID,
        };
        mutationVariables = {
          values,
          address,
        } as CreateOperationHoursForAddressVariables;
      } else if (
        operationHours.openingTime !== openingTimeParsed ||
        operationHours.closingTime !== closingTimeParsed
      ) {
        // Update hours of existing OperationHours
        performMutation = updateMutation;
        const values: UpdateOperationHoursInput = {
          openingTime: openingTimeParsed,
          closingTime: closingTimeParsed,
        };
        mutationVariables = {
          id: operationHours.id,
          values,
        } as UpdateOperationHoursForAddressVariables;
      }
    }

    if (performMutation) {
      this.setState({
        status: `saving`,
      });
      try {
        await performMutation(mutationVariables as any);
        openSnackbar(
          SnackbarType.success,
          `Successfully updated operating times for ${dayOfTheWeek}`
        );
        this.setState({
          status: `saved`,
        });
      } catch (error) {
        openSnackbar(
          SnackbarType.error,
          `Failed to update operating times for ${dayOfTheWeek}`
        );
        this.setState({
          status: `failed`,
        });
      }
    }
  }

  render() {
    const { dayOfTheWeek } = this.props;
    const { closingTime, open, openingTime, status } = this.state;

    return (
      <OperatingHoursRowLayout
        closingTime={closingTime}
        dayOfTheWeek={dayOfTheWeek}
        disabled={status === `saving`}
        open={open}
        openingTime={openingTime}
        status={status}
        onOpenChange={this.handleOpenChange.bind(this)}
        onTimesBlur={() => this.saveChanges()}
        onTimesChange={this.handleTimesChange.bind(this)}
      />
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createOperationHoursForAddress`,
    createMutation: CREATE_OPERATION_HOURS_FOR_ADDRESS,
    createOptimisticResponse: ({
      values,
    }: CreateOperationHoursForAddressVariables) => {
      return {
        createOperationHoursForAddress: {
          __typename: `OperationHours`,
          id: getOptimisticID(),
          closingTime: values.closingTime,
          dayOfTheWeek: values.dayOfTheWeek,
          openingTime: values.openingTime,
        },
      };
    },
    deleteMutation: DELETE_OPERATION_HOURS_FOR_ADDRESS,
    deleteOptimisticResponse: (id, { operationHours }: EnhancedProps) => {
      return {
        deleteOperationHoursForAddress: {
          __typename: `OperationHours`,
          id,
          closingTime: operationHours.closingTime,
          dayOfTheWeek: operationHours.dayOfTheWeek,
          openingTime: operationHours.openingTime,
        },
      };
    },
    listAccessPath: `getSelf.organisation.address.operationHoursList`,
    listQuery: gql`
      query GetAddress($organisationId: String!, $addressId: String!) {
        getSelf {
          id
          organisation(id: $organisationId) {
            id
            address(id: $addressId) {
              id
              operationHoursList {
                id
                closingTime
                openingTime
                dayOfTheWeek
              }
            }
          }
        }
      }
    `,
    listQueryVariables: ({ organisationID, addressID }) => ({
      organisationId: organisationID,
      addressId: addressID,
    }),
    updateMutation: UPDATE_OPERATION_HOURS_FOR_ADDRESS,
    updateOptimisticResponse: (
      { id, values }: UpdateOperationHoursForAddressVariables,
      { dayOfTheWeek }: EnhancedProps
    ) => {
      return {
        updateOperationHoursForAddress: {
          __typename: `OperationHours`,
          id,
          closingTime: values.closingTime,
          dayOfTheWeek,
          openingTime: values.openingTime,
        },
      };
    },
  }),
  withOpenSnackbar
);

export default enhancer(OperationHoursRowData);

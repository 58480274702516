import { DocumentNode } from "graphql";
import { graphql, OperationOption } from "react-apollo";
import { compose, lifecycle, withProps } from "recompose";
import withOpenSnackbar from "../snackbar/withOpenSnackbar";
import { get, omit } from "lodash";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export type Options = OperationOption<any, any, any, any> & {
  /*
   * Map from prop names to the path within the query result where their value should be read from.
   * The value passed to the props may be null, and will always be null if the query is skipped.
   */
  dataPaths?: {
    [propName: string]: string;
  };
  // Message to show on error, as either a string or a function mapping the error (null if due to null result) and props to a string.
  errorMessage:
    | string
    | ((error: any | null | undefined, props: any) => string);
  // Whether a null value for a result should be considered an error. Default is true.
  errorOnNull?: boolean;
  // Name of prop to assign a boolean value indicating loading state. Always false when the query is skipped.
  loadingProp?: string;
};

/**
 * HOC to perform a query and handle errors.
 * @param {DocumentNode} query The query to perform.
 * @param {Options} options Options for configuring the query, supports all of Apollo's graphql HOC options.
 * @return {HOC} Function for creating enhanced component.
 */
const withQuery = (query: DocumentNode, options: Options) =>
  compose<any, any>(
    withProps((ownProps) => ({
      withQuerySkipped:
        typeof options.skip === `function`
          ? options.skip(ownProps)
          : options.skip,
    })),
    withOpenSnackbar,
    graphql(query, {
      ...omit(
        options,
        `props`,
        `dataPaths`,
        `errorMessage`,
        `errorOnNull`,
        `loadingProp`
      ),
      props: (props) => {
        const {
          data: { loading, error },
        } = props;
        const resultProps: any = {
          withQueryError: error,
          withQueryLoading: loading,
          withQueryNullResult: false,
        };
        // Add props from the provided props function if it was specified
        if (options.props) {
          Object.assign(resultProps, options.props(props));
        }
        // Add props based on the dataPaths configuration if specified
        const { dataPaths } = options;
        if (dataPaths) {
          // Loop over keys and get values from result
          for (const dataKey of Object.keys(dataPaths)) {
            const value = get(props.data, dataPaths[dataKey], null);
            resultProps[dataKey] = value;
            // Set withQueryNullResult to true if a value is null
            if (value === null) {
              resultProps.withQueryNullResult = true;
            }
          }
        }

        return resultProps;
      },
    }),
    withProps((ownProps) => {
      const {
        // @ts-ignore
        withQueryError,
        // @ts-ignore
        withQueryLoading,
        // @ts-ignore
        withQueryNullResult,
        // @ts-ignore
        withQuerySkipped,
      } = ownProps;
      const resultProps = {};

      // Work out whether to show an error message
      // @ts-ignore
      resultProps.withQueryShowError =
        !withQuerySkipped &&
        (withQueryError ||
          (options.errorOnNull !== false &&
            !withQueryLoading &&
            withQueryNullResult));
      if (options.loadingProp) {
        // Work out whether loading is in progress
        resultProps[options.loadingProp] =
          !withQuerySkipped && withQueryLoading === true;
      }
      // Pass null to data props if the query is skipped
      if (withQuerySkipped && options.dataPaths) {
        for (const dataKey of Object.keys(options.dataPaths)) {
          resultProps[dataKey] = null;
        }
      }

      return resultProps;
    }),
    lifecycle({
      componentDidUpdate(prevProps) {
        // If withQueryShowError changed to true, show an error message
        // @ts-ignore
        if (this.props.withQueryShowError && !prevProps.withQueryShowError) {
          const errorMessage =
            typeof options.errorMessage === `function`
              ? options.errorMessage(
                  // @ts-ignore
                  this.props.withQueryError || null,
                  this.props
                )
              : options.errorMessage;
          // @ts-ignore
          this.props.openSnackbar(SnackbarType.error, errorMessage);
        }
      },
    })
  );

export default withQuery;

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StyleProps } from "../types";
import LoadingScreen from "../generic/LoadingScreen";
import PaperSection from "../generic/PaperSection";
import UserModerationMessage from "../profile/UserModerationMessage";
import ProfileForm from "../profile/ProfileForm";
import CreateOrganisationForm from "../profile/CreateOrganisationForm";
import {
  ProfileDataGetSelf_user as ProfileUser,
  ProfileDataGetSelf_user_organisationList as ProfileOrganisation,
} from "./__generated__/ProfileDataGetSelf";
import { UserStatus } from "../../../helpers/user/getStatus";

const styles = () => ({
  root: {
    alignItems: `stretch`,
    alignSelf: `stretch`,
    display: `flex`,
    flexDirection: `column` as const,
  },
});

type EnhancedProps = {
  loading: boolean;
  organisation?: ProfileOrganisation;
  user?: ProfileUser;
  userStatus?: UserStatus;
};

type Props = StyleProps & EnhancedProps;

class ProfileLayout extends React.PureComponent<Props> {
  render() {
    const { classes, loading, organisation, user, userStatus } = this.props;

    return (
      <div className={classes.root}>
        {loading && <LoadingScreen />}
        <UserModerationMessage userStatus={userStatus} />
        {user && (
          <PaperSection>
            <ProfileForm user={user} />
          </PaperSection>
        )}
        {user && (
          <PaperSection noGutter heading="Organisation">
            <Typography gutterBottom variant="caption">
              You will not be able to change the organisation name after saving
              it.
            </Typography>
            <CreateOrganisationForm organisation={organisation} />
          </PaperSection>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProfileLayout);

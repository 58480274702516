import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import CatalogueViewLayout from "../../components/product/catalogueView/CatalogueViewLayoutData";
import { Link, match, Route } from "react-router-dom";
import FormDialog from "../../components/generic/FormDialog";
import CreateCatalogueViewForm from "../../components/product/catalogueView/CreateForm";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import withQueryParams from "../../components/generic/withQueryParams";
import { get } from "lodash";
import { compose } from "recompose";
import {
  catalogueViewEditRoute,
  catalogueViewFormRoute,
  catalogueViewsRoute,
} from "../index";

type MatchParams = {
  organisationID: string;
};

type QueryParams = {
  catalogueViewID?: string;
};

type EnhancedProps = {
  history: H.History;
  location: H.Location;
  match: match<MatchParams>;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (arg0: Partial<QueryParams>) => void;
};

class CatalogueViewList extends React.PureComponent<Props> {
  handleCatalogueViewIDChange(catalogueViewID?: string) {
    const { setQueryParams } = this.props;

    setQueryParams({ catalogueViewID });
  }

  render() {
    const {
      history,
      match,
      queryParams: { catalogueViewID },
    } = this.props;
    const { organisationID } = match.params;
    if (!organisationID) {
      return null;
    }

    return (
      <MainPanel title="Catalogue Views">
        <CatalogueViewLayout
          organisationID={organisationID}
          catalogueViewID={catalogueViewID}
          onPressEdit={() =>
            catalogueViewID &&
            history.push(
              catalogueViewEditRoute(organisationID, catalogueViewID)
            )
          }
          onCatalogueViewIDChange={this.handleCatalogueViewIDChange.bind(this)}
        />
        <Route path={catalogueViewFormRoute(organisationID)}>
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              onClose={(result) => {
                const catalogueViewID: string | null | undefined = get(
                  result,
                  `data.createCatalogueView.id`
                );
                if (catalogueViewID != null) {
                  history.push(
                    catalogueViewEditRoute(organisationID, catalogueViewID)
                  );
                } else {
                  history.push(catalogueViewsRoute(organisationID));
                }
              }}
            >
              {({ handleClose, ...rest }) => (
                <CreateCatalogueViewForm
                  organisationID={organisationID}
                  onDismiss={handleClose}
                  {...rest}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          // @ts-ignore
          to={catalogueViewFormRoute(organisationID)}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());

export default enhancer(CatalogueViewList);

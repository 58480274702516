import React from "react";

import { Route, useHistory } from "react-router-dom";
import {
  buyStepRoute,
  buyStepShippingBatchRoute,
  ROUTE_PARAMETERS,
} from "../../index";
import { ORDER_STEP_SHIPPING } from "../../../../helpers/trading/orderStep";
import ShippingData from "../../../components/trading/shipping/ShippingData";
import FormDialog from "../../../components/generic/FormDialog";
import AddShippingCarrierData from "../../../components/trading/shipping/AddShippingCarrierData";

type Props = {
  organisationID: string;
  onNext: (orderID: string) => void;
  onBack: () => void;
};

export const ShippingRoute = ({ organisationID, onNext, onBack }: Props) => {
  const history = useHistory();

  return (
    <>
      <ShippingData
        organisationID={organisationID}
        onBack={onBack}
        onNext={onNext}
        onEditPressed={(shippingBatchID: string) =>
          history.push(
            buyStepShippingBatchRoute(
              organisationID,
              ORDER_STEP_SHIPPING,
              shippingBatchID
            )
          )
        }
      />

      <Route
        path={buyStepShippingBatchRoute(
          ROUTE_PARAMETERS.ORGANISATION_ID,
          ROUTE_PARAMETERS.STEP,
          ROUTE_PARAMETERS.SHIPPING_BATCH_ID
        )}
      >
        {({ match: formMatch }) => {
          const shippingBatchID =
            formMatch && formMatch.params.shippingBatchID != null
              ? formMatch.params.shippingBatchID
              : undefined;

          return (
            <FormDialog
              open={formMatch != null}
              onClose={() =>
                history.push(buyStepRoute(organisationID, `shipping`))
              }
            >
              {({ handleClose, ...rest }) => (
                <AddShippingCarrierData
                  organisationID={organisationID}
                  shippingBatchID={shippingBatchID}
                  onDismiss={handleClose}
                  onCancelPressed={() =>
                    history.push(buyStepRoute(organisationID, `shipping`))
                  }
                  {...rest}
                />
              )}
            </FormDialog>
          );
        }}
      </Route>
    </>
  );
};

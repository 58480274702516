import gql from "graphql-tag";
import { compose } from "recompose";
import withMutation from "../../generic/graphql/withMutation";
import Menu from "./Menu";

const GET_NOTIFICATION_COUNT_FOR_TYPE = gql`
  query GetNotificationCountByType($notificationTypes: [String!]) {
    getNotificationCountByType(notificationTypes: $notificationTypes)
  }
`;

const MARK_NOTIFICATION_TYPE_AS_READ = gql`
  mutation MarkNotificationAsRead($notificationType: String!) {
    markNotificationTypeAsRead(notificationType: $notificationType)
  }
`;

type EnhancedProps = {
  open?: boolean;
  organisationID: string;
  organisationRole: string;
  orderID?: string | null | undefined;
  onClose: () => void;
};

const enhancer = compose<any, EnhancedProps>(
  withMutation(MARK_NOTIFICATION_TYPE_AS_READ, {
    mapMutationToProps: (markNotificationAsRead) => ({
      markNotificationAsRead,
    }),
    errorMessage: `Failed to mark notifications as read`,
    mutationOptions: (_, [notificationType]) => ({
      variables: {
        notificationType: notificationType,
      },
      refetchQueries: [
        {
          query: GET_NOTIFICATION_COUNT_FOR_TYPE,
          variables: {
            notificationTypes: [notificationType],
          },
        },
      ],
    }),
  })
);
export default enhancer(Menu);

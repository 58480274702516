import * as React from "react";
import * as H from "history";
import MainPanel from "../../../components/app/MainPanel";
import WarehouseOverview from "../../../components/warehouse/OverviewLayout";
import { Link, match, Route } from "react-router-dom";
import FloatingActionButton from "../../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import FormDialog from "../../../components/generic/FormDialog";
import WarehouseForm from "../../../components/warehouse/WarehouseForm";
import {
  ROUTE_PARAMETERS,
  warehousesFormRoute,
  warehousesHoursRoute,
  warehousesInventoryRoute,
  warehousesRoute,
  warehousesServiceAreasRoute,
  warehousesViewRoute,
} from "../../index";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

class Overview extends React.PureComponent<Props> {
  render() {
    const { history, match } = this.props;
    const { organisationID } = match.params;
    if (organisationID == null) {
      return null;
    }

    return (
      <MainPanel title="Warehouses">
        <WarehouseOverview
          organisationID={organisationID}
          onPressView={(warehouseID) =>
            history.push(warehousesViewRoute(organisationID, warehouseID))
          }
          onPressEdit={(warehouseID) =>
            history.push(warehousesFormRoute(organisationID, warehouseID))
          }
          onPressEditInventory={(warehouseID) =>
            history.push(warehousesInventoryRoute(organisationID, warehouseID))
          }
          onPressEditOperatingTimes={(warehouseID) =>
            history.push(warehousesHoursRoute(organisationID, warehouseID))
          }
          onPressEditServiceAreas={(warehouseID) =>
            history.push(
              warehousesServiceAreasRoute(organisationID, warehouseID)
            )
          }
        />
        <Route
          path={warehousesFormRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            `${ROUTE_PARAMETERS.WAREHOUSE_ID}?`
          )}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.warehouseID != null
                    ? formMatch.params.warehouseID
                    : undefined,
              }}
              onClose={() => history.push(warehousesRoute(organisationID))}
            >
              {({ handleClose, ...rest }) => (
                <WarehouseForm
                  organisationID={organisationID || ``}
                  onDismiss={handleClose}
                  {...rest}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          // @ts-ignore
          to={warehousesFormRoute(organisationID)}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

export default Overview;

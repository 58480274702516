import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { Column } from "../../generic/DataTable/BaseDataTable";
import UserActionButtons from "./UserActionButtons";
import getUserStatus, {
  getUserStatusFragment,
} from "../../../../helpers/user/getStatus";
import getUserRoles, {
  getUserRolesFragment,
} from "../../../../helpers/user/getRoles";
import { get, uniq } from "lodash";
import { UserManagementTableUser as User } from "./__generated__/UserManagementTableUser";
import DataTableWithMenu from "../../generic/DataTable/WithMenu";

const userOrganisationNameAccessor = `organisationList[0].name`;

export const userManagementTableFragments = {
  user: gql`
    fragment UserManagementTableUser on User {
      id
      fullName
      username
      organisationList {
        id
        name
      }
      telephoneNumber
      ...GetUserStatusUser
      ...GetUserRolesUser
    }
    ${getUserStatusFragment}
    ${getUserRolesFragment}
  `,
};

const styles = () => ({
  table: {
    flex: 1,
  },
});

const columns: Column<User>[] = [
  {
    Header: `Name`,
    id: `fullName`,
  },
  {
    Header: `Email Address`,
    id: `username`,
  },
  {
    Header: `Organisation`,
    id: userOrganisationNameAccessor,
  },
  {
    Header: `Telephone Number`,
    id: `telephoneNumber`,
  },
  {
    Header: `Status`,
    id: `status`,
  },
];

type Props = {
  classes: {
    [className: string]: string;
  };
  loading: boolean;
  onApproveUser: (user: User) => void;
  onDeleteUser: (user: User) => void;
  onUnapproveUser: (user: User) => void;
  onPressEdit: (userID: string) => void;
  users: User[];
};

class UserManagementTable extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      users,
      loading,
      onApproveUser,
      onUnapproveUser,
      onDeleteUser,
      onPressEdit,
    } = this.props;

    // Add status to rows
    const data = users.map((user) => ({
      ...user,
      status: getUserStatus(user, getUserRoles(user)),
    }));

    const columnsWithActions = [
      ...columns,
      {
        cellProps: {
          padding: `checkbox`,
        },
        Cell: ({ row }) => (
          <UserActionButtons
            status={row.status}
            onApprove={() => onApproveUser(row)}
            onDelete={() => onDeleteUser(row)}
            onUnapprove={() => onUnapproveUser(row)}
          />
        ),
        Header: ``,
        compact: true,
        id: `actions`,
      },
    ];

    return (
      <DataTableWithMenu
        className={classes.table}
        data={data}
        columns={columnsWithActions}
        loading={loading}
        emptyMessage="No users found"
        idAccessor="id"
        filterOptions={{
          status: {
            label: `Status`,
            values: [`New`, `Pending`, `Approved`],
          },
          [userOrganisationNameAccessor]: {
            label: `Organisation`,
            values: uniq(
              data.map((row) => get(row, userOrganisationNameAccessor, `None`))
            ),
          },
        }}
        menuActions={[
          {
            perform: onPressEdit,
            text: `Edit`,
          },
        ]}
      />
    );
  }
}

export default withStyles(styles)(UserManagementTable);

import * as React from "react";
import { Theme, withStyles } from "@material-ui/core";
import { StyleProps } from "../../types";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { Route } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { ExpandLess, ExpandMore, SvgIconComponent } from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";

const styles = (theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
});

type Props = StyleProps & {
  children: React.ReactNode;
  Icon?: SvgIconComponent;
  open?: boolean;
  openPath: string | string[];
  text: string;
  onClick: () => void;
};

const NestedMenuItem = (props: Props) => {
  const { children, classes, Icon, open, openPath, text, onClick } = props;

  return (
    <Route path={openPath}>
      {({ match }) => {
        const isOpen = open || Boolean(match);

        return (
          <React.Fragment>
            <ListItem button onClick={onClick}>
              {Icon && (
                <ListItemIcon className={classes.icon}>
                  <Icon />
                </ListItemIcon>
              )}

              <ListItemText primary={text} />

              <Fade in={!match}>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </Fade>
            </ListItem>

            <Collapse unmountOnExit in={isOpen} timeout="auto">
              <List disablePadding component="div">
                {children}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }}
    </Route>
  );
};

export default withStyles(styles)(NestedMenuItem);

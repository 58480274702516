import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { UserStatus } from "../../../../helpers/user/getStatus";
import { StyleProps } from "../../types";

const styles = (theme) => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },

  actionContainer: {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `row` as const,
  },
});

type EnhancedProps = {
  onApprove: () => void;
  onDelete: () => void;
  onUnapprove: () => void;
  status: UserStatus;
};

type Props = StyleProps & EnhancedProps;

/**
 * Component for action buttons for a user.
 */
class UserActionButtons extends React.PureComponent<Props> {
  render() {
    const { classes, onApprove, onDelete, onUnapprove, status } = this.props;
    const approved = status === `Approved`;

    return (
      <div className={classes.actionContainer}>
        {!approved && (
          <Tooltip title="Approve">
            <IconButton className={classes.actionButton} onClick={onApprove}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
        {approved && (
          <Tooltip title="Ban">
            <IconButton className={classes.actionButton} onClick={onUnapprove}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton className={classes.actionButton} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles)(UserActionButtons);

import * as React from "react";
import Typography from "@material-ui/core/Typography";

type Props = {
  children: string;
};

const MainTitle = (props: Props) => (
  <Typography gutterBottom variant="h4" {...props} />
);

export default MainTitle;

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import InfoSnackbar from "./InfoSnackbar";
import { compose } from "recompose";
import { SnackbarContainerGetSnackbar } from "./__generated__/SnackbarContainerGetSnackbar";

const GET_SNACKBAR = gql`
  query SnackbarContainerGetSnackbar {
    getSnackbar @client {
      message
      open
      type
    }
  }
`;

const CLOSE_SNACKBAR = gql`
  mutation SnackbarContainerCloseSnackbar {
    closeSnackbar @client {
      open
    }
  }
`;

const enhancer = compose<any, any>(
  graphql<any, SnackbarContainerGetSnackbar>(GET_SNACKBAR, {
    // @ts-ignore
    props: ({ data: { getSnackbar: snackbar, loading, error } }) => {
      if (loading || error || !snackbar) {
        return {
          message: ``,
          open: false,
          type: `info`,
        };
      } else {
        return snackbar;
      }
    },
  }),
  graphql(CLOSE_SNACKBAR, {
    props: ({ mutate }) => ({
      onClose: () => {
        mutate({});
      },
    }),
  })
);

export default enhancer(InfoSnackbar);

import * as React from "react";
import Radio from "@material-ui/core/Radio";
import DataTable from "./index";
import { Column, EnhancedProps as DataTableProps } from "./BaseDataTable";

export type Props<Row> = DataTableProps<Row> & {
  onSelectedIDChange: (selectedID: string) => void;
  selectedID?: string;
  classes?: any;
};

/**
 * Function to create HOC to add a radio button column to DataTable.
 * @returns HOC to wrap DataTable.
 */
export const withRadioSelection = <Row,>() => (
  DataTableComponent: React.ComponentType<any>
) => {
  class DataTableWithRadioSelection extends React.PureComponent<Props<Row>> {
    render() {
      const {
        columns,
        selectedID,
        onSelectedIDChange,
        ...dataTableProps
      } = this.props;

      const enhancedColumns: Column<Row>[] = [
        ...columns,
        {
          Cell: ({ id }) => (
            <Radio
              checked={selectedID === id}
              onClick={() => onSelectedIDChange(id)}
            />
          ),
          Header: null,
          cellProps: {
            padding: `checkbox`,
          },
          compact: true,
          disableSortable: true,
          id: `_radio`,
        },
      ];

      return (
        <DataTableComponent columns={enhancedColumns} {...dataTableProps} />
      );
    }
  }

  return DataTableWithRadioSelection;
};

export default withRadioSelection()(DataTable);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import Fab, { FabProps } from "@material-ui/core/Fab";
import { StyleProps } from "../types";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "recompose";
import { SPACING_FAB } from "../../../helpers/constants";

export const GET_SNACKBAR = gql`
  query FloatingActionButtonGetSnackbar {
    getSnackbar @client {
      open
    }
  }
`;

const styles = (theme) => ({
  button: {
    bottom: theme.spacing(SPACING_FAB),
    position: `absolute` as const,
    right: theme.spacing(SPACING_FAB),
  },
  moveDown: {
    transform: `translate3d(0, 0, 0)`,
    transition: theme.transitions.create(`transform`, {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  moveUp: {
    transform: `translate3d(0, -${theme.dimensions.snackbarHeight}px, 0)`,
    transition: theme.transitions.create(`transform`, {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
});

type EnhancedProps = FabProps & {
  children: React.ReactNode;
  component?: React.ElementType;
};

type Props = StyleProps &
  (EnhancedProps & {
    isSnackbarOpen: boolean;
  });

/**
 * Button floating in the bottom-right hand corner.
 * Automatically moved out of the way of the snackbar.
 */
class FloatingActionButton extends React.PureComponent<Props> {
  render() {
    const { children, classes, isSnackbarOpen, ...rest } = this.props;
    const fabClasses = `${classes.button} ${
      isSnackbarOpen ? classes.moveUp : classes.moveDown
    }`;

    return (
      <Fab className={fabClasses} color="primary" {...rest}>
        {children}
      </Fab>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(
  withStyles(styles),
  graphql(GET_SNACKBAR, {
    // @ts-ignore
    props: ({ data: { getSnackbar: snackbar, loading, error } }) => ({
      isSnackbarOpen: !loading && !error && snackbar && snackbar.open,
    }),
  })
);

export default enhancer(FloatingActionButton);

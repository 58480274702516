import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import { match } from "react-router-dom";
import OrganisationDashboardData from "../../components/organisation/dashboard/OrganisationDashboardData";
import OrganisationDashboardLayout from "../../components/organisation/dashboard/OrganisationDashboardLayout";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  match: match<MatchProps>;
};

class Dashboard extends React.Component<Props> {
  render() {
    const { organisationID } = this.props.match.params;

    return (
      <MainPanel fullWidth title="Dashboard">
        {organisationID && (
          <OrganisationDashboardData organisationID={organisationID}>
            {({ loading, organisation }) => (
              <OrganisationDashboardLayout
                loading={loading}
                organisation={organisation}
              />
            )}
          </OrganisationDashboardData>
        )}
      </MainPanel>
    );
  }
}

export default Dashboard;

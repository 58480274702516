import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";
import classnames from "classnames";
import MainTitle from "./MainTitle";
import { Breadcrumbs } from "./Breadcrumbs";
import { SPACING_MAIN } from "../../../helpers/constants";

const styles = (theme) => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(1),
  },
  root: {
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `column` as const,
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works
    padding: theme.spacing(SPACING_MAIN),
  },
  rootFullWidth: {
    alignItems: `stretch`,
  },
  toolbarBuffer: theme.mixins.toolbar,
});

type Props = StyleProps & {
  children?: React.ReactNode;
  fullWidth?: boolean;
  title?: string;
};

const MainPanel = ({ classes, children, fullWidth, title, ...rest }: Props) => (
  <main
    className={classnames(classes.root, fullWidth && classes.rootFullWidth)}
    {...rest}
  >
    <div className={classes.toolbarBuffer} />
    <Breadcrumbs className={classes.breadcrumbs} />
    {title && <MainTitle>{title}</MainTitle>}
    {children}
  </main>
);

export default withStyles(styles)(MainPanel);

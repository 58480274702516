import * as React from "react";
import { withStyles } from "@material-ui/core";
import { StyleProps } from "../../../types";
import CurationLayout from "../../../generic/curation/CurationLayout";
import ItemListWithHeaders from "../../../generic/ItemListWithHeaders";
import {
  EnhancedProps as ItemListWithSelectionProps,
  withSelection,
} from "../../../generic/ItemListWithSelection";
import ItemListWithMenu from "../../../generic/ItemListWithMenu";
import { OrganisationIndustry as Organisation } from "./__generated__/OrganisationIndustry";

const ItemListWithSelection: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithHeaders as any
);
const ActiveList: React.ComponentType<ItemListWithSelectionProps> = withSelection(
  ItemListWithMenu as any
);

const styles = () => ({});

type Props = StyleProps & {
  activeItems?: Organisation[];
  activeItemsLoading?: boolean;
  availableItems?: Organisation[];
  availableItemsLoading?: boolean;
  onActivatePressed: () => Promise<void>;
  onPressMarketplaces: (marketplaceID: string) => void;
  onSelectedAvailableItemsChange: (selectIDs: string[]) => void;
  organisationID?: string;
  selectedAvailableItems: string[];
  onDeactivatePressed: () => Promise<void>;
  deactivateEnabled?: boolean;
};

class OrganisationIndustryLayout extends React.PureComponent<Props> {
  render() {
    const { onPressMarketplaces, ...curationLayoutProps } = this.props;

    return (
      <CurationLayout
        activateEnabled
        AvailableListComponent={ItemListWithSelection}
        ActiveListComponent={ActiveList}
        activeListProps={{
          emptyMessage: `There are no active industry memberships`,
          mainHeader: `Active Industry Memberships`,
          menuActions: [
            {
              perform: onPressMarketplaces,
              text: `Marketplaces`,
            },
          ],
        }}
        availableListProps={{
          mainHeader: `Available Industries`,
        }}
        itemName="industry"
        itemNamePlural="industries"
        deactivateEnabled={true}
        {...curationLayoutProps}
      />
    );
  }
}

export default withStyles(styles)(OrganisationIndustryLayout);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import MarketplaceCuration from "../../components/admin/marketplace/MarketplaceCuration";
import MarketplaceForm from "../../components/admin/marketplace/MarketplaceForm";
import { Link, Route } from "react-router-dom";
import FormDialog from "../../components/generic/FormDialog";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddIcon from "@material-ui/icons/Add";
import withQueryParams from "../../components/generic/withQueryParams";
import { compose } from "recompose";
import {
  adminCategoriesRoute,
  adminMarketplacesFormRoute,
  adminMarketplacesRoute,
  ROUTE_PARAMETERS,
} from "../index";

type QueryParams = {
  industryID?: string;
};

type EnhancedProps = {
  history: H.History;
  location: Location;
};

type Props = EnhancedProps & {
  queryParams: QueryParams;
  setQueryParams: (arg0: Partial<QueryParams>) => void;
};

class Marketplace extends React.PureComponent<Props> {
  handleIndustryIDChange(industryID?: string) {
    const { setQueryParams } = this.props;

    setQueryParams({ industryID });
  }

  render() {
    const {
      history,
      location,
      queryParams: { industryID },
      setQueryParams,
    } = this.props;

    return (
      <MainPanel title="Marketplaces">
        <MarketplaceCuration
          industryID={industryID}
          onIndustryIDChange={(industryID) => {
            setQueryParams({ industryID });
          }}
          onPressEdit={(marketplaceID) =>
            history.push(
              adminMarketplacesFormRoute(marketplaceID) + location.search
            )
          }
          onPressCategories={(marketplaceID) =>
            history.push(
              `${adminCategoriesRoute()}?industryID=${
                industryID || ``
              }&marketplaceID=${marketplaceID}`
            )
          }
        />
        <Route
          path={adminMarketplacesFormRoute(
            `${ROUTE_PARAMETERS.MARKETPLACE_ID}?`
          )}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null && industryID != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.marketplaceID != null
                    ? formMatch.params.marketplaceID
                    : undefined,
              }}
              onClose={() =>
                history.push(adminMarketplacesRoute() + location.search)
              }
            >
              {({ handleClose, ...rest }) => (
                <MarketplaceForm
                  {...rest}
                  industryID={industryID}
                  onDismiss={handleClose}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          disabled={industryID == null}
          // @ts-ignore
          to={adminMarketplacesFormRoute() + location.search}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

const enhancer = compose<Partial<Props>, EnhancedProps>(withQueryParams());

export default enhancer(Marketplace);

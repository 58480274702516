import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { StyleProps } from "../../types";
import UploadSummaryText, { UploadSummary } from "./UploadSummaryText";
import { compose, withState } from "recompose";

const styles = () => ({
  root: {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `row` as const,
  },
});

export type EnhancedProps = {
  itemTitleText: string;
  itemPluralText: string;
  uploadSummary?: UploadSummary;
};

type Props = EnhancedProps &
  StyleProps & {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
  };

class UploadSummaryLink extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      itemTitleText,
      itemPluralText,
      dialogOpen,
      setDialogOpen,
      uploadSummary,
    } = this.props;

    if (!uploadSummary) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Typography>
          Upload {uploadSummary.success ? `succeeded` : `failed`}
        </Typography>
        <Button onClick={() => setDialogOpen(true)}>View Summary</Button>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{itemTitleText} Upload Summary</DialogTitle>
          <DialogContent>
            <UploadSummaryText
              itemTitleText={itemTitleText}
              itemPluralText={itemPluralText}
              uploadSummary={uploadSummary}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Done</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withStyles(styles),
  withState(`dialogOpen`, `setDialogOpen`, false)
);

export default enhancer(UploadSummaryLink);

import OrganisationMarketplaceLayout from "./OrganisationMarketplaceLayout";
import gql from "graphql-tag";
import { compose } from "recompose";
import withCurationQueries from "../../../generic/curation/withCurationQueries";
import withActivateHandler from "../../../generic/curation/withActivateHandler";
import withDeactivateHandler from "../../../generic/curation/withDeactivateHandler";

export const organisationMarketplaceFragments = {
  marketplace: gql`
    fragment OrganisationMarketplace on Marketplace {
      id
      name
    }
  `,
};

export const GET_AVAILABLE_MARKETPLACES = gql`
  query OrganisationMarketplacesGetAvailableMarketplaces($industryID: String!) {
    industry: getIndustry(id: $industryID) {
      id
      marketplaceList {
        ...OrganisationMarketplace
      }
    }
  }
  ${organisationMarketplaceFragments.marketplace}
`;

export const GET_ORGANISATION_MARKETPLACES = gql`
  query OrganisationMarketplacesGetOrganisationMarketplaces(
    $organisationID: String!
    $industryID: String!
  ) {
    industry: getIndustry(id: $industryID) {
      id
      activeMarketplaceList(organisationId: $organisationID) {
        ...OrganisationMarketplace
      }
    }
  }
  ${organisationMarketplaceFragments.marketplace}
`;

export const ADD_TO_MARKETPLACE = gql`
  mutation OrganisationMarketplacesAddToMarketplace(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    addOrganisationToMarketplace(
      from: { id: $organisationID }
      to: { id: $marketplaceID }
    ) {
      to {
        id
      }
    }
  }
`;

export const REMOVE_ORGANISATION_TO_MARKETPLACE = gql`
  mutation OrganisationMarketplaceAffiliationsRemoveCategorySubscriptionToOrganisation(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    removeOrganisationToMarketplace(
      from: { id: $organisationID }
      to: { id: $marketplaceID }
    )
  }
`;

export type EnhancedProps = {
  industryID?: string;
  onIndustryIDChange: (id: string) => unknown;
  onPressCategories: (categoryID: string) => void;
  organisationID: string;
};

/**
 * Map (marketplaceID, props) to the variables for de/activate mutations
 * @param {string} marketplaceID ID of marketplace being de/activated
 * @param {EnhancedProps} props Props passed to enhanced component
 * @return {any} Variables
 */
const mutationVariables = (
  marketplaceID,
  { organisationID, industryID }: EnhancedProps
) =>
  industryID != null
    ? {
        marketplaceID,
        organisationID,
      }
    : undefined;

const enhancer = compose<Partial<any>, EnhancedProps>(
  withCurationQueries({
    activate: {
      mutation: ADD_TO_MARKETPLACE,
      optimisticResponse: {
        addOrganisationToMarketplace: {
          __typename: `OrganisationInMarketplace`,
          to: {
            __typename: `Marketplace`,
            id: `marketplaceID`,
          },
        },
      },
      variables: mutationVariables,
    },
    activeItems: {
      dataPath: `industry.activeMarketplaceList`,
      fragment: organisationMarketplaceFragments.marketplace,
      query: GET_ORGANISATION_MARKETPLACES,
      variables: ({ organisationID, industryID }) =>
        industryID != null
          ? {
              industryID,
              organisationID,
            }
          : undefined,
    },
    activeListName: `organisation`,
    availableItems: {
      dataPath: `industry.marketplaceList`,
      query: GET_AVAILABLE_MARKETPLACES,
      variables: ({ industryID }) =>
        industryID != null ? { industryID } : undefined,
    },
    deactivate: {
      mutation: REMOVE_ORGANISATION_TO_MARKETPLACE,
      optimisticResponse: {
        removeOrganisationToMarketplace: `SUCCESS`,
      },
      variables: mutationVariables,
    },
    itemLabel: `marketplace`,
    itemLabelPlural: `marketplaces`,
    itemTypeName: `Marketplace`,
  }),
  withActivateHandler(
    `selectedAvailableItems`,
    `onSelectedAvailableItemsChange`
  ),
  withDeactivateHandler(`selectedActiveItems`, `onSelectedActiveItemsChange`)
);

export default enhancer(OrganisationMarketplaceLayout);

import * as React from "react";
import gql from "graphql-tag";
import NestedListQuerySelectField, {
  EnhancedProps as NestedListQuerySelectFieldProps,
} from "../generic/NestedListQuerySelectField";

export const GET_INDUSTRY_LIST = gql`
  query MarketplaceSelectGetIndustries {
    industries: getIndustryList {
      id
      name
      marketplaceList {
        id
        name
      }
    }
  }
`;

type EnhancedProps = NestedListQuerySelectFieldProps;

const MarketplaceSelect = NestedListQuerySelectField({
  innerDataPath: `marketplaceList`,
  itemPluralLabel: `marketplaces`,
  itemTitleLabel: `Marketplace`,
  query: GET_INDUSTRY_LIST,
  rootDataPath: `industries`,
}) as React.ComponentType<EnhancedProps>;

export default MarketplaceSelect;

import * as React from "react";
import * as H from "history";
import { Redirect } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PerformLogin from "../../components/auth/PerformLogin";
import { LoginGetAuth } from "./__generated__/LoginGetAuth";
import LoadingScreen from "../../components/generic/LoadingScreen";
import { dashboardRoute } from "../index";

export const GET_AUTH = gql`
  query LoginGetAuth {
    auth: getAuth @client {
      isLoggedIn
    }
  }
`;

type LocationState = {
  referrer: any;
};

class GetAuthQuery extends Query<LoginGetAuth> {}

type Props = {
  location: H.PartialLocation<LocationState>;
};

class Login extends React.Component<Props> {
  render() {
    const {
      location: { state },
    } = this.props;
    const referrer = state ? state.referrer : null;

    return (
      <LoadingScreen>
        <GetAuthQuery query={GET_AUTH}>
          {({ data, loading }) => {
            const loggedIn =
              (data && data.auth && data.auth.isLoggedIn) === true;

            if (loading) {
              return null;
            }

            if (loggedIn) {
              // Already logged in, redirect to referrer
              return <Redirect to={referrer || dashboardRoute()} />;
            } else {
              return <PerformLogin referrer={referrer} />;
            }
          }}
        </GetAuthQuery>
      </LoadingScreen>
    );
  }
}

export default Login;

import * as React from "react";
import * as yup from "yup";
import gql from "graphql-tag";
import { CustomField, FieldList } from "../generic/form/index";
import TextInput from "../generic/form/TextInput";
import CreateUpdateForm from "../generic/form/CreateUpdateForm";
import withCreateUpdateQueries from "../generic/withCreateUpdateQueries";
import { GET_WAREHOUSES, warehouseListFragments } from "./WarehouseList";
import telephoneNumberSchema from "../../../helpers/schema/telephoneNumber";
import postcodeSchema from "../../../helpers/schema/postcode";
import { WarehouseFormCreateWarehouseVariables } from "./__generated__/WarehouseFormCreateWarehouse";
import { WarehouseFormGetWarehouse_getSelf_organisation_warehouse as Warehouse } from "./__generated__/WarehouseFormGetWarehouse";
import { WarehouseFormUpdateWarehouseVariables } from "./__generated__/WarehouseFormUpdateWarehouse";
import { compose } from "recompose";
import GridContainer from "../generic/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import PostcodeInput from "../generic/form/PostcodeInput";
import CountrySelect from "../generic/CountrySelect";
import StateDisplay from "../generic/StateDisplay";
import { get } from "lodash";

import { Typography } from "@material-ui/core";

export const warehouseFormFragments = {
  warehouse: gql`
    fragment WarehouseFormWarehouse on Warehouse {
      id
      name
      addressLine1
      addressLine2
      city
      postcode {
        id
        postcode
        country {
          id
          name
        }
        state
      }
      contactName
      telephoneNumber
      emailAddress
    }
  `,
};

export const CREATE_WAREHOUSE = gql`
  mutation WarehouseFormCreateWarehouse(
    $organisationID: String!
    $name: String!
    $postcode: String!
    $country: String!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $contactName: String
    $emailAddress: String
    $telephoneNumber: String
  ) {
    createWarehouse(
      values: {
        name: $name
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        contactName: $contactName
        emailAddress: $emailAddress
        telephoneNumber: $telephoneNumber
      }
      organisation: { id: $organisationID }
      postcode: { postcode: $postcode }
      country: { name: $country }
    ) {
      ...WarehouseFormWarehouse
      ...WarehouseListWarehouse
    }
  }
  ${warehouseFormFragments.warehouse}
  ${warehouseListFragments.warehouse}
`;

export const UPDATE_WAREHOUSE = gql`
  mutation WarehouseFormUpdateWarehouse(
    $id: String!
    $name: String!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $contactName: String
    $emailAddress: String
    $telephoneNumber: String
  ) {
    updateWarehouse(
      id: $id
      values: {
        name: $name
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        contactName: $contactName
        emailAddress: $emailAddress
        telephoneNumber: $telephoneNumber
      }
    ) {
      ...WarehouseFormWarehouse
      ...WarehouseListWarehouse
    }
  }
  ${warehouseFormFragments.warehouse}
  ${warehouseListFragments.warehouse}
`;

export const GET_WAREHOUSE = gql`
  query WarehouseFormGetWarehouse(
    $warehouseID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          ...WarehouseFormWarehouse
        }
      }
    }
  }
  ${warehouseFormFragments.warehouse}
`;

const schema = yup.object({
  addressLine1: yup.string().nullable().label(`Address Line 1`),
  addressLine2: yup.string().nullable().label(`Address Line 2`),
  city: yup.string().nullable().label(`City`),
  contactName: yup.string().nullable().label(`Contact Name`),
  country: yup.string().required().label(`Country`),
  emailAddress: yup.string().email().nullable().label(`Email Address`),
  name: yup.string().required().label(`Name`),
  postcode: postcodeSchema.required().label(`Postcode`),
  telephoneNumber: telephoneNumberSchema.nullable(),
});

// Get the form values from data
const getValues = (warehouse: Partial<Warehouse> | null | undefined) => {
  if (warehouse) {
    return {
      addressLine1: warehouse.addressLine1,
      addressLine2: warehouse.addressLine2,
      city: warehouse.city,
      contactName: warehouse.contactName,
      country: warehouse.postcode && warehouse.postcode.country.name,
      emailAddress: warehouse.emailAddress,
      name: warehouse.name,
      postcode: warehouse.postcode && warehouse.postcode.postcode,
      telephoneNumber: warehouse.telephoneNumber,
    };
  } else {
    return undefined;
  }
};

type EnhancedProps = {
  BodyContainer: React.ElementType;
  itemID?: string;
  onDismiss: (force?: boolean) => void;
  organisationID: string;
};

type Props = EnhancedProps & {
  createMutation: (
    warehouse: WarehouseFormCreateWarehouseVariables
  ) => Promise<void>;
  item?: Partial<Warehouse>;
  updateMutation: (
    warehouse: WarehouseFormUpdateWarehouseVariables
  ) => Promise<void>;
};

export class WarehouseForm extends React.PureComponent<Props> {
  render() {
    const {
      BodyContainer,
      createMutation,
      item,
      itemID,
      organisationID,
      ...createUpdateFormProps
    } = this.props;

    const editing = itemID != null;

    return (
      <CreateUpdateForm
        itemID={itemID}
        schema={schema}
        itemType="warehouse"
        itemTypeTitle="Warehouse"
        itemNameAccessor="name"
        createMutation={(values) =>
          createMutation({
            ...values,
            organisationID,
          })
        }
        item={getValues(item)}
        {...createUpdateFormProps}
      >
        {/* @ts-ignore */}
        {({ values }) => (
          <FieldList component={BodyContainer}>
            <GridContainer>
              <Grid item xs={12}>
                <CustomField
                  fullWidth
                  autoFocus={!editing}
                  name="name"
                  label="Name"
                  component={TextInput}
                />
              </Grid>

              <Grid item xs={12}>
                <GridContainer spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Address Details</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      name="addressLine1"
                      label="Address (Line 1)"
                      component={TextInput}
                    />
                    <CustomField
                      fullWidth
                      name="addressLine2"
                      label="Address (Line 2)"
                      component={TextInput}
                    />
                    <CustomField
                      fullWidth
                      name="city"
                      label="City"
                      component={TextInput}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      disabled={editing}
                      name="country"
                      label="Country"
                      component={CountrySelect}
                    />
                    <CustomField
                      fullWidth
                      disabled={editing}
                      name="postcode"
                      label="Postcode"
                      component={TextInput}
                      InputProps={{ inputComponent: PostcodeInput }}
                    />
                    <StateDisplay
                      fullWidth
                      postcodeString={values.postcode}
                      country={values.country}
                      state={
                        editing ? get(item, `postcode.state`, ``) : undefined
                      }
                    />
                  </Grid>
                </GridContainer>
              </Grid>

              <Grid item xs={12}>
                <GridContainer spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Contact Details</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      name="contactName"
                      label="Contact Name"
                      component={TextInput}
                    />
                    <CustomField
                      fullWidth
                      name="telephoneNumber"
                      label="Telephone Number"
                      component={TextInput}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomField
                      fullWidth
                      name="emailAddress"
                      label="Email Address"
                      component={TextInput}
                    />
                  </Grid>
                </GridContainer>
              </Grid>
            </GridContainer>
          </FieldList>
        )}
      </CreateUpdateForm>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withCreateUpdateQueries({
    createItemAccessPath: `createWarehouse`,
    createMutation: CREATE_WAREHOUSE,
    getItemAccessPath: `getSelf.organisation.warehouse`,
    getQuery: GET_WAREHOUSE,
    getQueryVariables: ({ itemID, organisationID }) =>
      itemID
        ? {
            organisationID,
            warehouseID: itemID,
          }
        : undefined,
    listAccessPath: `getSelf.organisation.warehouseList`,
    listQuery: GET_WAREHOUSES,
    listQueryVariables: ({ organisationID }) => ({ organisationID }),
    updateMutation: UPDATE_WAREHOUSE,
  })
);

export default enhancer(WarehouseForm);

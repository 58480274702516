/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum OrderStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum ShippingBatchStatus {
  DISPATCHED = "DISPATCHED",
  PENDING = "PENDING",
  PICKING = "PICKING",
  RECEIVED = "RECEIVED",
}

export enum SnackbarType {
  error = "error",
  info = "info",
  success = "success",
  warning = "warning",
}

export interface AddressInput {
  id: string;
}

export interface CreateOperationHoursInput {
  dayOfTheWeek: string;
  openingTime: string;
  closingTime: string;
}

export interface OrderItemInput {
  id: string;
}

export interface OrganisationInput {
  id: string;
}

export interface UpdateOperationHoursInput {
  openingTime?: string | null;
  closingTime?: string | null;
}

export interface UpdateOrderItemPartInput {
  organisation: OrganisationInput;
  orderItem: OrderItemInput;
  destination: AddressInput;
  quantityAllocated?: number | null;
}

export interface UpdateOrganisationInput {
  abn?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  contactEmail?: string | null;
  telephoneNumber?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

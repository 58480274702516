import * as React from "react";
import ItemListWithHeaders from "../../generic/ItemListWithHeaders";
import {
  Props as ItemListWithMenuProps,
  withMenu,
} from "../../generic/ItemListWithMenu";
import {
  Props as ItemListWithEditableRowsProps,
  withEditableRows,
} from "../../generic/ItemListWithEditableRows";
import gql from "graphql-tag";
import { compose } from "recompose";
import { ClassListClass } from "./__generated__/ClassListClass";

// Compose to get item list with editing and menus
type EnhancedItemListProps = ItemListWithMenuProps &
  ItemListWithEditableRowsProps;
const itemListEnhancer = compose<any, EnhancedItemListProps>(
  withMenu as any,
  withEditableRows
);
const EnhancedItemList = itemListEnhancer(ItemListWithHeaders);

export const classListFragments = {
  marketplace: gql`
    fragment ClassListClass on Class {
      id
      name
    }
  `,
};

type Props = {
  classList?: ClassListClass[];
  className?: string;
  editingClassID: string | null | undefined;
  loading?: boolean;
  onClassChanged: (classID: string, name: string) => void;
  onClassCreated: (name: string) => void;
  onPressDelete: (classID: string) => void;
  setEditingClassID: (classID: string | null | undefined) => void;
};

/**
 * Component to display an editable list of classes.
 */
export class ClassList extends React.PureComponent<Props> {
  handleCompleteEditing(itemID: string | null, text: string) {
    const { onClassChanged, onClassCreated, setEditingClassID } = this.props;

    if (itemID === null) {
      onClassCreated(text);
    } else {
      onClassChanged(itemID, text);
    }
    setEditingClassID(undefined);
  }

  render() {
    const {
      classList,
      className,
      editingClassID,
      loading,
      onPressDelete,
      setEditingClassID,
    } = this.props;

    const listItems = classList
      ? classList.map((classObject) => ({
          id: classObject.id,
          text: classObject.name,
        }))
      : [];

    return (
      <EnhancedItemList
        className={className}
        listProps={{ dense: true }}
        items={listItems}
        menuActions={[
          {
            perform: setEditingClassID,
            text: `Edit`,
          },
          {
            perform: onPressDelete,
            text: `Delete`,
          },
        ]}
        loading={loading}
        emptyMessage={
          classList == null
            ? `Select a category to edit classes`
            : `This category has no classes`
        }
        editingItemID={editingClassID}
        onCancelEditing={() => setEditingClassID(undefined)}
        onCompleteEditing={this.handleCompleteEditing.bind(this)}
        onStartEditing={setEditingClassID}
      />
    );
  }
}

export default ClassList;

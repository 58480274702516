import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { StyleProps } from "../../types";
import CatalogueViewSelect from "./CatalogueViewSelect";
import IncludedProductTable from "./IncludedProductTable";
import SubscribedOrganisationData from "./catalogueViewSubscriptions/SubscribedOrganisationData";
import { Edit } from "@material-ui/icons";
import ActiveMarketplaceSelect from "../../app/ActiveMarketplaceSelect";

const styles = (theme) => ({
  root: {
    alignSelf: `stretch`,
    alignContent: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
  },
  table: {
    flex: 1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxHeight: `40vh`,
  },
  catalogueViewSelect: {
    marginTop: theme.spacing(1),
  },
});

type Props = StyleProps & {
  canEditCatalogue?: boolean;
  catalogueViewID: string | null | undefined;
  onCatalogueViewIDChange: (catalogueViewID?: string) => void;
  onPressEdit: (catalogueViewID: string) => void;
  organisationID: string;
};

class CatalogueViewLayout extends React.PureComponent<Props> {
  render() {
    const {
      canEditCatalogue,
      catalogueViewID,
      classes,
      onCatalogueViewIDChange,
      onPressEdit,
      organisationID,
    } = this.props;

    return (
      <div className={classes.root}>
        <ActiveMarketplaceSelect organisationID={organisationID} />
        <CatalogueViewSelect
          className={classes.catalogueViewSelect}
          organisationID={organisationID}
          selectedID={catalogueViewID || undefined}
          onSelectedIDChange={onCatalogueViewIDChange}
        />
        <IncludedProductTable
          className={classes.table}
          catalogueViewID={catalogueViewID}
          organisationID={organisationID}
        />
        <Button
          color={"primary"}
          disabled={catalogueViewID == null || canEditCatalogue === false}
          onClick={() => onPressEdit(catalogueViewID)}
          startIcon={<Edit />}
          variant={"outlined"}
        >
          Edit
        </Button>

        <SubscribedOrganisationData
          catalogueViewID={catalogueViewID}
          organisationID={organisationID}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CatalogueViewLayout);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyleProps } from "../types";

const styles = () => ({
  root: {
    display: `flex`,
    justifyContent: `space-between`,
  },
});

type Props = StyleProps;

class WarehouseProductStockLevelUploadFormSubmitContainer extends React.PureComponent<
  Props
> {
  render() {
    const { classes, ...rest } = this.props;

    return <div className={classes.root} {...rest} />;
  }
}

export default withStyles(styles)(
  WarehouseProductStockLevelUploadFormSubmitContainer
);

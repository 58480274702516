import * as React from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { StyleProps } from "../../types";
import GridContainer from "../../generic/grid/GridContainer";
import getAddressString, {
  addressFragment,
} from "../../../../helpers/getAddressString";
import { formatUTCDate } from "../../../../helpers/formatDate";
import { ShippingBatchSaleDetails as ShippingBatchSaleDetailsType } from "./__generated__/ShippingBatchSaleDetails";
import DetailGridItem from "../../generic/grid/DetailGridItem";
import getStatusString from "../../../../helpers/shippingBatch/getStatusString";

export const shippingBatchSaleDetailsFragments = {
  shippingBatch: gql`
    fragment ShippingBatchSaleDetails on ShippingBatch {
      id
      batchNumber
      status
      supplier {
        id
        purchaseOrderNumber
        order {
          id
          confirmationDate
          orderNumber
          buyer {
            id
            name
          }
        }
      }
      origin {
        id
        name
      }
      destination {
        id
        ...GetAddressStringAddress
      }
      carrierRate {
        id
        service
        cardName
        carrier {
          id
          name
        }
      }
      estimatedDispatchDate
      dispatchDate
      preferredDeliveryDate
      status
    }
    ${addressFragment}
  `,
};

const styles = (theme) => ({
  root: {
    flex: 1,
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(1),
  },
});

type Props = StyleProps & {
  loading: boolean;
  shippingBatchSale: ShippingBatchSaleDetailsType;
};

class ShippingBatchSaleDetails extends React.PureComponent<Props> {
  render() {
    const { classes, loading, shippingBatchSale } = this.props;

    if (loading) {
      return null;
    }

    return (
      <div className={classes.root}>
        <GridContainer>
          <DetailGridItem
            title={"Customer"}
            value={shippingBatchSale?.supplier?.order?.buyer?.name ?? "-"}
          />

          <DetailGridItem
            title={"Confirmed"}
            value={formatUTCDate(
              shippingBatchSale?.supplier?.order?.confirmationDate ?? "-"
            )}
          />

          <DetailGridItem
            title={"Order Number"}
            value={shippingBatchSale?.supplier?.order?.orderNumber ?? "-"}
          />

          <DetailGridItem
            title={"Batch Number"}
            value={shippingBatchSale?.batchNumber ?? "-"}
          />

          <DetailGridItem
            title={"Purchase Order #"}
            value={shippingBatchSale?.supplier?.purchaseOrderNumber ?? "-"}
          />

          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Delivery
            </Typography>
          </Grid>

          <DetailGridItem
            title={"Carrier"}
            value={shippingBatchSale?.carrierRate?.carrier?.name ?? "-"}
          />

          <DetailGridItem
            title={"Rate Card"}
            value={shippingBatchSale?.carrierRate?.cardName ?? "-"}
          />

          <DetailGridItem
            title={"Service"}
            value={shippingBatchSale?.carrierRate?.service ?? "-"}
          />

          <DetailGridItem
            title={"Warehouse"}
            value={shippingBatchSale?.origin?.name ?? "-"}
          />

          <DetailGridItem
            title={"Delivery Address"}
            value={
              shippingBatchSale?.destination != null
                ? getAddressString(shippingBatchSale.destination)
                : "-"
            }
          />

          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              Details
            </Typography>
          </Grid>

          <DetailGridItem
            title={"Status"}
            value={getStatusString(shippingBatchSale?.status) ?? "-"}
          />

          <DetailGridItem
            title={"Est. Dispatch"}
            value={formatUTCDate(
              shippingBatchSale?.estimatedDispatchDate ?? "-"
            )}
          />

          <DetailGridItem
            title={"Dispatched"}
            value={formatUTCDate(shippingBatchSale?.dispatchDate ?? "-")}
          />

          <DetailGridItem
            title={"ETA"}
            value={formatUTCDate(
              shippingBatchSale?.preferredDeliveryDate ?? "-"
            )}
          />
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(ShippingBatchSaleDetails);

import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  submitButton: {
    margin: theme.spacing(1),
  },
});

type Props = {
  children: React.ReactNode;
  classes: {
    [className: string]: string;
  };
};

const FieldList = ({ classes, ...rest }: Props) => (
  <Button
    type="submit"
    variant="contained"
    className={classes.submitButton}
    {...rest}
  />
);

export default withStyles(styles)(FieldList);

import * as React from "react";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "recompose";
import { StyleProps } from "../types";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import classnames from "classnames";

const styles = (theme) => ({
  centerContainer: {
    ...theme.customMixins.centerChildrenInParent,
    pointerEvents: `none`,
  },

  list: {
    alignSelf: `stretch`,
    flex: 1,
  },

  paper: {
    alignItems: `center`,
    alignSelf: `stretch`,
    display: `flex`,
    flex: 1,
    flexDirection: `column` as const,
    overflow: `auto`,
    position: `relative` as const,
  },
});

export type Item = {
  detailText?: string;
  id: string;
  text: string;
};

export type EnhancedProps = {
  className?: string;
  emptyMessage?: string;
  items: Item[];
  listItemProps?: (item: Item) => any;
  listProps?: any;
  loading?: boolean;
  renderItemEnd?: (props: { item: Item }) => React.ReactNode;
  renderItemStart?: (props: { item: Item }) => React.ReactNode;
  renderListEnd?: () => React.ReactNode;
  renderSecondaryActions?: (props: { item: Item }) => React.ReactNode;
};

type Props = StyleProps & EnhancedProps;

/**
 * Component to display a basic list of items.
 */
class ItemList extends React.PureComponent<Props> {
  render() {
    const {
      className,
      classes,
      items,
      emptyMessage,
      listItemProps,
      listProps,
      loading,
      renderItemEnd,
      renderItemStart,
      renderListEnd,
      renderSecondaryActions,
    } = this.props;

    return (
      <Paper className={classnames(classes.paper, className)}>
        {loading && (
          <div className={classes.centerContainer}>
            <CircularProgress classes={{ root: classes.centerItem }} />
          </div>
        )}
        {!loading && (
          <List className={classes.list} {...listProps}>
            {items.map((item) => (
              <ListItem
                key={item.id}
                {...(listItemProps ? listItemProps(item) : undefined)}
              >
                {renderItemStart && renderItemStart({ item })}
                <ListItemText primary={item.text} secondary={item.detailText} />
                {renderItemEnd && renderItemEnd({ item })}
                {renderSecondaryActions && (
                  <ListItemSecondaryAction>
                    {renderSecondaryActions({ item })}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
            {renderListEnd && renderListEnd()}
          </List>
        )}
        {!loading && items.length === 0 && (
          <div className={classes.centerContainer}>
            <Typography>
              {emptyMessage != null ? emptyMessage : `No items are available.`}
            </Typography>
          </div>
        )}
      </Paper>
    );
  }
}

const enhancer = compose<Props, EnhancedProps>(withStyles(styles));
export default enhancer(ItemList);

import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import ServiceAreaForm from "../../components/admin/serviceArea/ServiceAreaForm";
import { match } from "react-router-dom";
import { adminServiceAreasRoute } from "../index";

type MatchProps = {
  serviceAreaID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

class ServiceAreaCreateEdit extends React.PureComponent<Props> {
  render() {
    const { match, history } = this.props;

    const { serviceAreaID } = match.params;

    return (
      <MainPanel title={`${serviceAreaID ? `Edit` : `Create`} Service Area`}>
        <ServiceAreaForm
          serviceAreaID={serviceAreaID}
          onDismiss={() => history.push(adminServiceAreasRoute())}
        />
      </MainPanel>
    );
  }
}

export default ServiceAreaCreateEdit;

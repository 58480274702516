import * as React from "react";
import gql from "graphql-tag";
import { compose } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import ConfirmationLayout, {
  confirmationLayoutFragments,
} from "./ConfirmationLayout";
import { ConfirmationDataGetConfirmedOrder_getSelf_organisation_purchasedOrder as OrderType } from "./__generated__/ConfirmationDataGetConfirmedOrder";

export const GET_CONFIRMED_ORDER = gql`
  query ConfirmationDataGetConfirmedOrder(
    $organisationID: String!
    $orderID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        purchasedOrder(id: $orderID) {
          id
          ...ConfirmationLayoutOrder
        }
      }
    }
  }
  ${confirmationLayoutFragments.order}
`;

type EnhancedProps = {
  organisationID: string;
  orderID: string;
};

type Props = EnhancedProps & {
  loading: boolean;
  order?: OrderType;
};

class ConfirmationData extends React.PureComponent<Props> {
  render() {
    const { loading, order, organisationID } = this.props;

    return (
      <ConfirmationLayout
        loading={loading}
        order={order}
        organisationID={organisationID}
      />
    );
  }
}

const enhancer = compose<any, any>(
  withQuery(GET_CONFIRMED_ORDER, {
    dataPaths: {
      order: `getSelf.organisation.purchasedOrder`,
    },
    errorMessage: `Unable to fetch confirmed order`,
    loadingProp: `loading`,
    options: ({ organisationID, orderID }) => ({
      variables: {
        organisationID,
        orderID,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ organisationID, orderID }) =>
      organisationID == null || orderID == null,
  })
);

export default enhancer(ConfirmationData);

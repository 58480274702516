import gql from "graphql-tag";
import { DisplayProductAvailabilityProduct } from "./__generated__/DisplayProductAvailabilityProduct";

export const AVAILABLE = "Available";
export const TO_ORDER = "To Order";
export const TO_PROMISE = "To Promise";
export const BOTH_TO_ORDER_AND_PROMISE = "To Order / Promise";
export const UNAVAILABLE = "Unavailable";

export const displayProductAvailabilityFragment = gql`
  fragment DisplayProductAvailabilityProduct on Product {
    id
    available
    inStock
    availableAsOrderToPromise
  }
`;

export default (product: DisplayProductAvailabilityProduct): string => {
  let result: string;

  if (product.available) {
    if (product.inStock && product.availableAsOrderToPromise) {
      result = BOTH_TO_ORDER_AND_PROMISE;
    } else if (product.inStock) {
      result = TO_ORDER;
    } else if (product.availableAsOrderToPromise) {
      result = TO_PROMISE;
    } else {
      result = AVAILABLE;
    }
  } else {
    result = UNAVAILABLE;
  }

  return result;
};

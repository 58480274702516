import * as React from "react";
import gql from "graphql-tag";
import { get, uniq } from "lodash";
import DataTableWithFiltering from "../../generic/DataTable/WithFiltering";
import { Column } from "../../generic/DataTable/BaseDataTable";
import { ShippingBatchSalesTableShippingBatch } from "./__generated__/ShippingBatchSalesTableShippingBatch";
import { formatUTCDate } from "../../../../helpers/formatDate";
import getStatusString from "../../../../helpers/shippingBatch/getStatusString";
import IconButton from "@material-ui/core/IconButton";
import { MoreHoriz } from "@material-ui/icons";

export const shippingBatchSalesTableFragments = {
  shippingBatch: gql`
    fragment ShippingBatchSalesTableShippingBatch on ShippingBatch {
      id
      batchNumber
      supplier {
        id
        order {
          id
          confirmationDate
          orderNumber
          buyer {
            id
            name
          }
        }
      }
      origin {
        id
        name
      }
      carrierRate {
        id
        carrier {
          id
          name
        }
      }
      preferredDeliveryDate
      estimatedDispatchDate
      dispatchDate
      status
    }
  `,
};

const customerNameAccessor = `supplier.order.buyer.name`;
const locationAccessor = `origin.name`;
const carrierAccessor = `carrierRate.carrier.name`;
const statusAccessor = `status`;
const orderIdAccessor = `supplier.order.orderNumber`;

type Props = {
  className?: string;
  loading: boolean;
  shippingBatchSalesList: ShippingBatchSalesTableShippingBatch[];
  onPressShippingBatch: (arg0: string) => void;
  itemsPerPage: number;
  pageOffset: number;
  handlePageChange: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

class ShippingBatchSalesTable extends React.PureComponent<Props> {
  render() {
    const {
      className,
      loading,
      shippingBatchSalesList,
      onPressShippingBatch,
      itemsPerPage,
      pageOffset,
      handlePageChange,
      handleRowsPerPageChange,
    } = this.props;

    const columns: Column<ShippingBatchSalesTableShippingBatch>[] = [
      {
        Header: `Order Number`,
        id: orderIdAccessor,
      },
      {
        Header: `Batch Number`,
        id: "batchNumber",
      },
      {
        Header: `Customer`,
        id: `supplier.order.buyer.name`,
      },
      {
        Header: `Warehouse`,
        id: `origin.name`,
      },
      {
        Header: `Carrier`,
        id: carrierAccessor,
      },
      {
        Header: `Confirmed`,
        id: `supplier.order.confirmationDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `Est. Dispatch`,
        id: `estimatedDispatchDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `Dispatched`,
        id: `dispatchDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `ETA`,
        id: `preferredDeliveryDate`,
        Cell: ({ value }) => (value ? formatUTCDate(value) || `` : ``),
      },
      {
        Header: `Status`,
        Cell: ({ value }) => getStatusString(value),
        id: `status`,
      },
      {
        Header: "More",
        id: `more`,
        disableSortable: true,
        compact: true,
        Cell: ({ row }) => (
          <IconButton
            title={"More Details"}
            onClick={(e) => {
              e.stopPropagation();
              onPressShippingBatch(row.id);
            }}
          >
            <MoreHoriz />
          </IconButton>
        ),
      },
    ];

    return (
      <DataTableWithFiltering
        className={className}
        loading={loading}
        columns={columns}
        data={shippingBatchSalesList}
        idAccessor="id"
        emptyMessage="No sales"
        pagination={{
          handlePageChange,
          handleRowsPerPageChange,
          pageOffset,
          itemsPerPage,
        }}
        filterOptions={{
          [customerNameAccessor]: {
            label: `Customer`,
            values: uniq(
              shippingBatchSalesList.map((row) =>
                get(row, customerNameAccessor, `None`)
              )
            ),
          },
          [locationAccessor]: {
            label: `Location`,
            values: uniq(
              shippingBatchSalesList.map((row) =>
                get(row, locationAccessor, `None`)
              )
            ),
          },
          [carrierAccessor]: {
            label: `Carrier`,
            values: uniq(
              shippingBatchSalesList.map((row) =>
                get(row, carrierAccessor, `None`)
              )
            ),
          },
          [statusAccessor]: {
            label: `Status`,
            values: uniq(
              shippingBatchSalesList.map((row) =>
                get(row, statusAccessor, `None`)
              )
            ),
          },
          [orderIdAccessor]: {
            label: `OrderId`,
            values: uniq(
              shippingBatchSalesList.map((row) =>
                get(row, orderIdAccessor, `None`)
              )
            ),
          },
        }}
        rowProps={({ id }) => ({
          hover: true,
          onClick: () => onPressShippingBatch(id),
        })}
      />
    );
  }
}

export default ShippingBatchSalesTable;

import * as React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import WarehouseName, { warehouseNameFragments } from "../WarehouseTitle";
import { OperatingTimesWarehouse } from "./__generated__/OperatingTimesWarehouse";
import CallOnMount from "../../../components/generic/CallOnMount";
import { compose } from "recompose";
import OperatingTimesTable from "./OperatingTimesTable";
import { operatingTimesDayFragments } from "./OperatingTimesDay";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../../components/generic/snackbar/withOpenSnackbar";
import LoadingScreen from "../../generic/LoadingScreen";
import { get } from "lodash";
import { StyleProps } from "../../types";
import withStyles from "@material-ui/core/styles/withStyles";
import { SnackbarType } from "../../../../__generated__/globalTypes";

export const operatingTimesFragments = {
  warehouse: gql`
    fragment OperatingTimesWarehouse on Warehouse {
      id
      name
      operationHoursList {
        ...OperatingTimesDayOperationHours
      }
      ...WarehouseNameWarehouse
    }
    ${warehouseNameFragments.warehouse}
    ${operatingTimesDayFragments.operationHours}
  `,
};

export const GET_WAREHOUSE = gql`
  query OperatingTimesGetWarehouse(
    $warehouseID: String!
    $organisationID: String!
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        warehouse(id: $warehouseID) {
          ...OperatingTimesWarehouse
        }
      }
    }
  }
  ${operatingTimesFragments.warehouse}
`;

const styles = () => ({
  table: {
    alignSelf: `stretch`,
    overflow: `auto`,
  },
});

type EnhancedProps = {
  onDismiss?: () => void;
  organisationID: string;
  warehouseID: string;
};

type Props = EnhancedProps &
  StyleProps & {
    error?: Error;
    loading: boolean;
    openSnackbar: OpenSnackbarFn;
    warehouse?: OperatingTimesWarehouse;
  };

export class WarehouseOperatingTimes extends React.PureComponent<Props> {
  render() {
    const {
      classes,
      error,
      loading,
      openSnackbar,
      organisationID,
      warehouse,
    } = this.props;

    if (loading) {
      return <LoadingScreen />;
    } else if (error || !warehouse) {
      return (
        <CallOnMount
          callback={() =>
            openSnackbar(
              SnackbarType.error,
              `Failed to load warehouse operating hours`
            )
          }
        />
      );
    }

    return (
      <>
        <WarehouseName warehouse={warehouse} />
        <OperatingTimesTable
          className={classes.table}
          operationHours={warehouse.operationHoursList}
          organisationID={organisationID}
          warehouseID={warehouse.id}
        />
      </>
    );
  }
}

const enhancer = compose<any, EnhancedProps>(
  withStyles(styles),
  graphql(GET_WAREHOUSE, {
    // @ts-ignore
    options: ({ organisationID, warehouseID }) => ({
      variables: {
        organisationID,
        warehouseID,
      },
    }),
    props: ({ data }) => {
      // @ts-ignore
      const { error, loading, getSelf } = data;

      return {
        error,
        loading,
        warehouse:
          !error && !loading
            ? get(getSelf, `organisation.warehouse`, undefined)
            : undefined,
      };
    },
  }),
  withOpenSnackbar
);

export default enhancer(WarehouseOperatingTimes);

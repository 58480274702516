import * as React from "react";
import MainPanel from "../../../components/app/MainPanel";
import { match } from "react-router-dom";
import WarehouseDetailsData from "../../../components/warehouse/details/WarehouseDetailsData";
import WarehouseDetailsLayout from "../../../components/warehouse/details/WarehouseDetailsLayout";

type MatchProps = {
  organisationID?: string;
  warehouseID?: string;
};

type Props = {
  match: match<MatchProps>;
};

class Details extends React.Component<Props> {
  render() {
    const { organisationID, warehouseID } = this.props.match.params;

    return (
      <MainPanel fullWidth>
        {organisationID && warehouseID && (
          <WarehouseDetailsData
            organisationID={organisationID}
            warehouseID={warehouseID}
          >
            {({ loading, warehouse }) => (
              <WarehouseDetailsLayout
                loading={loading}
                warehouse={warehouse}
                organisationID={organisationID}
                warehouseID={warehouseID}
              />
            )}
          </WarehouseDetailsData>
        )}
      </MainPanel>
    );
  }
}

export default Details;

import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import DataTable from "../generic/DataTable";
import { Column } from "../generic/DataTable/BaseDataTable";
import withSelection from "../generic/withSelection";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../generic/snackbar/withOpenSnackbar";
import { WithSelectionGetSelection_getSelection as WithSelectionSelection } from "../generic/__generated__/WithSelectionGetSelection";
import { compose } from "recompose";
import CallOnMount from "../generic/CallOnMount";
import { SnackbarType } from "../../../__generated__/globalTypes";

export const productOverviewFragment = gql`
  fragment ProductTableProductOverview on Product {
    id
    sku
    description
    marketplace {
      id
      name
      description
    }
    class {
      category {
        id
        name
      }
      id
      name
      description
    }
    standardPrice {
      value
      display
    }
  }
`;

export const GET_OWN_ORGANISATION_PRODUCTS_OVERVIEW = gql`
  query ProductTableGetOwnOrganisationProductsOverview(
    $organisationID: String!
    $marketplaceID: String!
  ) {
    self: getSelf {
      id
      organisation(id: $organisationID) {
        id
        products: productListInMarketplace(marketplaceId: $marketplaceID) {
          ...ProductTableProductOverview
        }
      }
    }
  }
  ${productOverviewFragment}
`;

const columns: Column<any>[] = [
  {
    Header: `SKU`,
    id: `sku`,
  },
  {
    Header: `Description`,
    id: `description`,
  },
  {
    Header: `Category`,
    id: `class.category.name`,
  },
  {
    Header: `Class`,
    id: `class.name`,
  },
  {
    Header: `Standard Price`,
    id: `standardPrice`,
    numeric: true,
  },
];

type EnhancedProps = {
  onPressProduct: (productID: string) => void;
  organisationID: string | null | undefined;
  className?: string;
};

type Props = EnhancedProps & {
  openSnackbar: OpenSnackbarFn;
  selection: WithSelectionSelection | null | undefined;
};

export class ProductTable extends React.Component<Props> {
  render() {
    const {
      onPressProduct,
      openSnackbar,
      organisationID,
      selection,
      className,
    } = this.props;

    const activeMarketplaceID =
      selection && selection.activeMarketplaceID
        ? selection.activeMarketplaceID
        : null;

    const columnsWithActions = [
      ...columns,
      {
        Header: "More",
        id: `more`,
        disableSortable: true,
        compact: true,
        Cell: ({ row }) => (
          <IconButton
            title={"More Details"}
            onClick={(e) => {
              e.stopPropagation();
              onPressProduct(row.id);
            }}
          >
            <MoreHoriz />
          </IconButton>
        ),
      },
    ];

    return (
      // @ts-ignore
      <Query
        query={GET_OWN_ORGANISATION_PRODUCTS_OVERVIEW}
        variables={{
          marketplaceID: activeMarketplaceID,
          organisationID: organisationID,
        }}
        skip={activeMarketplaceID == null}
      >
        {/* @ts-ignore */}
        {({ data, loading, error }) => {
          const products =
            !loading &&
            activeMarketplaceID != null &&
            !error &&
            data &&
            data.self &&
            data.self.organisation &&
            data.self.organisation.products
              ? data.self.organisation.products
              : null;
          const failed =
            activeMarketplaceID != null && (error || (!loading && !products));

          return (
            <React.Fragment>
              {failed && (
                <CallOnMount
                  callback={() =>
                    openSnackbar(SnackbarType.error, `Could not load products`)
                  }
                />
              )}
              <DataTable
                className={className}
                columns={columnsWithActions}
                data={products || []}
                loading={activeMarketplaceID == null ? false : loading}
                emptyMessage={
                  activeMarketplaceID == null
                    ? `No marketplace selected.`
                    : `No products found in marketplace.`
                }
                idAccessor="id"
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
const enhancer = compose<Partial<Props>, EnhancedProps>(
  withSelection as any,
  withOpenSnackbar
);
export default enhancer(ProductTable);

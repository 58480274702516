const DAYS = "days";
const DAY = "day";
export const DISPLAY_LEAD_TIME_EMPTY_STRING = "-";
export const displayLeadTime = (
  leadTime: number | null | undefined,
  emptyString = DISPLAY_LEAD_TIME_EMPTY_STRING
): string => {
  let result: string = emptyString;

  if (leadTime != null) {
    if (leadTime === 1) {
      result = `${leadTime} ${DAY}`;
    } else {
      result = `${leadTime} ${DAYS}`;
    }
  }

  return result;
};

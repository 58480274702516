import gql from "graphql-tag";
import { compose, withHandlers, withState } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import ProductSearchLayout, { productSearchLayoutFragments } from "./Layout";
import { ProductSearchTableCatalogueViewProduct as Product } from "./__generated__/ProductSearchTableCatalogueViewProduct";
import { ProductSearchDataGetCart_getSelf_shoppingCart } from "./__generated__/ProductSearchDataGetCart";
import { SubscribedSupplierList_getSelf_organisation_subscribedSupplierList } from "./__generated__/SubscribedSupplierList";

export const GET_CART = gql`
  query ProductSearchDataGetCart($organisationID: String!) {
    getSelf {
      id
      shoppingCart(organisationId: $organisationID) {
        id
        __typename
        productList {
          Product {
            id
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query ProductSearchDataGetProducts(
    $organisationID: String!
    $searchText: String
    $supplierID: String
  ) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        productListFromSubscribedCatalogueViews(
          searchText: $searchText
          supplierID: $supplierID
        ) {
          ...ProductSearchLayoutCatalogueViewProduct
        }
      }
    }
  }
  ${productSearchLayoutFragments.catalogueViewProduct}
`;

export const GET_SUPPLIER_LIST = gql`
  query SubscribedSupplierList($organisationID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        subscribedSupplierList {
          ...ProductSearchLayoutSupplier
        }
      }
    }
  }
  ${productSearchLayoutFragments.supplier}
`;

export type EnhancedProps = {
  onPressAddToCart: (productID: string) => void;
  onPressMoreDetails: (productID: string) => void;
  onNext: () => void;
  organisationID: string;
};

export type ProvidedProps = EnhancedProps & {
  loading: boolean;
  loadingCart: boolean;
  loadingSuppliers: boolean;
  products: Product[] | null | undefined;
  cart: ProductSearchDataGetCart_getSelf_shoppingCart | null | undefined;
  suppliers: SubscribedSupplierList_getSelf_organisation_subscribedSupplierList[];
  searchText: string;
  selectedSupplier: string; // is actually a supplierID
  setSearchText: (searchText: string) => void;
  setSelectedSupplier: (supplierID: string) => void;
  updateSearch: () => void;
};

const enhancer = compose<Partial<ProvidedProps>, EnhancedProps>(
  // The search text that is currently being queried (not always equal to the current search text)
  withState(`appliedSearchText`, `setAppliedSearchText`, ""),
  withState("selectedSupplier", "setSelectedSupplier", ""),
  withQuery(GET_CART, {
    dataPaths: {
      cart: `getSelf.shoppingCart`,
    },
    errorMessage: `Could not fetch cart`,
    loadingProp: `loadingCart`,
    errorOnNull: false, // null shopping cart means it hasn't been created
    options: ({ organisationID }) => ({
      variables: {
        organisationID,
      },
      fetchPolicy: `network-only`,
    }),
  }),
  withQuery(GET_SUPPLIER_LIST, {
    dataPaths: {
      suppliers: `getSelf.organisation.subscribedSupplierList`,
    },
    errorMessage: "Failed to fetch suppliers",
    loadingProp: "loadingSuppliers",
    options: ({ organisationID }) => ({
      variables: { organisationID },
      fetchPolicy: `network-only`,
    }),
    skip: ({ organisationID }) => !organisationID,
  }),
  withQuery(GET_PRODUCTS, {
    dataPaths: {
      products: `getSelf.organisation.productListFromSubscribedCatalogueViews`,
    },
    errorMessage: `Failed to search products`,
    loadingProp: `loading`,
    options: ({ organisationID, appliedSearchText, selectedSupplier }) => ({
      variables: {
        organisationID,
        searchText: appliedSearchText || null,
        supplierID: selectedSupplier || null,
      },
      fetchPolicy: `network-only`,
    }),
    skip: ({ appliedSearchText, selectedSupplier }) =>
      !appliedSearchText && !selectedSupplier,
  }),
  withState(`searchText`, `setSearchText`, ``), // The current search text input
  withHandlers({
    // Handler to apply the current search text
    updateSearch: ({ setAppliedSearchText, searchText }) => () => {
      const trimmedQuery = searchText.trim();
      if (trimmedQuery === ``) {
        setAppliedSearchText(null);
      } else {
        setAppliedSearchText(trimmedQuery);
      }
    },
  })
);

export default enhancer(ProductSearchLayout);

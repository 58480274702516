import * as React from "react";
import ConfirmDialog from "../../generic/ConfirmDialog";
import { compose, withPropsOnChange } from "recompose";

type Props = {
  // ID of marketplace to be deleted
  marketplaceID: string | null | undefined;
  name?: string;
  onAccept: (marketplaceID: string) => void;
  onClose: () => void;
};

class ConfirmDeleteMarketplace extends React.PureComponent<Props> {
  render() {
    const { marketplaceID, name, onAccept, onClose } = this.props;

    return (
      <ConfirmDialog
        severe
        open={marketplaceID != null}
        text={
          <React.Fragment>
            Do you really want to delete the marketplace
            <strong>{name ? ` ${name}` : ``}</strong>?
          </React.Fragment>
        }
        title="Delete Marketplace"
        onAccept={() => marketplaceID != null && onAccept(marketplaceID)}
        onClose={onClose}
      />
    );
  }
}

export default compose<Props, Props>(
  withPropsOnChange(
    // Update name only when marketplaceID is set
    (_prevProps, { marketplaceID }) => marketplaceID != null,
    ({ name }) => ({ name })
  )
)(ConfirmDeleteMarketplace);

import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import InlineTextDialog from "./InlineTextDialog";
import ItemList, { EnhancedProps as ItemListProps, Item } from "./ItemList";

export type Action = {
  perform: (itemID: string) => void;
  text: string;
};

export type Props = ItemListProps & {
  editingItemID: string | null | undefined;
  onCancelEditing: () => void;
  onCompleteEditing: (itemID: string | null, text: string) => void;
  onStartEditing: (itemID: string | null) => void;
};

type State = {
  editingText: string;
};

/**
 * HOC to make the text of rows in an ItemList editable
 * @param {React.ComponentType} ItemListComponent ItemList or compatible component.
 * @returns {React.ComponentType} New list component.
 */
export const withEditableRows = (
  ItemListComponent: React.ComponentType<ItemListProps>
) => {
  class ItemListWithEditableRows extends React.PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        editingText: ``,
      };
    }

    getEditingItem(): { index: number; item?: Item } {
      const { editingItemID, items } = this.props;
      const index = items.findIndex((item) => item.id === editingItemID);
      const item = index >= 0 ? items[index] : undefined;

      return {
        index,
        item,
      };
    }

    componentDidUpdate(prevProps: Props) {
      const { editingItemID } = this.props;
      if (editingItemID != null && prevProps.editingItemID !== editingItemID) {
        // Edited item changed, update state with initial text
        const { item } = this.getEditingItem();
        const text = item ? item.text : ``;
        this.setState({ editingText: text });
      }
    }

    handleClose(proceed?: boolean) {
      const {
        editingItemID,
        items,
        onCancelEditing,
        onCompleteEditing,
        onStartEditing,
      } = this.props;
      const { editingText } = this.state;

      if (editingItemID !== undefined) {
        const { item, index } = this.getEditingItem();
        const hasText = Boolean(editingText.trim());

        if (hasText && (!item || editingText !== item.text)) {
          onCompleteEditing(editingItemID, editingText);
        } else {
          onCancelEditing();
        }

        if (proceed) {
          if (index >= 0) {
            // Start editing the next item
            const nextIndex = index + 1;
            if (nextIndex < items.length) {
              onStartEditing(items[nextIndex].id);
            } else {
              // Start creating item if at last row
              onStartEditing(null);
            }
          } else if (hasText) {
            // Start creating another item
            onStartEditing(null);
          }
        }

        this.setState({ editingText: `` });
      }
    }

    renderEditDialog(itemID: string | null | undefined) {
      const { editingItemID } = this.props;
      const { editingText } = this.state;

      return (
        <InlineTextDialog
          value={editingText}
          open={itemID === editingItemID}
          onClose={this.handleClose.bind(this)}
          onChange={(value) => this.setState({ editingText: value })}
        />
      );
    }

    render() {
      const {
        listItemProps,
        onStartEditing,
        listProps,
        ...itemListProps
      } = this.props;

      const enhancedListProps = {
        ...listProps,
        onDoubleClick: () => onStartEditing(null),
      };

      const enhancedListItemProps = (item: Item) => ({
        ...(listItemProps && listItemProps(item)),
        onDoubleClick: (event) => {
          event.stopPropagation();
          onStartEditing(item.id);
        },
      });

      const enhancedRenderItemEnd = ({ item }: { item: Item }) =>
        this.renderEditDialog(item.id);
      const addItem = () => <ListItem>{this.renderEditDialog(null)}</ListItem>;

      return (
        <ItemListComponent
          listProps={enhancedListProps}
          listItemProps={enhancedListItemProps}
          renderItemEnd={enhancedRenderItemEnd}
          renderListEnd={addItem}
          {...itemListProps}
        />
      );
    }
  }

  return ItemListWithEditableRows;
};

export default withEditableRows(ItemList);

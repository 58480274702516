import * as React from "react";
import MaskedInput from "react-text-mask";

type Props = {
  inputRef: (ref: any) => any;
};

const TIME_PARTS = 2;
const TIME_LENGTH = 5;
const MAX_HOUR_TENS = 2;
const MAX_HOUR = 23;
export const TIME_PLACEHOLDER = `HH:MM`;

export const timePipe = (value: string) => {
  if (!value || value.length !== TIME_LENGTH) {
    return ``;
  }

  // Extract hour part from time
  const parts = value.split(`:`);
  if (parts.length !== TIME_PARTS) {
    return value;
  }
  const hour = parts[0].trim();

  // Autocorrect hour part
  if (hour.length > 0) {
    const number = Number(hour);

    // Auto insert 0 at start if otherwise invalid
    if (hour.length === 1 && number > MAX_HOUR_TENS) {
      return `0${hour}:${parts[1]}`;
    } else if (number > MAX_HOUR) {
      return false;
    }
  }

  return value;
};

const TimeInput = ({ inputRef, ...other }: Props) => (
  <MaskedInput
    {...other}
    guide
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/\d/, /\d/, `:`, /[0-5]/, /\d/]}
    placeholderChar={`\u2000`}
    placeholder={TIME_PLACEHOLDER}
    pipe={timePipe}
  />
);

export default TimeInput;

import gql from "graphql-tag";
import { compose } from "recompose";
import withQuery from "../../generic/graphql/withQuery";
import withMutation from "../../generic/graphql/withMutation";
import OrderDetailsDialog, {
  orderDetailsFragments,
} from "./OrderDetailsDialog";
import { GET_ORDERS } from "./OrderTableData";
import { DEFAULT_POLL_INTERVAL } from "../../../../helpers/constants";

export const GET_ORDER = gql`
  query OrderDetailsGetOrder($organisationID: String!, $orderID: String!) {
    getSelf {
      id
      organisation(id: $organisationID) {
        id
        purchasedOrder(id: $orderID) {
          id
          ...OrderDetailsDialogOrder
        }
      }
    }
  }
  ${orderDetailsFragments.order}
`;

const UPDATE_SHIPPING_BATCH = gql`
  mutation PurchasedOrderDetailsDataUpdateShippingBatch(
    $id: String!
    $status: ShippingBatchStatus!
  ) {
    updateShippingBatch(id: $id, values: { status: $status }) {
      id
      status
    }
  }
`;

type EnhancedProps = {
  open: boolean;
  organisationID: string;
  orderID: string | null | undefined;
  onClose: () => void;
};

const enhancer = compose<any, EnhancedProps>(
  withQuery(GET_ORDER, {
    dataPaths: {
      order: `getSelf.organisation.purchasedOrder`,
    },
    errorMessage: `Failed to load order`,
    loadingProp: `loading`,
    options: ({ organisationID, orderID }) => ({
      variables: {
        organisationID,
        orderID,
      },
      fetchPolicy: `network-only`,
      pollInterval: DEFAULT_POLL_INTERVAL,
    }),
    skip: ({ organisationID, orderID }) =>
      orderID == null || organisationID == null,
  }),
  withMutation(UPDATE_SHIPPING_BATCH, {
    mapMutationToProps: (updateShippingBatchStatus) => ({
      updateShippingBatchStatus,
    }),
    errorMessage: `Failed to update status of shipping batch`,
    mutationOptions: (
      { organisationID, orderID },
      [shippingBatchID, status]
    ) => ({
      variables: {
        id: shippingBatchID,
        status,
      },
      refetchQueries: [
        {
          query: GET_ORDER,
          variables: {
            organisationID,
            orderID,
          },
        },
        {
          query: GET_ORDERS,
          variables: {
            organisationID,
          },
        },
      ],
      optimisticResponse: {
        updateShippingBatch: {
          __typename: `ShippingBatch`,
          id: `batch id`,
          status: `status`,
        },
      },
    }),
    successMessage: `Status updated`,
  })
);
export default enhancer(OrderDetailsDialog);

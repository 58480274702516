import * as React from "react";
import { Form, Formik, FormikBag } from "formik";
import { compose, defaultProps } from "recompose";
import Button from "@material-ui/core/Button";
import withOpenSnackbar, {
  OpenSnackbarFn,
} from "../../generic/snackbar/withOpenSnackbar";
import LoadingScreen from "../../generic/LoadingScreen";
import CallOnMount from "../../generic/CallOnMount";
import CallOnChange from "../../generic/CallOnChange";
import { omit } from "lodash";
import { SnackbarType } from "../../../../__generated__/globalTypes";
import { Close, Save } from "@material-ui/icons";
import AlertDialog from "../AlertDialog";

export type EnhancedProps<V> = {
  SubmitContainer?: React.ElementType;
  TitleComponent?: React.ElementType;
  children:
    | React.ReactNode
    | ((formikBag: FormikBag<any, any>) => React.ReactNode);
  createMutation?: (values: V) => Promise<void>;
  disabled?: boolean;
  item: V;
  itemID?: string;
  // Name of property from form values to get a name for the item
  itemNameAccessor?: string;
  // String for item type to display in messages
  itemType: string;
  // String for item type to display in the title
  itemTypeTitle?: string;
  // Optional custom success message based on mutation result and form values
  successMessage?: (result: any, name: string, formValues: any) => string;
  loadItemError?: Error | null | undefined;
  loadingItem?: boolean;
  onDirtyChange?: (dirty: boolean) => void;
  onDismiss?: (force?: boolean, result?: any) => void;
  schema?: any;
  updateMutation?: (
    values: V & {
      id: string;
    }
  ) => Promise<void>;
  alert?: {
    body: string;
    buttonText: string;
    title: string;
  };
};

type Props<V> = EnhancedProps<V> & {
  SubmitContainer: React.ElementType;
  TitleComponent: React.ElementType;
  openSnackbar: OpenSnackbarFn;
};

/**
 * Generic form component that submits with a update or create mutation.
 */
export class CreateUpdateForm<V extends any> extends React.PureComponent<
  Props<V>,
  { alertOpen: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
    };
  }
  getItemLabel(values?: V) {
    const { itemNameAccessor, itemType } = this.props;
    const name = values && values[itemNameAccessor];

    return name ? `${itemType} "${name}"` : itemType;
  }

  getTitle(initialValues?: V) {
    const { itemNameAccessor, itemTypeTitle, itemID } = this.props;
    const name = initialValues && initialValues[itemNameAccessor];
    const action = itemID ? `Edit` : `Create`;

    return `${action} ${itemTypeTitle || ``}${name ? ` :: ${name}` : ``}`;
  }

  openAlert = () => {
    this.setState({
      alertOpen: true,
    });
  };

  closeAlert = () => {
    this.setState({
      alertOpen: false,
    });
  };

  _handleSubmit = async (values: V, { setSubmitting }: FormikBag<V, any>) => {
    const {
      createMutation,
      itemID,
      updateMutation,
      successMessage,
      openSnackbar,
      onDismiss,
    } = this.props;

    try {
      // Create or update
      let result = null;
      if (itemID == null && createMutation) {
        // @ts-ignore
        result = await createMutation(omit(values, [`id`]));
      } else if (updateMutation) {
        result = await updateMutation({
          // @ts-ignore
          ...values,
          id: itemID,
        });
      }
      this.openAlert();
      // Show snackbar and close form on success
      const itemName = this.getItemLabel(values);
      const message = successMessage
        ? successMessage(result, itemName, values)
        : `Successfully saved ${itemName}`;
      openSnackbar(SnackbarType.success, message);
      if (onDismiss) {
        onDismiss(true, result);
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      openSnackbar(
        SnackbarType.error,
        `Failed to save ${this.getItemLabel(values)}`
      );
      setSubmitting(false);
    }
  };

  componentDidMount(): void {
    // Initially not dirty
    const { onDirtyChange } = this.props;
    if (onDirtyChange) {
      onDirtyChange(false);
    }
  }

  handleError(): void {
    const { onDismiss, openSnackbar } = this.props;

    openSnackbar(SnackbarType.error, `Failed to load ${this.getItemLabel()}`);
    if (onDismiss) {
      onDismiss();
    }
  }

  render(): JSX.Element {
    const {
      SubmitContainer,
      TitleComponent,
      children,
      disabled,
      itemID,
      loadItemError,
      item,
      itemTypeTitle,
      loadingItem,
      schema,
      onDismiss,
      onDirtyChange,
      alert,
    } = this.props;
    const error = loadItemError || (itemID && !loadingItem && !item);
    const { alertOpen } = this.state;

    return (
      <>
        {itemTypeTitle && (
          <TitleComponent>{this.getTitle(item)}</TitleComponent>
        )}
        {loadingItem && <LoadingScreen />}
        {error && <CallOnMount callback={this.handleError.bind(this)} />}
        {!loadingItem && !error && (
          <Formik
            initialValues={item}
            validationSchema={schema}
            onSubmit={this._handleSubmit}
          >
            {(formikBag) => (
              <Form>
                {typeof children === `function`
                  ? // @ts-ignore
                    children(formikBag)
                  : children}
                <SubmitContainer>
                  {onDismiss && (
                    <Button onClick={() => onDismiss()} startIcon={<Close />}>
                      Cancel
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    variant={"contained"}
                    startIcon={<Save />}
                    disabled={
                      disabled || formikBag.isSubmitting || !formikBag.dirty
                    }
                  >
                    {itemID == null ? `Save` : `Update`}
                  </Button>
                </SubmitContainer>
                <CallOnChange
                  value={formikBag.dirty}
                  onChange={onDirtyChange}
                />
              </Form>
            )}
          </Formik>
        )}
        {alert && (
          <AlertDialog
            open={alertOpen}
            handleClose={this.closeAlert}
            body={alert.body}
            buttonText={alert.buttonText}
            title={alert.title}
          />
        )}
      </>
    );
  }
}

const enhancer = compose<Partial<Props<any>>, EnhancedProps<any>>(
  withOpenSnackbar,
  defaultProps({
    SubmitContainer: `div`,
    TitleComponent: `h1`,
  })
);

export default enhancer(CreateUpdateForm);

import * as React from "react";
import * as H from "history";
import { Link, match, Route } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import MainPanel from "../../components/app/MainPanel";
import FormDialog from "../../components/generic/FormDialog";
import FloatingActionButton from "../../components/generic/FloatingActionButton";
import AddressTableData from "../../components/trading/address/AddressTableData";
import AddressForm from "../../components/trading/address/AddressForm";
import {
  addressesHoursRoute,
  addressesRoute,
  addressFormRoute,
  ROUTE_PARAMETERS,
} from "../index";
import OperationHoursData from "../../components/address/OperationHoursData";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  history: H.History;
  match: match<MatchProps>;
};

class Address extends React.PureComponent<Props> {
  render() {
    const { history, match } = this.props;
    const { organisationID } = match.params;

    if (organisationID == null) {
      return null;
    }

    return (
      <MainPanel title="Addresses">
        <AddressTableData
          organisationID={organisationID}
          onPressEdit={(addressID) =>
            history.push(addressFormRoute(organisationID, addressID))
          }
          onPressEditOperatingTimes={(addressID) => {
            history.push(addressesHoursRoute(organisationID, addressID));
          }}
        />
        <Route
          path={addressFormRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            `${ROUTE_PARAMETERS.ADDRESS_ID}?`
          )}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.addressID != null
                    ? formMatch.params.addressID
                    : undefined,
              }}
              onClose={() => history.push(addressesRoute(organisationID))}
            >
              {({ handleClose, ...rest }) => (
                <AddressForm
                  organisationID={organisationID || ``}
                  onDismiss={handleClose}
                  {...rest}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <Route
          path={addressesHoursRoute(
            ROUTE_PARAMETERS.ORGANISATION_ID,
            ROUTE_PARAMETERS.ADDRESS_ID
          )}
        >
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.addressID != null
                    ? formMatch.params.addressID
                    : undefined,
              }}
              onClose={() => history.push(addressesRoute(organisationID))}
            >
              {({ handleClose, ...rest }) => (
                <OperationHoursData
                  organisationId={organisationID || ``}
                  onDismiss={handleClose}
                  {...rest}
                  addressId={formMatch && formMatch.params.addressID}
                />
              )}
            </FormDialog>
          )}
        </Route>
        <FloatingActionButton
          component={Link}
          // @ts-ignore
          to={addressFormRoute(organisationID)}
        >
          <AddIcon />
        </FloatingActionButton>
      </MainPanel>
    );
  }
}

export default Address;

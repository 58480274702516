import gql from "graphql-tag";
import { compose, withState } from "recompose";
import AddSubscribedOrganisationsDialogLayout, {
  addSubscribedOrganisationDialogLayoutFragments,
} from "./AddSubscribedOrganisationsDialogLayout";
import { AddSubscribedOrganisationDialogPartialOrganisation } from "./__generated__/AddSubscribedOrganisationDialogPartialOrganisation";

export const addSubscribedOrganisationDialogDataFragments = {
  partialOrganisation: gql`
    fragment AddSubscribedOrganisationDialogPartialOrganisation on PartialOrganisation {
      id
      ...AddSubscribedOrganisationDialogLayoutFragmentPartialOrganisation
    }
    ${addSubscribedOrganisationDialogLayoutFragments.partialOrganisation}
  `,
};

export type EnhancedProps = {
  availableItems: AddSubscribedOrganisationDialogPartialOrganisation[];
  availableItemsLoading: boolean;
  catalogueViewID: string;
  onActivatePressed: () => void;
  onClose: () => void;
  open: boolean;
  setSubscribingOrganisationID: (selectIds: string[]) => void;
  subscribingOrganisationID: string[];
};

type Props = EnhancedProps & {
  inviteDialogOpen: boolean;
  setInviteDialogOpen: (open: boolean) => void;
};

const enhancer = compose<Props, EnhancedProps>(
  withState(`inviteDialogOpen`, `setInviteDialogOpen`, false)
);

export default enhancer(AddSubscribedOrganisationsDialogLayout);

import * as React from "react";
import { match, Redirect } from "react-router-dom";
import { addressesRoute, dashboardRoute } from "../index";

type MatchProps = {
  organisationID?: string;
};

type Props = {
  match: match<MatchProps>;
};

const Logistics = function (props: Props) {
  const { organisationID } = props.match.params;

  if (organisationID == null) {
    return <Redirect to={dashboardRoute()} />;
  }

  return <Redirect to={addressesRoute(organisationID)} />;
};

export default Logistics;

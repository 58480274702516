import * as React from "react";
import * as H from "history";
import MainPanel from "../../components/app/MainPanel";
import UserMangementData from "../../components/admin/user/UserManagementData";
import { Route } from "react-router-dom";
import UserRoleForm from "../../components/admin/user/UserRoleForm";
import FormDialog from "../../components/generic/FormDialog";
import {
  adminUsersRoleFormRoute,
  adminUsersRoute,
  ROUTE_PARAMETERS,
} from "../index";

type Props = {
  history: H.History;
};

export class UserManagement extends React.PureComponent<Props> {
  render() {
    const { history } = this.props;

    return (
      <MainPanel fullWidth title="User Management">
        <UserMangementData
          onPressEdit={(userID) =>
            history.push(adminUsersRoleFormRoute(userID))
          }
        />
        <Route path={adminUsersRoleFormRoute(ROUTE_PARAMETERS.USER_ID)}>
          {({ match: formMatch }) => (
            <FormDialog
              open={formMatch != null}
              childProps={{
                itemID:
                  formMatch && formMatch.params.userID != null
                    ? formMatch.params.userID
                    : undefined,
              }}
              onClose={() => history.push(adminUsersRoute())}
            >
              {({ handleClose, ...rest }) => (
                <UserRoleForm
                  itemID={(rest as any).itemID}
                  onDismiss={handleClose}
                  {...rest}
                />
              )}
            </FormDialog>
          )}
        </Route>
      </MainPanel>
    );
  }
}

export default UserManagement;

import * as React from "react";
import MainPanel from "../../components/app/MainPanel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { loginRoute } from "../index";

class Unauthenticated extends React.Component {
  render() {
    return (
      <MainPanel title="Unauthenticated">
        <Typography>An error occurred while logging you in.</Typography>
        <Button component={Link} to={loginRoute()}>
          Try again
        </Button>
      </MainPanel>
    );
  }
}

export default Unauthenticated;
